import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import "./AllChefs.scss";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import ChefCard from "../../../components/chef-card/ChefCard";
import { STRINGS } from "../../../core/enums/strings";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../shared/store";
import { getChefList } from "../../../shared/store/slices/Chefs/ChefsService";
import { ChefHomeType } from "../../../core/models/Chef";
import EmptyItem from "../../../components/empty-item/EmptyItem";
import sun from "../../../assets/icons/Découvrez FR.png";
import sun2 from "../../../assets/icons/Discover EN.svg";
import emptyChef from "../../../assets/images/chef_empty.png";
import { setBackRoot } from "../../../shared/store/slices/Root/rootSlice";
import {t} from "i18next";
import {isMobile} from "../../../shared/utils/utils-functions";

/* eslint-disable max-lines-per-function */

// eslint-disable-next-line sonarjs/cognitive-complexity
const AllChef = (): JSX.Element => {
  const [isNextPage, setNextPage] = useState(false);
  const currentPage = useRef(0);
  const [chefList, setChefList] = useState<ChefHomeType[]>([]);
  const isLoading = useSelector((state: RootState) => state.root.isLoading);
    const [isLoadApi,setIsLoadApi]=useState<any>(false)

    const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setBackRoot(undefined));
    getData();
  }, []);
  const getData = async () => {
    currentPage.current += 1;
    await getChefList(1).then((res) => {
        setIsLoadApi(true)

        setNextPage(res.data.list.length === 10);
      setChefList(
          currentPage.current === 1
              ? [...res.data.list]
              : [...chefList, ...res.data.list]
      );
    });
  };
    const [language,setLanguage]=useState<any>()
    useEffect(()=>{
        setLanguage(localStorage.getItem('SUPERNOVA_LANGUAGE'))
    },[])
    const [scrollY, setScrollY] = useState(0);

    useLayoutEffect(() => {
        const handleScroll = () => {
            setScrollY(Math.min(0, window.scrollY / 3 - 60) - 10);
        }
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    }, [scrollY])

  return (
      <div className="chefs-page">
          <Header classname={ scrollY === 0 || scrollY < -50 ? '' : 'home-header'} isHome={true} isHomeActive={true} />
        <div className="top-page">
          <div className="container-desk center-horizontally space-between align-center header-page">
            <div className="center-horizontally align-center title-chef">
              <div className="blc-img">
                  {
                      language === 'fr' ?<img src={sun} className="round" style={{height:'unset'}}/> :
                          <img src={sun2} className="round" style={{height:'unset'}}/>

                  }
              </div>
              <h1 style={{position:'relative',top:isMobile()?'':'10px'}}>{t('titleTousLesChefs')}</h1>
            </div>
            <p>{t('titleSupernovaDetails')}</p>
          </div>
        </div>
        { isLoadApi && chefList.length === 0  && (
            <EmptyItem center={true} img={emptyChef}>
              <p className="empty-content">{t('noChefs')}</p>
            </EmptyItem>
        )}
        <div className="list-chef center-horizontally">
          {chefList?.map((chef:any) => {
            return (
                <ChefCard
                    isChef={true}
                    key={chef.id}
                    idCat={chef.id}
                    isHome={false}
                    item={chef}
                    number={chef.restaurant.length}
                    image={
                        chef?.image_profile?.find((item: any) => item.type === "4:3")
                            ?.url as string
                    }
                    isGray={
                        chef?.image_profile?.find((item: any) => item.type === "4:3")
                            ?.is_grey}
                    active_option={'image'}
                />

            );
          })}
        </div>

        {isNextPage && (
            <button onClick={getData} className="btn-primary">
              {STRINGS.SHOW_MORE}
            </button>
        )}
          {isMobile() &&           <div style={{height:'100px'}}/>  }

          <Footer  quantity={0} isHome={true} priceOrder={0} />
      </div>
  );
};
/* eslint-enable max-lines-per-function */

export default AllChef;
