import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {Address} from "../../../../core/models/Address";
export interface BackRootModel {
  idResto?: number,
  idChef?: number,
  idPlate?: number,
  itemResto?: any,
  item?: any,
  path?: string
  isFromDish?:any
}
export interface RootState {
  isMobile: boolean,
  DEVICE_WIDTH: number,
  isLoading: boolean,
  address_name:any,
  selected_address: Address | undefined,
  isEditAddress: boolean,
  backRootData: BackRootModel | undefined,
  isBackFromAddress: boolean,
  RE_CORRECT: number
  isFromMap:any

}

export const rootInitialState: RootState = {
  isMobile: window.screen.width <= 767,
  DEVICE_WIDTH: window.screen.width,
  isLoading: false,
  address_name:null,
  selected_address: undefined,
  isEditAddress: false,
  backRootData: undefined,
  isBackFromAddress: false,
  RE_CORRECT: 0,
  isFromMap:false


}

export const rootSlice = createSlice({
  name: 'root',
  initialState: rootInitialState,
  reducers: {
    setDeviceWidth: (state, action: PayloadAction<number>): void => {
      state.DEVICE_WIDTH = action.payload;
    },
    setRootLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setAddressName: (state, action: PayloadAction<any>): void => {
      state.address_name = action.payload;
    },
    setSelectedAddress: (state, action: PayloadAction<Address | undefined>): void => {
      state.selected_address = action.payload;
    },
    setIsEditAddress: (state, action: PayloadAction<boolean>): void => {
      state.isEditAddress = action.payload;
    },
    setBackRoot: (state, action: PayloadAction<BackRootModel | undefined>): void => {
      state.backRootData = action.payload;
    },
    setIsBackFromAddress: (state, action: PayloadAction<boolean>): void => {
      state.isBackFromAddress = action.payload;
    },
    setReCorrect: (state): void => {
      state.RE_CORRECT += 1;
    },
    setIsFromMap: (state, action: PayloadAction<boolean>): void => {
      state.isFromMap = action.payload;
    }
  }
})

export const {setIsFromMap,setAddressName, setDeviceWidth, setRootLoading, setSelectedAddress, setIsEditAddress, setBackRoot, setIsBackFromAddress, setReCorrect} = rootSlice.actions;
