import React, {useRef} from "react";
import './desireAndDietItem.scss'
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setBackRoot} from "../../../../../../shared/store/slices/Root/rootSlice";
import {SubCategoryInterface} from "../../../../../../shared/store/slices/explore/exploreSlice";
import Parallax from "../../../../../../components/parallex/parallax";
import {motion} from "framer-motion";
import ImageContainer from "../../../../../../components/imageContainer/imageContainer";

const DesireAndDietItem: React.FC<{
  item: SubCategoryInterface,
// eslint-disable-next-line max-lines-per-function
}> = ({item}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const goTo = () => {
    const path = `/new-chef/subcategory/${item?.id}`;
    const state = {
      from: location.pathname,
      state: {id: 1},
    }
    dispatch(setBackRoot({path: location.pathname, ...state.state}))
    navigate(path, state);
  }

  const getSrc = () => {
    return item?.image?.find(item => item?.type === '4:3') || item?.image[0]
  }

  const clickData = useRef({
    x: NaN, y: NaN
  })
  const mouseDown = (e: React.MouseEvent) => {
    clickData.current = {x: e.pageX, y: e.pageY}
  }

  const mouseUp = (e: React.MouseEvent) => {
    if (
      (e.pageX >= clickData.current.x - 6 && e.pageX <= clickData.current.x + 6) &&
      (e.pageY >= clickData.current.y - 6 && e.pageY <= clickData.current.y + 6)
    ) {
      goTo()
    }
  }
  const MotionComponent = motion(ImageContainer)
  return (
    <div
      onMouseDown={mouseDown}
      onMouseUp={mouseUp}
      // style={{
      //   backgroundImage: `url(${getSrc().url})`
      // }}
      className={'desireAndDietItem'}
    >
      <Parallax>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/*@ts-ignore*/}
        <MotionComponent
          draggable={false} src={getSrc().url} alt=""/>
      </Parallax>
      {getSrc().is_grey && <div className={'rootMask'}/>}
      <span>{item.name}</span>
    </div>
  )
}

export default DesireAndDietItem
