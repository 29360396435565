import React, {useEffect, useState} from "react";
import './destinations.scss'
import DestinationItem from "./destinationItem/destinationItem";
import {CategoryInterface, SubCategoryInterface} from "../../../../../shared/store/slices/explore/exploreSlice";
import {isMobile} from "../../../../../shared/utils/utils-functions";

// eslint-disable-next-line max-lines-per-function
const Destinations: React.FC<{ showAll?: Array<SubCategoryInterface>, data?: CategoryInterface }> = ({
                                                                                                       showAll,
                                                                                                       data
                                                                                                     }) => {
  return (
    <div className={'destinationsMainContainer'}>
      {
        isMobile() ?  data?.subCategories?.slice(0,6).map((item: SubCategoryInterface, index: number) => {
                return (
                    <DestinationItem key={index} item={item}/>
                )
            }
        ) :
            // eslint-disable-next-line sonarjs/no-identical-functions
            data?.subCategories?.slice(0,12).map((item: SubCategoryInterface, index: number) => {
                    return (
                        <DestinationItem key={index} item={item}/>
                    )
                }
            )
      }
    </div>
  )
}

export default Destinations;
