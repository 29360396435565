/***
 * Excel Sheet Link: https://docs.google.com/spreadsheets/d/1O86N8BbhZZVAhpq_77iJ_uX-b30oJ-3F1jOENXi78NE/edit
 */

/* eslint-disable */
export const languages =
    {
            "NOEX_common": {
                "FR": null,
                "EN": null
            },
            "kitchen_one_star": {
                "FR": "Étoile",
                "EN": "Star"
            },
            "calendarValidateBtn": {
                "FR": "confirmer ma date de livraison",
                "EN": "confirm the delivery date"
            },
            "newBasket": {
                "FR": "Nouveau panier",
                "EN": "New shopping bag"
            },
            "yourBasketIncludesAnotherChefPlate": {
                "FR": "VOTRE PANIER CONTIENT DÉJÀ UN PLAT D'UN AUTRE CHEF. VOULEZ-VOUS VIDER VOTRE PANIER ET AJOUTER CE PLAT À LA PLACE ?",
                "EN": "YOUR SHOPPING BAG ALREADY CONTAINS A COURSE BY ANOTHER CHEF. DO YOU WANT TO EMPTY YOUR SHOPPING BAG AND ADD THIS COURSE INSTEAD?"
            },
            "pickNumberPlaceholder": {
                "FR": "Choisir Numéro",
                "EN": "Choose a number"
            },
            "agreeSignUpStep3": {
                "FR": "DE suppernova",
                "EN": "by suppernova"
            },
            "agreeSignUpStep2": {
                "FR": "Et je confirme avoir lu",
                "EN": "And I confirm that I have read"
            },
            "agreeSignUpStep1": {
                "FR": "j'accepte",
                "EN": "I accept"
            },
            "insertCodeInMail": {
                "FR": "Saisissez le code envoyé par mail",
                "EN": "Enter the code sent by email"
            },
            "withMail": {
                "FR": "par mail",
                "EN": "by email"
            },
            "agreeSignUpStep4": {
                "FR": "je souhaite recevoir des communications commerciales personnalisées de la part de suppernova",
                "EN": "I would like to receive personalized commercial communications from suppernova"
            },
            "newOrder": {
                "FR": "Nouvelle commande",
                "EN": "New order"
            },
            "ustensils": {
                "FR": "ustensiles",
                "EN": "cooking tools"
            },
            "payerButton": {
                "FR": "Payer",
                "EN": "Pay"
            },
            "france": {
                "FR": "Payer",
                "EN": "FRANCE"
            },
            "address": {
                "FR": "Adresse",
                "EN": "Address"
            },
            "return": {
                "FR": "RETOUR",
                "EN": "BACK"
            },
            "send": {
                "FR": "Envoyer",
                "EN": "Send"
            },
            "sended": {
                "FR": "ENVOYÉE",
                "EN": "Sent"
            },
            "manageThe": {
                "FR": "Gérer les",
                "EN": "Manage the"
            },
            "discoverHome": {
                "FR": "Commander",
                "EN": "Ordre"
            },
            "discover": {
                "FR": "Découvrir",
                "EN": "Discover"
            },
            "articleSing": {
                "FR": "Article",
                "EN": "Item"
            },
            "article": {
                "FR": "Articles",
                "EN": "Items"
            },
            "yours": {
                "FR": "votre",
                "EN": "your"
            },
            "seeAll": {
                "FR": "voir plus",
                "EN": "see all"
            },
            "restaurants": {
                "FR": "restaurants",
                "EN": "restaurants"
            },
            "continue": {
                "FR": "Continuer",
                "EN": "Continue"
            },
            "toPrepare": {
                "FR": "EN PRÉPARATION",
                "EN": "BEING PREPARED"
            },
            "waiting": {
                "FR": "EN ATTENTE",
                "EN": "PENDING"
            },
            "allSent": {
                "FR": "ENVOYÉES",
                "EN": "SENT"
            },
            "sent": {
                "FR": "Envoyée",
                "EN": "Sent"
            },
            "readMore": {
                "FR": "lire la suite",
                "EN": "read more"
            },
            "registerPageMaintenance": {
                "FR": "SUBSCRIBE",
                "EN": "SUBSCRIBE"
            },
            "showLess": {
                "FR": "Afficher moins",
                "EN": "Show less"
            },
            "finshed": {
                "FR": "TERMINÉES",
                "EN": "DISPATCHED"
            },
            "canceled": {
                "FR": "ANNULÉES",
                "EN": "CANCELED"
            },
            "noResult": {
                "FR": "pas de résultat",
                "EN": "no results"
            },
            "titleSupernovaCategoryDetails": {
                "FR": "PLONGEZ ICI DANS L'UNIVERS DES PLUS GRANDS CHEFS INTERNATIONAUX, ET COMMANDEZ LES CRÉATIONS DE CEUX QUI INVENTENT LA GASTRONOMIE D'AUJOURD'HUI ET DE DEMAIN.",
                "EN": "DIVE HERE INTO THE UNIVERSE OF THE GREATEST INTERNATIONAL CHEFS, AND ORDER THE CREATIONS OF THOSE WHO INVENT THE GASTRONOMY OF TODAY AND TOMORROW"
            },
            "titleSupernovaDetails": {
                "FR": "DÉCOUVREZ PROCHAINEMENT ICI LES PLUS GRANDS CHEFS INTERNATIONAUX, ET COMMANDEZ LES CRÉATIONS DE CEUX QUI INVENTENT LA GASTRONOMIE D'AUJOURD'HUI ET DE DEMAIN.",
                "EN": "ALL THE CHEFS: DISCOVER SOON HERE THE GREATEST INTERNATIONAL CHEFS, AND ORDER THE CREATIONS OF THOSE WHO INVENT THE GASTRONOMY OF TODAY AND TOMORROW."
            },
            "confirmed": {
                "FR": "confirmer",
                "EN": "confirm"
            },
            "seeEverything": {
                "FR": "voir tout",
                "EN": "see all"
            },
            "accept": {
                "FR": "accepter",
                "EN": "accept"
            },
            "without": {
                "FR": "sans",
                "EN": "without"
            },
            "showMore": {
                "FR": "Afficher plus",
                "EN": "Show more"
            },
            "buyButton": {
                "FR": "Acheter",
                "EN": "Buy"
            },
            "appearsIn": {
                "FR": "Apparait dans",
                "EN": "Also in"
            },
            "inTheCart": {
                "FR": "Au panier",
                "EN": "In the cart"
            },
            "menuIn": {
                "FR": "Menu En",
                "EN": "Menu In"
            },
            "service": {
                "FR": "service",
                "EN": "course"
            },
            "services": {
                "FR": "services",
                "EN": "courses"
            },
            "amount": {
                "FR": "Montant",
                "EN": "Amount"
            },
            "DeliveryNotAvailable": {
                "FR": "Non disponible",
                "EN": "Not available"
            },
            "modifierAdressBasket": {
                "FR": "Veuillez changer votre adresse de livraison",
                "EN": "Please change your delivery address"
            },
            "addAdressBasket": {
                "FR": "Veuillez ajouter votre adresse de livraison",
                "EN": "Please add your delivery address"
            },
            "usedSocialMedia": {
                "FR": "Cet email est déjà utilisé par les réseaux sociaux",
                "EN": "This email is already used by social networks"
            },
            "obligationMail": {
                "FR": "Veuillez ajouter une adresse email",
                "EN": "Please add an email address"
            },
            "dateBasketNotFound": {
                "FR": "date non disponible",
                "EN": "date not available"
            },
            "guarantedDelivryBasket": {
                "FR": "CHOISIR VOTRE DATE DE LIVRAISON",
                "EN": "CHOOSE YOUR DELIVERY DATE"
            },
            "guarantedDelivryFrom": {
                "FR": "Livraison possible dès le",
                "EN": "Possible delivery on the"
            },
            "guaranteedDeliveryOn": {
                "FR": "Livraison garantie le",
                "EN": "Delivery on the"
            },
            "includedPlates": {
                "FR": "Les Plats inclus",
                "EN": "Included Dishes"
            },
            "otherMenusSameChef": {
                "FR": "Autres menus du même chef",
                "EN": "Other menus from the same chef"
            },
            "otherPlatesSameChef": {
                "FR": "Autres plats du même chef",
                "EN": "Other creations from the same chef"
            },
            "createPasswordTitle": {
                "FR": "CRÉER VOTRE",
                "EN": "CREATE YOUR"
            },
            "edit": {
                "FR": "Modifier",
                "EN": "Edit"
            },
            "updateButton": {
                "FR": "Mettre à jour",
                "EN": "Update"
            },
            "newsletter": {
                "FR": "newsletter",
                "EN": "newsletter"
            },
            "thanks": {
                "FR": "merci",
                "EN": "thank you"
            },
            "noData": {
                "FR": "Pas de donnée",
                "EN": "No data"
            },
            "save": {
                "FR": "Enregistrer",
                "EN": "Save"
            },
            "validate": {
                "FR": "Valider",
                "EN": "Confirm"
            },
            "step": {
                "FR": "ÉTAPE",
                "EN": "STEP"
            },
            "validateAndContinue": {
                "FR": "Valider et continuer",
                "EN": "Confirm and continue"
            },
            "createMyAccount": {
                "FR": "Créer mon compte",
                "EN": "Create my account"
            },
            "close": {
                "FR": "fermer",
                "EN": "close"
            },
            "add": {
                "FR": "ajouter",
                "EN": "add"
            },
            "editer": {
                "FR": "editer",
                "EN": "edit"
            },
            "NOEX_splash screen": {
                "FR": null,
                "EN": null
            },
            "splashScreenLoader": {
                "FR": "Chargement",
                "EN": "Loading"
            },
            "NOEX_maintenance": {
                "FR": null,
                "EN": null
            },
            "dishMoreDetatail": {
                "FR": "plat",
                "EN": "dish"
            },
            "days": {
                "FR": "Days",
                "EN": "Days"
            },
            "hours": {
                "FR": "Hours",
                "EN": "Hours"
            },
            "minutes": {
                "FR": "Minutes",
                "EN": "Minutes"
            },
            "seconds": {
                "FR": "Seconds",
                "EN": "Seconds"
            },
            "aNewTable": {
                "FR": "A new table",
                "EN": "A new table"
            },
            "isComing": {
                "FR": "is coming",
                "EN": "is coming"
            },
            "register": {
                "FR": "S'inscrire",
                "EN": "Register"
            },
            "NOEX_form rules": {
                "FR": null,
                "EN": null
            },
            "emailMustBeValid": {
                "FR": "L'EMAIL DOIT ÊTRE VALIDE",
                "EN": "EMAIL MUST BE VALID"
            },
            "fieldIsRequired": {
                "FR": "CE CHAMP EST OBLIGATOIRE",
                "EN": "THIS FIELD IS REQUIRED"
            },
            "maximum": {
                "FR": "MAXIMUM",
                "EN": "MAXIMUM"
            },
            "minimum": {
                "FR": "MINIMUM",
                "EN": "MINIMUM"
            },
            "characters": {
                "FR": "CARACTÈRES",
                "EN": "CHARACTERS"
            },
            "invalidPhoneNumber": {
                "FR": "NUMÉRO DE TÉLÉPHONE INVALIDE",
                "EN": "INVALID PHONE NUMBER"
            },
            "fieldError": {
                "FR": "CHAMP INCORRECT",
                "EN": "INCORRECT FIELD"
            },
            "verificationCodeError": {
                "FR": "VÉRIFICARTION CODE INCORRECT",
                "EN": "INCORRECT CODE VERIFICATION"
            },
            "passwordsNotSimilar": {
                "FR": "MOT DE PASSE NON SIMILAIRE",
                "EN": "PASSWORDS ARE NOT SIMILAR"
            },
            "notValidAdressMessage": {
                "FR": "Cette addresse contient des données manquantes, veuillez saisir votre addresse de livraison",
                "EN": "This address contains missing data, please enter your delivery address"
            },
            "notValidAdress": {
                "FR": "CE N'EST PAS UNE ADRESSE VALIDE",
                "EN": "THIS IS NOT A VALID ADDRESS"
            },
            "pleaseRespectTheFormat": {
                "FR": "VEUILLEZ RESPECTER LE FORMAT REQUIS",
                "EN": "PLEASE FOLLOW THE REQUIRED FORMAT"
            },
            "NOEX_Home Screen": {
                "FR": null,
                "EN": null
            },
            "homeScreenEndQuestion": {
                "FR": "Plus de plaisir ?",
                "EN": "More pleasure ?"
            },
            "homeScreenEndRegisterText": {
                "FR": "Inscrivez-vous",
                "EN": "Sign up"
            },
            "homeScreenInfoText": {
                "FR": "SOYEZ INFORMÉ EN AVANT-PREMIÈRE DES NOUVEAUX CHEFS QUI NOUS REJOIGNENT",
                "EN": "BE THE FIRST INFORMED OF THE NEW CHEFS JOINING US."
            },
            "homeScreenButtonRegister": {
                "FR": "S'inscrire",
                "EN": "Register"
            },
            "homeScreenAllCategories": {
                "FR": "Toutes nos catégories",
                "EN": "All categories"
            },
            "NOEX_explore Screen": {
                "FR": null,
                "EN": null
            },
            "exploreScreenTitle": {
                "FR": "explorer",
                "EN": "explore"
            },
            "exploreKitchenScreenTitle": {
                "FR": "Cuisine",
                "EN": "Cuisine"
            },
            "exploreChefsScreenTitle": {
                "FR": "chefs",
                "EN": "chefs"
            },
            "NOEX_kitchen Screen": {
                "FR": null,
                "EN": null
            },
            "exploreKitchenScreenStarsText": {
                "FR": "Étoiles",
                "EN": "Stars"
            },
            "exploreKitchenScreenNoStars": {
                "FR": "Vogue",
                "EN": "Vogue"
            },
            "exploreKitchenScreenEmptyMessage": {
                "FR": "Pas de restaurants",
                "EN": "No restaurants"
            },
            "Stars": {
                "FR": "En",
                "EN": "En"
            },
            "": {
                "FR": null,
                "EN": null
            },
            "NOEX_chefs Screen ": {
                "FR": null,
                "EN": null
            },
            "exploreChefsScreenEmptyMessage": {
                "FR": "Pas de résultat",
                "EN": "No results"
            },
            "exploreChefsScreenButtonPlaceholder": {
                "FR": "Recherche...",
                "EN": "Search..."
            },
            "NOEX_categories screen": {
                "FR": null,
                "EN": null
            },
            "allCategories": {
                "FR": "TOUTES LES CATÉGORIES",
                "EN": "ALL CATEGORIES"
            },
            "noCategory": {
                "FR": "AUCUNE CATÉGORIE",
                "EN": "NO CATEGORY"
            },
            "NOEX_Search Screen": {
                "FR": null,
                "EN": null
            },
            "searchScreenPlaceholderText": {
                "FR": "Chef, restaurant, plat...",
                "EN": "Chef, restaurant,dish..."
            },
            "searchScreenRecentSearches": {
                "FR": "recherches récentes",
                "EN": "recent searches"
            },
            "searchScreenTypeChefAndRestaurants": {
                "FR": "Chefs",
                "EN": "Chefs"
            },
            "searchScreenTypeRestaurants": {
                "FR": "Restaurants",
                "EN": "Restaurants"
            },
            "searchScreenTypeThemes": {
                "FR": "Thémes",
                "EN": "Themes"
            },
            "NoRestaurantFound": {
                "FR": "Aucun restaurant disponible",
                "EN": "No restaurant available"
            },
            "searchScreenTypeDish": {
                "FR": "Plats",
                "EN": "Dishes"
            },
            "exploreKitchenScreenNoStarsStart": {
                "FR": "en",
                "EN": "without"
            },
            "allSearchScreenTypeMenus": {
                "FR": "Menu",
                "EN": "Menu"
            },
            "searchScreenTypeMenus": {
                "FR": "Menus",
                "EN": "Menus"
            },
            "NOEX_Registration Screen ": {
                "FR": null,
                "EN": null
            },
            "registrationScreenTitleRegistrationStepOne": {
                "FR": "inscription ou",
                "EN": "registration or"
            },
            "registrationScreenTitleRegistrationStepTwo": {
                "FR": "connexion",
                "EN": "login"
            },
            "exploreKitchenScreenStarsTextSingle": {
                "FR": "Étoile",
                "EN": "Stars"
            },
            "registrationScreenWithApple": {
                "FR": "S’identifier avec Apple",
                "EN": "Sign in with Apple"
            },
            "registrationScreenWithGoogle": {
                "FR": "S'identifier avec Google",
                "EN": "Sign in with Google"
            },
            "registrationScreenWithFacebook": {
                "FR": "S'identifier avec Facebook",
                "EN": "Sign in with Facebook"
            },
            "registrationScreenOr": {
                "FR": "ou",
                "EN": "or"
            },
            "registrationScreenWithEmail": {
                "FR": "S’identifier avec un email",
                "EN": "Login with email"
            },
            "connectWithMail": {
                "FR": "Connexion avec email",
                "EN": "Connect with email"
            },
            "parseEmailErrorType": {
                "FR": "L'email est obligatoire",
                "EN": "Email is required"
            },
            "otherLoginMethod": {
                "FR": "Vous pouvez vous identifier via le compte précédement crée avec cette adresse",
                "EN": "You can identify yourself via the account previously created with this address"
            },
            "mailAlreadyInUse": {
                "FR": "CETTE ADRESSE EMAIL EXISTE DÉJÀ",
                "EN": "this email address already exists"
            },
            "createYourAccount": {
                "FR": "Créer votre compte",
                "EN": "Create your account"
            },
            "NOEX_Email  Screen ": {
                "FR": null,
                "EN": null
            },
            "emailScreenTitleStepOne": {
                "FR": "Saisissez",
                "EN": "Enter"
            },
            "emailScreenTitleStepTwo": {
                "FR": "votre",
                "EN": "your"
            },
            "emailScreenTitleStepThree": {
                "FR": "email",
                "EN": "email"
            },
            "emailScreenPlaceholderText": {
                "FR": "Votre Adresse Email",
                "EN": "Your Email Address"
            },
            "NOEX_Personal Information Screen": {
                "FR": null,
                "EN": null
            },
            "personalInformationTitleWelcome": {
                "FR": "Bienvenue",
                "EN": "Welcome"
            },
            "personnelInformationDescription": {
                "FR": "Vous devez créer un compte pour continuer",
                "EN": "You must create an account to continue"
            },
            "personnelInformationLastNameInput": {
                "FR": "Nom",
                "EN": "Last Name"
            },
            "personnelInformationFirstNameInput": {
                "FR": "Prénom",
                "EN": "First Name"
            },
            "NOEX_Phone Screen ": {
                "FR": null,
                "EN": null
            },
            "phoneScreenTitleStepOne": {
                "FR": "Votre",
                "EN": "Your"
            },
            "phoneScreenTitleStepTwo": {
                "FR": "numéro",
                "EN": "number"
            },
            "phoneScreenTitleStepThree": {
                "FR": "de",
                "EN": null
            },
            "phoneScreenTitleStepFour": {
                "FR": "téléphone",
                "EN": "phone"
            },
            "phoneScreenButtonText": {
                "FR": "Envoyer le code de vérification",
                "EN": "Send verification code"
            },
            "phoneScreenInputLabel": {
                "FR": "Numéro de téléphone",
                "EN": "Phone number"
            },
            "phoneScreenButtonUpdated": {
                "FR": "Mettre à jour le numéro de téléphone",
                "EN": "Update phone number"
            },
            "phoneScreenVerificationCode": {
                "FR": "Code de vérification",
                "EN": "Verification code"
            },
            "NOEX_Code confirm Screen": {
                "FR": null,
                "EN": null
            },
            "codeConfirmScreenStepOne": {
                "FR": "Saisissez",
                "EN": "Enter"
            },
            "codeConfirmScreenStepTwo": {
                "FR": "le code",
                "EN": "the code"
            },
            "codeConfirmScreenStepThree": {
                "FR": "envoyé",
                "EN": "sent"
            },
            "codeAtivatedToast": {
                "FR": "Code activé avec succès",
                "EN": "Code successfully activated"
            },
            "passwordScreenUpdateHeaderStepOne": {
                "FR": "modifier votre",
                "EN": "change your"
            },
            "passwordScreenHeaderText": {
                "FR": "Dernière étape",
                "EN": "Last step"
            },
            "passwordScreenStepOne": {
                "FR": "Créez votre",
                "EN": "Create your"
            },
            "passwordScreenStepTwo": {
                "FR": "mot de passe",
                "EN": "password"
            },
            "passwordScreenInputPassword": {
                "FR": "Votre mot de passe",
                "EN": "Your password"
            },
            "passwordScreenValidationMinCharacters": {
                "FR": "caractères minimum",
                "EN": "minimum characters"
            },
            "passwordScreenValidationUppercase": {
                "FR": "Majuscule",
                "EN": "Uppercase"
            },
            "passwordScreenValidationSpecial": {
                "FR": "caractère special",
                "EN": "special character"
            },
            "passwordScreenChiffre": {
                "FR": "chiffre",
                "EN": "number"
            },
            "passwordScreenInputConfirmPassword": {
                "FR": "confirmez Votre mot de passe",
                "EN": "confirm your password"
            },
            "passwordScreenUpdateOldPassword": {
                "FR": "votre mot de passe actuel",
                "EN": "your actual password"
            },
            "passwordScreenUpdateMessageError": {
                "FR": "Mot de passe non similaire",
                "EN": "Password not similar"
            },
            "countryScreenBtn": {
                "FR": "Modifier le pays",
                "EN": "Change the country"
            },
            "passwordScreenUpdateButtonPassword": {
                "FR": "Modifier le mot de passe",
                "EN": "Change the password"
            },
            "passwordScreenNewPassword": {
                "FR": "Votre nouveau mot de passe",
                "EN": "Your new password"
            },
            "passwordScreenConfirmNewPassword": {
                "FR": "Confirmez votre nouveau mot de passe",
                "EN": "Confirm your new password"
            },
            "passwordScreenErrorToast": {
                "FR": "Mot de passe non respecter",
                "EN": "Password not respected"
            },
            "NOEX_login Password Sceen": {
                "FR": null,
                "EN": null
            },
            "NotSamePassword": {
                "FR": "MOT DE PASSE NON SIMILAIRE",
                "EN": "PASSWORD NOT SIMILAR"
            },
            "loginPasswordScreenTitleStepOne ": {
                "FR": "Bonjour",
                "EN": "Hello"
            },
            "loginPasswordScreenTitleStepTwo": {
                "FR": "connectez-vous",
                "EN": "log in"
            },
            "loginPasswordForgetPassword": {
                "FR": "Mot de passe oublié",
                "EN": "Forgot your password"
            },
            "NOEX_MyOrder Screen": {
                "FR": null,
                "EN": null
            },
            "myOrderScreenTitle": {
                "FR": "Mes commandes",
                "EN": "My orders"
            },
            "myOrderScreenItemDateOrder": {
                "FR": "commandé le",
                "EN": "ordered on"
            },
            "myOrderScreenItemCanceled": {
                "FR": "commande annulée",
                "EN": "cancelled order"
            },
            "myOrderScreenItemDateDelivered": {
                "FR": "Livré le",
                "EN": "Delivered on"
            },
            "discoverVideo": {
                "FR": "Découvrez la vidéo personnalisée :",
                "EN": "Discover the personalized video :"
            },
            "myOrderScreenYouDontHaveOrdersByNow": {
                "FR": "vous n'avez pas de commande pour le moment",
                "EN": "you do not have any order at the moment"
            },
            "NOEX_Details order Screen": {
                "FR": null,
                "EN": null
            },
            "statusCanceled": {
                "FR": "ANNULÉE",
                "EN": "CANCELED"
            },
            "statusEnded": {
                "FR": "TERMINÉE",
                "EN": "DELIVERED"
            },
            "statusSended": {
                "FR": "ENVOYÉE",
                "EN": "SENT"
            },
            "statusWaiting": {
                "FR": "EN ATTENTE",
                "EN": "PENDING"
            },
            "statutPreparation": {
                "FR": "EN PRÉPARATION",
                "EN": "IN PREPARATION"
            },
            "detailsOrderScreenTitle": {
                "FR": "Détails de la commande",
                "EN": "Details of the order"
            },
            "detailsOrderScreenOrder": {
                "FR": "Commande",
                "EN": "Order"
            },
            "detailsOrderScreenDelivery": {
                "FR": "Livraison",
                "EN": "Delivery"
            },
            "detailsOrderScreenDeliveryAddress": {
                "FR": "Adresse de livraison",
                "EN": "Delivery address"
            },
            "detailsOrderScreenSeePreparationButtonMobile": {
                "FR": "VOIR LE MESSAGE DU CHEF",
                "EN": "VIEW THE CHEF’S MESSAGE"
            },
            "detailsOrderScreenSeePreparationButton": {
                "FR": "Voir la préparation",
                "EN": "See the preparation"
            },
            "detailsOrderScreenDownloadInvoice": {
                "FR": "Télécharger le reçu",
                "EN": "Download receipt"
            },
            "detailOrderScreenPreparation": {
                "FR": "Préparation",
                "EN": "Preparation"
            },
            "detailsOrderScreenHelp": {
                "FR": "Aide",
                "EN": "Help"
            },
            "detailsOrderScreenError": {
                "FR": "Erreur inconnue",
                "EN": "Unknown error"
            },
            "detailsOrderDelivryIn": {
                "FR": "livré le",
                "EN": "delivered on"
            },
            "detailsOrderCommanderIn": {
                "FR": "commandé le",
                "EN": "ordered on"
            },
            "OrderStatus": {
                "FR": "STATUT",
                "EN": "STATUS"
            },
            "detailsAndPreparation": {
                "FR": "détails & préparation",
                "EN": "details & preparation"
            },
            "identityUpdateSuccess": {
                "FR": "Identité modifiée avec succès",
                "EN": "Identity updated with succes"
            },
            "detailsOrderScreenDateCommande": {
                "FR": "DATE DE LA COMMANDE",
                "EN": "DATE OF THE ORDER"
            },
            "NOEX_Help Screen": {
                "FR": null,
                "EN": null
            },
            "UN PRODUIT EST ARRIVÉ ENDOMMAGÉ": {
                "FR": "UN PRODUIT EST ARRIVÉ ENDOMMAGÉ",
                "EN": "A PRODUCT ARRIVED DAMAGED"
            },
            "AUTRES": {
                "FR": "AUTRE",
                "EN": "OTHER"
            },
            "UN PRODUIT N'A PAS ÉTÉ LIVRÉ": {
                "FR": "UN PRODUIT N'A PAS ÉTÉ LIVRÉ",
                "EN": "A PRODUCT WAS NOT DELIVERED"
            },
            "helpScreenOther": {
                "FR": "autre",
                "EN": "other"
            },
            "NOEX_Basket Screen": {
                "FR": null,
                "EN": null
            },
            "basketScreenTitle": {
                "FR": "votre panier",
                "EN": "your shopping bag"
            },
            "basketScreenDaysTitle": {
                "FR": "Date de livraison",
                "EN": "Delivery date"
            },
            "basketScreenEmpty": {
                "FR": "Votre panier est vide",
                "EN": "Your shopping bag is empty"
            },
            "basketScrennDatePossible": {
                "FR": "Dates de livraison possibles",
                "EN": "Possible delivery dates"
            },
            "basketScreenDeliveryFree": {
                "FR": "Livraison garantie le",
                "EN": "Delivery on the"
            },
            "basketScreenDeleteItemModalMessageConfirm": {
                "FR": "Êtes-vous sûr de vouloir supprimer cet article?",
                "EN": "Are you sure you want to delete this item?"
            },
            "basketScreenButtonOrder": {
                "FR": "Passer Commande",
                "EN": "Order"
            },
            "NOEX_Order summary Screen": {
                "FR": null,
                "EN": null
            },
            "orderSummaryScreenTitle": {
                "FR": "Récapitulatif de commande",
                "EN": "Order summary"
            },
            "orderSummaryScreenOrder": {
                "FR": "commande",
                "EN": "order"
            },
            "orderSummaryScreenDelivery": {
                "FR": "Livraison",
                "EN": "Delivery"
            },
            "orderSummaryScreenDeliveryAddress": {
                "FR": "Adresse de",
                "EN": "Address of"
            },
            "orderSummaryDetailsOrder": {
                "FR": "RÉSUMÉ DE LA",
                "EN": "SUMMARY OF THE"
            },
            "orderSummaryDeliveryMan": {
                "FR": "méthode de livraison",
                "EN": "delivery method"
            },
            "orderSummaryScreenExpressDelivery": {
                "FR": "LIVRAISON EXPRESS",
                "EN": "EXPRESS DELIVERY"
            },
            "orderSummaryScreenNotYetAvailable": {
                "FR": "pas encore disponible",
                "EN": "not yet available"
            },
            "orderSummaryAddPersonalizedMessage": {
                "FR": "AJOUTER UN MESSAGE POUR UN CADEAU PERSONNALISÉ",
                "EN": "ADD A MESSAGE FOR A PERSONALIZED GIFT"
            },
            "orderSummaryYourPersonalizedMessagePlacehorder": {
                "FR": "Votre message ici",
                "EN": "Your message here"
            },
            "orderSummerYourMessage": {
                "FR": "votre message",
                "EN": "your message"
            },
            "orderSummaryTotalText": {
                "FR": "total",
                "EN": "total"
            },
            "orderSummaryButton": {
                "FR": "Paiement de la commande",
                "EN": "Order payment"
            },
            "orderSummaryCodePromoText": {
                "FR": "Suppernova code",
                "EN": "Suppernova code"
            },
            "orderSummaryCodePromoPlaceholder": {
                "FR": "GIFT CARD / CODE PROMOTIONNEL",
                "EN": "GIFT CARD / PROMOTIONAL CODE"
            },
            "orderSummaryUnitTotal": {
                "FR": "Total unitaire",
                "EN": "Unit total"
            },
            "orderSummaryTypeDelivery": {
                "FR": "OFFERTS",
                "EN": "FREE"
            },
            "orderSummerAddPhoneNumber": {
                "FR": "Veuillez ajouter votre numéro de téléphone",
                "EN": "Please add your phone number"
            },
            "orderSummerAddNumber": {
                "FR": "ajouter un numéro",
                "EN": "add a number"
            },
            "shippingCost": {
                "FR": "frais de livraison",
                "EN": "delivery fees"
            },
            "messageSlectCountry": {
                "FR": "veuillez sélectionner un pays",
                "EN": "please select a country"
            },
            "returnCart": {
                "FR": "Retour panier",
                "EN": "Return to shopping bag"
            },
            "expressDelivery": {
                "FR": "LIVRAISON EXPRESS",
                "EN": "EXPRESS DELIVERY"
            },
            "deliveryAdress": {
                "FR": "Adresse de livraison",
                "EN": "Delivery address"
            },
            "NOEX_claimScreen": {
                "FR": null,
                "EN": null
            },
            "claimScreenHeaderQuestion": {
                "FR": "COMMENT POUVONS-NOUS VOUS AIDER ?",
                "EN": "HOW CAN WE HELP YOU?"
            },
            "claimScreenHeaderProduct": {
                "FR": "Sélectionnez le(s) produit(s) concerné(s)",
                "EN": "Select the product(s)"
            },
            "NOEX_Profil Screen": {
                "FR": null,
                "EN": null
            },
            "profileScreenTitleStepOne": {
                "FR": "mon",
                "EN": "my"
            },
            "profileScreenTitleStepTwo": {
                "FR": "compte",
                "EN": "account"
            },
            "profileScreenIdentify": {
                "FR": "Identité",
                "EN": "Identity"
            },
            "profileScreenCountry": {
                "FR": "Pays",
                "EN": "Country"
            },
            "profileScreenContinueOrder": {
                "FR": "Continuer la commande",
                "EN": "Continue order"
            },
            "NOEX_Identity Screen": {
                "FR": null,
                "EN": null
            },
            "IdentityScreenTitleStepOne": {
                "FR": "votre",
                "EN": "your"
            },
            "NOEX_Update phone Screen": {
                "FR": null,
                "EN": null
            },
            "updatePhoneScreenTitleStepThree": {
                "FR": "de",
                "EN": null
            },
            "updatePhoneScreenMessageValidation": {
                "FR": "Un code de vérification sera envoyé à ce numéro",
                "EN": "A verification code will be sent to this number"
            },
            "updatePhoneScreenButtonText": {
                "FR": "mettre À jour le numéro de téléphone",
                "EN": "update phone number"
            },
            "NOEX_Update Country Screen": {
                "FR": null,
                "EN": null
            },
            "updateCountryScreenTitle": {
                "FR": "Pays",
                "EN": "Country"
            },
            "updateCountryScreenLabelInput": {
                "FR": "chercher un pays",
                "EN": "search for a country"
            },
            "NOEX_Global Profile Screen": {
                "FR": null,
                "EN": null
            },
            "globalProfileScreenTitleHello": {
                "FR": "Bonjour",
                "EN": "Hello"
            },
            "globalProfileScreenMyProfileText": {
                "FR": "Mon compte",
                "EN": "My account"
            },
            "globalProfileScreenMyOrderText": {
                "FR": "Mes Commandes",
                "EN": "My Orders"
            },
            "globalProfileScreenMyDeliveryAddressText": {
                "FR": "Mes adresses de livraison",
                "EN": "My delivery addresses"
            },
            "globalProfileScreenNotification": {
                "FR": "gérer les notifications",
                "EN": "manage notifications"
            },
            "globalProfileScreenNewsletters": {
                "FR": "gérer les newsletters",
                "EN": "manage newsletter"
            },
            "globalProfileScreenContact": {
                "FR": "contact",
                "EN": "contact"
            },
            "SmsToBody": {
                "FR": "As-tu déjà commandé chez SUPPERNOVA ? J'ai récemment dégusté un repas de mon Chef préféré et j'ai reçu un cadeau exclusif pour un ami. Je pensais que cela serait parfait pour toi. Tu recevras 15% de réduction sur ta première commande. Clique ci-dessous et utilise le code FRIEND15. suppernova.com",
                "EN": "Have you ordered from SUPPERNOVA before? I recently tasted a meal from my favourite Chef and received an exclusive gift for a friend. I thought it was perfect for you. You'll receive a 15% off your first order. Click below and use the code FRIEND15. suppernova.com"
            },
            "MailToBody": {
                "FR": "As-tu déjà commandé chez SUPPERNOVA ? J'ai récemment dégusté un repas de mon Chef préféré et j'ai reçu un cadeau exclusif pour un ami. Je pensais que cela serait parfait pour toi. Tu recevras 15% de réduction sur ta première commande. Clique ci-dessous et utilise le code FRIEND15. suppernova.com",
                "EN": "Have you ordered from SUPPERNOVA before? I recently tasted a meal from my favourite Chef and received an exclusive gift for a friend. I thought it was perfect for you. You'll receive a 15% off your first order. Click below and use the code FRIEND15. suppernova.com"
            },
            "globalProfileScreenAbout": {
                "FR": "À propos",
                "EN": "About"
            },
            "globalProfileScreenLogout": {
                "FR": "Se déconnecter",
                "EN": "Sign out"
            },
            "personalized_message": {
                "FR": "message personnalisé",
                "EN": "personalized message"
            },
            "globalProfileScreenDeleteModalQuestion": {
                "FR": "Êtes-vous certain de vouloir supprimer votre compte ?",
                "EN": "Are you sure you want to delete your account ?"
            },
            "globalProfileScreenLogoutModalQuestion": {
                "FR": "Êtes-vous certain de vouloir vous déconnecter ?",
                "EN": "Are you sure you want to sign out ?"
            },
            "NOEX_Manage My Delivery Addresses Screen": {
                "FR": null,
                "EN": null
            },
            "defaultAdress": {
                "FR": "par défaut",
                "EN": "by default"
            },
            "manageMyDeliveryAddresses": {
                "FR": "Gérer mes adresses de livraison",
                "EN": "Manage my delivery addresses"
            },
            "manageMyDeliveryAddressesScreenTitleStepOne": {
                "FR": "gérer mes",
                "EN": "manage my"
            },
            "manageMyDeliveryAddressesScreenTitleStepTwo": {
                "FR": "adresses",
                "EN": "addresses"
            },
            "manageMyDeliveryAddressesScreenTitleStepThree": {
                "FR": "de livraison",
                "EN": "delivery"
            },
            "manageMyDeliveryAddressesScreenEmptyMessage": {
                "FR": "Vous n’avez pas encore ajouté d’adresse.",
                "EN": "You haven't added any address yet."
            },
            "manageMyDeliveryAddressesScreenButtonAdd": {
                "FR": "Ajouter une adresse",
                "EN": "Add an address"
            },
            "manageMyDeliveryAddressesScreenButtonContinue": {
                "FR": "Continuer la commande",
                "EN": "Continue order"
            },
            "manageMyDeliveryAddressesScreenButtonConfirmDelivery": {
                "FR": "Modifier",
                "EN": "Modify"
            },
            "manageMyDeliveryAddressesModalMessageConfirmDelete": {
                "FR": "Supprimer",
                "EN": "Delete"
            },
            "manageMyDeliveryAddressesModalQuestion": {
                "FR": "voulez-vous supprimer cette adresse ?",
                "EN": "do you want to delete this address?"
            },
            "NOEX_Update Address screen": {
                "FR": null,
                "EN": null
            },
            "updateAddressScreenHeaderAdd": {
                "FR": "ajouter une adresse",
                "EN": "add an address"
            },
            "updateAddressScreenHeaderUpdate": {
                "FR": "Modifier une adresse",
                "EN": "Modify an address"
            },
            "updateAddressScreenTitleStepOne": {
                "FR": "une",
                "EN": "an"
            },
            "updateAddressScreenButtonText": {
                "FR": "ajuster le repère",
                "EN": "adjust the mark"
            },
            "updateAddressScreenClickOnAdjustHint": {
                "FR": "CLIQUER SUR 'AJUSTER LE REPÈRE' POUR SELECTIONNER UNE ADRESS À PARTIR DE LA MAP",
                "EN": "CLICK ON 'ADJUST MARK' TO SELECT AN ADDRESS FROM THE MAP"
            },
            "updateAddressScreenCodePostal": {
                "FR": "code postal",
                "EN": "zip code"
            },
            "updateAddressScreenCity": {
                "FR": "ville",
                "EN": "city"
            },
            "updateAddressScreenCountry": {
                "FR": "pays",
                "EN": "country"
            },
            "updateAddressScreenRegion": {
                "FR": "région",
                "EN": "region"
            },
            "updateAddressScreenBatimen": {
                "FR": "Code d'entrée, entreprise, étage, bâtiment...",
                "EN": "Entry code, company, floor, building..."
            },
            "updateAddressScreenDigicode": {
                "FR": "digicode",
                "EN": "door code"
            },
            "updateAddressScreenAppartmentNumber": {
                "FR": "numéro de l'appartement, porte, étage...",
                "EN": "apartment number, door, floor..."
            },
            "updateAddressScreenAddressName": {
                "FR": "nom de l'adresse (domicile/ Bureau ...)",
                "EN": "name of the address (home / office...)"
            },
            "updateAddressScreenConfirmePosition": {
                "FR": "confirmer la position",
                "EN": "confirm position"
            },
            "NOEX_Manage the  Notification screen": {
                "FR": null,
                "EN": null
            },
            "notificationScreenHeaderStepOne": {
                "FR": "Autoriser les",
                "EN": "Allow"
            },
            "notificationScreenHeaderStepTwo": {
                "FR": "notifications",
                "EN": "notifications"
            },
            "notificationsScreenInfo": {
                "FR": "NE MANQUEZ AUCUNE NOUVELLE CRÉATION DES CHEFS",
                "EN": "DON'T MISS ANY NEW CREATIONS"
            },
            "notificationScreenEnableNotification": {
                "FR": "Autoriser les notifications",
                "EN": "Allow notifications"
            },
            "notificationScreenDisabledNotification": {
                "FR": "Désactiver les notifications",
                "EN": "Deactivate the notifications"
            },
            "NOEX_Manage the newsletters Screen": {
                "FR": null,
                "EN": null
            },
            "manageNewslettersScreenTitleScreenStepOne": {
                "FR": "Gérer les",
                "EN": "Manage the"
            },
            "manageNewslettersScreenTitleScreenStepTwo": {
                "FR": "newsletters",
                "EN": "newsletters"
            },
            "manageNewslettersScreenInfo": {
                "FR": "J’ai lu et compris l’information sur le traitement de mes données personnelles énoncée dans la politique de confidentialité et j’accepte de recevoir des communications commerciales personnalisées de Suppernova par email ou d’autres moyens.",
                "EN": "I have read and understood the information on the processing of my personal data set out in the privacy policy and I agree to receive personalized commercial communications from Suppernova by email or other means."
            },
            "NOEX_Contact Screen": {
                "FR": null,
                "EN": null
            },
            "contactScreenInfoHeader": {
                "FR": "ICI NOUS RÉPONDRONS À TOUTES VOS QUESTIONS",
                "EN": "HERE WE WILL ANSWER ALL YOUR QUESTIONS"
            },
            "contactScreenSupportDepartment": {
                "FR": "LE SERVICE SUPPORT",
                "EN": "THE SUPPORT TEAM"
            },
            "contactScreenFormText": {
                "FR": "Utiliser le formulaire ci-dessous",
                "EN": "Use the form below"
            },
            "parsePhoneRequiredErrorType": {
                "FR": "Le numéro doit être valide",
                "EN": "A valid phone number is required"
            },
            "contactScreenMessageInput": {
                "FR": "Message",
                "EN": "Message"
            },
            "contactScreenNumberDelivery": {
                "FR": "NUMÉRO DE COMMANDE",
                "EN": "ORDER REFERENCE"
            },
            "contactScreenNoChoise": {
                "FR": "aucun choix",
                "EN": "no choice"
            },
            "contactScreenNoDeliveryAvailable": {
                "FR": "aucune commande disponible",
                "EN": "no order available"
            },
            "contactScreenCheckboxText": {
                "FR": "J'AI LU ET COMPRIS L'INFORMATION SUR L'UTILISATION DE MES DONNÉES PERSONNELLES ÉNONCÉE DANS LA politique de confidentialité",
                "EN": "I HAVE READ AND UNDERSTAND THE INFORMATION ON THE USE OF MY PERSONAL DATA SET OUT IN THE PRIVACY POLICY"
            },
            "contactScreenCheckboxText1": {
                "FR": "J'AI LU ET COMPRIS L'INFORMATION SUR L'UTILISATION DE MES DONNÉES PERSONNELLES ÉNONCÉE DANS",
                "EN": "I HAVE READ AND UNDERSTAND THE INFORMATION ON THE USE OF MY PERSONAL DATA SET OUT IN"
            },
            "contactScreenCheckboxText2": {
                "FR": "la politique de confidentialité",
                "EN": "the privacy policy"
            },
            "deliveredOn": {
                "FR": "à partir du",
                "EN": "from the"
            },
            "article_single": {
                "FR": "Article",
                "EN": "Item"
            },
            "contactScreenWhatsup": {
                "FR": "WHATSAPP",
                "EN": "WHATSAPP"
            },
            "noChefs": {
                "FR": "Pas de chef pour le moment",
                "EN": "No chef at the moment"
            },
            "chooseCommandeNumber": {
                "FR": "Choisir le numéro de commande",
                "EN": "Choose the order reference"
            },
            "NOEX_About Screen": {
                "FR": null,
                "EN": null
            },
            "aboutScreenTitleStepOne": {
                "FR": "À",
                "EN": null
            },
            "aboutScreenTitleStepTwo": {
                "FR": "propos",
                "EN": "About"
            },
            "NOEX_Details Screen": {
                "FR": null,
                "EN": null
            },
            "detailsScreenCarteText": {
                "FR": "À la carte",
                "EN": "À la carte"
            },
            "detailsScreenVideoChefText": {
                "FR": "APPARAIT DANS :",
                "EN": "APPEARS IN :"
            },
            "NOEX_Modal Newsletter Screen": {
                "FR": null,
                "EN": null
            },
            "modalNewsletterScreenTitle": {
                "FR": "newsletter",
                "EN": "newsletter"
            },
            "modalNewsletterScreenHeader": {
                "FR": "SOUSCRIVEZ À NOTRE NEWSLETTER ET NOUS VOUS ENVERRONS DES INFORMATIONS SUR LES NOUVEAUTÉS.",
                "EN": "SUBSCRIBE TO OUR NEWSLETTER AND WE WILL SEND YOU UPDATES."
            },
            "modalNewsletterScreenInfoActive": {
                "FR": "J’ai lu et compris l’information sur le traitement de mes données personnelles énoncée dans",
                "EN": "I have read and understood the information on the processing of my personal data set out in"
            },
            "politic": {
                "FR": "la politique de confidentialité",
                "EN": "the privacy policy"
            },
            "politicSuite": {
                "FR": "et j’accepte de recevoir des communications commerciales personnalisées de Suppernova par email ou d’autres moyens.",
                "EN": "and I agree to receive personalized commercial communications from Suppernova by email or other means."
            },
            "modalNewsletterScreenSuccess": {
                "FR": "Merci de vous être abonné à notre newsletter !",
                "EN": "Thank you for subscribing to our newsletter !"
            },
            "modalNewsletterScreenFailed": {
                "FR": "Vous êtes désabonné !",
                "EN": "You are unsubscribed !"
            },
            "newsLettreTitle": {
                "FR": "politique de confidentialité",
                "EN": "privacy policy"
            },
            "modalNewsletterScreenUnsubscribe": {
                "FR": "se désabonner",
                "EN": "unsubscribe"
            },
            "NOEX_Problem": {
                "FR": null,
                "EN": null
            },
            "connectionLostMessage": {
                "FR": "pas de réseau veuillez réessayer une fois connecté",
                "EN": "no network please try again once connected"
            },
            "connectionProblemMessage": {
                "FR": "Problème de connexion",
                "EN": "Connection problem"
            },
            "NOEX_Website Header link": {
                "FR": null,
                "EN": null
            },
            "kitchenLink": {
                "FR": "Cuisine",
                "EN": "Cusine"
            },
            "chefsLink": {
                "FR": "Chefs",
                "EN": "Chefs"
            },
            "categoriesLink": {
                "FR": "Catégories",
                "EN": "Categories"
            },
            "searchLink": {
                "FR": "Recherche",
                "EN": "Search"
            },
            "myAccountLink": {
                "FR": "Mon Compte",
                "EN": "My Account"
            },
            "basketLink": {
                "FR": "Panier",
                "EN": "Shopping Bag"
            },
            "NOEX_Website Bottom Footer": {
                "FR": null,
                "EN": null
            },
            "delivery": {
                "FR": "Livraison",
                "EN": "Delivery"
            },
            "commandsTracker": {
                "FR": "Suivi des commandes",
                "EN": "Track orders"
            },
            "frequentQuestions": {
                "FR": "Questions fréquentes",
                "EN": "Frequently asked questions"
            },
            "generalConditionOfUseSecondaryPage": {
                "FR": "Conditions générales d'utilisation",
                "EN": "General terms of use"
            },
            "generalConditionsOfUse": {
                "FR": "Conditions générale d'utilisation",
                "EN": "General terms of use"
            },
            "forYourRestaurantSuppernova": {
                "FR": "Suppernova pour votre restaurant",
                "EN": "Suppernova for your restaurant"
            },
            "contactUs": {
                "FR": "Contactez nous",
                "EN": "Contact us"
            },
            "credit": {
                "FR": "Crédits",
                "EN": "Credits"
            },
            "sustainability": {
                "FR": "sustainability",
                "EN": "sustainability"
            },
            "legalNotice": {
                "FR": "Mentions légales",
                "EN": "Legal notice"
            },
            "NOEX_New account": {
                "FR": null,
                "EN": null
            },
            "enterYourEmail": {
                "FR": "Entrez votre adresse email",
                "EN": "Enter your email address"
            },
            "enterYourNameLastNameETC": {
                "FR": "Entrez votre nom, prénom et numéro de téléphone",
                "EN": "Enter your last name, first name and phone number"
            },
            "confirmPhoneNumber": {
                "FR": "Confirmation de votre numéro de téléphone",
                "EN": "Confirm your phone number"
            },
            "createYourPassword": {
                "FR": "Création de votre mot de passe",
                "EN": "Create your password"
            },
            "NOEX_Tablet Dashboard Screen": {
                "FR": null,
                "EN": null
            },
            "restaurant": {
                "FR": "Restaurant",
                "EN": "Restaurant"
            },
            "overview": {
                "FR": "Overview",
                "EN": "Overview"
            },
            "orders_to_prepare": {
                "FR": "commandes À préparer",
                "EN": "orders to prepare"
            },
            "orders_sent": {
                "FR": "Commandes envoyées",
                "EN": "Orders sent"
            },
            "total_orders": {
                "FR": "Total de commandes",
                "EN": "Total of orders"
            },
            "today": {
                "FR": "Aujourd'hui",
                "EN": "Today"
            },
            "tomorrow": {
                "FR": "Demain",
                "EN": "Tomorrow"
            },
            "comming": {
                "FR": "à venir",
                "EN": "to come"
            },
            "a_la_carte": {
                "FR": "À la carte",
                "EN": "À la carte"
            },
            "menu": {
                "FR": "menu",
                "EN": "menu"
            },
            "logoutConfirmation": {
                "FR": "Êtes-vous certain de vouloir vous deconnecter?",
                "EN": "Are you sure you want to sign out?"
            },
            "logout": {
                "FR": "Déconnexion",
                "EN": "Sign out"
            },
            "cancel": {
                "FR": "Annuler",
                "EN": "Cancel"
            },
            "no_plat_found": {
                "FR": "Aucun plat trouvé",
                "EN": "Zero found"
            },
            "no_menu_found": {
                "FR": "Aucun Menu trouvé",
                "EN": "Zero found"
            },
            "sunday": {
                "FR": "dimanche",
                "EN": "sunday"
            },
            "monday": {
                "FR": "lundi",
                "EN": "monday"
            },
            "tuesday": {
                "FR": "mardi",
                "EN": "tuesday"
            },
            "wednesday": {
                "FR": "mercredi",
                "EN": "wednesday"
            },
            "thursday": {
                "FR": "jeudi",
                "EN": "thursday"
            },
            "saturday": {
                "FR": "samedi",
                "EN": "saturday"
            },
            "friday": {
                "FR": "vendredi",
                "EN": "friday"
            },
            "january": {
                "FR": "janvier",
                "EN": "january"
            },
            "february": {
                "FR": "février",
                "EN": "february"
            },
            "march": {
                "FR": "mars",
                "EN": "march"
            },
            "april": {
                "FR": "avril",
                "EN": "april"
            },
            "may": {
                "FR": "mai",
                "EN": "may"
            },
            "june": {
                "FR": "juin",
                "EN": "june"
            },
            "july": {
                "FR": "juillet",
                "EN": "july"
            },
            "august": {
                "FR": "août",
                "EN": "august"
            },
            "september": {
                "FR": "septembre",
                "EN": "september"
            },
            "october": {
                "FR": "octobre",
                "EN": "october"
            },
            "november": {
                "FR": "novembre",
                "EN": "november"
            },
            "december": {
                "FR": "décembre",
                "EN": "december"
            },
            "delivery_note_dhl": {
                "FR": "bon de livraison",
                "EN": "shipping label"
            },
            "status": {
                "FR": "statut",
                "EN": "status"
            },
            "name": {
                "FR": "nom",
                "EN": "name"
            },
            "record_video": {
                "FR": "Prendre la vidéo",
                "EN": "Record video"
            },
            "print": {
                "FR": "Imprimer",
                "EN": "Print"
            },
            "allFinished": {
                "FR": "TERMINÉES",
                "EN": "COMPLETED"
            },
            "finished": {
                "FR": "Terminée",
                "EN": "Completed"
            },
            "to_prepare": {
                "FR": "À préparer",
                "EN": "to prepare"
            },
            "report_unavailability": {
                "FR": "Signaler une indisponibilité",
                "EN": "Report unavailability"
            },
            "overmorrow": {
                "FR": "Après demain",
                "EN": "Day after tomorrow"
            },
            "alert_message": {
                "FR": "Alerte",
                "EN": "Alert"
            },
            "NOEX_Tablet Historic screen": {
                "FR": null,
                "EN": null
            },
            "orders_to_prepare_for": {
                "FR": "À préparer",
                "EN": "Orders to prepare"
            },
            "orders_waiting_for": {
                "FR": "en attente",
                "EN": "waiting for pick up"
            },
            "orders_sent_for": {
                "FR": "envoyée",
                "EN": "sent"
            },
            "order_finished_for": {
                "FR": "terminée",
                "EN": "completed"
            },
            "allOrders_sent_for": {
                "FR": "envoyées",
                "EN": "sent"
            },
            "allOrder_finished_for": {
                "FR": "terminées",
                "EN": "completed"
            },
            "order_canceled_for": {
                "FR": "annulée",
                "EN": "canceled"
            },
            "allOrder_canceled_for": {
                "FR": "annulées",
                "EN": "canceled"
            },
            "orders_for": {
                "FR": "commandes",
                "EN": "orders"
            },
            "no_result_found": {
                "FR": "Aucun résultat trouvé",
                "EN": "No results found"
            },
            "NOEX_Tablet Regulation screen": {
                "FR": null,
                "EN": null
            },
            "regulation": {
                "FR": "Règlements",
                "EN": "Payments"
            },
            "to": {
                "FR": "au",
                "EN": "at the"
            },
            "for_a_total_of": {
                "FR": "pour un total de",
                "EN": "for a total of"
            },
            "period": {
                "FR": "Période",
                "EN": "Period"
            },
            "date": {
                "FR": "date",
                "EN": "date"
            },
            "detail": {
                "FR": "Detail",
                "EN": "Details"
            },
            "download": {
                "FR": "Télécharger",
                "EN": "Download"
            },
            "NOEX_Tablet push notification": {
                "FR": null,
                "EN": null
            },
            "deliveredNotif": {
                "FR": "Votre commande est livré.",
                "EN": "Your order is delivered."
            },
            "deletedNotif": {
                "FR": "Vous avez une commande annulée.",
                "EN": "You have a cancel order."
            },
            "sendedDeliveryNotif": {
                "FR": "Votre commande est envoyé.",
                "EN": "Your order is sent."
            },
            "deliveredCommandNotif": {
                "FR": "Votre commande est en attente de livraison.",
                "EN": "Your order is waiting for delivery."
            },
            "prepareNewCommandNotif": {
                "FR": "Votre commande est en cour de préparation.",
                "EN": "Your order is being prepared."
            },
            "getNewOrdersNotif": {
                "FR": "Une nouvelle commande reçue",
                "EN": "A new order received"
            },
            "NOEX_Tablet list plats screen": {
                "FR": null,
                "EN": null
            },
            "Entrée": {
                "FR": "entrée",
                "EN": "starter"
            },
            "Dessert": {
                "FR": "Dessert",
                "EN": "Dessert"
            },
            "starter": {
                "FR": "entrée",
                "EN": "starter"
            },
            "Plat": {
                "FR": "plat",
                "EN": "main course"
            },
            "plate": {
                "FR": "plat",
                "EN": "main course"
            },
            "dessert.not.foundID": {
                "FR": "ce dessert n'existe pas avec l'ID:",
                "EN": "dessert not found with ID :"
            },
            "profile": {
                "FR": "Profil",
                "EN": "Profile"
            },
            "NOEX_Tablet detail plat screen": {
                "FR": null,
                "EN": null
            },
            "delete_plat": {
                "FR": "Supprimer ce plat",
                "EN": "Delete this course"
            },
            "presentation_plat": {
                "FR": "présentation du plat",
                "EN": "presentation of the course"
            },
            "short_title": {
                "FR": "titre court",
                "EN": "short title"
            },
            "full_title": {
                "FR": "titre complet",
                "EN": "full title"
            },
            "description": {
                "FR": "Description",
                "EN": "Description"
            },
            "no_description": {
                "FR": "aucune description",
                "EN": "no description"
            },
            "pictures": {
                "FR": "Photos",
                "EN": "Photos"
            },
            "noPhoto": {
                "FR": "aucune photo",
                "EN": "no photo"
            },
            "allergens": {
                "FR": "Allergènes",
                "EN": "Allergens"
            },
            "noAllergenes": {
                "FR": "sans allergènes",
                "EN": "no allergens"
            },
            "conservation": {
                "FR": "conservation",
                "EN": "preservation"
            },
            "noConservation": {
                "FR": "aucun conservation",
                "EN": "no preservation"
            },
            "preparation": {
                "FR": "préparation",
                "EN": "preparation"
            },
            "video": {
                "FR": "Vidéo",
                "EN": "Video"
            },
            "noVideo": {
                "FR": "aucune vidéo",
                "EN": "no video"
            },
            "singlePlat": {
                "FR": "Plat",
                "EN": "Dish"
            },
            "plats": {
                "FR": "Plats",
                "EN": "Dishes"
            },
            "composition": {
                "FR": "Composition du colis",
                "EN": "Composition of the pack"
            },
            "NOEX_Tablet detail menu screen": {
                "FR": null,
                "EN": null
            },
            "delete_menu": {
                "FR": "présentation du menu",
                "EN": "menu presentation"
            },
            "title": {
                "FR": "titre",
                "EN": "title"
            },
            "price": {
                "FR": "prix",
                "EN": "price"
            },
            "commandes Á préparer": {
                "FR": "Photo",
                "EN": "Photo"
            },
            "menu_cover": {
                "FR": "Couverture du menu",
                "EN": "Menu cover picture"
            },
            "menu_dishes": {
                "FR": "Plats du menu",
                "EN": "Dishes of the menu"
            },
            "NOEX_Tablet profile screen": {
                "FR": null,
                "EN": null
            },
            "your_supernova_profile": {
                "FR": "Votre profil Suppernova",
                "EN": "Your Suppernova Profile"
            },
            "restaurant_name": {
                "FR": "Nom du restaurant",
                "EN": "Name of the restaurant"
            },
            "appearance_in_list": {
                "FR": "apparence dans la liste",
                "EN": "appearance in the list"
            },
            "NOEX_Tablet contact screen": {
                "FR": null,
                "EN": null
            },
            "contact": {
                "FR": "Contact",
                "EN": "Contact"
            },
            "emergency_case": {
                "FR": "En cas d'urgence ( indisponibilité de plat, panne, fermeture, suppression d'un plat ... )",
                "EN": "In case of emergency (unavailability of a course, breakdown, closure, deletion of a dish, etc...)"
            },
            "support_contact": {
                "FR": "contacter le support",
                "EN": "contact the chefs"
            },
            "chefs": {
                "FR": "des chefs",
                "EN": "support team"
            },
            "support_service": {
                "FR": "Contacter le service support",
                "EN": "Contact the support team"
            },
            "other_request": {
                "FR": "pour toutes les autres demandes",
                "EN": "for any other demand"
            },
            "use_form_below": {
                "FR": "Utiliser le formulaire ci-dessous",
                "EN": "Use the form below"
            },
            "your_object_request": {
                "FR": "Objet de votre demande",
                "EN": "Subject of your request"
            },
            "your_message": {
                "FR": "Votre Message",
                "EN": "Your Message"
            },
            "send_message_success": {
                "FR": "Merci, votre message a bien été envoyé, nous y repondrons dans les plus brefs delais.",
                "EN": "Thank you, your message has been sent, we will respond as soon as possible."
            },
            "app": {
                "FR": "application",
                "EN": "app"
            },
            "choose_order": {
                "FR": "Choisir une commande",
                "EN": "Choose an order"
            },
            "orderPaymentSuccess": {
                "FR": "paiement validé",
                "EN": "payment validated"
            },
            "orderPaymentFailure": {
                "FR": "paiement invalide",
                "EN": "invalid payment"
            },
            "seeAllCategories": {
                "FR": "Voir les catégories",
                "EN": "See all categories"
            },
            "addressAddSetDefault": {
                "FR": "Modifications réalisées avec succès",
                "EN": "Changes validated"
            },
            "customMessageSetSuccess": {
                "FR": "Message sauvegardé",
                "EN": "Message saved"
            },
            "defaultAddressChangeSuccess": {
                "FR": "Adresse préférée changée avec succès",
                "EN": "Preferred address successfully changed"
            },
            "disableNotifications": {
                "FR": "Désactiver les notifications",
                "EN": "Deactivate the notifications"
            },
            "addressUpdateSuccess": {
                "FR": "Adresse mise à jour avec succès",
                "EN": "Address updated successfully"
            },
            "enableNotifications": {
                "FR": "Autoriser les notifications",
                "EN": "Allow notifications"
            },
            "manageNotifications": {
                "FR": "gérer les notifications",
                "EN": "manage notifications"
            },
            "manageNewsletters": {
                "FR": "gérer les newsletters",
                "EN": "manage Newsletters"
            },
            "myAddresses": {
                "FR": "Mes adresses de livraison",
                "EN": "Adress Book"
            },
            "goodMorning": {
                "FR": "Bonjour",
                "EN": "Hello"
            },
            "see": {
                "FR": "voir",
                "EN": "see"
            },
            "about": {
                "FR": "À propos",
                "EN": "About"
            },
            "invoiceDownloadInProgress": {
                "FR": "Téléchargement de la facture en cours",
                "EN": "Downloading invoice"
            },
            "deliverAtUpdateSuccess": {
                "FR": "Date de livraison modifiée",
                "EN": "Delivery date modified"
            },
            "codeActivatedToast": {
                "FR": "code activé avec succès",
                "EN": "code successfully activated"
            },
            "AnErrorHasOccurred": {
                "FR": "Une erreur est survenue",
                "EN": "An error has occurred"
            },
            "invoiceDownloadSuccess": {
                "FR": "Téléchargement de la facture réussi",
                "EN": "Invoice successfully uploaded"
            },
            "geoLocationAccessDisabledPressToActivate": {
                "FR": "L'accès à la localisation est désactivé, tapez ici pour réessayer",
                "EN": "Location access is disabled, tap here to try again"
            },
            "lastStep": {
                "FR": "Dernière étape",
                "EN": "Last step"
            },
            "privacyPolicy": {
                "FR": "politique de confidentialité",
                "EN": "privacy policy"
            },
            "privacyPolicyTitle": {
                "FR": "souscrivez à notre newsletter et nous vous enverrons des informations sur les nouveautés",
                "EN": "subscribe to our newsletter and we will send you information on new créations"
            },
            "yourRequestContactPart3": {
                "FR": "ÉTÉ",
                "EN": "BEEN"
            },
            "yourRequestContactPart2": {
                "FR": "envoyée",
                "EN": "sent"
            },
            "yourRequestContactPart1": {
                "FR": "Votre demande a bien",
                "EN": "Your request has"
            },
            "yourRequestContact": {
                "FR": "Votre demande a bien été envoyée",
                "EN": "your request has been sent"
            },
            "sellAllcategories ": {
                "FR": "Toutes les catégories",
                "EN": "All categories"
            },
            "NOEX_Tablet login screen": {
                "FR": null,
                "EN": null
            },
            "actionSheetCommand": {
                "FR": "Commander",
                "EN": "Order"
            },
            "dishList": {
                "FR": "liste des plats:",
                "EN": "list of dishes:"
            },
            "yourRequest": {
                "FR": "votre demande a",
                "EN": "your request has"
            },
            "yourRequestSuite": {
                "FR": "bien été",
                "EN": "been"
            },
            "quantite_item": {
                "FR": "quantité",
                "EN": "quantity"
            },
            "sorryRequest": {
                "FR": "NOUS NOUS EXCUSONS DU DÉSAGRÉMENT, LE SERVICE CLIENT REVIENDRA VERS VOUS AU PLUS VITE.",
                "EN": "WE ARE SORRY FOR THE INCONVENIENCE, THE CUSTOMER SERVICE WILL RETURN TO YOU ASAP."
            },
            "whatsappError": {
                "FR": "WhatsApp n'est pas installé sur votre appareil",
                "EN": "WhatsApp is not downloaded on your device"
            },
            "welcome": {
                "FR": "Bonjour et",
                "EN": "Hello and"
            },
            "homeTab": {
                "FR": "Accueil",
                "EN": "Home"
            },
            "orderTab": {
                "FR": "Commandes",
                "EN": "Orders"
            },
            "historicTab": {
                "FR": "Historique",
                "EN": "History"
            },
            "regulationTab": {
                "FR": "Règlements",
                "EN": "Payments"
            },
            "menuTab": {
                "FR": "Votre menu",
                "EN": "Your menu"
            },
            "presentation_menu": {
                "FR": "présentation du menu",
                "EN": "menu presentation"
            },
            "contactTab": {
                "FR": "Contact",
                "EN": "Contact"
            },
            "noNetwork": {
                "FR": "Pas de réseaux",
                "EN": "No Network"
            },
            "welcome1": {
                "FR": "Bienvenue !",
                "EN": "Welcome !"
            },
            "NOEX_END .": {
                "FR": null,
                "EN": null
            },
            "generalConditionStepOne": {
                "FR": "Conditions",
                "EN": "Terms"
            },
            "generalConditionStepTwo": {
                "FR": "générales",
                "EN": null
            },
            "generalConditionStepThree": {
                "FR": "de vente",
                "EN": "of sale"
            },
            "alertRappel": {
                "FR": "rappel",
                "EN": "reminder"
            },
            "closeAlert": {
                "FR": "traiter mainentant",
                "EN": "treat now"
            },
            "privecyAndPolicylSIgnUpStep1": {
                "FR": "En continuant, vous acceptez",
                "EN": "By continuing, you agree"
            },
            "privecyAndPolicyloginStep4": {
                "FR": "de Suppernova",
                "EN": "by Suppernova"
            },
            "privecyAndPolicyloginStep3": {
                "FR": "et vous confirmez avoir lu",
                "EN": "and you confirm that you have read"
            },
            "privecyAndPolicyloginStep2": {
                "FR": "les Conditions d’utilisation",
                "EN": "the Terms of Use"
            },
            "privecyAndPolicyloginStep1": {
                "FR": "En continuant, vous acceptez",
                "EN": "y continuing, you agree"
            },
            "generalConditionHeadersTitle": {
                "FR": "Suppernova pour les",
                "EN": "Suppernova for the"
            },
            "Messages": {
                "FR": null,
                "EN": null
            },
            "about.not_found": {
                "FR": "à propos n'existe pas.",
                "EN": "about is not found."
            },
            "access.denied": {
                "FR": "accès refusé.",
                "EN": "access denied."
            },
            "address.not_found": {
                "FR": "cette adresse n'existe pas.",
                "EN": "address is not found."
            },
            "admin.incorrect_name": {
                "FR": "Le nom n'est pas correcte. L'utilisateur est inconnu",
                "EN": "incorrect name. unknown user"
            },
            "admin.not_found": {
                "FR": "administrateur introuvable.",
                "EN": "admin not found."
            },
            "basket.not_found": {
                "FR": "ce panier n'existe pas.",
                "EN": "basket is not found."
            },
            "basket.one_restaurant": {
                "FR": "une commande par restaurant uniquement pour le moment.",
                "EN": "one order per restaurant for the moment"
            },
            "basketID.non.vide": {
                "FR": "basket_id est obligatoire.",
                "EN": "basket_id is mandatory."
            },
            "category.is_empty": {
                "FR": "cette catégorie est vide.",
                "EN": "category is empty."
            },
            "category.not_found": {
                "FR": "cette catégorie n'existe pas.",
                "EN": "category is not found"
            },
            "category.not_foundID": {
                "FR": "cette catégorie n'existe pas avec l'identifiant",
                "EN": "category is not found with ID :"
            },
            "chef.blocked": {
                "FR": "le chef de ce restaurant est bloqué, vous devez l'activer.",
                "EN": "restaurant chef is blocked. You can activate him."
            },
            "chef.deleted": {
                "FR": "ce chef est supprimé.",
                "EN": "chef is deleted."
            },
            "chef.not_found": {
                "FR": "ce chef n'existe pas.",
                "EN": "chef is not found."
            },
            "chef.remove_trending": {
                "FR": "supprimer un chef de tendance pour ajouter un nouveau à liste de tendance",
                "EN": "delete a trend chef to add a new one to the trend list."
            },
            "claim.not.valide": {
                "FR": "ce type de réclamation n'est pas valide!",
                "EN": "invalid claim"
            },
            "claim.not_found": {
                "FR": "cette réclamation n'existe pas.",
                "EN": "claim not found."
            },
            "client.not_found": {
                "FR": "ce client n'existe pas.",
                "EN": "customer not found."
            },
            "code.envoyer": {
                "FR": "code est envoyé avec succès.",
                "EN": "code successfully sent"
            },
            "code.expirer": {
                "FR": "code est expiré.",
                "EN": "expired code."
            },
            "code.invalid": {
                "FR": "code est invalide.",
                "EN": "invalid code."
            },
            "colis.not_found": {
                "FR": "ce colis n'existe pas.",
                "EN": "pack not found."
            },
            "colis.not_foundID": {
                "FR": "aucun colis trouvé avec l'identifiant:",
                "EN": "no pack found with ID :"
            },
            "command.not.null": {
                "FR": "la commande ne doit pas être vide.",
                "EN": "order should not be empty."
            },
            "command.not_found": {
                "FR": "la commande n'existe pas.",
                "EN": "order not found."
            },
            "compte_stripe_non_configure": {
                "FR": "votre compte stripe est bien configuré",
                "EN": "your stripe account is correctly configured"
            },
            "conservation.not_found": {
                "FR": "cette conservation n'existe pas.",
                "EN": "this preservation is not found."
            },
            "conservation.not_foundID": {
                "FR": "aucune conservation trouvée avec ID :",
                "EN": "no preservation found with ID :"
            },
            "country.not.found": {
                "FR": "ce pays n'existe pas.",
                "EN": "country not found."
            },
            "dataNotFound": {
                "FR": "cette donnée n'existe pas.",
                "EN": "data not found."
            },
            "date.command": {
                "FR": "la date doit être soit aujourd'hui soit demain.",
                "EN": "date must be today or tomorrow."
            },
            "date.intervale": {
                "FR": "la date doit être entre",
                "EN": "date must be between"
            },
            "date.invalid": {
                "FR": "date invalide.",
                "EN": "invalid date."
            },
            "date.not_found": {
                "FR": "date manquante.",
                "EN": "date is missing."
            },
            "dateFin.sup.dateDeb": {
                "FR": "la date de fin doit être supérieure à la date de début.",
                "EN": "end date must be greater than start date."
            },
            "default_address.not_found": {
                "FR": "veuillez ,s'il vous plait, entrer votre adresse de livraison.",
                "EN": "please enter your delivery address."
            },
            "email.exist": {
                "FR": "cet email existe déjà.",
                "EN": "this email already exists."
            },
            "email.not.exist": {
                "FR": "cet email n'existe pas.",
                "EN": "email is not found."
            },
            "email.valide": {
                "FR": "email valide.",
                "EN": "valid email."
            },
            "entry.not.found": {
                "FR": "l'entrée n'existe pas avec l'ID :",
                "EN": "entry is not found with this ID :"
            },
            "extra_weight": {
                "FR": "le poid total de la commande doit être inferieur ou égal à 70Kg.",
                "EN": "the total weight of the order must be less than or equal to 70Kg."
            },
            "fail.upload": {
                "FR": "échec du téléchargement du fichier.",
                "EN": "Failed to upload the file."
            },
            "fcmToken.missing": {
                "FR": "fcm_token est manquant.",
                "EN": "fcm_token is missing."
            },
            "fcm_token.exist": {
                "FR": "fcm_token existe déjà.",
                "EN": "fcm_token already exists."
            },
            "fcm_token.not_found": {
                "FR": "fcm_token n'existe pas.",
                "EN": "fcm_token not found."
            },
            "img.invalid.ext": {
                "FR": "extension d'image est invalide.",
                "EN": "invalid image extension."
            },
            "jwt.non.trouver": {
                "FR": "JWT token n'existe pas.",
                "EN": "JWT token not found."
            },
            "max_quota": {
                "FR": "la quantité de cet article doit être inférieure ou égale au quota",
                "EN": "the quantity of this article must be less than or equal to the quota"
            },
            "menu.not_found": {
                "FR": "ce menu n'existe pas.",
                "EN": "menu is not found."
            },
            "menu.not_foundID": {
                "FR": "ce menu n'existe pas avec l'ID :",
                "EN": "menu is not found with ID :"
            },
            "messages.success": {
                "FR": "succès.",
                "EN": "success."
            },
            "messages.token.invalid": {
                "FR": "Ce code de reset du mot de passe n'est pas valide.",
                "EN": "Password reset code is invalid."
            },
            "missing": {
                "FR": "manquant",
                "EN": "missing"
            },
            "msg.non.vide.obj.autres": {
                "FR": "le message ne peut pas être vide lorsque le type d'objet est \"Autre\"!",
                "EN": "message cannot be empty when object type is \"Other\"."
            },
            "not.admin": {
                "FR": "vous n'êtes pas un administrateur.",
                "EN": "you are not an admin."
            },
            "not.client": {
                "FR": "vous n'êtes pas un client.",
                "EN": "you are not a customer."
            },
            "not.login": {
                "FR": "Impossible de vous identifier avec ce compte.",
                "EN": "It is impossible to authenticate with this account."
            },
            "panier.vide": {
                "FR": "votre panier est vide",
                "EN": "your shopping bag is empty."
            },
            "password.incorrect": {
                "FR": "mot de passe incorrect.",
                "EN": "incorrect password."
            },
            "phone.exist": {
                "FR": "ce numéro de téléphone existe déjà.",
                "EN": "phone number already exists."
            },
            "phone.not.exist": {
                "FR": "ce numéro de téléphone n'existe pas.",
                "EN": "phone number not found."
            },
            "plat.not.foundID": {
                "FR": "ce plat n'existe pas avec l'ID :",
                "EN": "dish not found with this ID :"
            },
            "plat.not_found": {
                "FR": "ce plat n'existe pas.",
                "EN": "dish not found."
            },
            "plate_or_restaurant_not_found": {
                "FR": "plat ou restaurant introuvable.",
                "EN": "dish or restaurant not found."
            },
            "preparation.not.found": {
                "FR": "l'étape de préparation n'existe pas avec l'ID :",
                "EN": "preparation step does not exist with this ID:"
            },
            "product.not_found": {
                "FR": "ce produit n'existe pas.",
                "EN": "product not found."
            },
            "product.not_found_in_command": {
                "FR": "le plat est introuvable pour cette commande.",
                "EN": "the dish cannot be found for this order."
            },
            "product_already_in_claim": {
                "FR": "vous avez déjà fait une réclamation sur cet article",
                "EN": "you have already complained about this order."
            },
            "profil.complete": {
                "FR": "votre profil est déjà complété.",
                "EN": "your profile is already completed."
            },
            "profile.non.complete": {
                "FR": "votre profil reste en",
                "EN": "your profile remains"
            },
            "promoCode.added": {
                "FR": "un nouveau code a été ajouté avec succès!",
                "EN": "a new code has been added successfully!"
            },
            "promoCode.deleted": {
                "FR": "ce code a été supprimé avec succès!",
                "EN": "this code has been successfully removed!"
            },
            "promoCode.existe": {
                "FR": "ce code est déjà dans la base.",
                "EN": "this code is already in the database!"
            },
            "promoCode.invalide": {
                "FR": "ce code est invalide.",
                "EN": "this code is invalid."
            },
            "promoCode.not.found": {
                "FR": "ce code n'existe pas.",
                "EN": "code not found."
            },
            "promoCode.not.foundID": {
                "FR": "ce code n'existe pas pour cet identifiant",
                "EN": "code not found with this ID"
            },
            "promoCode.valide": {
                "FR": "ce code est valide.",
                "EN": "this code is valid."
            },
            "provider.incorrect": {
                "FR": "provider ID n'est pas correct.",
                "EN": "provider ID is incorrect"
            },
            "ref.exists": {
                "FR": "cette référence existe déjà",
                "EN": "this reference already exists!"
            },
            "refreshToken.invalid": {
                "FR": "refresh_token n'est pas valide.",
                "EN": "refresh_token is invalid."
            },
            "refreshToken.missing": {
                "FR": "refresh_token est manquant.",
                "EN": "refresh_token is missing."
            },
            "restaurant.bloque": {
                "FR": "le restaurant est bloqué.",
                "EN": "restaurant is blocked."
            },
            "restaurant.not_found": {
                "FR": "le restaurant n'existe pas.",
                "EN": "restaurant not found."
            },
            "restaurant.not_foundID": {
                "FR": "le restaurant n'existe pas avec l'ID :",
                "EN": "restaurant not found with this ID :"
            },
            "role.not.chef": {
                "FR": "vous n'êtes pas un chef.",
                "EN": "you are not a chef."
            },
            "select.restaurant": {
                "FR": "veuillez sélectionner un restaurant.",
                "EN": "please select a restaurant."
            },
            "six.chefs": {
                "FR": "vous avez déjà 6 chefs à la une.",
                "EN": "You already have 6 chefs."
            },
            "status.not_to_prepare": {
                "FR": "cette commande n'est pas en préparation.",
                "EN": "this order is not in preparation."
            },
            "stripe.verify.email": {
                "FR": "La nouvelle URL est envoyée avec succès, vérifiez votre email et accédez à l'URL pour terminer les étapes de configuration de votre compte stripe.",
                "EN": "The new URL has been sent successfully, check your email and access the URL to complete the steps for setting up your stripe account."
            },
            "stripe.success.operation": {
                "FR": "l'opération a été réalisée avec succès",
                "EN": "the operation has been successfully completed"
            },
            "sub_category.not_found": {
                "FR": "cette sous-catégorie n'existe pas.",
                "EN": "this sub category is not found."
            },
            "sub_category.not_foundID": {
                "FR": "aucune sous-catégorie trouvée avec l'identifiant",
                "EN": "sub category not found with this ID :"
            },
            "terms_and_conditions.not_found": {
                "FR": "termes et conditions introuvables.",
                "EN": "terms and conditions not found."
            },
            "token.expired": {
                "FR": "votre JWT token est expiré.",
                "EN": "your JWT token is expired."
            },
            "token.invalid": {
                "FR": "votre JWT token n'est pas valide, veuillez vous reconnecter pour en obtenir un nouveau.",
                "EN": "your JWT token is invalid, please log in again to get a new one."
            },
            "totalPrice.non.vide": {
                "FR": "ajouter un prix total.",
                "EN": "add a total price!"
            },
            "updated": {
                "FR": "mis à jour!",
                "EN": "updated!"
            },
            "userID.non.null": {
                "FR": "user_id est obligatoire.",
                "EN": "user_id is mandatory."
            },
            "ustensile.not_found": {
                "FR": "ustensile introuvable.",
                "EN": "utensil not found."
            },
            "utensil.not.foundID": {
                "FR": "aucun ustensile avec l'identifiant :",
                "EN": "no utensil found with this ID :"
            },
            "vide": {
                "FR": "ne doit pas être vide.",
                "EN": "should not be blank."
            },
            "video.invalid.ext": {
                "FR": "extension vidéo invalide.",
                "EN": "invalid video extension."
            },
            "video.not_found": {
                "FR": "vous devez ajouter une vidéo personnalisée avant le téléchargement du bon UPS.",
                "EN": "you must add a personal video before downloading the UPS sticker."
            },
            "parseRequiredErrorType": {
                "FR": "Le champ est obligatoire",
                "EN": "The field is required"
            },
            "parsePasswordRequiredErrorTypeShort": {
                "FR": "Le mot de passe doit être valide",
                "EN": "Password must be valid"
            },
            "parseEmailErrorTypeValid": {
                "FR": "L'email doit être valide",
                "EN": "Email must be valid"
            },
            "parsePasswordRequiredErrorType": {
                "FR": "Le mot de passe est obligatoire",
                "EN": "Password is required"
            },
            "video_command.not_found": {
                "FR": "la vidéo n'existe pas.",
                "EN": "video not found."
            },
            "not_include_region_europa": {
                "FR": "Malheureusement nous ne livrons pas encore votre région, mais ça arrive bientôt.",
                "EN": "Unfortunately, we’re not delivering your region yet, but it’s coming soon."
            },
            "your_profile_in_step": {
                "FR": "votre profil reste en",
                "EN": "Your profile is on step"
            },
            "your_profile_is_completed": {
                "FR": "votre profil est déjà complété",
                "EN": "your profile is already completed."
            },
            "email_or_password_incorrect": {
                "FR": "email ou mot de passe invalide",
                "EN": "email or password is invalid"
            },
            "expired_date": {
                "FR": "la date est expirée",
                "EN": "date is expired"
            },
            "delivery_date_not_available": {
                "FR": "la date de livraison est indisponible",
                "EN": "delivery date not available"
            },
            "count_command_restaurant_by_day_expired": {
                "FR": "Les commandes des restaurants ont dépassé la limite",
                "EN": "Restaurant orders exceeded limit"
            },
            "command_id_type_integer": {
                "FR": "l'identifiant de la commande doit être integer.",
                "EN": "the command identifier must be integer."
            },
            "promoCode_used": {
                "FR": "Vous avez déjà utilisé le code de réduction",
                "EN": "You have already used the discount code"
            },
            "profile.complete": {
                "FR": "ce profil existe déjà.",
                "EN": "your profile already completed."
            },
            "client.valid": {
                "FR": "client validé avec succès.",
                "EN": "customer successfully validated."
            },
            "vtt_file.invalid.ext": {
                "FR": "extension vtt invalide.",
                "EN": "invalid vtt extension"
            },
            "payment.toaster": {
                "FR": "paiement validé",
                "EN": "Order payment success"
            },
            "terms_and_conditiond.not_found": {
                "FR": "Les termes et conditions sont introuvables.",
                "EN": "Terms and conditions are not found."
            },
            "Messages_END": {
                "FR": null,
                "EN": null
            },
            "Validations": {
                "FR": null,
                "EN": null
            },
            "active.type.boolean": {
                "FR": "active doit être de type booléen.",
                "EN": "active should be of type boolean."
            },
            "activeOption.type.string": {
                "FR": "active_option doit être une chaîne de caractères.",
                "EN": "active_option should be of type string."
            },
            "address.non.vide": {
                "FR": "address ne doit pas être vide.",
                "EN": "address should not be blank."
            },
            "address.type.string": {
                "FR": "address doit être une chaîne de caractères.",
                "EN": "address should be of type string."
            },
            "addresseID.non.vide": {
                "FR": "address_id est obligatoire.",
                "EN": "address_id should not be blank."
            },
            "addresseID.type.entier": {
                "FR": "address_id doit être de type entier.",
                "EN": "address_id should be of type integer."
            },
            "aller.non.vide": {
                "FR": "allergenes ne doit pas être vide.",
                "EN": "allergenes should not be blank."
            },
            "aller.type.string": {
                "FR": "allergenes doit être une chaîne de caractères.",
                "EN": "allergenes should be of type string."
            },
            "appartementNum.type.string": {
                "FR": "apartment_number doit être une chaîne de caractères.",
                "EN": "apartment_number should be of type string."
            },
            "avaiDaysWeek.not.array": {
                "FR": "available_days_week tableau ne doit pas être vide.",
                "EN": "available_days_week array should not be blank."
            },
            "avaiDaysWeek.type.array": {
                "FR": "available_days_week doit être de type tableau.",
                "EN": "available_days_week should be of type array."
            },
            "callCode.non.vide": {
                "FR": "calling_code ne doit pas être vide.",
                "EN": "calling_code should not be blank."
            },
            "callCode.type.string": {
                "FR": "calling_code doit être une chaîne de caractères.",
                "EN": "calling_code should be of type string."
            },
            "category.non.vide": {
                "FR": "categorie ne doit pas être vide.",
                "EN": "categorie should not be blank."
            },
            "category.type.entier": {
                "FR": "categorie doit être de type entier.",
                "EN": "categorie should be of type integer."
            },
            "category.type.string": {
                "FR": "categorie doit être une chaîne de caractères.",
                "EN": "categorie should be of type string."
            },
            "categoryID.non.vide": {
                "FR": "category_id ne doit pas être vide.",
                "EN": "category_id should not be blank."
            },
            "categoryID.type.entier": {
                "FR": "category_id doit être de type entier.",
                "EN": "category_id should be of type integer."
            },
            "city.non.vide": {
                "FR": "city ne doit pas être vide.",
                "EN": "city should not be blank."
            },
            "city.type.string": {
                "FR": "city doit être une chaîne de caractères.",
                "EN": "city should be of type string."
            },
            "claim.type.choices": {
                "FR": "status ne doit être soit 'bon livraison' soit 'commandes non lus'.",
                "EN": "status must be either 'bon livraison' or 'commandes non lus'."
            },
            "claimChef.type.choices": {
                "FR": "status doit être soit 'Not treated' soit 'In progress' soit 'treated'.",
                "EN": "status must be either 'Not treated' or 'In progress' or 'treated'."
            },
            "code.exact.quatre": {
                "FR": "code doit être de 4 chiffres.",
                "EN": "code must be 4 digits."
            },
            "code.non.vide": {
                "FR": "code ne doit pas être vide.",
                "EN": "code should not be blank."
            },
            "code.type.entier": {
                "FR": "code doit être de type entier.",
                "EN": "code should be of type integer."
            },
            "codePays.non.vide": {
                "FR": "country_code ne doit pas être vide.",
                "EN": "country_code should not be blank."
            },
            "codePays.type.string": {
                "FR": "country_code doit être une chaîne de caractères.",
                "EN": "country_code should be of type string."
            },
            "codePostal.non.vide": {
                "FR": "postal_code ne doit pas être vide.",
                "EN": "postal_code should not be blank."
            },
            "codePostal.type.string": {
                "FR": "postal_code doit être une chaîne de caractères.",
                "EN": "postal_code should be of type string."
            },
            "colisID.non.vide": {
                "FR": "id_colis ne doit pas être vide.",
                "EN": "id_colis should not be blank."
            },
            "colisID.type.int": {
                "FR": "id_colis doit être de type entier.",
                "EN": "id_colis should be of type integer."
            },
            "commandeID.non.vide": {
                "FR": "command_id ne doit pas être vide.",
                "EN": "command_id should not be blank."
            },
            "commandeID.type.entier": {
                "FR": "command_id doit être de type entier.",
                "EN": "command_id should be of type integer."
            },
            "compPack.not.array": {
                "FR": "composition_package tableau ne doit pas être vide.",
                "EN": "composition_package array should not be blank."
            },
            "compPack.type.array": {
                "FR": "composition_package doit être de type tableau.",
                "EN": "composition_package should be of type array."
            },
            "consPlat.not.array": {
                "FR": "conservation_plate tableau ne doit pas être vide.",
                "EN": "conservation_plate array should not be blank."
            },
            "consPlat.type.array": {
                "FR": "conservation_plate doit être de type tableau.",
                "EN": "conservation_plate should be of type array."
            },
            "convID.non.vide": {
                "FR": "id_conservation ne doit pas être vide.",
                "EN": "id_conservation should not be blank."
            },
            "convID.type.int": {
                "FR": "id_conservation doit être de type entier.",
                "EN": "id_conservation should be of type integer."
            },
            "country.non.vide": {
                "FR": "country ne doit pas être vide.",
                "EN": "country should not be blank."
            },
            "countryID.non.entier": {
                "FR": "country_id doit être de type entier.",
                "EN": "country_id should be of type integer."
            },
            "covPict.not.array": {
                "FR": "cover_picture tableau ne doit pas être vide.",
                "EN": "cover_picture array should not be blank."
            },
            "covPict.type.array": {
                "FR": "cover_picture doit être de type tableau.",
                "EN": "cover_picture should be of type array."
            },
            "coverPic.non.vide": {
                "FR": "cover_picture ne doit pas être vide.",
                "EN": "cover_picture should not be blank."
            },
            "coverPic.type.string": {
                "FR": "cover_picture doit être une chaîne de caractères.",
                "EN": "cover_picture should be of type string."
            },
            "date.non.vide": {
                "FR": "deliver_at ne doit pas être vide.",
                "EN": "deliver_at should not be blank."
            },
            "date.type.date": {
                "FR": "deliver_at doit être de type date au format YYYY-mm-dd.",
                "EN": "deliver_at should be of type date in the format 'YYYY-mm-dd'."
            },
            "day.non.vide": {
                "FR": "day ne doit pas être vide.",
                "EN": "day should not be blank."
            },
            "day.type.string": {
                "FR": "day doit être une chaîne de caractères.",
                "EN": "day should be of type string."
            },
            "daysWeek.type.boolean": {
                "FR": "is_available_days_week doit être de type booléen.",
                "EN": "is_available_days_week should be of tye boolean."
            },
            "desc.non.vide": {
                "FR": "description ne doit pas être vide.",
                "EN": "description should not be blank."
            },
            "desc.type.string": {
                "FR": "description doit être une chaîne de caractères.",
                "EN": "description should be of type string."
            },
            "dessert.non.vide": {
                "FR": "dessert ne doit pas être vide.",
                "EN": "dessert should not be blank."
            },
            "dessert.type.entier": {
                "FR": "dessert doit être de type entier.",
                "EN": "dessert should be of type integer."
            },
            "desserts.non.vide": {
                "FR": "desserts ne doit pas être vide.",
                "EN": "desserts should not be blank."
            },
            "desserts.not.array": {
                "FR": "desserts tableau ne doit pas être vide.",
                "EN": "desserts array should not be blank."
            },
            "desserts.type.array": {
                "FR": "desserts doit être de type tableau.",
                "EN": "desserts should be of type array."
            },
            "deviceID.non.vide": {
                "FR": "device_id ne doit pas être vide.",
                "EN": "device_id should not be blank."
            },
            "deviceID.type.string": {
                "FR": "device_id doit être une chaîne de caractères.",
                "EN": "device_id should be of type string."
            },
            "digiCode.type.string": {
                "FR": "digi_code doit être une chaîne de caractères.",
                "EN": "digi_code should be of type string."
            },
            "email.non.valide": {
                "FR": "email non valide.",
                "EN": "invalid email."
            },
            "email.non.vide": {
                "FR": "email ne doit pas être vide.",
                "EN": "email should not be blank."
            },
            "entries.not.array": {
                "FR": "entries tableau ne doit pas être vide.",
                "EN": "entries array should not be blank."
            },
            "entries.type.array": {
                "FR": "entries doit être de type tableau.",
                "EN": "entries should be of type array."
            },
            "entry.non.vide": {
                "FR": "entries ne doit pas être vide.",
                "EN": "entries should not be blank."
            },
            "entryone.non.vide": {
                "FR": "entry ne doit pas être vide.",
                "EN": "entry should not be blank."
            },
            "entryone.type.string": {
                "FR": "entry doit être une chaîne de caractères.",
                "EN": "entry should be of type string."
            },
            "factAddr.non.vide": {
                "FR": "facture_address ne doit pas être vide.",
                "EN": "facture_address should not be blank."
            },
            "factAddr.type.string": {
                "FR": "facture_address doit être une chaîne de caractères.",
                "EN": "facture_address should be of type string."
            },
            "fcmToken.non.vide": {
                "FR": "fcm_token ne doit pas être vide.",
                "EN": "fcm_token should not be blank."
            },
            "fcmToken.type.string": {
                "FR": "fcm_token doit être une chaîne de caractères.",
                "EN": "fcm_token should be of type string."
            },
            "firstName.non.vide": {
                "FR": "firstName ne doit pas être vide.",
                "EN": "firstName should not be blank."
            },
            "firstName.type.string": {
                "FR": "firstName doit être une chaîne de caractères.",
                "EN": "firstName should be of type string."
            },
            "gif.not.array": {
                "FR": "gif tableau ne doit pas être vide.",
                "EN": "gif array should not be blank."
            },
            "gif.type.array": {
                "FR": "gif doit être de type tableau.",
                "EN": "gif should be of type array."
            },
            "gray.bool": {
                "FR": "is_grey doit être de type booléen.",
                "EN": "is_grey should be of tye boolean."
            },
            "holiday.type.boolean": {
                "FR": "is_available_holidays doit être de type booléen.",
                "EN": "is_available_holidays should be of tye boolean."
            },
            "icon.non.vide": {
                "FR": "icon ne doit pas être vide.",
                "EN": "icon should not be blank."
            },
            "icon.type.string": {
                "FR": "icon doit être une chaîne de caractères.",
                "EN": "icon should be of type string."
            },
            "id.non.vide": {
                "FR": "id ne doit pas être vide.",
                "EN": "id should not be blank."
            },
            "id.type.entier": {
                "FR": "id doit être de type entier.",
                "EN": "id should be of type integer."
            },
            "idCmd.type.entier": {
                "FR": "id_command doit être de type entier.",
                "EN": "id_command should be of type integer."
            },
            "idMenu.type.entier": {
                "FR": "id_menu doit être de type entier.",
                "EN": "id_menu should be of type integer."
            },
            "idPlate.array.elt.non.vide": {
                "FR": "est obligatoire.",
                "EN": "should not be blank."
            },
            "idPlate.array.elt.type.entier": {
                "FR": "doit être de type entier.",
                "EN": "should be of type integer."
            },
            "idPlate.array.non.vide": {
                "FR": "id_plate ne doit pas être un tableau vide.",
                "EN": "id_plate should not be empty array."
            },
            "idPlate.non.vide": {
                "FR": "id_plate est obligatoire.",
                "EN": "id_plate should not be blank."
            },
            "idPlate.type.array": {
                "FR": "id_plate doit être de type tableau.",
                "EN": "id_plate should be of type array."
            },
            "idRestaurant.non.vide": {
                "FR": "id_restaurant ne doit pas être vide.",
                "EN": "id_restaurant should not be blank."
            },
            "idRestaurantID.type.entier": {
                "FR": "id_restaurant doit être de type entier.",
                "EN": "id_restaurant should be of type integer."
            },
            "img.non.vide": {
                "FR": "image ne doit pas être vide.",
                "EN": "image should not be blank."
            },
            "img.not.array": {
                "FR": "image tableau ne doit pas être vide.",
                "EN": "image array should not be blank."
            },
            "img.type.array": {
                "FR": "image doit être de type tableau.",
                "EN": "image should be of type array."
            },
            "img.type.string": {
                "FR": "image doit être une chaîne de caractères.",
                "EN": "image should be of type string."
            },
            "imgChef.not.array": {
                "FR": "image_chef tableau ne doit pas être vide.",
                "EN": "image_chef array should not be blank."
            },
            "imgChef.type.array": {
                "FR": "image_chef doit être de type tableau.",
                "EN": "image_chef should be of type array."
            },
            "imgPlat.not.array": {
                "FR": "imagePlats tableau ne doit pas être vide.",
                "EN": "imagePlats array should not be blank."
            },
            "imgPlat.type.array": {
                "FR": "imagePlats doit être de type tableau.",
                "EN": "imagePlats should be of type array."
            },
            "imgProfile.not.array": {
                "FR": "image_profile tableau ne doit pas être vide.",
                "EN": "image_profile array should not be blank."
            },
            "imgProfile.type.array": {
                "FR": "image_profile doit être de type tableau.",
                "EN": "image_profile should be of type array."
            },
            "img_menu": {
                "FR": "vous devez choisir une image qui a une dimension parmi les suivantes : 16:9, 3:4,4:3,1:1",
                "EN": "you should choose an image that has one dimension among the following : 16:9, 3:4,4:3,1:1"
            },
            "increment.type.boolean": {
                "FR": "increment doit être de type booléen.",
                "EN": "increment should be of tye boolean."
            },
            "invalid_image_category": {
                "FR": "vous devez choisir une image qui a une dimension parmi les suivantes : 16:9, 3:4, 4:3",
                "EN": "you must choose an image that has one dimension among the following : 16:9, 3:4, 4:3"
            },
            "invalid_image_chef": {
                "FR": "vous devez choisir une image qui a une dimension parmi les suivantes : 3:4, 4:3",
                "EN": "you must choose an image that has one dimension among the following : 3:4, 4:3"
            },
            "invalid_image_menu": {
                "FR": "vous devez choisir une image qui a une dimension parmi les suivantes : 16:9, 3:4,4:3,1:1",
                "EN": "you must choose an image that has one dimension among the following : 16:9, 3:4,4:3,1:1"
            },
            "invalid_image_plats": {
                "FR": "vous devez choisir une image qui a une dimension parmi les suivantes : 1:1, 3:4, 4:3",
                "EN": "you must choose an image that has one dimension among the following : 1:1, 3:4, 4:3"
            },
            "invalid_image_restaurant": {
                "FR": "vous devez choisir une image qui a une dimension parmi les suivantes : 3:4, 4:3",
                "EN": "you must choose an image that has one dimension among the following : 3:4, 4:3"
            },
            "invalid_type_stars_integer": {
                "FR": "stars doivent être de type entier.",
                "EN": "stars should be of type integer."
            },
            "invalid_value_stars": {
                "FR": "vous devez choisir un nombre des étoilés parmi les suivantes : 1,2,3",
                "EN": "you must choose a number of stars among the following : 1,2,3"
            },
            "isAvailable.non.null": {
                "FR": "is_available ne doit pas être null.",
                "EN": "is_available should not be null."
            },
            "isAvailable.non.vide": {
                "FR": "is_available ne doit pas être vide.",
                "EN": "is_available should not be blank."
            },
            "isAvailable.type.boolean": {
                "FR": "is_available doit être de type booléen.",
                "EN": "is_available should be of tye boolean."
            },
            "isBestSell.non.null": {
                "FR": "is_best_seller ne doit pas être null.",
                "EN": "is_best_seller should not be null."
            },
            "isBestSell.non.vide": {
                "FR": "is_best_seller ne doit pas être vide.",
                "EN": "is_best_seller should not be blank."
            },
            "isBestSell.type.bool": {
                "FR": "is_best_seller doit être de type booléen.",
                "EN": "is_best_seller should be of tye boolean."
            },
            "isGray.non.null": {
                "FR": "is_grey ne doit pas être null.",
                "EN": "is_grey should not be null."
            },
            "isGray.non.vide": {
                "FR": "is_grey ne doit pas être vide.",
                "EN": "is_grey should not be blank."
            },
            "isGray.type.boolean": {
                "FR": "is_grey doit être de type booléen.",
                "EN": "is_grey should be of tye boolean."
            },
            "isNotify.type.boolean": {
                "FR": "is_notify doit être de type booléen.",
                "EN": "is_notify should be of tye boolean."
            },
            "lastName.non.vide": {
                "FR": "lastName ne doit pas être vide.",
                "EN": "lastName should not be blank."
            },
            "lastName.type.string": {
                "FR": "lastName doit être une chaîne de caractères.",
                "EN": "lastName should be of type string."
            },
            "listCateg.not.array": {
                "FR": "list_categories tableau ne doit pas être vide.",
                "EN": "list_categories array should not be blank."
            },
            "listCateg.type.array": {
                "FR": "list_categories doit être de type tableau.",
                "EN": "list_categories should be of type array."
            },
            "listHolidays.not.array": {
                "FR": "list_holidays tableau ne doit pas être vide.",
                "EN": "list_holidays array should not be blank."
            },
            "listHolidays.type.array": {
                "FR": "list_holidays doit être de type tableau.",
                "EN": "list_holidays should be of type array."
            },
            "listMenu.non.vide": {
                "FR": "list_menus ne doit pas être vide.",
                "EN": "list_menus should not be blank."
            },
            "listMenu.not.array": {
                "FR": "list_menus tableau ne doit pas être vide.",
                "EN": "list_menus array should not be blank."
            },
            "listMenu.type.array": {
                "FR": "list_menus doit être de type tableau.",
                "EN": "list_menus should be of type array."
            },
            "listPlat.non.vide": {
                "FR": "list_plates ne doit pas être vide.",
                "EN": "list_plates should not be blank."
            },
            "listPlat.not.array": {
                "FR": "list_plates tableau ne doit pas être vide.",
                "EN": "list_plates array should not be blank."
            },
            "listPlat.type.array": {
                "FR": "list_plates doit être de type tableau.",
                "EN": "list_plates should be of type array."
            },
            "listRestau.not.array": {
                "FR": "list_restaurants tableau ne doit pas être vide.",
                "EN": "list_restaurants array should not be blank."
            },
            "listRestau.type.array": {
                "FR": "list_restaurants doit être de type tableau.",
                "EN": "list_restaurants should be of type array."
            },
            "listSubCateg.not.array": {
                "FR": "list_sub_categories tableau ne doit pas être vide.",
                "EN": "list_sub_categories array should not be blank."
            },
            "listSubCateg.type.array": {
                "FR": "list_sub_categories doit être de type tableau.",
                "EN": "list_sub_categories should be of type array."
            },
            "max.prep.cmd.day.entier": {
                "FR": "max_prepared_commands_per_day doit être de type entier.",
                "EN": "max_prepared_commands_per_day should be of type integer."
            },
            "menuID.non.vide": {
                "FR": "menu_id ne doit pas être vide.",
                "EN": "menu_id should not be blank."
            },
            "menuID.type.entier": {
                "FR": "menu_id doit être de type entier.",
                "EN": "menu_id should be of type integer."
            },
            "message.min.cinq": {
                "FR": "message doit être aun minimum 5 caractères.",
                "EN": "message should be at least 5 characters."
            },
            "message.non.vide": {
                "FR": "message ne doit pas être vide.",
                "EN": "message should not be blank."
            },
            "message.type.string": {
                "FR": "message doit être une chaîne de caractères.",
                "EN": "message should be of type string."
            },
            "mis": {
                "FR": "manquant",
                "EN": "missing"
            },
            "msgAnsw.non.vide": {
                "FR": "message_answer ne doit pas être vide.",
                "EN": "message_answer should not be blank."
            },
            "msgAnsw.type.string": {
                "FR": "message_answer doit être une chaîne de caractères.",
                "EN": "message_answer should be of type string."
            },
            "msgCust.type.string": {
                "FR": "custom_message doit être une chaîne de caractères.",
                "EN": "custom_message should be of type string."
            },
            "nbrHour.non.vide": {
                "FR": "nbr_hour ne doit pas être vide.",
                "EN": "nbr_hour should not be blank."
            },
            "nbrHour.type.entier": {
                "FR": "nbr_hour doit être de type entier.",
                "EN": "nbr_hour should be of type integer."
            },
            "newFcmToken.non.vide": {
                "FR": "new_fcm_token ne doit pas être vide.",
                "EN": "new_fcm_token should not be blank."
            },
            "newFcmToken.type.string": {
                "FR": "new_fcm_token doit être une chaîne de caractères.",
                "EN": "new_fcm_token should be of type string."
            },
            "newPassword.min.huit": {
                "FR": "new_password doit contenir au moins 8 caractères.",
                "EN": "new_password should contain at least 8 characters."
            },
            "newPassword.non.vide": {
                "FR": "new_password ne doit pas être vide.",
                "EN": "new_password should not be blank."
            },
            "nom.non.null": {
                "FR": "name ne doit pas être null.",
                "EN": "name should not be null."
            },
            "nom.non.vide": {
                "FR": "name ne doit pas être vide.",
                "EN": "name should not be blank."
            },
            "nom.type.string": {
                "FR": "name doit être une chaîne de caractères.",
                "EN": "name should be of type string."
            },
            "nomFr.non.vide": {
                "FR": "nom ne doit pas être vide.",
                "EN": "nom should not be blank."
            },
            "nomFr.type.string": {
                "FR": "nom doit être une chaîne de caractères.",
                "EN": "nom should be of type string."
            },
            "not.array.vide": {
                "FR": "Le tableau ne doit pas être vide.",
                "EN": "array should not be blank."
            },
            "num.non.vide": {
                "FR": "number ne doit pas être vide.",
                "EN": "number should not be blank."
            },
            "num.type.int": {
                "FR": "number doit être de type entier.",
                "EN": "number should be of type integer."
            },
            "password.min.huit": {
                "FR": "password doit contenir au moins 8 caractères.",
                "EN": "password should contain at least 8 characters."
            },
            "password.non.vide": {
                "FR": "password ne doit pas être vide.",
                "EN": "password should not be blank."
            },
            "password.type.string": {
                "FR": "password doit être une chaîne de caractères.",
                "EN": "password should be of type string."
            },
            "paymentIntent.non.vide": {
                "FR": "paymentIntent est obligatoire.",
                "EN": "paymentIntent should not be blank."
            },
            "paymentIntent.type.string": {
                "FR": "paymentIntent doit être une chaîne de caractères.",
                "EN": "paymentIntent should be of type string."
            },
            "pays.non.vide": {
                "FR": "country ne doit pas être vide.",
                "EN": "country should not be blank."
            },
            "pays.type.string": {
                "FR": "country doit être une chaîne de caractères.",
                "EN": "country should be of type string."
            },
            "permission.non.vide": {
                "FR": "permission ne doit pas être vide.",
                "EN": "permission should not be blank."
            },
            "permission.type.array": {
                "FR": "permission doit être de type tableau.",
                "EN": "permission should be of type array."
            },
            "phone.min.huit": {
                "FR": "phone doit être contenir au moins 8 caractères.",
                "EN": "phone should contain at least 8 characters."
            },
            "phone.non.vide": {
                "FR": "phone ne doit pas être vide.",
                "EN": "phone should not be blank."
            },
            "phone.type.string": {
                "FR": "phone doit être une chaîne de caractères.",
                "EN": "phone should be of type string."
            },
            "plat.non.vide": {
                "FR": "plat ne doit pas être vide.",
                "EN": "plat should not be blank."
            },
            "plat.type.entier": {
                "FR": "plat doit être de type entier.",
                "EN": "plat should be of type integer."
            },
            "platID.non.vide": {
                "FR": "plate_id ne doit pas être vide.",
                "EN": "plate_id should not be blank."
            },
            "platID.type.entier": {
                "FR": "plate_id doit être de type entier.",
                "EN": "plate_id should be of type integer."
            },
            "plates.not.array": {
                "FR": "plates tableau ne doit pas être vide.",
                "EN": "plates array should not be blank."
            },
            "plates.type.array": {
                "FR": "plates doit être de type tableau.",
                "EN": "plates should be of type array."
            },
            "plats.non.vide": {
                "FR": "plates ne doit pas être vide.",
                "EN": "plates should not be blank."
            },
            "position.array": {
                "FR": "position doit être un tableau.",
                "EN": "position should be an array."
            },
            "position.lat.non.vide": {
                "FR": "latitude ne doit pas être vide.",
                "EN": "latitude should not be blank."
            },
            "position.lat.type.double": {
                "FR": "latitude doit être de type double.",
                "EN": "latitude should be of type double."
            },
            "position.long.non.vide": {
                "FR": "longitude ne doit pas être vide.",
                "EN": "longitude should not be blank."
            },
            "position.long.type.double": {
                "FR": "longitude doit être de type double.",
                "EN": "longitude should be of type double."
            },
            "prenom.non.vide": {
                "FR": "prenom ne doit pas être vide.",
                "EN": "prenom should not be blank."
            },
            "prenom.type.string": {
                "FR": "prenom doit être une chaîne de caractères.",
                "EN": "prenom should be of type string."
            },
            "prep.not.array": {
                "FR": "preparation tableau ne doit pas être vide.",
                "EN": "preparation array should not be blank."
            },
            "prep.type.array": {
                "FR": "preparation doit être de type tableau.",
                "EN": "preparation should be of type array."
            },
            "prepStage.not.array": {
                "FR": "preparation_stage tableau ne doit pas être vide.",
                "EN": "preparation_stage array should not be blank."
            },
            "prepStage.type.array": {
                "FR": "preparation_stage doit être de type tableau.",
                "EN": "preparation_stage should be of type array."
            },
            "prix.non.vide": {
                "FR": "price ne doit pas être vide.",
                "EN": "price should not be blank."
            },
            "prix.type.float": {
                "FR": "price doit être de type float.",
                "EN": "price should be of type float."
            },
            "prix.type.num": {
                "FR": "price doit être de type numérique.",
                "EN": "price should be of type numeric."
            },
            "products.non.vide": {
                "FR": "products ne doit pas être vide.",
                "EN": "products should not be blank."
            },
            "products.type.array": {
                "FR": "products doit être un tableau.",
                "EN": "products should be an array."
            },
            "providerId.non.vide": {
                "FR": "provider_id ne doit pas être vide.",
                "EN": "provider_id should not be blank."
            },
            "providerId.type.string": {
                "FR": "provider_id doit être une chaîne de caractères.",
                "EN": "provider_id should be of type string."
            },
            "providerName.choices": {
                "FR": "provider_name doit être parmi les choix suivants: Apple, Google or Facebook.",
                "EN": "provider_name should be among the following choices: Apple, Google or Facebook."
            },
            "providerName.non.vide": {
                "FR": "provider_name doit pas être vide.",
                "EN": "provider_name should not be blank."
            },
            "quantite.non.vide": {
                "FR": "quantite ne doit pas être vide.",
                "EN": "quantite should not be blank."
            },
            "quantite.type.entier": {
                "FR": "quantite doit être de type entier.",
                "EN": "quantite should be of type integer."
            },
            "refPlat.non.vide": {
                "FR": "parent_ref_plat ne doit pas être vide.",
                "EN": "parent_ref_plat should not be blank."
            },
            "refPlat.type.string": {
                "FR": "parent_ref_plat doit être une chaîne de caractères.",
                "EN": "parent_ref_plat should be of type string."
            },
            "remAddr.non.vide": {
                "FR": "removal_address ne doit pas être vide.",
                "EN": "removal_address should not be blank."
            },
            "remAddr.type.string": {
                "FR": "removal_address doit être une chaîne de caractères.",
                "EN": "removal_address should be of type string."
            },
            "restaurantID.non.vide": {
                "FR": "restaurant_id ne doit pas être vide.",
                "EN": "restaurant_id should not be blank."
            },
            "restaurantID.type.entier": {
                "FR": "restaurant_id doit être de type entier.",
                "EN": "restaurant_id should be of type integer."
            },
            "roles.non.vide": {
                "FR": "roles ne doit pas être vide.",
                "EN": "roles should not be blank."
            },
            "roles.type.string": {
                "FR": "roles doit être une chaîne de caractères.",
                "EN": "roles should be of type string."
            },
            "searchType.choices": {
                "FR": "type de recherche doit être parmi la liste suivante: Chef, restaurant, plate, menu, category et sub_category.",
                "EN": "choice type should be from the following list: Chef, restaurant, plate, menu, category et sub_category"
            },
            "siret.type.string": {
                "FR": "siret doit être une chaîne de caractères.",
                "EN": "siret should be of type string."
            },
            "stars.non.vide": {
                "FR": "stars ne doit pas être vide.",
                "EN": "stars should not be blank."
            },
            "state.non.vide": {
                "FR": "state ne doit pas être vide.",
                "EN": "state should not be blank."
            },
            "state.type.string": {
                "FR": "state doit être une chaîne de caractères.",
                "EN": "state should be of type string."
            },
            "status.non.vide": {
                "FR": "status ne doit pas être vide.",
                "EN": "status should not be blank."
            },
            "status.type.bool": {
                "FR": "status doit être de type booléen.",
                "EN": "status should be of tye boolean."
            },
            "subCategory.non.vide": {
                "FR": "sub_category ne doit pas être vide.",
                "EN": "sub_category should not be blank."
            },
            "subCategory.type.entier": {
                "FR": "sub_category doit être de type entier.",
                "EN": "sub_category should be of type integer."
            },
            "subject.non.vide": {
                "FR": "subject ne doit pas être vide.",
                "EN": "subject should not be blank."
            },
            "subject.type.string": {
                "FR": "subject doit être une chaîne de caractères.",
                "EN": "subject should be of type string."
            },
            "text.non.null": {
                "FR": "text ne doit pas être null.",
                "EN": "text should not be null."
            },
            "text.non.vide": {
                "FR": "text ne doit pas être vide.",
                "EN": "text should not be blank."
            },
            "text.type.string": {
                "FR": "text doit être une chaîne de caractères.",
                "EN": "text should be of type string."
            },
            "thumb.type.string": {
                "FR": "thumbnail doit être une chaîne de caractères.",
                "EN": "thumbnail should be of type string."
            },
            "thumbnail.non.vide": {
                "FR": "thumbnail ne doit pas être vide.",
                "EN": "thumbnail should not be blank."
            },
            "thumbnail.type.string": {
                "FR": "thumbnail doit être une chaîne de caractères.",
                "EN": "thumbnail should be of type string."
            },
            "title.non.vide": {
                "FR": "title ne doit pas être vide.",
                "EN": "title should not be blank."
            },
            "title.type.string": {
                "FR": "title doit être une chaîne de caractères.",
                "EN": "title should be of type string."
            },
            "titleComp.non.vide": {
                "FR": "titre_complet ne doit pas être vide.",
                "EN": "titre_complet should not be blank."
            },
            "titleComp.type.str": {
                "FR": "titre_complet doit être une chaîne de caractères.",
                "EN": "titre_complet should be of type string."
            },
            "titleComp.type.string": {
                "FR": "titre_complet doit être une chaîne de caractères.",
                "EN": "titre_complet should be of type string."
            },
            "token.non.vide": {
                "FR": "token ne doit pas être vide.",
                "EN": "token should not be blank."
            },
            "token.type.string": {
                "FR": "token doit être une chaîne de caractères.",
                "EN": "token should be of type string."
            },
            "totPrix.non.vide": {
                "FR": "total_price ne doit pas être vide.",
                "EN": "total_price should not be blank."
            },
            "totPrix.type.float": {
                "FR": "total_price doit être de type float.",
                "EN": "total_price should be of type float."
            },
            "totPrix.type.num": {
                "FR": "total_price doit être de type numérique.",
                "EN": "total_price should be of type numeric."
            },
            "trend.type.boolean": {
                "FR": "trending doit être de type booléen.",
                "EN": "trending should be of tye boolean."
            },
            "tva.type.string": {
                "FR": "tva doit être une chaîne de caractères.",
                "EN": "tva should be of type string."
            },
            "type.array": {
                "FR": "Doit être de type tableau.",
                "EN": "should be of type array."
            },
            "type.integer": {
                "FR": "type doit être de type entier.",
                "EN": "type should be of type integer."
            },
            "type.non.vide": {
                "FR": "type ne doit pas être vide.",
                "EN": "type should not be blank."
            },
            "type.nu": {
                "FR": "type ne doit pas être vide.",
                "EN": "type should not be blank."
            },
            "type.string": {
                "FR": "type doit être une chaîne de caractères.",
                "EN": "type should be of type string."
            },
            "typeID.inf.cinq": {
                "FR": "id_type doit être inférieur à 5.",
                "EN": "id_type should be less than 5."
            },
            "typeID.non.vide": {
                "FR": "id_type ne doit pas être vide.",
                "EN": "id_type should not be blank."
            },
            "typeID.sup.deux": {
                "FR": "id_type doit être supérieur à 2.",
                "EN": "id_type should be greater than 2."
            },
            "typeID.type.entier": {
                "FR": "id_type doit être de type entier.",
                "EN": "id_type should be of type integer."
            },
            "url.non.vide": {
                "FR": "url ne doit pas être vide.",
                "EN": "url should not be blank."
            },
            "url.nu": {
                "FR": "url ne doit pas être vide.",
                "EN": "url should not be blank."
            },
            "url.type.string": {
                "FR": "url doit être une chaîne de caractères.",
                "EN": "url should be of type string."
            },
            "userID.non.vide": {
                "FR": "user_id ne doit pas être vide.",
                "EN": "user_id should not be blank."
            },
            "userID.type.entier": {
                "FR": "user_id doit être de type entier.",
                "EN": "user_id should be of type integer."
            },
            "utensil.not.array": {
                "FR": "utensils tableau ne doit pas être vide.",
                "EN": "utensils array should not be blank."
            },
            "utensil.type.array": {
                "FR": "utensils doit être de type tableau.",
                "EN": "utensils should be of type array."
            },
            "utensil.type.entier": {
                "FR": "utensil doit être de type entier.",
                "EN": "utensil should be of type integer."
            },
            "video.non.vide": {
                "FR": "video ne doit pas être vide.",
                "EN": "video should not be blank."
            },
            "video.not.array": {
                "FR": "video tableau ne doit pas être vide.",
                "EN": "video array should not be blank."
            },
            "video.type.array": {
                "FR": "video doit être de type tableau.",
                "EN": "video should be of type array."
            },
            "video.type.string": {
                "FR": "video doit être une chaîne de caractères.",
                "EN": "video should be of type string."
            },
            "weight.non.vide": {
                "FR": "weight ne doit pas être vide.",
                "EN": "weight should not be blank."
            },
            "weight.type.float": {
                "FR": "weight doit être de type float.",
                "EN": "weight should be of type float."
            },
            "message_Désabonnement-newsLettre": {
                "FR": "Désabonnement de Newsletter",
                "EN": "Newsletter unsubscribe"
            },
            "message_souscription-newsLettre": {
                "FR": "Souscription au Newsletter",
                "EN": "Newsletter subscription"
            },
            "message_desabonner": {
                "FR": "VOUS ÊTES MAINTENANT DÉSABONNÉ de notre newsletter",
                "EN": "YOU ARE NOW UNSUBSCRIBED from our newsletter"
            },
            "validationChiffre": {
                "FR": "1 CHIFFRE",
                "EN": "1 DIGIT"
            },
            "validationSpecialChar": {
                "FR": "1 CARACTÈRE SPÉCIALE",
                "EN": "1 SPECIAL CHARACTER"
            },
            "validationMajuscule": {
                "FR": "1 MAJUSCULE",
                "EN": "1 CAPITAL"
            },
            "validationPasswordSize": {
                "FR": "8 CARACTÈRES MINIMUM",
                "EN": "MINIMUM 8 CHARACTERS"
            },
            "titleTousLesChefs": {
                "FR": "TOUS LES CHEFS",
                "EN": "ALL CHEFS"
            },
            "confirmdeliveryDate": {
                "FR": "Confirmer ma date de livraison",
                "EN": "Confirm my delivery date"
            },
            "weight.type.num": {
                "FR": "weight doit être de type numérique.",
                "EN": "weight should be of type numeric."
            },
            "pivot_code.type.string": {
                "FR": "pivot_code doit être de type chaîne de caractères.",
                "EN": "pivot_code should be of type string."
            },
            "phone.type.string.string": {
                "FR": "phone_receiver doit être de type chaîne de caractères.",
                "EN": "phone_receiver should be of type string."
            },
            "short_name.type.string": {
                "FR": "short_name doit être une chaîne de caractères.",
                "EN": "short_name should be of type string."
            },
            "Validations_END": {
                "FR": null,
                "EN": null
            }

    }
