import React, {useEffect, useRef} from "react";
import './stars.scss'
import HorizontalScrollComponent from "../horizontalScrollComponents/horizontalScrollComponents";
import {useNavigate} from "react-router-dom";
import {setBackRoot} from "../../../../../shared/store/slices/Root/rootSlice";
import {useDispatch} from "react-redux";
import star0 from '../../../../../assets/icons/explore/stars/0.jpg'
import star1 from '../../../../../assets/icons/explore/stars/1.jpg'
import star2 from '../../../../../assets/icons/explore/stars/2.jpg'
import star3 from '../../../../../assets/icons/explore/stars/3.png'
import starIcon from '../../../../../assets/icons/explore/stars/star-03.svg'
import {useTranslation} from "react-i18next";
import ImageContainer from "../../../../../components/imageContainer/imageContainer";


// eslint-disable-next-line max-lines-per-function
const Stars: React.FC = () => {


  const data = [
    {
      content: star3,
      text: 'exploreKitchenScreenStarsText',
      stars: [starIcon, starIcon, starIcon],
      id:3,
    },
    {
      content: star2,
      text: 'exploreKitchenScreenStarsText',
      stars: [starIcon, starIcon],
      id:2,

    },
    {
      content: star1,
      text: 'kitchen_one_star',
      stars: [starIcon],
      id:1,
    },
    {
      content: star0,
      text: 'vogue',
      stars: [],
      id:0,

    },
  ]

  return (
    <HorizontalScrollComponent  className={'starsMainContainer'}>
      {
        data?.map((item, index) => {
          return (
            <div key={item.content} className={'animationStarCont'}>
              <StarItem config={{
                item,
                index
              }}/>
            </div>
          )
        })
      }
    </HorizontalScrollComponent>
  )
}

export default Stars;


const StarItem:React.FC<{
  config: {
    item: {
      content: string,
      text: string,
      stars: Array<string>,
      id: number,
    },
    index: number
  }
// eslint-disable-next-line max-lines-per-function
}> = ({config}) => {
  const starItemRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTimeout(() => {
      if(starItemRef.current) {
        starItemRef.current.classList.toggle('fullHeight')
      }
    }, (0.2 + config?.index * 0.2)*1000)
  }, [])

  const navigate = useNavigate();
  const dispatch = useDispatch()

  const goTo = (id:number) => {
    const path = `/all-chef/stars/${id}`;
    const state = {
      from: location.pathname,
      state: {
        Props:id
      },

    }
    dispatch(setBackRoot({path: location.pathname, ...state.state}))
    navigate(path, state);
  }

  const clickData = useRef({
    x: NaN, y: NaN
  })
  const mouseDown = (e: React.MouseEvent) => {
    clickData.current = {x: e.pageX, y: e.pageY}
  }

  const mouseUp = (e: React.MouseEvent, id: number) => {
    if (
      (e.pageX >= clickData.current.x - 6 && e.pageX <= clickData.current.x + 6) &&
      (e.pageY >= clickData.current.y - 6 && e.pageY <= clickData.current.y + 6)
    ) {
      goTo(id)
    }
  }

  const {t} = useTranslation()

  return (
    <div
      className={'starItem'}
      ref={starItemRef}
      onMouseDown={mouseDown}
      onMouseUp={(e) => mouseUp(e, config.item?.id)}
    >
      <div style={{backgroundImage: `url(${config.item.content})`}} className={'backgroundImg'}>
        <div className={'starCount'}>
          <span
            className={`number ${config.item.id ? '' : 'sans'}`}
          >{config.item.id || t('Stars')}</span>
          <span className={'iconsCont'}>
            {
              config.item.stars.map((item, index) => (
                <img key={index} draggable={false} src={item}/>
              ))
            }
          </span>
        </div>
        <div className={'starTitle'}>{config.item.text==='vogue' ? t("exploreKitchenScreenNoStars") : t(config.item.text)}</div>
      </div>
    </div>
  )
}
