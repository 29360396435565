import React from "react";
import { InputItem } from "../../core/models/Input";
import "./TextArea.scss";

interface Item {
  item: InputItem;
}

const TextArea = (Props: Item): JSX.Element => {
  return (
    <div className="textarea-blc">
      <textarea
        onChange={Props.item.onChange}
        defaultValue={Props.item.value}
        name={Props.item.name}
        id={Props.item.name}
        placeholder={Props.item.name}
        disabled={Props.item.isOnEdit}
      />
      <label htmlFor={Props.item.name}> {Props.item.name}</label>
    </div>
  );
};

export default TextArea;
