import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import HeaderAccount from "../../../components/header-account/HeaderAccount";
import { STRINGS } from "../../../core/enums/strings";
import { Address } from "../../../core/models/Address";
import {setSelectedAddress} from "../../../shared/store/slices/Root/rootSlice";
import ManageAddress from "./manage-address/ManageAddress";
import "./MyAddresses.scss";
import {useTranslation} from "react-i18next";

/* eslint-disable max-lines-per-function */
const MyAddresses = () :JSX.Element => {
const history=useNavigate()
    const dispatch = useDispatch();
  const editAddress = (item?: Address) => {
    dispatch(setSelectedAddress(item));
    history('/account/add-edit-address',{state:item})
  }
  const {t} = useTranslation()

  return (
    <div className="adresse">
      <HeaderAccount title={t('manageMyDeliveryAddresses') || STRINGS.ACCOUNT.EDIT_ADRESSES.TITLE} />
      <div className="center-horizontally address-content">
        <ManageAddress onEdit={(item?: Address) => editAddress(item)} />
      </div>
    </div>
  );
};
/* eslint-enable max-lines-per-function */

export default MyAddresses;
