import React, { useEffect, useState } from "react";
import "./auth.scss";
import Header from "../../components/header/Header";
import { STRINGS } from "../../core/enums/strings";
import Input from "../../components/input/Input";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../core/enums/paths";
import check from ".././../assets/icons/check.svg";
import checkValid from ".././../assets/icons/check-valid.svg";
import {
  checkAtLeastLength,
  checkDigit,
  checkSpecialCharacter,
  checkUpper,
} from "../../shared/Validator/validation-service";
import {
  resetPassword, sendMail, socialAccountLogin,
  verifyCode,
} from "../../shared/store/slices/Auth/AuthService";
import { toast } from "react-toastify";
import {useTranslation} from "react-i18next";
import ImageContainer from "../../components/imageContainer/imageContainer";
import  {isPossiblePhoneNumber, isValidPhoneNumber} from "react-phone-number-input";
import {GoogleLogin} from "react-google-login";
import {APP_ID_FACEBOOK_AUTHENTIFICATION, CLIENT_ID_GOOGLE_AUTHENTIFICATION} from "../../core/enums/APIS";
import {alertMessage} from "../../shared/store/interceptor";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import {isMobile} from "../../shared/utils/utils-functions";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import AppleSignin from 'react-apple-signin-auth';

interface Error {
  id?: number;
  message?: string;
  icon?: string;
  className?: string;
}
/* eslint-disable max-lines-per-function */
// eslint-disable-next-line sonarjs/cognitive-complexity
const ForgetPassword = (): JSX.Element => {
  const {t}=useTranslation()
  const error = t('NotSamePassword');

  const [token, setToken] = useState<string>("");
  const history = useNavigate();
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(true);
  const [isCode, setIsCode] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    code: "",
  });
  const [confirmErrorMessage, setConfirmErrorMessage] = useState<string>("");
  const [confirmEmptyMessage, setConfirmEmptyMessage] = useState<string>("");

  const [errorPassword, setErrorPassword] = useState<Error[]>([
    {
      id: 1,
      message: t('validationPasswordSize'),
      icon: check,
      className: "",
    },
    {
      id: 2,
      message: t('validationMajuscule'),
      icon: check,
      className: "",
    },
    {
      id: 3,
      message: t('validationSpecialChar'),
      icon: check,
      className: "",
    },
    {
      id: 4,
      message: t('validationChiffre'),
      icon: check,
      className: "",
    },
  ]);
  const [number,setNumber]=useState<any>()
  // const submit = async () => {
  //   // setIsSubmit(true);
  //   data.phone.length &&
  //     !errorMessage(data.phone || "", [
  //       { name: "required" },
  //       { name: "phone" },
  //     ], t) &&
  //     (await sendSms({ phone:data.phone.slice(1,data.phone.length) }).then((res) => {
  //       res.success && setIsCode(true);
  //     }));
  // };
  const [valid,setValid]=useState(false)
  // const checkNumber=async (e: any) => {
  //   setConfirmEmptyMessage('')
  //   if (e && isValidPhoneNumber(e) && isPossiblePhoneNumber(e) ) {
  //     setValid(true)
  //     setNumber(e)
  //     }else{
  //     setConfirmEmptyMessage(t('invalidPhoneNumber'))
  //   }
  // }


  const setInputsPassword = async () => {
    await verifyCode({
      email:data.email,
      code: Number(data.code),
    }).then((res) => {
      res.success && setNewPassword(true);
      setToken(res.data.token);
    });
  };

  const onShowPassword = (isPassword: boolean) => {
    isPassword
      ? setShowPassword(!showPassword)
      : setShowConfirmPassword(!showConfirmPassword);
  };

  const updateItem = (id: number) => {
    const indexToUpdate = errorPassword.findIndex((todo) => todo.id === id);
    const updatedTodos = [...errorPassword]; // creates a copy of the array

    updatedTodos[indexToUpdate].className = "valid";
    updatedTodos[indexToUpdate].icon = checkValid;
    setErrorPassword(updatedTodos);
  };
  const ResetItem = (id: number) => {
    const indexToUpdate = errorPassword.findIndex((todo) => todo.id === id);
    const updatedTodos = [...errorPassword]; // creates a copy of the array

    updatedTodos[indexToUpdate].className = "";
    updatedTodos[indexToUpdate].icon = check;
    setErrorPassword(updatedTodos);
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const onChangePassword = (e: { target: { value: string } }) => {
    setData({ ...data, password: e.target.value });

    const value = e.target.value;

    if (checkAtLeastLength(value, 8)) {
      updateItem(1);
    } else {
      ResetItem(1);
    }

    if (checkUpper(value)) {
      updateItem(2);
    } else {
      ResetItem(2);
    }

    if (checkSpecialCharacter(value)) {
      updateItem(3);
    } else {
      ResetItem(3);
    }
    if (checkDigit(value)) {
      updateItem(4);
    } else {
      ResetItem(4);
    }
    if (data.confirmPassword !== value && data.confirmPassword.length > 0) {
      setConfirmErrorMessage(error);
    } else {
      setConfirmErrorMessage("");
    }
  };
  const [isFromSocial,setIsFromSocial]=useState<boolean>()
  const [openLoginModal,setIsOpenModal]=useState<boolean>()
  const [loginType,setLoginType]=useState<string>('')
 const checkPhoneValidity=async () => {
     setData({...data, email: data.email})

     await sendMail({email: data.email}).then((res) => {
       if (res?.message.includes('Impossible de vous')){
         setIsFromSocial(res.data.social_account_method)
         setLoginType(res.data.social_account_method)
         setIsOpenModal(true)
       }else{
         res.success && setIsCode(true);
       }
     })

 }
  const onChangeConfirmPassword = (e: { target: { value: string } }) => {
    setData({ ...data, confirmPassword: e.target.value });
    const value = e.target.value;
    data.password !== value
      ? setConfirmErrorMessage(error)
      : setConfirmErrorMessage("");
  };

  const saveNewPassword = async () => {
    data.confirmPassword.length &&
    data.password.length &&
    confirmErrorMessage.length === 0 &&
    !isError
      ? await resetPassword({
          email:data.email,
          token: token,
          password: data.password,
        }).then((res) => {
          res.success && history(PATHS.LOGIN_MAIL);
        })
      : toast.error(t('passwordScreenErrorToast'));
  };

  useEffect(() => {
    const result = errorPassword.some((i) => {
      return i.icon === check;
    });
    !result ? setIsError(false) : setIsError(true);
  }, [errorPassword]);
  const validationMail=(e:any)=>{
    if(e.target.value.length > 0){
      setData({ ...data, email: e.target.value })
      setConfirmEmptyMessage('')
    }else{
      setConfirmEmptyMessage('error')
    }
  }
  const connectSocialAccount = (data:any) => {
    socialAccountLogin(data).then(() => {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      !isMobile() ? history("/account/profile") : history("/account")  ;
    });
  };
  const responseApple = (response:any) => {

    if (response?.authorization) {
      const appleData  = {
        firstName: response?.user?.name?.firstName,
        lastName: response?.user?.name?.lastName,
        email:data.email,
        provider_id: response?.authorization?.id_token,
        provider_name: "Apple",
        basket_id: localStorage.getItem('basket_id') ? Number(localStorage.getItem('basket_id')) : null
      }
      connectSocialAccount(appleData);
    }
  }
  const responseFacebook = (response:any) => {
    console.warn('response facebook', response);
    if (response?.email){
      const fullName=response?.name;
      let details=[]
      details=fullName?.split(' ');
      let firstName = '';
      let lastName = '';
      if (details?.length > 1) {
        firstName = details[0];
        lastName = details[1];
      }
      const facebookData = {
        firstName,
        lastName,
        email: response?.email,
        provider_id: response?.accessToken,
        provider_name: "Facebook",
        basket_id: localStorage.getItem('basket_id') ? Number(localStorage.getItem('basket_id')) : null
      }
      connectSocialAccount(facebookData);
    }
  };

  const responseGoogle = (response:any) => {
    const googleData = {
      firstName: response?.profileObj?.givenName,
      lastName: response?.profileObj?.familyName,
      email: response?.profileObj?.email,
      provider_id: response?.accessToken,
      provider_name: "Google",
      basket_id: localStorage.getItem('basket_id') ? Number(localStorage.getItem('basket_id')) : null
    }
    connectSocialAccount(googleData);
  };
  return (
    <div className="auth fg-password">
      <Header />
      <div className="auth-content">
        <div className="container-desk mobile-height">
          {/*<div className="img-top">*/}
          {/*  <SunIcn text={STRINGS.LOGIN.TXT_SUN1} />*/}
          {/*</div>*/}
          <h1 className="title">{t('loginPasswordForgetPassword')}</h1>

          <div className="inputs" style={{display:"flex",flexDirection:'column'}}>
            {!newPassword ? (
              <>
                {/*<label htmlFor={'phone'} className={'label'}*/}
                {/*       style={{*/}
                {/*         textAlign: 'left',*/}
                {/*         color: '#c4c4c4',*/}
                {/*         fontSize: '12px',*/}
                {/*         position:"relative",*/}
                {/*         bottom:'30px'*/}
                {/*       }}>{t('phoneScreenInputLabel')}</label>*/}
                {/*<PhoneInput*/}
                {/*    name={'phone'}*/}
                {/*    style={{position:'relative',bottom:'24px'}}*/}
                {/*    international={true}*/}
                {/*    withCountryCallingCode={true}*/}
                {/*    useNationalFormatForDefaultCountryValue={true}*/}
                {/*    defaultCountry={'FR'}*/}
                {/*    placeholder="Enter phone number"*/}
                {/*    onChange={(e:any)=>checkNumber(e)}*/}
                {/*    disabled={isCode}*/}
                {/*/>*/}

                <Input
                  item={{
                    type: "email",
                    name: "email",
                    isEditable: false,
                    isOnEdit: false,
                    rules: [{ name: "required" }, { name: "email" }],
                    label: t('emailScreenPlaceholderText') ,
                    onChange: (e) => validationMail(e),
                  }}
                  isSubmit={isSubmit}
                  isDisabled={isCode}
                />
                {isCode && (
                  <div className="blc-code-verification">
                    <p>{t('phoneScreenVerificationCode')}</p>
                    <Input
                      item={{
                        label: t('phoneScreenButtonText') ||STRINGS.REGISTER.INPUTS.VERIFICATION_CODE,
                        rules: [{ name: "verification-code" }],
                        type: "number",
                        name: "code",
                        isOnEdit: false,
                        onChange: (e) =>
                          setData({ ...data, code: e.target.value }),
                      }}
                      isSubmit={isSubmit}
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                <Input
                  eyes={true}
                  onShow={() => onShowPassword(true)}
                  isShow={showPassword}
                  item={{
                    label: t('passwordScreenNewPassword') || STRINGS.REGISTER.INPUTS.NEW_PASSWORD,
                    name: "password",
                    type: !showPassword ? "password" : "text",
                    onChange: onChangePassword,
                    value: data.password,
                  }}
                  isSubmit={isSubmit}
                />
                {isError && (
                  <div className="error-container center-vertically">
                    {errorPassword?.map((error, i) => {
                      if (error.icon === check) {
                        return (
                          <div
                            key={i}
                            className="center-horizontally align-center"
                          >
                            <ImageContainer alt="check" src={error.icon} />
                            <p className={error.className}>{error.message}</p>
                          </div>
                        );
                      } else {
                        null;
                      }
                    })}
                  </div>
                )}

                <Input
                  eyes={true}
                  onShow={() => onShowPassword(false)}
                  isShow={showConfirmPassword}
                  item={{
                    label: t('passwordScreenConfirmNewPassword') || STRINGS.REGISTER.INPUTS.CONFIRM_NEW_PASSWORD,
                    name: "confirmPassword",
                    type: !showConfirmPassword ? "password" : "text",
                    onChange: onChangeConfirmPassword,
                    value: data.confirmPassword,
                  }}
                  isSubmit={isSubmit}
                />
                {confirmErrorMessage.length > 0 && (
                  <div className="error-container center-vertically">
                    <p className="confirm-error">{confirmErrorMessage}</p>
                  </div>
                )}
              </>
            )}
          </div>
          {/*{confirmEmptyMessage.length > 0 && (*/}
          {/*    <div className="error-container center-vertically">*/}
          {/*      <p className="confirm-error">{confirmEmptyMessage}</p>*/}
          {/*    </div>*/}
          {/*)}   */}
          <div className="btn-container">
            <button
              onClick={() =>
                !isCode
                  ? checkPhoneValidity()
                  : newPassword
                  ? saveNewPassword()
                  : setInputsPassword()
              }
              disabled={confirmEmptyMessage.length > 0}
              style={{backgroundColor:confirmEmptyMessage.length > 0 ? 'gray' : ''}}
              className="btn-primary"
            >
              {!isCode
                ? (t('send') || STRINGS.HOME.BTN.SEND)
                : newPassword
                ? (t('save') || STRINGS.SAVE)
                : (t('validate') || STRINGS.VALIDATE)}
            </button>
            {
                openLoginModal&&isFromSocial && loginType!=='' &&
                <div className={'loginModal'} style={!isMobile() ? {width:'100%',position:'relative',top:'20px'}:{left:'20px'}} >
                  <button className={'close'} style={{position:'absolute',backgroundSize:'22px',width:'30px',height:'30px',right:'0',top:'-40px',left:'unset'}}
                          onClick={() => setIsOpenModal(false)} />


                  <div className={'titleModalLogin'}>
						<span className={'titleText'}>
							{t('mailAlreadyInUse')}
						</span>
                  </div>
                  <div className={'bodyModalLogin'}>
                    <span className={'bodyText'}> {t('otherLoginMethod')}</span>

                  </div>
                  <div className={'Smedia'}>
                    {
                      loginType==='Google' ?
                          <GoogleLogin
                              clientId={`${process.env.REACT_APP_API_GOOGLE_AUTHENTIFICATION}`}
                              render={(renderProps) => (
                                  <button onClick={renderProps.onClick} className="gmail">
                                    <span>{t('registrationScreenWithGoogle') || STRINGS.LOGIN.LOGIN_GOOGLE}</span>
                                  </button>
                              )}
                              onSuccess={responseGoogle}
                              onFailure={(res) => res?.details && alertMessage(res.details)}
                              cookiePolicy={"single_host_origin"}
                          />
                          : loginType=== 'Apple' ?
                              <AppleSignin
                                  authOptions={{
                                    clientId: "com.supernova.service",
                                    scope: "email name",
                                    redirectURI: "https://suppernova.com/login",
                                    state: "",
                                    nonce: "nonce",
                                    usePopup: true,
                                  }}
                                  uiType="dark"
                                  className="apple-auth-btn"
                                  noDefaultStyle={false}
                                  onSuccess={responseApple}
                                  onError={(error:any) => console.error(error)}
                                  skipScript={false}
                                  iconProp={{ style: { marginRight: "10px" } }}
                                  render={(props:any) => (
                                      <button onClick={props.onClick} className="apple">
                                        <span>{t('registrationScreenWithApple').toUpperCase() || STRINGS.LOGIN.LOGIN_APPLE.toUpperCase()}</span>
                                      </button>
                                  )}
                              /> :
                              <FacebookLogin
                                  appId={APP_ID_FACEBOOK_AUTHENTIFICATION}
                                  callback={responseFacebook}
                                  onFailure={(res:any) => res?.status && alertMessage(res.status)}
                                  fields="name,picture,email"
                                  render={(renderProps:any) => (
                                      <button onClick={renderProps.onClick} className="fb">
                                        <span>{t('registrationScreenWithFacebook') || STRINGS.LOGIN.LOGIN_FACEBOOK}</span>
                                      </button>
                                  )}
                                  disableMobileRedirect={true}
                                  autoLoad={false}
                              />
                    }
                  </div>

                </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

/* eslint-enable max-lines-per-function */
export default ForgetPassword;
