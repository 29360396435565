import React from "react";
import './trends.scss';
import TrendItem from "./trendItem/trendItem";
import {CategoryInterface, SubCategoryInterface} from "../../../../../shared/store/slices/explore/exploreSlice";

// eslint-disable-next-line max-lines-per-function
const Trends: React.FC<{
  showAll?: Array<SubCategoryInterface>,
  data?: CategoryInterface
}> = ({showAll, data}) => {

  return (
    <div className={'trendsMainContainer'}>
      {
        data?.subCategories?.map((item: SubCategoryInterface, index: number) => {
            return (
              <TrendItem key={index} item={item} index={index} showAll={!!showAll}/>
            )
          }
        )
      }

    </div>

  )
}

export default Trends;
