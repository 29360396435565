import React, {useEffect, useRef, useState} from "react";
import Autocomplete from "react-google-autocomplete";
import {InputItem} from "../../core/models/Input";
import Geocode from "react-geocode";
import {AUTO_COMPLETE_API_KEY} from "../../core/enums/APIS";


interface GooglePlacesItem {
	item: InputItem;
	placeSelectedEvent: (place: any) => void;
	errors?: string[];
	onChange: (value: string) => void
	ref?: React.MutableRefObject<HTMLInputElement | null>
	setElement?: (value: any) => void
}

/* eslint-disable max-lines-per-function */
// eslint-disable-next-line sonarjs/cognitive-complexity
const GooglePlacesAutoComplete = (Props: GooglePlacesItem) => {
	const refElement = useRef<any>();
	const [autoCompleteValue, setAutoCompleteValue] = useState<string | number>();
	const [isVisibleAutoComplete, setIsVisibleAutoComplete] = useState<boolean>(false);
	useEffect(() => {
		Props.setElement && Props.setElement(refElement);
	}, [refElement])
	useEffect(() => {
		Props.item.value && setAutoCompleteValue(Props.item.value);
		setIsVisibleAutoComplete(false);
	}, [Props.item.value]);
	return (
		<div>
			<div className="input-item">
				<div className="input-blc">
					{
						isVisibleAutoComplete && (
							<Autocomplete
								inlist={'true'}
								ref={refElement as any}
								onChange={(value) => Props.onChange(value.currentTarget.value)}
								inputAutocompleteValue={"formatted_address"}
								placeholder={Props.item.label}
								apiKey={AUTO_COMPLETE_API_KEY}
								language="fr"
								onPlaceSelected={(place) => Props.placeSelectedEvent(place)}
								defaultValue={Props.item.value}
								options={{
									types: ['address'],
									fields:['address_components', 'geometry.location', 'place_id', 'formatted_address','name']
								}}

							/>
						)
					}
					{
						!isVisibleAutoComplete && (
							<input
								onClick={() => setIsVisibleAutoComplete(true)}
								value={Props.item.value}
								type="text"
								name='Props.item.name'
								id='Props.item.name'
								autoComplete="off"
							/>
						)
					}
					<label htmlFor={Props.item.name}
						   style={{textAlign: 'left', cursor: 'pointer', transform: "translate(0, -4px)"}}>
						{Props.item?.label && Props.item.label?.length > 0 ? Props.item.label : Props.item.name}
					</label>
				</div>
				{/*{Props.errors && Props.errors.length > 0 && <span className="error-msg">{Props.errors[0]}</span>}*/}
			</div>
		</div>
	);
};

export default GooglePlacesAutoComplete;
