import React, {useEffect, useState} from 'react';
import Calendar from "react-calendar";
import moment from "moment";
import {useSelector} from "react-redux";
import {RootState} from "../../shared/store";
import {STRINGS} from '../../core/enums/strings';
import {WeekDays} from "../../core/models/Chef";
import {Available} from "../../shared/store/slices/Account/AccountService";

import {t} from "i18next";
import {convertDateToValidFormat, isMobile} from "../../shared/utils/utils-functions";
import {PATHS} from "../../core/enums/paths";

interface Props {
	value?: string;
	show?: boolean;
	onChange?: (value: string) => void;
	isAvailableWeekDays: boolean | undefined;
	daysAvailable: WeekDays | undefined;
	idResto?: any
	isOrder?: any
	defaultValue?:any
	params?:any
}

/* eslint-disable max-lines-per-function */
// eslint-disable-next-line sonarjs/cognitive-complexity
const CalendarMenuPlat = (props: Props): JSX.Element => {
	const [language,setLanguage]=useState<any>()

	useEffect(()=>{
		setLanguage(localStorage.getItem('SUPERNOVA_LANGUAGE'))
	},[])
	const [listHolidays, setListHolidays] = useState<any[]>([]);
	const [view, setView] = useState<string>('month');
	const nextSixMonthsDate = moment(new Date()).add(6, 'M').endOf('month').toDate();
	const [selectedDate, setSelectedDate] = useState<string>("");
	const cart = useSelector((state: RootState) => state.cart)

	const isDisabledDate = (date: Date) => {
		return view === 'month' && (listHolidays.some((item: any) =>
			((new Date(item.delivery_date).getDate() === date?.getDate()) && (new Date(item.delivery_date).getMonth() + 1 === date?.getMonth() + 1) && (new Date(item.delivery_date).getFullYear() === date?.getFullYear()))
		));
	}
	// eslint-disable-next-line sonarjs/cognitive-complexity
	useEffect(() => {
		Available(props?.idResto,{			is_from_basket_details_screen:false
		}).then((res: any) => {
			if (res.data.first_day_available){
				setListHolidays(res?.data?.list_days_by_status.filter((item: any) => !item?.is_available_ups || !item?.is_available_pickup))
			}else{
				Available(props?.idResto,{
					month: new Date().getMonth()+2,
					year: new Date().getMonth()+1 === 12 ?  new Date().getFullYear()+1 : new Date().getFullYear() ,
					is_from_basket_details_screen:false
				}).then((res:any)=>{
					if (res.data.first_day_available){
						setListHolidays(res?.data?.list_days_by_status.filter((item: any) => !item?.is_available_ups || !item?.is_available_pickup))
					}
				})
			}

		})
	}, [props?.idResto]);
	const newBasket = useSelector((state: RootState) => state.cart.basketDate)
	const isWeekendDaysDisabled = (date: Date) => {
		return (date.getDay() === 0 || date.getDay() === 1) ;
	}
	const [year, setYear] = useState<string>(new Date().getFullYear().toString());

	const changeDate = (value: any) => {
		Available(props?.idResto, {
			month: value.activeStartDate.getMonth() + 1,
			year: value.activeStartDate.getFullYear(),
			is_from_basket_details_screen:false
		}).then((res: any) => {
			setListHolidays(res?.data?.list_days_by_status.filter((item: any) => !item?.is_available_ups || !item?.is_available_pickup ))
		})
		value.view === "month" && (value.activeStartDate.getFullYear().toString() !== year) && setYear(value.activeStartDate.getFullYear().toString())
	}

	return (
		<div className="calendar-blc">
			<div style={{width:'100%',display:'flex',alignItems:'center'}}>
				<div>
					<button onClick={()=> props.onChange && props.onChange('')} className="back-btn" />
				</div>
				<div style={{position:'relative',left:'5px'}}>
					<h1 style={{top:'14px',right:"unset",position:"relative"}} className="title-calendar">{t('basketScrennDatePossible') || STRINGS.TITLE_CALENDAR}</h1>

				</div>
			</div>

			{
					<Calendar
						className={'calendarMenu'}
						onActiveStartDateChange={(value) => changeDate(value)}
						formatShortWeekday={(locale, value) => value.toLocaleString(language === 'fr' ? 'fr-fr' : 'en-en', {weekday: 'narrow'})}
						tileDisabled={({date}) => isDisabledDate(date)}
						tileClassName={({date}) => isDisabledDate(date) ? 'disable' : ''}
						minDate={new Date()}
						minDetail={"month"}
						defaultActiveStartDate={new Date(convertDateToValidFormat( props.defaultValue))}
						maxDate={nextSixMonthsDate}
						onViewChange={(value) => setView(value.view)}
						defaultView={'month'}
						locale={language}
					/>
			}
			{/*<button className="btn-primary btn-calendar" onClick={()=> props.onChange && props.onChange('')}>*/}
			{/*	{t('close') || STRINGS.BTN_CALENDAR}*/}
			{/*</button>*/}
		</div>
	);
}

export default CalendarMenuPlat;
