import React from "react";
import './blockContainer.scss'
import {motion} from "framer-motion";
import moreArrow from '../../../../../assets/icons/explore/rightArrow.svg'
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ImageContainer from "../../../../../components/imageContainer/imageContainer";

export interface BlockContainerInterface {
  id: number,
  title: string,
  count?:any,
  name:string
  icon: string,
  content: JSX.Element,
  showMoreLink?: string,
  noPadding?: boolean
  subCategories?:number
  type_of_display?:string
}

const BlockContainer: React.FC<{
  config: BlockContainerInterface,
  index: number,
  showAll?:boolean
  content: JSX.Element,
  showMoreLink?:string

// eslint-disable-next-line max-lines-per-function
}> = ({config, index,content,showMoreLink,showAll}) => {
  const navigate = useNavigate()

  const goToMoreDetail = () => {
    navigate(`${showMoreLink as string}${config?.id}`)
  }

  const {t} = useTranslation()
  return (
    <div className={`blockMainContainer`}>
      <motion.div
        className={`titleRow ${config?.noPadding}`}
        initial={{
          opacity: index > 1 ? 1 : 0
        }}
        animate={{
          opacity: 1
        }}
        transition={{
          duration: 0.5,
          delay: index || 0.5
        }}
      >
        <div className={'left'}>
          <ImageContainer src={config?.icon} alt=""/>
          <span>{config.name}</span>
        </div>
        {
          showAll && <div onClick={goToMoreDetail} className={'right'}>
           <div style={{width:'10px'}}></div>
            <span>{t('seeAll')}</span>
            <img src={moreArrow} alt=""/>
          </div>
        }
      </motion.div>
      <div className={`contentRow ${config?.noPadding}`}>
        {content}
      </div>
    </div>
  )
}

export default BlockContainer;
