import { Address } from "../models/Address";

const Adresses :Address[]=
[
    // {
    //     id:0,
    //     firstName:'HARRY',
    //     lastName:'HEBERT',
    //     apartment:'3EME ETAGE DROITE',
    //     phoneNumber:0o643455667,
    //     road:'51 RUE DU PARADIS',
    //     country:'75010 PARIS',
    //     type:'HOME',
    //     isOnEdit:false,
    //     code:'44A16',
    //     byDefault: true
    // },
    // {
    //     id:1,
    //     firstName:'HARRY',
    //     lastName:'HEBERT',
    //     apartment:'BATIMENT C ,1ER ETAGE',
    //     phoneNumber:0o643455667,
    //     road:'AVENUE TRUDAINE',
    //     country:'75010 PARIS',
    //     type:'BUREAU',
    //     isOnEdit:false,
    //     code:'3',
    //     companyName:'SUPPERNOVA',
    //     byDefault: false

    // }
]
export default Adresses
