import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Profile } from "../../../../core/models/Profile";



export interface AuthenticationState {
  loggedInUser?: any;
  isLoggedIn: boolean;
  profile: any;
  errorMessage:string,
  listCountry:any,
  countrySelected?:any,
  countryId:any,
  step:number,
  terms:any
  socialResponse:any
}

export const authenticationInitialState: AuthenticationState = {
  loggedInUser: undefined,
  isLoggedIn: false,
  terms:[],
  profile: undefined,
  errorMessage:'',
  listCountry:[],
  countrySelected:undefined,
  countryId:undefined,
  step:0,
  socialResponse:undefined
};
export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: authenticationInitialState,
  reducers: {
    setLoggedInUser: (state, action: PayloadAction<any>): void => {
      state.isLoggedIn = true;
      state.loggedInUser = action.payload;
    },
    setSignUpUser: (state, action: PayloadAction<any>): void => {
      state.isLoggedIn = true;
      state.loggedInUser = action.payload;
    },

    disconnect: (state): void => {
      state.loggedInUser = undefined;
      state.isLoggedIn = false;
      state.profile = undefined;
      state.errorMessage = '';
    },
    setSocialResponse: (state, action: PayloadAction<any>): void => {
      state.socialResponse = action.payload;
    },
    getProfile: (state, action: PayloadAction<Profile>): void => {
      state.profile = action.payload;
    },
    setErrorMessage: (state, action: PayloadAction<string>): void => {
      state.errorMessage = action.payload;
    },
    setIsBlockedNewsLetter: (state, action: PayloadAction<boolean>): void => {
      state.profile && (state.profile.is_notify = action.payload);
    },
    setListCountry:(state,action:PayloadAction<any>):void=>{
      state.listCountry=action.payload
    },
    setCountrySelected:(state,action:PayloadAction<any>):void=>{
      state.countrySelected=action.payload
    },
    newCountryId:(state,action:PayloadAction<any>):void=>{
      state.countryId=action.payload
    },
    setStepNow:(state,action:PayloadAction<number>):void=>{
      state.step=action.payload
    },
    setTerms:(state,action:PayloadAction<any>):void=>{
      state.terms=action.payload
    }
  },
});
export const {setSocialResponse,setTerms,setErrorMessage, getProfile, disconnect, setLoggedInUser,setStepNow, setSignUpUser,newCountryId, setIsBlockedNewsLetter,setListCountry,setCountrySelected} = authenticationSlice.actions;



