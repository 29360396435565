import React from "react";
import './trendItem.scss'
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setBackRoot} from "../../../../../../shared/store/slices/Root/rootSlice";
import {SubCategoryInterface} from "../../../../../../shared/store/slices/explore/exploreSlice";
import {motion} from "framer-motion";
import Parallax from "../../../../../../components/parallex/parallax";
import {useTranslation} from "react-i18next";
import ImageContainer from "../../../../../../components/imageContainer/imageContainer";

const TrendItem: React.FC<{
  item: any,
  index: any,
  showAll?: any
// eslint-disable-next-line max-lines-per-function
}> = ({item, index, showAll}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const goTo = () => {
    const path = `/new-chef/subcategory/${item?.id}`;
    const state = {
      from: location.pathname,
      state: {id: 1},
    }
    dispatch(setBackRoot({path: location.pathname, ...state.state}))
    navigate(path, state);
  }

  const getSrc = () => {
    return item?.image?.find((item:any) => item?.type === '4:3') || item?.image[0]
  }

  const {t} = useTranslation()

  const MotionComponent = motion(ImageContainer)

  return (
    <div
      className={'animationCont'}
    >
      <motion.div
        className={'trendItem'}
        initial={{
          height: showAll ? '100%' : 0
        }}
        animate={{
          height: '100%'
        }}
        transition={{
          delay: 0.6+ index * 0.2,
          duration: 0.4,
        }}
      >
        {/*{getSrc().is_grey && <div className={'rootMask'}/>}*/}
        <Parallax>
          <MotionComponent
            onClick={goTo} src={getSrc()?.url} alt="" draggable={false}/>
        </Parallax>
        <div className={'bottomAction'}>
          <span>{item.name}</span>
          <button onClick={goTo}>{t('discover')}</button>
        </div>
      </motion.div>
    </div>
  )
}

export default TrendItem;
