import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe, StripeElementsOptions} from "@stripe/stripe-js";
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import CheckoutForm from "../../components/checkout-form/CheckoutForm";
import Header from "../../components/header/Header";
import { RootState } from "../../shared/store";
import {STRIP_KEY} from "../../core/enums/APIS";
/* eslint-disable max-lines-per-function */
function CheckoutPage() {
  const payment = useSelector(
    (state: RootState) => state.payment.paymentIntent
  );
  const stripePromise = loadStripe(
      `${process.env.REACT_APP_API_STRIP}`
  );
  //   const appearance = {
  //     theme: "stripe",
  //   };

  // const payment = useSelector((state: RootState) => state.payment.paymentIntent);
  // let stripePromise: Stripe | PromiseLike<Stripe | null> | null = null;
  // //   const appearance = {
  // //     theme: "stripe",
  // //   };
  // // let stripe = null;
  // useEffect(() => {
  //   // !stripePromise && loadStripe("pk_test_51JjNKELxTNoO7OVPlYEDYS7d29g2MPCtQqg7lxBEidEHBH3Fp5ZcnTxIAu33iwZ0S1cllzHxZAQ5KsSEiuVcEOFe00SHAlpRkF")
  //   //     .then((response) => stripePromise = response);
  //   if (window.Stripe) {
  //     stripePromise =  window.Stripe('pk_test_51JjNKELxTNoO7OVPlYEDYS7d29g2MPCtQqg7lxBEidEHBH3Fp5ZcnTxIAu33iwZ0S1cllzHxZAQ5KsSEiuVcEOFe00SHAlpRkF');
  //   } else {
  //     document.querySelector('#stripe-js')?.addEventListener('load', () => {
  //       // Create Stripe instance once Stripe.js loads
  //       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //       // @ts-ignore
  //       stripePromise =  window?.Stripe('pk_test_51JjNKELxTNoO7OVPlYEDYS7d29g2MPCtQqg7lxBEidEHBH3Fp5ZcnTxIAu33iwZ0S1cllzHxZAQ5KsSEiuVcEOFe00SHAlpRkF');
  //     });
  //   }
  // }, []);
  const options: StripeElementsOptions = {
    clientSecret: payment.paymentIntent,
    appearance: {
      theme: "flat",
      variables: {
        fontFamily: ' "Gill Sans", sans-serif',
        fontLineHeight: "1.5",
        borderRadius: "10px",
        colorBackground: "#F6F8FA",
        colorPrimaryText: "#262626",
      },
      rules: {
        ".Block": {
          backgroundColor: "var(--colorBackground)",
          boxShadow: "none",
          padding: "15px",
        },
        ".Input": {
          padding: "12px",
        },
        ".Input:disabled, .Input--invalid:disabled": {
          color: "lightgray",
        },
        ".Tab": {
          padding: "10px 12px 8px 12px",
          border: "none",
        },
        ".Tab:hover": {
          border: "none",
          boxShadow:
            "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
        },
        ".Tab--selected, .Tab--selected:focus, .Tab--selected:hover": {
          border: "none",
          backgroundColor: "#fff",
          boxShadow:
            "0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
        },
        ".Label": {
          fontWeight: "500",
        },
      },
    },
  };
  return (
      <>
        {
          stripePromise &&
            <Elements options={options} stripe={stripePromise}>
              <Header/>
              <div
                  style={{height: "50rem"}}
                  className="center-horizontally align-center justify-center"
              >
                <CheckoutForm/>
              </div>
            </Elements>
        }
      </>
  );
}
/* eslint-enable max-lines-per-function */
export default CheckoutPage;
