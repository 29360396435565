import React, {useRef} from "react";
import './destinationItem.scss'
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setBackRoot} from "../../../../../../shared/store/slices/Root/rootSlice";
import {SubCategoryInterface} from "../../../../../../shared/store/slices/explore/exploreSlice";
import Parallax from "../../../../../../components/parallex/parallax";
import {motion} from "framer-motion";
import ImageContainer from "../../../../../../components/imageContainer/imageContainer";

// eslint-disable-next-line max-lines-per-function
const DestinationItem: React.FC<{ item: SubCategoryInterface }> = ({item}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const goTo = () => {
    const path = `/new-chef/subcategory/${item?.id}`;
    const state = {
      from: location.pathname,
      state: {id: 1},
    }
    dispatch(setBackRoot({path: location.pathname, ...state.state}))
    navigate(path, state);
  }

  const getSrc = () => {
    return item?.image?.find(item => item?.type === '3:4') || item?.image[0]
  }

  const clickData = useRef({
    x: NaN, y: NaN
  })
  const mouseDown = (e: React.MouseEvent) => {
    clickData.current = {x: e.pageX, y: e.pageY}
  }

  const mouseUp = (e: React.MouseEvent) => {
    if (
      (e.pageX >= clickData.current.x - 6 && e.pageX <= clickData.current.x + 6) &&
      (e.pageY >= clickData.current.y - 6 && e.pageY <= clickData.current.y + 6)
    ) {
      goTo()
    }
  }
  const MotionComponent = motion(ImageContainer)
  return (
    <div onMouseDown={mouseDown} onMouseUp={mouseUp} className={'destinationItem'}>
      <div className={'imgContainer'}>
        {getSrc().is_grey && <div className={'rootMask'}/>}
        <Parallax>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/*@ts-ignore*/}
          <MotionComponent
            draggable={false} src={getSrc().url} alt={''}/>
        </Parallax>
      </div>

      {/*</Parallax>*/}
      <span style={{}}> {item.name}</span>
    </div>
  )
}

export default DestinationItem;
