import React from "react";
import { ClaimType } from "../../core/models/Claim";
import arrowRight from "../../assets/icons/arrow-right.svg";
import {t} from "i18next";
import {useTranslation} from "react-i18next";

interface ItemProps {
  item: ClaimType;
  onClick: (type: ClaimType) => void;
  isChecked: boolean;
}
const HelpItem = (props: ItemProps) => {
  const {t}=useTranslation()
  return (
    <div
      onClick={() => props.onClick(props.item)}
      className="blc center-horizontally space-between align-center"
    >
      <label className={props.isChecked ? "active" : undefined}>
        {t(`${props.item.name}`)}
      </label>
      <img src={arrowRight} alt="row-right" />
    </div>
  );
};

export default HelpItem;
