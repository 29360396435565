import React, {useEffect, useLayoutEffect, useState} from "react";
import './explore.scss'
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import Header from "../../components/header/Header";
import sun from "../../assets/icons/round-decouvrirF.png";
import Footer from "../../components/footer/Footer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../shared/store";
import './chef/components/searchChef/searchChef.scss'
import backArrow from '../../assets/icons/back-arrow.svg'
import {motion} from "framer-motion";
import Search from "./search/search";
import {getMacAddress, getSearchRecent} from "../../shared/store/slices/explore/exploreService";
import {useTranslation} from "react-i18next";

// eslint-disable-next-line max-lines-per-function,sonarjs/cognitive-complexity
const Explore: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const width = useSelector((state: RootState) => state.root.DEVICE_WIDTH);
  const params = useParams();
  const location = useLocation();
  const {t} = useTranslation()

  // eslint-disable-next-line sonarjs/cognitive-complexity,max-lines-per-function
  const getTitle = () => {
    if (window.location.pathname.includes('explore/chefs')) {
      return t(width > 768 ? 'chefsLink' : 'exploreScreenTitle')
    } else {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      if (window.location.pathname === '/explore/kitchen') {
        return t(width > 768 ? 'kitchenLink' : 'exploreScreenTitle')
      } else {
        return params?.slug
      }
    }
  }

  useEffect(() => {
    getSearchRecent(getMacAddress()).then();
  }, [])
  const [scrollY, setScrollY] = useState<number>(0);

  useLayoutEffect(() => {
    const handleScroll = () => {
      setScrollY(Math.min(0, window.scrollY / 3 - 60) - 10);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  }, [scrollY]);
  const [reAnimate, setReAnimate] = useState(true)
  useEffect(() => {
    setReAnimate(false)
    setTimeout(() => {
      setReAnimate(true)
    }, 10)
  }, [location])

  return (
    <div className={'exploreMainContainer'}>
      <div className="chefs-page">
        <Header classname={ scrollY === 0 || scrollY < -50 ? '' : 'home-header'} isHome={true} isHomeActive={true} />
        <div
          className={window.location.pathname.includes('/categoryDetail') ? 'topPage toppageCategory' : "topPage top-page"}
        >
          <div
            style={{
              // eslint-disable-next-line sonarjs/no-duplicate-string
              height: (window.location.pathname === '/explore/chefs' || window.location.pathname === '/explore/kitchen') ? '' : '100%'
            }}
            className={'animationContainer'}
          >
            {
              reAnimate && (
                <motion.div
                  initial={{
                    height: width > 768 ? 0 : '100%'
                  }}
                  animate={{
                    height: '100%'
                  }}
                  transition={{
                    delay: 0.2,
                    duration: 0.3
                  }}
                  className="desktopContainer container-desk"
                >
                  <div style={{
                    position: 'relative',
                    display:"flex",
                    top: (width < 768 && window.location.pathname === '/explore/kitchen') ? '9px' : "10px"
                  }} className="titleContainer">
                    {/* eslint-disable-next-line sonarjs/no-duplicate-string */}
                    {['/explore/chefs', '/explore/kitchen'].includes(window.location.pathname) ? (
                      <div className="blc-img">
                        <img src={sun} className="round"/>
                      </div>) : (
                      <div className={'backArrowCont'} onClick={() => navigate('/explore/kitchen')}>
                        <img src={backArrow} draggable={false} alt=""/>
                      </div>
                    )}
                    <h1 >{getTitle()}</h1>
                    {
                      (window.location.pathname === '/explore/kitchen' && width > 1000) &&
                        <span className={'rightText'}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                         </span>}
                  </div>
                  {
                    (width < 768 && ['/explore/kitchen', '/explore/chefs'].includes(window.location.pathname)) && (
                      <div
                        style={{top: window.location.pathname === '/explore/kitchen' ? '9px' : "10px"}}
                        className={'exploreHeader'}>
              				<span onClick={() => navigate('kitchen')}
                            className={`${window.location.pathname.includes('kitchen')}`}
                      >
												{t('kitchenLink') || 'cuisine'}
											</span>
                        <span onClick={() => navigate('chefs')}
                              className={`${window.location.pathname.includes('chefs')}`}
                        >
                          {t('chefsLink') || 'chefs'}
                        </span>
                        <div className={`line ${window.location.pathname.includes('chefs')}`}/>
                      </div>
                    )
                  }
                  {
                    (width < 768 &&
                      (window.location.pathname === '/explore/chefs'))
                    && (
                      <div className={'absoluteSearchInput'}>
                        <Search config={{closeSearch: undefined, inputOnly: true}}/>
                      </div>
                    )
                  }
                </motion.div>
              )
            }
          </div>
        </div>
        <Outlet/>
        {/*<div style={{height: '100px'}}/>*/}
        <Footer quantity={0} isHome={true} priceOrder={0}/>
      </div>
    </div>
  )
}

export default Explore
