/* eslint-disable max-lines-per-function */
import React, {useState, useRef, useEffect} from "react";
import "./InputCode.scss";
interface InputCodeProps {
  length: number;
  onComplete: (x: any) => void;
}
const InputCode = ({ length,  onComplete }: InputCodeProps) => {
  const [code, setCode] = useState([...Array(length)].map(() => ""));
  const inputs = useRef<(HTMLInputElement | null)[]>([]);
  const processInput = (
    e: any,
    slot: number
  ) => {
      const num = e.target.value;
      if (/[^0-9]/.test(num)) return;
      const newCode = [...code];
      newCode[slot] = num;
      setCode(newCode);
      if (slot !== length   && e.target.value !== '') {
          e.preventDefault()
        inputs.current[slot + 1]?.focus();
      }
      if (newCode.every((num) => num !== "")) {
        onComplete(newCode.join(""));
      }

  };

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>, slot: number) => {
    if (e.keyCode === 8 ) {
      const newCode = [...code];
      // newCode[slot-1]=''
        setCode(newCode);
      inputs.current[slot - 1]?.focus();
    }
  };

  return (
    <div className="code-input">
      <div className="code-inputs center-horizontally space-between">
        {code?.map((num, idx) => {
          return (
            <input
              id={idx.toString()}
              placeholder="-"
              key={idx}
              type="text"
              inputMode="numeric"
              maxLength={1}
              value={num}
              autoFocus={!code[0].length && idx === 0}
              onChange={(e) => processInput(e, idx)}
              onKeyUp={(e) => onKeyUp(e, idx)}
              ref={(ref) => inputs.current.push(ref)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default InputCode;
