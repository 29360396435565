import React, {useEffect, useState} from "react";
import {STRINGS} from "../../../core/enums/strings";
import "./Newsletters.scss";
import {Switch} from "antd";
import HeaderAccount from "../../../components/header-account/HeaderAccount";
import Footer from "../../../components/footer/Footer";
import {enableNewsLetter} from "../../../shared/store/slices/Account/AccountService";
import {alertMessage} from "../../../shared/store/interceptor";
import {useSelector} from "react-redux";
import {RootState} from "../../../shared/store";
import {getMyProfile, getTerms} from "../../../shared/store/slices/Auth/AuthService";
import {useTranslation} from "react-i18next";
import BackBtn from "../../../components/back-btn/BackBtn";
import {PATHS} from "../../../core/enums/paths";
import NewsletterBottomsheet from "../../../components/newsletter-bottomsheet/NewsletterBottomsheet";
import Sheet from "../../../components/bottom-sheet/Sheet";
import {isMobile} from "../../../shared/utils/utils-functions";

interface NewslettresProps {
	isHome?: boolean;
	isChecked?: (value: boolean) => void;
}

// eslint-disable-next-line max-lines-per-function,sonarjs/cognitive-complexity
const Newslettres = (props: NewslettresProps) => {
	const {t} = useTranslation()
	const isNewsletterBlocked = useSelector(
		(state: RootState) => state.authentication.profile?.news_letter_block
	);
	const isLoggedIn = useSelector(
		(state: RootState) => state.authentication.isLoggedIn
	);
	const [isChecked, setIsChecked] = useState<boolean>(isNewsletterBlocked);
	const [setNotify, setIsNotify] = useState<boolean>(!isNewsletterBlocked)
	const [isOpenNew, setIsOpenNews] = useState(false)
	useEffect(() => {
		isLoggedIn && getMyProfile(true).then()
	}, [setNotify])

	// eslint-disable-next-line sonarjs/cognitive-complexity
	const activateNotifs = (value: boolean) => {
		if (!props?.isHome) {
			enableNewsLetter({active: !isNewsletterBlocked}).then((data) => {
				if (data.success) {
					data.data.news_letter_block ? setIsNotify(false) : setIsNotify(true)
					alertMessage(
						!isNewsletterBlocked
							? t('message_souscription-newsLettre')
							: t('message_Désabonnement-newsLettre'),
						true
					);
				}

			});
		} else {
			setIsChecked(value);
			props.isChecked && props.isChecked(value);
		}
	};
	// eslint-disable-next-line sonarjs/cognitive-complexity
	useEffect(() => {
		const target = document.getElementById(1 as unknown as string);
		const target2 = document.getElementById('regleContainer')
		if (target) {
			target.className = 'newsLettreContainerReglement'
		}
		if (isOpenNew && target2){
			document.body.style.overflow='hidden'
		}else{
			document.body.style.overflow='auto'
		}
	}, [isOpenNew])

	const terms = useSelector((state: RootState) => state.authentication.terms)
	useEffect(() => {
		getTerms().then()
	}, [])
	const txt = t('newsLettreTitle')
	const onCloseMenu = () => {
		setIsOpenNews(false);
		document.body.style.overflow = "auto";
	};
	const width = useSelector((state: RootState) => state.root.DEVICE_WIDTH)
	return (
		<div className="newslettres">
			{!props?.isHome ? (
				<>
					{!isMobile() && isOpenNew && !window.location.pathname.includes('/home') &&
                    <div id={'1'} className={'newsLettreContainer'} style={{width:'40%'}}>
                        <div style={{display: "flex",alignItems:'center'}}>
                            <button onClick={() => setIsOpenNews(false)} className="back-btn"/>
                            <h1 className={'titleReglement'} style={{
								color: "black",
								width: '90%',
								fontStyle: 'unset',
								top:'4px'
							}}> {txt.toUpperCase()} </h1>
                        </div>
                        <div className={'divHight'}/>
                        <div style={{position: "relative"}}>
							{
								terms?.map((item: any) => (
									<div className={'regleContainer'} key={item?.id}>
                            <span dangerouslySetInnerHTML={{__html: item?.text}} className={'regleText'}>
                        </span>
									</div>
								))
							}
							{/*<div className={'regleBlur1'}/>*/}
                            <div className={'regleBlur'}/>
                        </div>
                    </div>
					}

					<HeaderAccount title={t('globalProfileScreenNewsletters') || STRINGS.ACCOUNT.NEWSLETTERS.TITLE}/>
					<h1 className="title-blc">
						{t('manageThe') || STRINGS.ACCOUNT.NEWSLETTERS.MANAGE}{" "}
						<span>{t('manageNewslettersScreenTitleScreenStepTwo') || STRINGS.ACCOUNT.NEWSLETTERS.NEWSLETTERS}</span>
					</h1>
					<div className="newslettres-content">
						<div className="blc">
							<div className="center-horizontally space-between">
								<h1 className="sub-title">
									{t('newsletter') || STRINGS.ACCOUNT.NEWSLETTERS.SUB_TITLE2}
								</h1>
								<Switch
									onChange={activateNotifs}
									checked={isNewsletterBlocked}
								/>
							</div>
							<p style={{lineHeight : window.location.pathname.includes('/home') ||  window.location.pathname.includes('/maintenance') ? '18px' : ''}}  >{t('contactScreenCheckboxText1')}&nbsp;<span style={{textDecoration:'underline',cursor:'pointer'}} onClick={() => {
								setIsOpenNews(true)
							}}>{t('politic').toUpperCase()}</span>&nbsp;{t('politicSuite').toUpperCase()}</p>

						</div>
					</div>
					<div className="blc-footer">
						<Footer quantity={0} priceOrder={0} isHome={true}/>
					</div>
					{!isMobile()&& window.location.pathname.includes('/home')&&
                    <div className={isOpenNew ? "overlay" : "hide"}>
                        <NewsletterBottomsheet isVisible={isOpenNew} onClose={onCloseMenu}/>
                    </div>
					}
					{
						isMobile() && isOpenNew &&
                        <div id={'1'} className={'newsLettreContainer'}>
                            <div style={{display: "flex"}}>
                                <button onClick={onCloseMenu} className="back-btn"/>
                                <h1 className={'regleTitle'} style={{
									color: "black",
									width: '85%',
									fontStyle: 'unset',
									position:"relative",
									top:'10px'
								}}> {txt.toUpperCase()} </h1>
                            </div>
                            <div className={'divHight'}/>
                            <div style={{position: "relative"}}>
								{
									terms?.map((item: any) => (
										<div className={'regleContainer'} key={item?.id}>
                <span id={'regleText'} className={'regleText'}>
                            <span dangerouslySetInnerHTML={{__html: item?.text}} className={'regleText'}/>
                </span>
										</div>
									))
								}
								    <div className={'regleBlur'}/>

                            </div>
                        </div>
					}


				</>
			) : (
				<>
					<div className="center-horizontally space-between is-home">
						<p style={{lineHeight : window.location.pathname.includes('/home') ||  window.location.pathname.includes('/maintenance') ? '18px' : ''}}  >{t('contactScreenCheckboxText1')}&nbsp;<span style={{textDecoration:'underline',cursor:'pointer',color:'black'}} onClick={() => {
							setIsOpenNews(true)
						}}>{t('politic').toUpperCase()}</span>&nbsp;{t('politicSuite').toUpperCase()}</p>
						{isOpenNew &&
                        <div id={'1'} className={'newsLettreContainer'}>
                            <div style={{display: "flex"}}>
                                <button onClick={() => setIsOpenNews(false)} className="back-btn"/>
                                <h1 className={'titleReglement'} style={{
									color: "black",
									width: '90%',
									fontStyle: 'unset',
									top:!isMobile()? '0px' : '4px'
								}}> {txt.toUpperCase()} </h1>
                            </div>
                            <div  className={'divHight'}/>
                            <div  style={{position: "relative"}}>
								{
									terms?.map((item: any) => (
										<div  id={'regleContainer'} className={'regleContainer'} key={item?.id}>
                            <span dangerouslySetInnerHTML={{__html: item?.text}} className={'regleText'}>
                        </span>
										</div>
									))
								}
								{/*<div className={'regleBlur1'}/>*/}
								<div className={'regleBlur'}/>
							</div>
                        </div>
						}
						<Switch onChange={activateNotifs} checked={isChecked}/>
					</div>

				</>
			)}
		</div>

	);

};

export default Newslettres;
