import { store} from '../..';
import {BaseUrl, APIS} from '../../../../core/enums/APIS'
import {Executor} from '../../Executor';
import {getCart, isRestaurantChanged, setDeliveryDate, setIsChanged, setNewCart, setRecentDate} from './CartSlice';
import moment from "moment";
import axios, {Method} from "axios";
import {setRootLoading} from "../Root/rootSlice";
import {isMobile} from "../../../utils/utils-functions";
import {recapOrder} from "../Payment/PaymentService";

interface PlateBasket {
    plate_id: number;
    restaurant_id: number;
    quantite: number;
    deliver_at?: string;
    override?: boolean;
}

interface MenuBasket {
    menu_id: number;
    restaurant_id: number;
    quantite: number;
    deliver_at?: string;
    override?: boolean;
}

export const getPublicOwnCart = (id?: number, isSilent = true) => {
    
    return Executor({
        method: "get",
        url: BaseUrl + (id ? APIS.CART.getPublicCart(id) : APIS.CART.getOwnCart),
        successFun: (data: any) => {
            store.dispatch(getCart(data.data))
        },
        errorFun: () => {
            id && localStorage.removeItem('basket_id');
        },
        isSilent
    });
}
export const testPublicCart = (id?: number, isSilent = true) => {

    return Executor({
        method: "get",
        url: BaseUrl + (id ? APIS.CART.getPublicCart(id) : APIS.CART.getOwnCart),
        isSilent
    });
}
export const createBasket = () => {
    return Executor({
        method: "post",
        url: BaseUrl + APIS.CART.createCart,
        successFun: (data: any) => {
            localStorage.setItem('basket_id', data.data.id)
        },
    });
}

export const addPlatetoOwnCart = (data: PlateBasket) => {
    const cart=store.getState().cart.basket
    return Executor({
        method: "post",
        url: BaseUrl + APIS.CART.addPlateToCart,
        data,
        successFun: (data: any) => {
            store.dispatch(getCart(data.data))
            store.dispatch(setNewCart({id:data.data?.restaurant?.id,deliver_at:cart.deliver_at}))
            // store.dispatch(setRecentDate(data.data.deliver_at))
        },
    });
}

export const addMenutoCart = (data: MenuBasket, isLoggedIn: boolean) => {
    const cart=store.getState().cart.basket
    const path = isLoggedIn ? APIS.CART.addMenuToCart : APIS.CART.addMenuToCartPublic(Number(localStorage.getItem("basket_id")));
    return Executor({
        method: "post",
        url: BaseUrl + path,
        data,
        successFun: (data: any) => {
            store.dispatch(getCart(data.data))
            store.dispatch(setNewCart({id:data.data?.restaurant?.id,deliver_at:cart.deliver_at}))

        },
    });
}

export const deletePlateFromOwnCart = (id: number) => {
    return Executor({
        method: "delete",
        url: BaseUrl + APIS.CART.deletePlateFromCart(id),
        successFun: (data: any) => {
            store.dispatch(getCart(data.data));
        },
    });
}
export const deleteMenuFromOwnCart = (id: number) => {
    return Executor({
        method: "delete",
        url: BaseUrl + APIS.CART.deleteMenuFromCart(id),
        successFun: (data: any) => {
            store.dispatch(getCart(data.data))
        },
    });
}

export const addPlatetoPublicCart = (id: number, data: PlateBasket) => {
    const cart=store.getState().cart.basket
    return Executor({
        method: "post",
        url: BaseUrl + APIS.CART.addPlateToCartPublic(id),
        data,
        successFun: (data: any) => {
            store.dispatch(getCart(data.data))
            store.dispatch(setNewCart({id:data.data?.restaurant?.id,deliver_at:cart.deliver_at}))

        },
    });
}
export const addMenutoPublicCart = (id: number, data: MenuBasket) => {
    const cart=store.getState().cart.basket
    return Executor({
        method: "post",
        url: BaseUrl + APIS.CART.addMenuToCartPublic(id),
        data,
        successFun: (data: any) => {
            store.dispatch(getCart(data.data))
            store.dispatch(setNewCart({id:cart.restaurant?.id,deliver_at:cart.deliver_at}))

        },
    });
}

export const deleteMenuFromPublicCart = (id: number, idMenu: number) => {
    return Executor({
        method: "delete",
        url: BaseUrl + APIS.CART.deleteMenuFromCartPublic(id, idMenu),
        successFun: (data: any) => {
            store.dispatch(getCart(data.data))
        },
    });
}

export const deletePlateFromPublicCart = (id: number, idPlate: number) => {
    return Executor({
        method: "delete",
        url: BaseUrl + APIS.CART.deletePlateFromCartPublic(id, idPlate),
        successFun: (data: any) => {
            store.dispatch(getCart(data.data))
        },
    });
}
export const increDecrItemCart = (id: number, data: { increment: boolean }, isMenu: boolean, isLoggedIn: boolean) => {
    const menuPath = isLoggedIn ? APIS.CART.incrDecrMenuCart(id) : APIS.CART.incrDecrPublicMenuCart(Number(localStorage.getItem("basket_id")), id);
    const platePath = isLoggedIn ? APIS.CART.incrDecrPlateCart(id) : APIS.CART.incrDecrPublicPlateCart(Number(localStorage.getItem("basket_id")), id);
    return Executor({
        method: "put",
        data,
        url: BaseUrl + (isMenu ? menuPath : platePath),
        isSilent: true,
        successFun: (data: any) => {
            store.dispatch(getCart(data.data))
        },
    });
}

export const getHolidays = (year: string) => {
    const options = {
        method: 'GET' as Method,
        url: 'https://public-holiday.p.rapidapi.com/' + year + '/FR',
        headers: {
            'x-rapidapi-host': 'public-holiday.p.rapidapi.com',
            'x-rapidapi-key': 'bafa9c5e5fmsh8a684e891df0853p116ec5jsn6c5fa526286c'
        }
    };
    store.dispatch(setRootLoading(true));
    return new Promise((resolve, reject) => {
        axios.request(options).then(function (response) {
            resolve(response.data);
            store.dispatch(setRootLoading(false));
        }).catch(function (error) {
            reject(error)
            store.dispatch(setRootLoading(false));
        });
    });
}



export const updateDeliveryDate = (date: string,pickDate?:any, service_code?:any,isBasket?: boolean) => {

    const cart=store.getState().cart.basket.restaurant?.id
    let path = APIS.CART.updateDeliveryDate;
    if (!localStorage.getItem('supernova_token') && localStorage.getItem('basket_id')) {
        path = APIS.CART.updateDeliveryDatePublicBasket(localStorage.getItem('basket_id') as string);
    }
    const deliver_at = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD').toString();
    // console.warn("delivery_date", deliver_at);
    return Executor({
        method: "put",
        data: {deliver_at, pickup_at: pickDate,service_code:service_code},
        url: BaseUrl + path,
        successFun: (() => {
             !isBasket &&
             store.dispatch(setDeliveryDate(date));
            store.dispatch(setNewCart({id:cart,deliver_at:date}))
            recapOrder().then()

            //  store.dispatch(setRecentDate(date))
            // store.dispatch(setIsChanged(true))
            // store.dispatch(isRestaurantChanged(true))
            // isBasket &&
        }) ,
    });
}
export const setCustomMessage = (custom_message: string) => {
    return Executor({
        method: "put",
        data: {custom_message},
        url: BaseUrl + APIS.CART.SET_CUSTOM_MESSAGE,
    });
}
