import React, {useEffect} from "react";
import {checkoutPayment} from "../../shared/store/slices/Payment/PaymentService";
import {isMobile} from "../../shared/utils/utils-functions";

const StripPage=()=>{
    // eslint-disable-next-line sonarjs/cognitive-complexity
    useEffect( () => {
        const query = window.location.pathname;


        if (query.includes("/stripe_connect/success_url_session_stripe")
        ) {
            const search = window.location.search
            const searchParams: any = new URLSearchParams(search)
            const sessionId = (searchParams.get("session_id"));
            checkoutPayment( {
                session_id: sessionId,
            }).then((res) => {
                if (res.success) {
                    !isMobile() ? window.open('/account/orders', '_self') : window.open('/orders', '_self');
                }
            });

        }
        if (query.includes('/stripe_connect/cancel_url_session_stripe')) {
            window.open('/home', '_self')

        }

    },[])
    return(
        <div>

        </div>
    )
}
export default StripPage
