import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {Cart, CartBasket} from "../../../../core/models/Cart";
import {tomorrowStringDate} from "../../../utils/utils-functions";
import moment from "moment";
import {RestoModel} from "../../../../core/models/Resto";

export interface CartState {
    basket:Cart,
    selectedChef?:RestoModel
    isChanged:any,
    restaurantId:any
    recentDate:any
    isOpen:any
    recentResto:any
    recentChanged:any
    basketDate?:CartBasket
    recentDateFromMenuMobile:'',
    objectOfDateWhenChangeCalendar:any
    serviceType:any,
    listHoliday:any,
    pickUp:any,
    serviceCode:any,


}


export const cartInitialState: CartState = {
    isOpen:false,
    pickUp:'',
    serviceCode:'',
    listHoliday:null,
    serviceType:'',
    objectOfDateWhenChangeCalendar:[],
    basketDate:{
        id:undefined,
        deliver_at:tomorrowStringDate()
    },
    basket: {
        id: undefined,
        recently_added_product: 0,
        menus: [],
        total_price: 0,
        nbr_article: 0,
        plates: [],
        deliver_at: '',
        restaurant: null
    },
    isChanged:false,
    restaurantId:0,
    selectedChef: undefined,
    recentDate:'',
    recentResto:'',
    recentChanged:false,
    recentDateFromMenuMobile:'',

};
const checkDeliveryDate = (date: string) => {
    const basketDate = moment(date?.substring(0,10)).format("DD/MM/YYYY");
 return basketDate.toString()
}
// eslint-disable-next-line sonarjs/cognitive-complexity
export const cartSlice = createSlice({

    name: 'cart',
    initialState: cartInitialState,
    reducers: {
        // eslint-disable-next-line sonarjs/cognitive-complexity
        getCart: (state, action: PayloadAction<Cart|undefined>): void => {
                state.basket = {
                id: action.payload?.id || undefined,
                recently_added_product: action.payload?.recently_added_product || 0,
                menus: action.payload?.menus || [],
                total_price: action.payload?.total_price || 0,
                nbr_article: action.payload?.nbr_article || 0,
                plates: action.payload?.plates || [],
                deliver_at :checkDeliveryDate(action.payload?.deliver_at as string),
                restaurant:action.payload?.restaurant ||  null
            };
        },
        setDeliveryDate: (state, action: PayloadAction<any>): void => {
            state.basket = { ...state.basket,
                deliver_at : action.payload
            }
        },
        setSelectedChef : (state, action: PayloadAction<any>): void => {
            state.selectedChef = action.payload;
        },
        setIsChanged : (state, action: PayloadAction<any>): void => {
            state.isChanged = action.payload;
        },
        setRestaurantId : (state, action: PayloadAction<any>): void => {
            state.restaurantId = action.payload;
        },
        setPickUpDate : (state, action: PayloadAction<any>): void => {
            state.pickUp = action.payload;
        },
        setServiceCode : (state, action: PayloadAction<any>): void => {
            state.serviceCode = action.payload;
        },
        setRecentDate : (state, action: PayloadAction<any>): void => {
            state.recentDate = action.payload;
        },
        setRecentRestaurant : (state, action: PayloadAction<any>): void => {
            state.recentResto = action.payload;
        },
        isRestaurantChanged : (state, action: PayloadAction<any>): void => {
            state.recentChanged = action.payload;
        },
        setNewCart: (state, action: PayloadAction<CartBasket|undefined>): void => {
            state.basketDate = action.payload
        },
        setDateFromMenuMobile: (state, action: PayloadAction<any>): void => {
            state.recentDateFromMenuMobile = action.payload
        },
        setIsOpen: (state, action: PayloadAction<any>): void => {
            state.isOpen = action.payload
        },
        setObjectOfCalendarDate: (state, action: PayloadAction<any>): void => {
            state.objectOfDateWhenChangeCalendar = action.payload
        },
        setServiceType: (state, action: PayloadAction<any>): void => {
            state.serviceType = action.payload
        },
        setListHolidayFromOrder: (state, action: PayloadAction<any>): void => {
            state.listHoliday = action.payload
        },
    },
});
export const {setServiceCode,setPickUpDate,setListHolidayFromOrder,setServiceType,setObjectOfCalendarDate,setIsOpen, getCart,setNewCart,isRestaurantChanged,setRecentRestaurant, setRecentDate,setDeliveryDate, setSelectedChef,setIsChanged,setRestaurantId} = cartSlice.actions;
