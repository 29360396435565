import axios from "axios";
import { PATHS } from "../../core/enums/paths";
import { BaseUrl, APIS } from "../../core/enums/APIS";
import { toast } from "react-toastify";
import { logout, refreshMyToken } from '../store/slices/Auth/AuthService'
import { message, notification } from "antd";
import { store } from "./index";
import {setSocialResponse} from "./slices/Auth/AuthSlice";

const Interceptor = axios.create({
    timeout: 20000,

});

Interceptor.interceptors.request.use((config: any) => {
    if (localStorage.getItem("supernova_token")) {
        config.headers.common = {
            Authorization: `Bearer ${localStorage.getItem("supernova_token")}`,
        };
    }
    config.headers.common = {
        ...config.headers.common,
        "Content-Type": "application/json",
        "x-localization": localStorage.getItem('SUPERNOVA_LANGUAGE')
    }
    return config;
});

Interceptor.interceptors.response.use(
    (response: any) => (response),
    /* eslint-disable-next-line sonarjs/cognitive-complexity */
    async (error: { config: any; response: { status: number; data: { message: string; }; }; }) => {
        const originalRequest = error.config;
        if (error.response) {

            if (error.response.status === 403) {
                originalRequest._retry = true;
                await refreshMyToken({ refresh_token: localStorage.getItem('supernova_refresh_token') }).then((res) => {
                    // console.log(res)
                }).catch(() => logout());
                originalRequest.headers.Authorization = `Bearer ${localStorage.getItem(
                    "supernova_token"
                )}`;
                return Interceptor(originalRequest).then((response: any) => {
                    // console.log(response)
                });
            } else if (error.response.status === 401) {
                logout()

            } else if (error.response.status === 400) {
                alertMessage(error.response.data.message, false);

            } else alertMessage("une erreur s'est produite ", false);
        }
    }
);

export const alertMessage = (msg: string, success?: boolean) => {
    notification.config({ duration: 3 })
    message.config({ duration: 3 })
    notification[success ? "success" : "error"]({
        message: msg
    });
}

export default Interceptor;


