import { TabAccount } from "../models/TabsAccount";
import { isMobile } from "../../shared/utils/utils-functions";

const TabsAccount: TabAccount[] = [

  {
    name: "globalProfileScreenMyProfileText",
    link: isMobile() ? "/profile" : "/account/profile",
    id: 1,
    mobileLink:"/profile",
    desktopLink:"/account/profile"
  },
  {
    name: "globalProfileScreenMyOrderText",
    link: isMobile() ? "/orders" : "/account/orders",
    id: 2,
    mobileLink:'/orders',
    desktopLink:'/account/orders'
  },

  {
    name: "globalProfileScreenMyDeliveryAddressText",
    link: isMobile() ? "/address" : "/account/addresses",
    id: 3,
    mobileLink:"/address" ,
    desktopLink:"/account/addresses",
  },
  {
    name: "globalProfileScreenNewsletters",
    link: isMobile() ? "/newsletters" : "/account/newsletters-setting",
    id: 4,
    mobileLink:"/newsletters",
    desktopLink:"/account/newsletters-setting"
  },
  {
    name: "globalProfileScreenContact",
    link: isMobile() ? "/contact" : "/account/contact",
    id: 5,
    mobileLink:"/contact",
    desktopLink:"/account/contact"
  },
  {
    name: "globalProfileScreenAbout",
    link: isMobile() ? "/about" : "/account/about/cgv",
    id: 6,
    mobileLink:"/about",
    desktopLink:"/account/about/cgv"
  },
];
export default TabsAccount;
