import React, {useEffect, useRef, useState} from "react";
import './search.scss';
import {SearchOutlined} from "@ant-design/icons";
import sunImage from '../../../assets/icons/orange-sun.svg'
import {useNavigate} from "react-router-dom";
import {closeSearching, setRechercheRecent, setResult} from "../../../shared/store/slices/Chefs/ChefsSlice";
import {useDispatch, useSelector} from "react-redux";
import {searchHome} from "../../../shared/store/slices/explore/exploreService";
import {RootState} from "../../../shared/store";
import SearchCard from "./searchCard";
import loader from "../../../assets/icons/explore/loading-svgrepo-com.svg"
import {getChef, getMenuChef} from "../../../shared/store/slices/Chefs/ChefsService";
import {setBackRoot} from "../../../shared/store/slices/Root/rootSlice";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";


export interface SearchInterface {
	closeSearch?: () => void,
	inputOnly?: boolean
}

export const debounce = (item: any, timeout = 300) => {
	let timer: NodeJS.Timeout
	return (...args: any) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			item.apply(item, args);
		}, timeout)
	}
}
export const checkData = (data: any) => {
	return (data.data.chef_restaurants?.chefs?.length === 0)
		&& (data.data.chef_restaurants?.restaurants?.length === 0)
		&& (data.data.plates?.plates?.length === 0)
		&& (data.data.plates?.menus.length === 0)
		&& (data.data.themes?.categories?.length === 0)
		// && (data.data.themes?.subCategories?.length === 0);

}

// eslint-disable-next-line max-lines-per-function
const Search: React.FC<{ config: SearchInterface }> = ({config}) => {
	const searchRef = useRef<HTMLDivElement>(null)
	const value = useRef('');
	const navigate = useNavigate();

	const listRecherche = useSelector((state: RootState) => state.explore.listSearch)
	// eslint-disable-next-line sonarjs/cognitive-complexity
	const saveInput = (el: any) => {
		if (el.length > 2) {
			setLoading(true)
			searchHome(el.toUpperCase()).then((res: any) => {

				setLoading(false)
				setActive(checkData(res))
			})
		} else
			setLoading(false)
	}
	const Debounce = debounce((el: any) => saveInput(el));
	//End Debounce
	useEffect(() => {
		document.addEventListener('click', (e: MouseEvent) => {
			if (searchRef?.current && !searchRef?.current?.contains(e.target as Node)) {
				config?.closeSearch && config?.closeSearch()
			}
		})
	}, [])
	const [searchState, setSearchState] = useState('RECENT')
	const [active, setActive] = useState(false)
	const checkListToShow = (state: string) => {
		if (state === 'change' &&  value?.current?.length>2) {
			setSearchState('RESULT')
		} else {
			setSearchState('RECENT')
		}
	}
	const dispatch = useDispatch();
	const goToSearchPage = () => {
		if (!!config?.inputOnly) {
			dispatch(setRechercheRecent(listRecherche))
			navigate('/search')
		}
	}
	const {t} = useTranslation()
	const [load, setLoading] = useState(false)
	const recentList = useSelector((state: RootState) => state.explore.listRecent)
	return (
		<div ref={searchRef} className={'searchMainContainer'}>
			<div className={'inputContainer'}>
				<SearchOutlined className={'icon'}/>
				<input onClick={goToSearchPage} onChange={(e) => {
					value.current = e.target.value;
					checkListToShow('change');
				}}
					   onKeyUp={(e: any) => Debounce(e.target.value)}
					   placeholder={t('searchScreenPlaceholderText')} type="text"
				/>
				<img src={loader} className={`loaderr ${load}`}/>
			</div>
			{
				(!config?.inputOnly && ['RESULT'].includes(searchState)) && (
					<div className={'listContainerTest'}>
						<div className={'listScrollContainer'}>
							{
								// searchState === 'RECENT' ? (
								// 	<RecentSearchList data={[...( recentList ||[])]} length={recentList?.length}/>
								// ) : (
									<ResultSearchList data={listRecherche || []} noData={'message'} active={active}/>
								// )
							}
						</div>
					</div>
				)
			}
			{
				(!config?.inputOnly && ['RECENT'].includes(searchState)) && (
					recentList?.length >0 &&
					<div className={'listContainer'}>
						<div className={'listScrollContainer'}>
							{
								// searchState === 'RECENT' ? (
									<RecentSearchList data={[...( recentList ||[])]} length={recentList?.length}/>
								// ) : (
								// <ResultSearchList data={listRecherche || []} noData={'message'} active={active}/>
								// )
							}
						</div>
					</div>
				)
			}
		</div>
	)
}


export default Search


// eslint-disable-next-line max-lines-per-function
export const RecentSearchList: React.FC<{ data: Array<any> , length:any }> = ({data,length}) => {

	const dispatch = useDispatch();
	const history = useNavigate()
	// eslint-disable-next-line max-lines-per-function,sonarjs/cognitive-complexity
	const goToRecent = (index: number) => {
		let state = {} as any;
		let path;
		let count = '' as any;
		const val = data[index]
		dispatch(closeSearching(false))
		switch (val?.type) {
			case 'chef':
				count = parseInt(val?.name.split(' ')[0])
				getChef(val?.chef_id).then(data => {
					if (isNaN(count)) {
						dispatch(setBackRoot({
							path: location.pathname,
							item: data?.data,
							itemResto: data?.data?.chef?.restaurant[0]?.id
						}))
						history(`/more-detail/chef=${val?.chef_id}/restaurant=${data?.data?.chef?.restaurant[0]?.id}`, {
							state: {
								from: location.pathname,
								item: data,
								itemResto: {id: data?.data?.chef?.restaurant?.[0]?.id},
								id: parseInt(val?.chef_id)
							}
						})

					} else {
						history(`/details-chef/chef=${val?.chef_id}`, {
							state: {
								from: location.pathname,
								item: {
									id: val?.chef_id,
									firstName: data.data.chef.firstName,
									lastName: data.data.chef.lastName
								},
							}
						})
					}
				})
				break;
			case 'restaurant':
				getChef(val?.chef_id).then(data => {
					dispatch(setBackRoot({
						path: location.pathname,
						item: data?.data,
						itemResto: val?.chefIdWhenSecondSpan
					}))
					history(`/more-detail/chef=${val?.chef_id}/restaurant=${val?.restaurant_id}`, {
						state: {
							from: location.pathname,
							item: data,
							itemResto: val?.restaurant_id,
							id: parseInt(val?.chef_id)
						}
					})
				})
				break;
			case 'category':
			case 'sub_category':
			case 'restaurant_category':
				path = `/new-chef/${val?.type === 'category' ? 'category' : 'subcategory'}/${val?.type === 'category' ? val?.category_id : val?.sub_category_id}`;
				state = {
					from: location.pathname,
					state: {id: val?.type === 'category' ? val?.category_id : val?.sub_category_id},
				}
				dispatch(setBackRoot({path: location.pathname, ...state.state}))
				history(path, state);
				break;
			case 'menu':
				getMenuChef(val.chef_id, val.restaurant_id, val.menu_id).then((res: any) => {
					history(`/menu-details/chef=${val?.chef_id}/restaurant=${val.restaurant_id}/menu=${val.menu_id}`, {
						state: {
							idChef: val.chef_id,
							idResto: val.restaurant_id,
							idMenu: val.menu_id,
						},
					})
				})
				break;
			case 'plate':
				history(`/dish-details/chef=${val.chef_id}/restaurant=${val.restaurant_id}/dish=${val.plate_id}`, {
					state: {
						idChef: val.chef_id,
						idResto: val.restaurant_id,
						idPlate: val.plate_id
					}
				})
				break;
			default:
				break;
		}
	}

	const {t} = useTranslation()

	return (

		<div>
			{ length > 0 &&
				<div className={'recentSearchListCont'}>
					<span className={'title'}>{t('searchScreenRecentSearches')}</span>
					<div className={'list'}>
						{
							data.map((item, index) => (
								<div onClick={() => goToRecent(index)} className={'listItem'} style={{bottom: '12px'}}
									 key={index}>
									<span className={'name'}>{item.type !== 'restaurant' ?  item.full_name : item.name}</span>
									<span className={'restaurantName'}>{item.type !== 'restaurant' ?  item.name : item.full_name}</span>
									<div style={{height: '6px'}}></div>
								</div>
							))
						}
					</div>
				</div>

			}
		</div>


	)
}

// eslint-disable-next-line sonarjs/no-identical-functions,max-lines-per-function,sonarjs/cognitive-complexity
const ResultSearchList: React.FC<{ data: any, noData: any, active: boolean }> = ({data, noData, active}) => {

	const {t} = useTranslation()

	const isAlone = (data: any, key: string) => {

		const cond: Record<string, boolean> = {
			chef: data?.data?.chef_restaurants?.chefs?.length > 0,
			themes: data?.themes?.categories?.length > 0,
			plates: data?.plates?.plates?.length > 0,
			menu: data?.plates?.menus?.length > 0,
		}

		delete cond[key];
		return !Object.values(cond).includes(true)
	}

	return (
		<div className={'resultSearchListCont'}>
			<div className={'list'}>
				{active ?
					<div className={'resultListItem'}>
						<div className={'category'}>
							<span className={'noData'}>{t('exploreChefsScreenEmptyMessage').toUpperCase()}</span>
						</div>
					</div> :
					<>
						<div className={'resultListItem'}>
							{(data?.chef_restaurants?.chefs?.length > 0) && <div className={`categoryCont ${''}`}>
                                <img src={sunImage} alt="" draggable={false}/>
                                <span>{"CHEFS"}</span>
                            </div>}
							{data?.chef_restaurants?.chefs.map((item: any, indexRestaurant: number) => (
									<div key={item.id} className={'listItem'} style={{top: '10px'}}>
										<SearchCard key={item.id} item={{
											firstSpan: item?.firstName + ' ' + item?.lastName,
											number_restaurant: item?.number_restaurant,
											idFirstSpan: item?.id,
											typeFirstSpan: 'chef',
											lastName: item?.lastName,
											SecondSpan: item.restaurant.length > 1 ? `${item.number_restaurant} Restaurants` : item.restaurant?.[0]?.name,
											idSecondSpan: item?.restaurant?.map((el: any) => {
												return el.name;
											}),
											typeSecondSpan: 'restaurant',
											type: "chef_restaurants"
										}} index={indexRestaurant}/>
									</div>

								)
							)}
						</div>
						<div className={'resultListItem'} >
							{data?.chef_restaurants?.restaurants.length > 0 && <div className={`categoryCont ${isAlone(data, 'themes') ? 'unsetPadding' : ''}`}>
                                <img src={sunImage} alt="" draggable={false}/>
                                <span>{"RESTAURANTS"}</span>
                            </div>}
							{data?.chef_restaurants?.restaurants.map((item: any, indexRestaurant: number) => (
									<div key={item.id} className={'listItem'} style={{top: '10px'}}>
										<SearchCard key={item.id} item={{
											firstSpan: item?.name,
											number_restaurant: 1,
											idFirstSpan: item.chef_restaurant.id,
											typeFirstSpan: 'restaurant',
											lastName: item?.lastName,
											SecondSpan: item?.chef_restaurant?.firstName + ' ' +  item?.chef_restaurant?.lastName,
											idSecondSpan: item.id,
											typeSecondSpan: 'restaurant',
											type: "chef_restaurants"
										}} index={indexRestaurant}/>
									</div>

								)
							)}
						</div>
						<div className={'resultListItem'}>
							{data?.themes?.categories.length > 0 && <div className={`categoryCont ${isAlone(data, 'themes') ? 'unsetPadding' : ''}`}>
                                <img src={sunImage} alt="" draggable={false}/>
                                <span>{"THEMES"}</span>
                            </div>}

							{data?.themes?.categories?.map((item: any, indexRestaurant: number) =>
								(

									<div key={item.id} className={'listItem'}>
										<SearchCard key={item.id} item={{
											firstSpan: item?.name,
											idFirstSpan: item?.id,
											typeFirstSpan: 'category',
											typeSecondSpan: 'restaurant_category',
											number_restaurant: item.restaurants.length > 1 ? `${item.restaurants.length} ` : 1,
											SecondSpan: `${item.restaurants.length} Restaurants`,
											idSecondSpan: item?.subCategories.map((el: any) => {
												return el.id;
											}),
											type: "themes"
										}} index={indexRestaurant}/>
									</div>
								)
							)}
						</div>
						<div className={'resultListItem'}>
							{data?.plates?.plates.length > 0 && <div className={`categoryCont ${isAlone(data, 'plates') ? 'unsetPadding' : ''}`}>
                                <img src={sunImage} alt="" draggable={false}/>
                                <span>{"PLATS"}</span>
                            </div>}
							{data?.plates?.plates?.map((item: any, indexRestaurant: number) =>
								(
									<div key={item.id} className={'listItem'}>
										<SearchCard key={item.id} item={{
											firstSpan: item?.title,
											idFirstSpan: item?.id,
											typeFirstSpan: 'plate',
											restaurantID: item?.restaurant?.id,
											SecondSpan: item?.restaurant?.chef_restaurant?.firstName + ' ' + item?.restaurant?.chef_restaurant?.lastName ,
											idSecondSpan: item?.restaurant.chef_restaurant.id,
											typeSecondSpan: 'chef',
											type: "plates"
										}} index={indexRestaurant}/>
									</div>
								)
							)}
						</div>
						<div className={'resultListItem'}>
							{data?.plates?.menus.length > 0 && <div className={`categoryCont ${isAlone(data, 'menu') ? 'unsetPadding' : ''}`}>
                                <img src={sunImage} alt="" draggable={false}/>
                                <span>{"MENU"}</span>
                            </div>}
							{data?.plates?.menus?.map((item: any, indexRestaurant: number) => (
									<div key={item.id} className={'listItem'}>
										<SearchCard key={item.id} item={{
											firstSpan: item?.title,
											idFirstSpan: item?.id,
											typeFirstSpan: 'menu',
											typeSecondSpan: 'chef',
											SecondSpan: item?.restaurant?.chef_restaurant?.firstName + ' ' + item?.restaurant?.chef_restaurant?.lastName ,
											idSecondSpan: item?.restaurant?.chef_restaurant?.id,
											restaurantID: item?.restaurant?.id,
											type: "menu"
										}} index={indexRestaurant}/>
									</div>
								)
							)}
						</div>
					</>


				}


			</div>
		</div>
	)
}
