import React, {useEffect, useState} from "react";
import "./MenuItem.scss";
import { STRINGS } from "../../core/enums/strings";
import { CategorieModel, MenuModel } from "../../core/models/Menu";
import {Category} from "../../core/models/Resto";
import {motion, useReducedMotion, useTransform, useViewportScroll } from "framer-motion";
import Parallax from "../parallex/parallax";
import {useTranslation} from "react-i18next";
import ImageContainer from "../imageContainer/imageContainer";
import {currency} from "../../shared/utils/utils-functions";

interface Item {
  isMenu?: boolean;
  inCard?: boolean;
  isCarousel?: boolean;
  menus?: MenuModel;
  plates?: CategorieModel;
  onClick?: () => void;
  platsIncluds?: string;
  open?: () => void;
  descMenu?: boolean;
  category?: Category;
  isCategory?: boolean;
  isPlateFromMenu?: boolean;
  isGray?:boolean
}
interface Props {
  item: Item;
  image?: string;
}

/* eslint-disable max-lines-per-function */
// eslint-disable-next-line sonarjs/cognitive-complexity
const IsMenu = (Props: Props): JSX.Element => {
  const TestDesc = (): JSX.Element => {
    if (!Props.item.descMenu) {
      return <p className="price">{Props.item.plates?.description}</p>;
    } else {
      return <p className="price">{Props.item.menus?.description}</p>;
    }
  };

  const {t} = useTranslation()
  return (
    <>
      {!Props.item.isCarousel && (
        <div>
          {Props.item.isMenu ? (
            <>
              <TestDesc />
              <button onClick={Props.item?.onClick}>
                <span>{t('discover') || STRINGS.HOME.BTN.SHOW}</span>
              </button>
            </>
          ) : (
            <>
              <TestDesc />
              {/*<p className="price">*/}
              {/*  {Props.item.menus?.total_price*/}
              {/*    ? Props.item.menus?.total_price*/}
              {/*    : Props.item.plates?.price}*/}
              {/*  €*/}
              {/*</p>*/}
              <p className="price">
                {currency(Props.item.menus?.total_price
                    ? Props.item.menus?.total_price
                    : Props.item.plates?.price)}
              </p>
              <button className="icn">
                <span>{t('buyButton') || STRINGS.HOME.BTN.BUY}</span>
              </button>
            </>
          )}
        </div>
      )}
    </>
  );
};
// eslint-disable-next-line sonarjs/cognitive-complexity
const MenuItem = (Props: Props): JSX.Element => {
  const checkPlatName=(item:any)=>{
    if (item === 'Dessert'){
      return ('Dessert')
    }else if (item === 'Plat'){
      return (t('plate'))
    }else{
      return (t('starter'))
    }
  }

  const [language,setLanguage]=useState<any>()
  useEffect(()=>{
    setLanguage(localStorage.getItem('SUPERNOVA_LANGUAGE'))
  },[])
  const { scrollY } = useViewportScroll();
  const {t} = useTranslation()
  const y = useTransform(scrollY, [0, 1], [0, -0.1], {
    clamp: false,
  })
  const MotionComponent = motion(ImageContainer)
  return (
    <div
      onClick={() => Props.item?.isPlateFromMenu && Props.item?.open ? Props.item.open() : Props.item.onClick && Props.item.onClick()}
      className={`menu-item ${Props.item.isCarousel && "minHeight"}`}
    >
      <Parallax>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/*@ts-ignore*/}
        <MotionComponent
            className={Props.item.isGray ? "gray" : ""}
            src={Props.image as string}
            alt="food"
        />
      </Parallax>
      {Props.item.plates?.categorie && (
          <span className="type-menu">{checkPlatName(Props.item.plates?.categorie)}</span>
      )}
      {Props.item.descMenu && <span className="type-menu">{t('menu')}</span>}

      <div className="mask" />

      <div className="blc-absolute">
        <p className="type-menu">{Props.item.platsIncluds}</p>

        <p className="menu-name">
          {Props?.item?.isCategory && Props?.item?.category?.image ? Props?.item?.category.name: (Props.item.menus?.title
            ? Props.item.menus?.title
            : Props.item.plates?.title)}
        </p>
        {
              <IsMenu
                  item={{
                    menus: Props.item.menus,
                    isCarousel: Props.item.isCarousel,
                    isMenu: Props.item.isMenu,
                    plates: Props.item.plates,
                    descMenu: Props.item?.descMenu,
                    onClick: Props.item.open,
                  }}
              />
        }
      </div>
    </div>
  );
};
/* eslint-enable max-lines-per-function */
export default MenuItem;
