
import React, {useCallback, useEffect, useRef, useState} from "react";
import "./BottomSheetDish.scss";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import food2 from "../../assets/images/food-2.jpg";
import icn1 from "../../assets/icons/icn-1.svg";
import { STRINGS } from "../../core/enums/strings";
import { useSelector } from "react-redux";
import { RootState } from "../../shared/store";
import {PreparationArray} from "../../core/models/Menu";
import {useTranslation} from "react-i18next";
import ImageContainer from "../imageContainer/imageContainer";
import {useInView} from "react-intersection-observer";

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  cssEase: "linear",
};

const settings1 = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  cssEase: "linear",
};

interface Props {
  onClose: () => void;
}
function useOnClickOutside(ref: any, handler: { (): void; (arg0: { target: any; }): void; }) {
  useEffect(() => {
    const listener = (event: { target: any; }) => {
      if (!ref.current || ref.current?.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}
/* eslint-disable max-lines-per-function */
// eslint-disable-next-line sonarjs/cognitive-complexity
const BottomSheetDish = (Props: Props): JSX.Element => {
  const plate = useSelector((state: RootState) => state.chefs.plateBottomSheet);
  const settings3 = {
    dots: true,

    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 767,
        settings: {
          horizontal: true,
          horizontalSwiping: true,
          swipeToSlide: true,
          infinite: true,
          dots: true,
          autoplay: false,
          autoplaySpeed: 2000,
        },
      },
    ],
  };

  const divRef = useRef();
  const [videos, setVideos] = useState<PreparationArray[]>([]);
  const handler = useCallback(() => {
    setVideos([]);
    Props.onClose && Props.onClose();
  }, []);
  useOnClickOutside(divRef, handler);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    plate && plate?.preparation_array && setVideos(plate?.preparation_array);
    const request = new XMLHttpRequest();
    request.open('GET', `${plate?.preparation_array?.[0]?.translate_content_link}`, true);
    request.send(null);
    request.onreadystatechange = function () {
      if (request.readyState === 4 && request.status === 200) {
        const type = request.getResponseHeader('Content-Type');
        if (type?.indexOf("text") !== 1) {
          setText(request.responseText)
          return request.responseText;

        }
      }
    }
  }, [plate]);

  const close = () => {
    setVideos([]);
    Props.onClose && Props.onClose();
  }

  const {t} = useTranslation()
  const [blobb,setBlobb]=useState<any>()
  const  [text,setText]=useState<any>('')

  useEffect(()=>{
    setBlobb(new Blob([text],
        { type: "text/plain;charset=utf-8" }))
  },[text])

  return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <div className="bottom-sheet" ref={divRef}>
      <button className="close" onClick={() => close()} />
      <Slider {...settings1} className="dish-carousel bottomSheet">
        {plate?.imagePlats?.map((img, i) => {
          return (
            <div key={i} className="item">
              <div className="mask" />
              <ImageContainer className={img.photos_versions.find((item) => item.type === "4:3")?.is_grey ? "gray" : ""} src={img ? img.photos_versions.find((item) => item.type === "4:3")?.url as string : food2} alt="" />
            </div>
          );
        })}
      </Slider>
      <div className="content">
        <h1 className="title-card">{plate?.title}</h1>
        <p className="descript">{plate?.description}</p>
        <div className="dish-content">
          <div className="card">
            <div className="title-card">
              <span>01</span>
              <h1> {t('detailOrderScreenPreparation') || STRINGS.DISH_PAGE.TITLE2}</h1>
            </div>
            <div className="card-content">
              <div className="blc-video">
                <Slider {...settings3} className={'vid'}>
                  {videos && videos?.map((url, i) => {
                    return (
                        <div key={i}>
                          <video playsInline={true} loop={true}
                              // width={width > 1024 ? 2800 : 1300}
                                 style={{width:'100%',height:'100%',objectFit:'contain'}}
                                 controls={true}
                          >
                            <source  type={'video/mp4'} src={url.video
                                ?url.video
                                : "https://www.youtube.com/watch?v=dA0VGEbbw4g"}/>
                            <track  src={URL.createObjectURL(blobb)}
                                    kind="captions"  default/>
                          </video>
                        </div>
                    );
                  })}
                </Slider>

              </div>
            </div>
          </div>
          <div className="card">
            <div className="title-card">
              <span>02</span>
              <h1>{t('ustensils') || STRINGS.DISH_PAGE.TITLE3}</h1>
            </div>
            {plate?.utensils?.length && <div className="card-content">
              <div className="center-horizontally space-between align-center">
                {plate?.utensils?.map((item) => {
                  return (
                    <div key={item.id} className="item-logo">
                      <ImageContainer src={item.image} alt="" />
                      <p>{item.title}</p>
                    </div>
                  );
                })}
              </div>
            </div>}

          </div>
          <div className="card">
            <div className="title-card">
              <span>03</span>
              <h1>{t('composition') || STRINGS.DISH_PAGE.TITLE4}</h1>
            </div>
            {plate?.composition_package?.length && <div style={{overflow:'auto',height:'400px',margin:'20px'}}  className="card-content">
              {plate?.composition_package?.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="center-horizontally align-center  colis-item composition-item"
                  >
                    <div className="nmbr center-horizontally align-center justify-center">
                      {item.number} <span>x</span>
                    </div>
                    <p className="colis-description">{item.name_colis}</p>
                  </div>
                );
              })}
            </div>}

          </div>
        </div>
      </div>
      {plate?.preparation_stage?.length && <Slider {...settings} className="dish-carousel details">
        {plate?.preparation_stage?.map((item) => {
          return (
            <div key={item.id} className="item">
              <div className="step center-horizontally">
                <ImageContainer src={item?.image} alt="food" />
                <div className="step-infs">
                  <p className="title-step">{item.title}</p>
                  <div className={'stepDescriptionBottomShhet'}><p>
                    {item.description}
                  </p></div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>}

      <div className="rows center-vertically space-between">
        <div className="title-card center-vertically">
          <span>04</span>
          <h1>{t('allergens') || STRINGS.DISH_PAGE.TITLE5}</h1>
        </div>
        <p style={{whiteSpace:'pre-line'}}>{plate?.allergenes ? plate?.allergenes : t('noAllergenes')}</p>
      </div>
      <div className="rows center-vertically space-between">
        <div className="title-card center-vertically">
          <span>05</span>
          <h1>{t('conservation') || STRINGS.DISH_PAGE.TITLE6}</h1>
        </div>
        {plate?.conservation_plate?.length ? <div style={{overflow:'auto',height:'400px'}}  className="txt-blc">
          {plate?.conservation_plate?.map((item) => {
            return (
              <div key={item.id} className="center-horizontally align-center">
                <ImageContainer alt={item.name_conservation} src={item.icon_conservation} />
                <p>{item.name_conservation}</p>
              </div>
            );
          })}
        </div> :<div className="txt-blc"> <p>{t('noConservation')}</p></div> }

      </div>
    </div>
  );
};
/* eslint-enable max-lines-per-function */
export default BottomSheetDish;
