import React, {useEffect, useState} from "react";
import {gsap, Power3} from "gsap";
import moment from "moment";
import {Link} from "react-router-dom";
import './Maintenance.scss'
import logo from "../../assets/images/Logo.svg";
import img1 from "../../assets/images/img.png"
import img2 from "../../assets/images/cancer.png";
import insta from "../../assets/images/instagram.png"
import img3 from "../../assets/images/fish.png"
import sun from "../../assets/icons/sun2.png";
import {STRINGS} from "../../core/enums/strings";
import {isMobile} from "../../shared/utils/utils-functions";
import NewsletterBottomsheet from "../../components/newsletter-bottomsheet/NewsletterBottomsheet";
import Sheet from "../../components/bottom-sheet/Sheet";
import {useTranslation} from "react-i18next";
import "../../assets/fonts/Futura/Futura.css";
import {getDateSystem} from "../../shared/store/slices/Account/AccountService";

// eslint-disable-next-line max-lines-per-function,sonarjs/cognitive-complexity
const Maintenance:React.FC = () => {
    const date = moment("2022-11-17 10:00:00");
  const [reRender, setReRender] = useState(0)
    const [currentDate,setCurrentDate]=useState<any>()

    const [timer, setTimer] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
  });
    useEffect(()=>{
        getDateSystem().then((res:any)=>{
            setCurrentDate(moment(res.data.date_current_datetime))

        })
    },[])

  React.useEffect(() => {
    // const currentDate = moment();
      setTimeout(()=>{
          setCurrentDate(moment(currentDate).add(1,'second'))
      },1000)

      const duration = moment.duration(date.diff(currentDate, 'seconds'), 'seconds')
    const get = (num: number, isSeconds?: boolean): string => {
      let str = (num.toString().length === 1 || num < 0) ? '0' : ''
      str += num >= 0 ? num.toString() : '0'
      return str;
    }
    setTimer({
      days: get(duration.days()),
      hours: get(duration.hours()),
      minutes: get(duration.minutes()),
      seconds: get(duration.seconds(), true),
    })

    setTimeout(() => {
      setReRender(reRender + 1)
    }, 1000)
  }, [reRender])
  const [reAnimate, setReAnimate] = useState(0)
  useEffect(() => {
    setInterval(() => {
      setReAnimate(Math.floor(Math.random() * 10000))
    }, 8000)
  }, [])
  // eslint-disable-next-line max-lines-per-function,sonarjs/cognitive-complexity
  useEffect(() => {
    const targetsBreak: any = document.querySelectorAll(".default-page");
    // eslint-disable-next-line max-lines-per-function
    targetsBreak.forEach((target: any, index: any) => {
      const pinSpacing = index === targetsBreak.length - 1 ? "true" : false;
      const tl = gsap.timeline({
        defaults: {duration: window.innerWidth > 768 ? 5 : 8},
        scrollTrigger: {
          trigger: target,
          start: "top 50%",
          toggleActions: "play none play none",
          pinSpacing,
        },
      });

      tl.from(
        ".img1", {
          top: "-50vh",
          duration: window.innerWidth > 768 ? 5 : 8,
          delay: 0.1,
          rotate: 180,
          ease: Power3.easeInOut,
        }, "start").to(
        ".img1", {
          top: "150vh",
          duration: window.innerWidth > 768 ? 5 : 8,
          rotate: 0,
          delay: 0.1,
          ease: Power3.easeInOut,
        }, "start")
      tl.from(
        ".img2", {
          top: "-50vh",
          duration: window.innerWidth > 768 ? 5 : 8,
          delay: 0.1,
          rotate: 0,
          ease: Power3.easeInOut,
        }, 1
      ).to(
        ".img2", {
          top: "150vh",
          duration: window.innerWidth > 768 ? 5 : 8,
          rotate: 360,
          delay: 0.1,
          ease: Power3.easeInOut,
        }, 1)
      tl.from(
        ".img3", {
          top: "-54vh",
          duration: window.innerWidth > 768 ? 5 : 8,
          delay: 0.1,
          rotate: 0,
          ease: Power3.easeInOut,
        }, 2
      ).to(
        ".img3", {
          top: "150vh",
          duration: window.innerWidth > 768 ? 5 : 8,
          rotate: 180,
          delay: 0.1,
          ease: Power3.easeInOut,
        }, 2)

    });


  }, [reAnimate]);
  const {t} = useTranslation()
  const [open, setOpen] = useState(false);
  const [openSheet, setOpenSheet] = useState(false);
  const onOpenMenu = async () => {
    setOpen(true);
    document.body.style.overflow = "hidden";
  };
  const onCloseMenu = () => {
    setOpen(false);
    document.body.style.overflow = "auto";
  };

  const onOpenSheet = async () => {
    setOpenSheet(true);
  }

  const onDismiss = () => {
    setOpenSheet(false);
  };
  return (
    <div className="default-page">
      <div className={'logosCont'}>
        {/*<Link to="" ><img src={insta} alt=""/></Link>*/}
          <a className="insta" href={"https://www.instagram.com/wearesuppernova/?hl=fr"}  rel="noopener noreferrer" target="_blank"><img src={insta} alt=""/></a>

          <img src={logo} alt="logo"/>
      </div>
       <div className={'bodtContainerMaintenance'}>
           <div className="counter">
               <div className={'timerItemsCont'}>
                   <div className={'item'}>
                       <span className={'title'}>{t('days')}</span>
                       <span className={'number'}>{timer.days}</span>
                   </div>
                   <div className={'item'}>
                       <span className={'title'}>{t('hours')}</span>
                       <span className={'number'}>{timer.hours}</span>
                   </div>
                   <div className={'item'}>
                       <span className={'title'}>{t('minutes')}</span>
                       <span className={'number'}>{timer.minutes}</span>
                   </div>
                   <div className={'item'}>
                       <span className={'title'}>{t('seconds')}</span>
                       <span className={'number'}>{timer.seconds}</span>
                   </div>
               </div>
               <h1>{t('aNewTable')} <span>{t('isComing')}</span></h1>
           </div>
           <div className="img img1">
               <img src={img1} alt=""/>
           </div>
           <div className="img img2">
               <img src={img2} alt=""/>
           </div>
           <div className="img img3">
               <img src={img3} alt=""/>
           </div>
           <div className="blc-register">
               <img alt="sun-1" src={sun} className="sun-img"/>
               <button className="btn-register" onClick={!isMobile() ?
                   onOpenMenu : onOpenSheet}>{t('registerPageMaintenance')}</button>
               <div className={open ? "overlay" : "hide"}>
                   <NewsletterBottomsheet isVisible={open} onClose={onCloseMenu}/>
               </div>
               <Sheet open={openSheet} onDismiss={onDismiss}>
                   <NewsletterBottomsheet isVisible={openSheet} onClose={onDismiss}/>
               </Sheet>
           </div>
       </div>
    </div>
  );
};


export default Maintenance;
