import React, {useRef, useState} from "react";
import "./ManageAddress.scss";
import {useDispatch, useSelector} from "react-redux";
import {RootState, store} from "../../../../shared/store";
import {Address} from "../../../../core/models/Address";
import AdresseItem from "../../../../components/adresse-item/AdresseItem";
import {STRINGS} from "../../../../core/enums/strings";
import {useEffect} from "react";
import {
	setAddressName,
	setIsBackFromAddress,
	setIsEditAddress,
	setSelectedAddress
} from "../../../../shared/store/slices/Root/rootSlice";
import Footer from "../../../../components/footer/Footer";
import BackBtn from "../../../../components/back-btn/BackBtn";
import {useNavigate} from "react-router-dom";
import {
	Available,
	getAddressesList,
} from "../../../../shared/store/slices/Account/AccountService";
import {getPickUpDate, isMobile} from "../../../../shared/utils/utils-functions";
import OrderBottomsheet from "../../../../components/order-bottomsheet/OrderBottomsheet";
import {setAutoComplete} from "../../../../shared/store/slices/Account/AccountSlice";
import {useTranslation} from "react-i18next";
import {getMyProfile} from "../../../../shared/store/slices/Auth/AuthService";
import {updateDeliveryDate} from "../../../../shared/store/slices/Cart/CartService";
import moment from "moment";
import {
	setListHolidayFromOrder,
	setObjectOfCalendarDate,
	setServiceType
} from "../../../../shared/store/slices/Cart/CartSlice";

/* eslint-disable max-lines-per-function */
interface ManageAddressProps {
	onEdit?: (item?: Address) => void;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const ManageAddress = (Props: ManageAddressProps) => {

	const {t} = useTranslation()
	const history = useNavigate();
	const addresses = useSelector((state: RootState) => state.account.addresses);
	const dispatch = useDispatch();
	const [openRecap, setOpenRecap] = useState<boolean>(false);
	const isFromRecap = useSelector((state: RootState) => state.root.isBackFromAddress);
	const [open, setOpen] = useState<boolean>(false);
	const goToAddOrEdit = (item: Address) => {
		dispatch(setIsEditAddress(true));
		dispatch(setSelectedAddress(item));
		history('/add-address', {state: item})
	}
	const onCloseMenu = () => {
		setOpen(false);
		document.body.style.overflow = "auto";
	};

	useEffect(() => {
		dispatch(setAutoComplete(undefined));
		dispatch(setIsEditAddress(false));
		dispatch(setSelectedAddress(undefined));
		dispatch(setAddressName(undefined));
		getAddressesList().then();
	}, []);
	useEffect(() => {
		isFromRecap && setOpenRecap(false);
	}, [isFromRecap]);

	// eslint-disable-next-line sonarjs/cognitive-complexity
	const returnToRecap = () => {
		return isMobile() ? setOpenRecap(true) : setOpen(true)

		// getMyProfile().then((result: any) => {
		// 	Available(result.data.basket.restaurant.id, {is_from_basket_details_screen: true}).then((res: any) => {
		// 		if (res?.data.first_day_available) {
		// 			dispatch(setServiceType(res?.data?.infos_first_day_available?.service?.name ? res?.data?.infos_first_day_available?.service?.name : 'liv'))
		// 			const objectOfDate = getPickUpDate(res.data.list_days_by_status, res.data.infos_first_day_available.delivery_date)
		// 			updateDeliveryDate(moment(res.data.infos_first_day_available.delivery_date).format('DD/MM/YYYY'), objectOfDate[0].pickup_date, objectOfDate?.[0]?.service?.code ? objectOfDate?.[0]?.service?.code : 0, true).then(() => {
		// 				return isMobile() ? setOpenRecap(true) : setOpen(true)
		// 			})
		// 		} else {
		// 			Available(result.data.basket.restaurant.id, {
		// 				month: new Date().getMonth() + 2,
		// 				year: new Date().getFullYear(),
		// 				is_from_basket_details_screen: true
		// 			}).then((res: any) => {
		// 				dispatch(setListHolidayFromOrder(res?.data?.list_days_by_status.filter((item: any) => !item?.is_available_ups || !item?.is_available_pickup || !item?.service)))
		// 				dispatch(setObjectOfCalendarDate(res?.data?.list_days_by_status))
		// 				dispatch(setServiceType(res?.data?.infos_first_day_available?.service?.name ? res?.data?.infos_first_day_available?.service?.name : 'liv'))
		// 				return isMobile() ? setOpenRecap(true) : setOpen(true)
		//
		// 			})
		// 		}
		//
		// 	})
		// })

	}
	return (
		<div className="manage-address">
			<div className="blc-top">
				<BackBtn pathTo='/account'/>
				<h1>
					{t('manageMyDeliveryAddressesScreenTitleStepOne') || STRINGS.ACCOUNT.EDIT_ADRESSES.PART1}{" "}
					<span>{t('manageMyDeliveryAddressesScreenTitleStepTwo') || STRINGS.ACCOUNT.EDIT_ADRESSES.PART2}</span>
					{" "}{t('manageMyDeliveryAddressesScreenTitleStepThree') || STRINGS.ACCOUNT.EDIT_ADRESSES.PART3}
				</h1>
			</div>
			<div className="center-vertically items-content">
				{addresses?.map((item: Address) => (
					<AdresseItem
						key={item.id}
						action={() => Props.onEdit ? Props.onEdit(item) : goToAddOrEdit(item)}
						item={item}
					/>
				))}
				{!addresses && (
					<div>
						<p className="empty-adresse">
							{t('manageMyDeliveryAddressesScreenEmptyMessage') || STRINGS.ACCOUNT.EDIT_ADRESSES.EMTPTY}
						</p>
					</div>
				)}

				<div>
					<button
						onClick={
							!isMobile()
								? () => Props.onEdit && Props.onEdit()
								: () => history({pathname: `/add-address`})
						}
						className="btn-primary "
					>
						{t('manageMyDeliveryAddressesScreenButtonAdd') || STRINGS.ACCOUNT.EDIT_ADRESSES.SUB_TITLE2}
					</button>
				</div>
				{
					isFromRecap && addresses && addresses.length > 0 &&
					(
						<div>
							<button onClick={returnToRecap} className="btn-primary ">
								{t('manageMyDeliveryAddressesScreenButtonContinue') || STRINGS.ACCOUNT.EDIT_ADRESSES.RETURN_RECAP}
							</button>
						</div>
					)
				}
			</div>
			<div className={open ? "overlay" : "hide"}>
				<OrderBottomsheet onClose={onCloseMenu} open={open}/>
			</div>
			<div className="blc-footer">
				<Footer quantity={0} priceOrder={0} isHome={true} returnToRecap={openRecap}/>
			</div>
			<div style={{height: '350px'}}/>

		</div>
	);
};
/* eslint-enable max-lines-per-function */

export default ManageAddress;
