import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EmptyItem from "../../../components/empty-item/EmptyItem";
import Footer from "../../../components/footer/Footer";
import HeaderAccount from "../../../components/header-account/HeaderAccount";
import OrderItemAccount from "../../../components/order-item-account/OrderItemAccount";
import { STRINGS } from "../../../core/enums/strings";
import Orders from "../../../core/mocks/Orders";
import { OrderRecap } from "../../../core/models/Order";
import { RootState, store } from "../../../shared/store";
import { getMyOrders } from "../../../shared/store/slices/Account/AccountService";
import { getOrders } from "../../../shared/store/slices/Account/AccountSlice";
import { setRootLoading } from "../../../shared/store/slices/Root/rootSlice";
import { isMobile } from "../../../shared/utils/utils-functions";
import "./MyOrders.scss";
import emptyChef from "../../../assets/images/chef_empty.png";
import {useTranslation} from "react-i18next";


/* eslint-disable max-lines-per-function */
const MyOrders = (): JSX.Element => {
  const [orderList, setOrderList] = useState<OrderRecap[]>([]);
  const [isNextPage, setNextPage] = useState(false);
  const currentPage = useRef(0);
  const [isLoadApi,setIsLoadApi]=useState(false)

  const history = useNavigate();
  const isLoading = useSelector((state: RootState) => state.root.isLoading);

  const orders = useSelector((state: RootState) => state.account.orderList);
  const dispatch = useDispatch();
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {

    currentPage.current += 1;
    await getMyOrders(currentPage.current).then((res) => {
      setIsLoadApi(true)
      setNextPage(res.data.list.length === 10);
      setOrderList(
        currentPage.current === 1
          ? [...res.data.list]
          : [...orderList, ...res.data.list]
      );
    });
  };
  const {t} = useTranslation()
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    })
  }

  useEffect(() => {
    window.addEventListener('resize', setDimension);

    return(() => {
      window.removeEventListener('resize', setDimension);
    })
  }, [screenSize])
  return (
    <div className="blc-orders">
      <HeaderAccount title={t('globalProfileScreenMyOrderText') || STRINGS.ACCOUNT.MY_ORDERS.TITLE} backRoute={true}/>
      {isLoadApi && orderList.length === 0 && !isLoading && (
            <EmptyItem img={emptyChef}>
              <p className="empty-content">{t('myOrderScreenYouDontHaveOrdersByNow') || STRINGS.ACCOUNT.MY_ORDERS.EMPTY}</p>
            </EmptyItem>
      )}
      {orderList?.map((item) => {
        return (
          <OrderItemAccount
            key={item.id}
            onClick={() =>
                screenSize.dynamicWidth > 768
                ? history(`/account/${item.id}`,{state:{orderId:item.id}})
                : history(`/order-details/${item.id}`,{state:{orderId:item.id}})
            }
            item={item}
          />
        );
      })}
      {isNextPage && (
        <button style={{width:'230px'}} onClick={getData} className="btn-primary">
          {t('showMore').toUpperCase()}
        </button>
      )}
      {/* <Switch>
          <Route
            path={PATHS.MY_ACCOUNT.ORDER_DETAILS}
            component={OrderDetails}
          />
        </Switch> */}
      <div className="blc-footer">
        <Footer quantity={0} priceOrder={0} isHome={true} isOrder={false} />
      </div>
    </div>
  );
};
/* eslint-enable max-lines-per-function */
export default MyOrders;
