import React, { useEffect, useState } from "react";
import "./Contact.scss";
import { STRINGS } from "../../../core/enums/strings";
import HeaderAccount from "../../../components/header-account/HeaderAccount";
import ContactForm from "./contact-form/ContactForm";
import ContactTel from "./contact-tel/ContactTel";
import Footer from "../../../components/footer/Footer";
import { isMobile } from "../../../shared/utils/utils-functions";
import {useTranslation} from "react-i18next";

export interface Data {
  subject: string;
  message: string;
}
/* eslint-disable max-lines-per-function */
const Contact = (): JSX.Element => {
  const {t} = useTranslation()
  const phoneNumber = "+33756800802";
  const [isKeyboardUp, setIsKeyboardUp] = useState<boolean>(false);
  const eventResize = () => {
    setIsKeyboardUp(
      window.screen.height - 300 > window.visualViewport.height && isMobile()
    );
  };
  useEffect(() => {
    window.addEventListener("resize", eventResize);
    return () => {
      isMobile() && window.removeEventListener("resize", eventResize);
    };
  }, []);
  return (
    <div className="contact">
      <HeaderAccount title={t('globalProfileScreenContact') || STRINGS.ACCOUNT.CONTACT.TITLE} />
      <div className="contact-content center-vertically">
        <div className="blc center-horizontally space-between" style={{alignItems:'unset'}}>
          <ContactTel
            number={phoneNumber}
            title={t('contactScreenInfoHeader') || STRINGS.ACCOUNT.CONTACT.SUB_TITLE2}
            subTitle={t('contactScreenSupportDepartment') || STRINGS.ACCOUNT.CONTACT.TXT}
            tel={t('contactScreenWhatsup') || STRINGS.ACCOUNT.CONTACT.NMBR2}
          />
          <ContactForm />
        </div>
      </div>
      {!isKeyboardUp && (
        <div className="blc-footer">
          <Footer quantity={0} priceOrder={0} isHome={true} />
        </div>
      )}
    </div>
  );
};
/* eslint-enable max-lines-per-function */

export default Contact;
