import "./MoreDetails.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useLocation, useNavigate} from "react-router-dom";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import MenuItem from "../../../components/menu-item/MenuItem";
import {STRINGS} from "../../../core/enums/strings";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../shared/store";
import {getChefDetails} from "../../../shared/store/slices/Chefs/ChefsSlice";
import {getRestoChef} from "../../../shared/store/slices/Chefs/ChefsService";
import {CategorieModel, MenuModel} from "../../../core/models/Menu";
import {motion} from "framer-motion";
import { isMobile} from "../../../shared/utils/utils-functions";
import star_1 from "../../../assets/icons/CHEF 1 ETOILE FR.svg";
import star_2 from "../../../assets/icons/CHEF 2 ETOILES FR.svg";
import star_3 from "../../../assets/icons/CHEF 3 ETOILES FR.svg";
import star_1_eng from "../../../assets/icons/CHEF 1 STAR EN.svg";
import star_2_eng from "../../../assets/icons/CHEF 2 STARS EN.svg";
import star_3_eng from "../../../assets/icons/CHEF 3 STARS EN.svg";
import {Category} from "../../../core/models/Resto";
import emptyChef from "../../../assets/images/chef_empty.png";
import EmptyItem from "../../../components/empty-item/EmptyItem";
import {setBackRoot} from "../../../shared/store/slices/Root/rootSlice";
import {
	setSelectedChef
} from "../../../shared/store/slices/Cart/CartSlice";
import {useTranslation} from "react-i18next";
import ImageContainer from "../../../components/imageContainer/imageContainer";
import {useParams} from "react-router";

const settings = {
	dots: false,
	infinite: true,
	slidesToShow: 4.5,
	slidesToScroll: 1,
	autoplay: true,
	speed: 5000,
	autoplaySpeed: 3000,
	cssEase: "linear",
	responsive: [
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 1.5,
			},
		},
	],
};

interface RestoModel {
	id: any;
	image: string;
	name: string;
}

/* eslint-disable max-lines-per-function */
// eslint-disable-next-line sonarjs/cognitive-complexity
const MoreDetails = (): JSX.Element => {
	const [isReadMore, setIsReadMore] = useState(true);
	const toggleReadMore = () => {
		setIsReadMore(!isReadMore);
	};
	const params = useParams()
	const history = useNavigate();
	const chef = useSelector((state: RootState) => state.chefs.chef);
	const dispatch = useDispatch();
	const [language,setLanguage]=useState<any>()
	useEffect(()=>{
		setLanguage(localStorage.getItem('SUPERNOVA_LANGUAGE'))
	},[])
	const [listResto, setListResto] = useState<RestoModel[]>([]);
	const starChef = chef?.restaurant?.chef_restaurant?.stars;
	const [starChefState,setStarChefState]=useState<any>()
	// useEffect(()=>{
	// 	setStarChefState(chef?.restaurant?.chef_restaurant?.stars)
	// },[])
	const cart = useSelector((state: RootState) => state.cart.basket)
	const [listEntre, setListEntre] = useState<any>()
	const [listDessert, setListDessert] = useState<any>()
	const [listPlat, setListPlat] = useState<any>()
    const [isLoadApi,setIsLoadApi]=useState(false)
// eslint-disable-next-line @typescript-eslint/no-empty-function

	useEffect(() => {
		getData();
		return () => {
			dispatch(
				getChefDetails({
					restaurant: {
						id: undefined,
						name: "",
						city: "",
						address: "",
						category: {
							id: undefined,
							name: "",
							description: "",
							image: [],
						},
						number_menu: 0,
						number_plate: 0,
						menus: [],
						plates: [],
						image: "",
						description: "",
						chef_restaurant: {
							id: undefined,
							email: "",
							role: "",
							firstName: "",
							lastName: "",
							description: "",
							image_profile: [],
							number_restaurant: 0,
							isResto: false,
							restaurant: [],
							stars: undefined,
						},
					},
					categories: [],
					same_plates: [],
				})
			);
		};
	}, [params.id2]);

	const getData = async () => {
		await getRestoChef(Number(params.id), Number(params.id2)).then(
			(res: any) => {
				setStarChefState(res.data?.restaurant?.chef_restaurant?.stars)
				setListDessert(res.data.restaurant.plates.filter((item: any) => item.categorie === 'Dessert'))
				setListEntre(res.data.restaurant.plates.filter((item: any) => item.categorie === 'Entrée'))
				setListPlat(res.data.restaurant.plates.filter((item: any) => item.categorie === 'Plat'))

				const arrayResto =
					res?.data?.restaurant?.chef_restaurant?.restaurant.reduce(
						(acc: any, curr: any) => {
							if (res?.data?.restaurant?.name === curr.name) {
								return [curr, ...acc];
							}
							return [...acc, curr];
						},
						[]
					);
			}
		);
	};

	// eslint-disable-next-line sonarjs/cognitive-complexity
	useEffect(() => {
		if (!isMobile()) {
			chef?.same_plates?.length < 3
				? (settings.slidesToShow = 1.5)
				: chef?.same_plates?.length < 6
					? (settings.slidesToShow = 2.5)
					: (settings.slidesToShow = 4.5);
		} else {
			settings.responsive[0].settings.slidesToShow =
				chef?.same_plates?.length === 1 ? 1 : 1.5;
		}
	}, [chef]);

	const goToPlateDetails = (menu: CategorieModel, toPlate?: boolean) => {
		chef.restaurant && dispatch(setSelectedChef(chef.restaurant));
		dispatch(
			setBackRoot({
				item: chef.restaurant.chef_restaurant,
				itemResto: {id: params.id2},
			})
		);
		history(`/dish-details/chef=${params.id}/restaurant=${params.id2}/dish=${menu.id}`, {
			state: {
				idChef: toPlate ? menu.chef_id : chef.restaurant.chef_restaurant?.id,
				idResto: toPlate ? menu.restaurant_id : chef.restaurant.id,
				idPlate: menu.id,
			},
		});
	};

	const goToMenuDetails = (item: MenuModel) => {
		chef.restaurant && dispatch(setSelectedChef(chef.restaurant));
		dispatch(
			setBackRoot({
				item: chef.restaurant.chef_restaurant,
				itemResto: {id: params.id2},
			})
		);
		history(`/menu-details/chef=${params.id}/restaurant=${params.id2}/menu=${item.id}`, {
			state: {
				idChef: params.id,
				idResto: params.id2,
				idMenu: item.id,
			},
		});
	};

	const goToCategory = (category: Category) => {
		history(`/new-chef/category/${category.id}`, {
			state: {id: category.id},
		});
	};

  const getSrc=(item:any)=>{
	  return Math.abs(window.innerWidth - window.innerHeight) > 500  ?  '16:9' :   '4:3'
  }
	const GetPlatSrc=()=>{
		return Math.abs(window.innerWidth - window.innerHeight) > 500  ?  '4:3' :   '3:4'
	}
	const {t} = useTranslation()
	const MotionComponent = motion(ImageContainer)
	const [scrollY, setScrollY] = useState<number>(0);

	useLayoutEffect(() => {
		const handleScroll = () => {
			setScrollY(Math.min(0, window.scrollY / 3 - 60) - 10);
		}
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		}
	}, [scrollY]);
	return (
		<>
			<motion.div
				initial={{y: 0}}
				animate={{
					y: "100vh",
					transitionEnd: {
						display: "none",
					},
				}}
				transition={{type: "spring", delay: 1, duration: 2}}
				className="first-l center-vertically position-center"
			/>
			<motion.div
				className="second-l"
				initial={{y: "-100vh", height: "-100vh"}}
				animate={{
					height: 0,
					y: "100vh",
					transitionEnd: {
						display: "none",
					},
				}}
				transition={{type: "spring", delay: 1, duration: 2}}
			/>
			<div className="more-page">
				<Header pathTo={isMobile()&& '/home'} classname={scrollY === 0 || scrollY < -50 ? '' : 'home-header'}
						isHome={true} isHomeActive={true}/>
				<div className="top-infs">
					<motion.div>
						<motion.h1
							className="name -1"
							initial={{y: 170, height: 0, opacity: 0}}
							animate={{y: 0, height: "auto", opacity: 1}}
							transition={{delay: 1.6, duration: 0.7}}
						>
							<div className="last-name">{`${chef?.restaurant?.chef_restaurant?.firstName}`}</div>
							<div className="first-name">{`${chef?.restaurant?.chef_restaurant?.lastName}`}</div>
						</motion.h1>
						<div className="chef-photo">
							{chef?.restaurant?.chef_restaurant?.image_profile && (
								<div className="blc-img">
									<motion.div
										className="bg-absolute"
										initial={{scale: 0}}
										animate={{scale: 1}}
										transition={{delay: 1, duration: 1}}
									/>
									<motion.img
										className={chef?.restaurant?.chef_restaurant?.image_profile?.find(
											(item) => item.type === "4:3"
										)?.is_grey ? "gray" : ""}
										src={
											chef?.restaurant?.chef_restaurant?.image_profile?.find(
												(item) => item.type === "4:3"
											)?.url as string
										}
										alt=""
										initial={{scale: 0}}
										animate={{scale: 1}}
										transition={{delay: 1.5, duration: 1}}
									/>
									{
                                        <motion.div
                                            style={{display: starChef? "none" : "flex"}}
                                            className="cercle-stars"
                                            initial={{scale: 0}}
                                            animate={{scale: 1}}
                                            transition={{delay: 1.5, duration: 1}}
                                        />
									}
									<motion.div
										initial={{opacity: 0}}
										animate={{opacity: 1}}
										transition={{delay: 2.5, duration: 0.2}}
										className="blc-absolute"
									>
										{
											language === 'fr' ?
												<ImageContainer
													style={{display: !starChef ? "none" : "flex",borderRadius:'unset'}}
													src={
														starChef === 1
															? star_1
															: starChef === 2
																? star_2
																: star_3
													}
													className="round"
												/>
												:
												<ImageContainer
													style={{display: !starChef ? "none" : "flex",borderRadius:'unset'}}
													src={
														starChef === 1
															? star_1_eng
															: starChef === 2
																? star_2_eng
																: star_3_eng
													}
													className="round"
												/>
										}
									</motion.div>
								</div>
							)}
							<motion.h1
								className="name -2"
								initial={{y: 500, opacity: 0}}
								animate={{y: 0, opacity: 1}}
								transition={{delay: 2.5, duration: 0.5}}
							>
								<div className="last-name">{`${chef?.restaurant?.chef_restaurant?.firstName}`}</div>
								<div className="first-name">{`${chef?.restaurant?.chef_restaurant?.lastName}`}</div>
							</motion.h1>
						</div>

						<motion.div
							className="chef-infos"
							initial={{y: 1000, opacity: 0}}
							animate={{y: 0, opacity: 1}}
							transition={{delay: 2.5, duration: 1}}
						>
							<div className="list-resto">
								{listResto?.length > 0 &&
								listResto.map((item, index) => {
									return (
										<div
											onClick={() => {
												history(`/more-detail/chef=${params.id}/restaurant=${item.id}`)
											}}

											className={`text-button ${
												index === 0 ? "active" : "primary"
											}`}
											key={index}
										>
											{item?.name}
										</div>
									);
								})}
							</div>
							<h2 style={{display: 'none'}}>{chef?.restaurant?.name}</h2>
							<p className={isReadMore ? "readMore" : ""}>
								{chef?.restaurant?.description}
							</p>
							{chef.restaurant.description.length > 120 && (
								<button onClick={toggleReadMore}>
									{isReadMore
										? (t('readMore') || STRINGS.HOME.BTN.READ_MORE)
										: (t('showLess') || STRINGS.HOME.BTN.READ_LESS)}
								</button>
							)}
						</motion.div>
					</motion.div>
					<div className="container-desk">
						<div className="top-menus">
							{/* eslint-disable-next-line sonarjs/cognitive-complexity */}
							{chef?.restaurant?.menus?.map((item) => {
								return (
									<div key={item.id} onClick={() => goToMenuDetails(item)}>
										<MenuItem
											image={
												item.cover_picture?.find((item) =>
													isMobile() ? item.type === "3:4" : item.type === getSrc(item)
												)?.url as string
											}
											item={{
												isGray: item.cover_picture?.find((item) =>
													isMobile() ? item.type === "3:4" : item.type ===  getSrc(item)
												)?.is_grey,
												menus: item,
												inCard: false,
												descMenu: true,
												platsIncluds: (t('menuIn')) + '  ' + `${
													item?.plates.length +
													(item?.entries ? item?.entries.length : 0) +
													(item?.desserts ? item?.desserts.length : 0)
												} ${
													item?.plates.length +
													(item?.entries ? item?.entries.length : 0) +
													(item?.desserts ? item?.desserts.length : 0) <
													2
														? (t('dishMoreDetatail'))
														: (t('searchScreenTypeDish'))
												}`,
											}}

										/>
									</div>
								);
							})}
						</div>
						{chef?.restaurant?.plates?.length !== 0 && (
							<h1 className="title-menu">{t('detailsScreenCarteText') || STRINGS.MORE_DETAILS.IN_CARD}</h1>
						)}

						<div className="menus">
							{listEntre?.map((menu: any) => {
								return (
									<div key={"entree"+menu.id+Math.round(Math.random()*1000)
									} onClick={() => goToPlateDetails(menu)}>
										<MenuItem
											item={{
												plates: menu,
												inCard: false,
												isGray: menu?.imagePlats[0]?.photos_versions?.find(
													(item: any) => isMobile() ? item.type === "1:1" : item.type === GetPlatSrc()
												)?.is_grey
											}}
											image={
												menu?.imagePlats[0]?.photos_versions?.find(
													(item: any) => isMobile() ? item.type === "1:1" : item.type === GetPlatSrc()
												)?.url
											}
										/>
									</div>
								);
							})}
							{/* eslint-disable-next-line sonarjs/no-identical-functions */}
							{listPlat?.map((menu: any) => {
								return (
									<div key={"plat"+menu.id+Math.round(Math.random()*1000)
									} onClick={() => goToPlateDetails(menu)}>
										<MenuItem
											item={{
												plates: menu,
												inCard: false,
												isGray: menu?.imagePlats[0]?.photos_versions?.find(
													(item: any) => isMobile() ? item.type === "1:1" : item.type === GetPlatSrc()
												)?.is_grey
											}}
											image={
												menu?.imagePlats[0]?.photos_versions?.find(
													(item: any) => isMobile() ? item.type === "1:1": item.type === GetPlatSrc()
												)?.url
											}
										/>
									</div>
								);
							})}
							{/* eslint-disable-next-line sonarjs/no-identical-functions */}
							{listDessert?.map((menu: any) => {
								return (
									<div key={"dessert"+menu.id+Math.round(Math.random()*1000)
									} onClick={() => goToPlateDetails(menu)}>
										<MenuItem
											item={{
												plates: menu,
												inCard: false,
												isGray: menu?.imagePlats[0]?.photos_versions?.find(
													(item: any) => isMobile() ? item.type === "1:1" : item.type === GetPlatSrc()
												)?.is_grey
											}}
											image={
												menu?.imagePlats[0]?.photos_versions?.find(
													(item: any) => isMobile() ? item.type === "1:1" : item.type === GetPlatSrc()
												)?.url
											}
										/>
									</div>
								);
							})}
						</div>
						{isLoadApi && chef.restaurant.plates?.length === 0 && (
							<EmptyItem img={emptyChef}>
								<p className="empty-content">
									{t('no_plat_found')}
								</p>
							</EmptyItem>
						)}
						{ chef?.categories && chef.categories.length > 0 && (
							<h1 className="title-menu title-carousel">
								{chef?.restaurant?.chef_restaurant?.firstName +
								" " +
								chef?.restaurant?.chef_restaurant?.lastName +
								` ${t('appearsIn')}: `}
							</h1>
						)}
					</div>
					<div className="scroll-content">
						<div className="blc-scroll">
							{chef?.categories && chef.categories.length > 0 && (
								<>
									{chef?.categories?.map((category) => {
										return (
											<div key={category.id}>
												<MenuItem
													item={{
														isGray: category.image?.find((item) => item.type === "4:3")
															?.is_grey,
														category: category,
														inCard: false,
														isCarousel: true,
														isCategory: true,
														onClick: () => goToCategory(category),
													}}
													image={
														category.image?.find((item) => item.type === "4:3")
															?.url
													}
													key={category.id}
												/>
											</div>
										);
									})}
								</>
							)}
						</div>
					</div>
				</div>
				<Footer quantity={0} priceOrder={0} isHome={true}/>
			</div>
		</>
	);
};
/* eslint-enable max-lines-per-function */

export default MoreDetails;
