import React, { useEffect, useState } from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import "./Map.scss";
import Geocode from "react-geocode";
import { useDispatch, useSelector } from "react-redux";
import {setAddressName, setIsFromMap} from "../../shared/store/slices/Root/rootSlice";
import { store } from "../../shared/store";
import { useNavigate } from "react-router-dom";
import { isMobile } from "../../shared/utils/utils-functions";
import { alertMessage } from "../../shared/store/interceptor";
import { STRINGS } from "../../core/enums/strings";
import { RootState } from "../../shared/store";
import {useTranslation} from "react-i18next";
import {setPositionState} from "../../shared/store/slices/Account/AccountSlice";
import {GOOGLE_API_KEY, GoogleApiKey, googleApiKey} from "../../core/enums/APIS";
Geocode.setApiKey(GOOGLE_API_KEY);
Geocode.setLocationType("ROOFTOP");

/*global google*/
// eslint-disable-next-line max-lines-per-function
const MyMap = (props) => {
    const [isAdded,setIsAdded]=useState(false)

    const history = useNavigate();
  const dispatch = useDispatch();
  const [errorgeo, setErrorGeo] = useState(false);
  const autoCompletePosition = useSelector(
    (state) => state.account.autoComplete
  );
  const [position, setPosition] = useState({
    lat: 0,
    lng: 0,
  });
  const [auxPosition,setAuxPosition]=useState({...position})
  const [initialPosition, setInitialPosition] = useState({
    lat: 0,
    lng: 0,
  });


  const onAdjustRepere = () => {
      navigator.geolocation.getCurrentPosition(
          function (position) {
              setPosition({
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
              });

          })
    // if (props.lat && props.lng) {
    //   setPosition({
    //     lat: position.lat,
    //     lng: position.lng,
    //   });
    // } else {
    //
    //   setPosition({
    //     lat: initialPosition.lat,
    //     lng: initialPosition.lng,
    //   });
    // }
  };
  useEffect(() => {
    if (!!navigator.geolocation && !props.lat && !props.lng) {
      navigator.geolocation.getCurrentPosition(
          // eslint-disable-next-line sonarjs/no-identical-functions
        function (position) {
          setPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setInitialPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (err) => {
          alertMessage(STRINGS.ERRORGEO);
          setPosition({
            lat: 48.8566,
            lng: 2.3522,
          });
          setInitialPosition({
            lat: 48.8566,
            lng: 2.3522,
          });
        },
        { enableHighAccuracy: true, timeout: 10000000, maximumAge: 1000000 }
      );
    } else if (props.lat && props.lng) {
      setPosition({
        lat: props.lat,
        lng: props.lng,
      });
    } else {
      alertMessage(STRINGS.ERRORPOSITION.toUpperCase());
    }
  }, []);
  useEffect(() => {
    position && Geocode.fromLatLng(position.lat.toString(), position.lng.toString()).then(
        (response) => {
            const adress = response.results[0];
          dispatch(setAddressName(adress));
          dispatch(setIsFromMap(true))
        },
        (error) => {
           dispatch(setAddressName(null));
        }
      );
  }, [position]);
  const addMarker = (place) => {

      const { latLng } = place;
    !isMobile() &&
      setPosition({
        lat: latLng.lat(),
        lng: latLng.lng(),
      });
  };
  useEffect(()=>{
     setAuxPosition(position)
  },[])
useEffect(()=>{
    if (props?.lng !== position?.lng){
       store.dispatch(setPositionState(position))
    }
},[position])

  useEffect(() => {
    autoCompletePosition && setPosition(autoCompletePosition);
  }, [autoCompletePosition]);
  const {t} = useTranslation()
    const OPTIONS = {
        minZoom: 15,
        maxZoom: 15,
    }
  return (
    <div className="center-vertically">
      <GoogleMap
        ref={(map) => {
          if (map && position.lat && position.lng) {
            map.panTo(position);
          }
        }}
       options={OPTIONS}
        defaultZoom={15}

        defaultCenter={position}
        onClick={(item) => {

          addMarker(item);
        }}
      >
          {/* eslint-disable-next-line sonarjs/no-identical-expressions */}
        {props.isMarkerShown && position.lng !== 0 && position.lat !==0 &&  <Marker  position={position}  />}
      </GoogleMap>

      <div className="center-horizontally align-center justify-center">
        <p
          onClick={() => {
            isMobile()
              ? history("/map", { state: { lat: props.lat, lng: props.lng } })
              : onAdjustRepere();
          }}
          className="adjust"
        >
            {t('updateAddressScreenButtonText')}
        </p>
      </div>
    </div>
  );
};
const locationAutoComplete = store.getState().account.autoComplete;
const position = store.getState().account.position;

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: (
      <div
        style={{
          width: !isMobile() ? "626px" : "374px",
          height: !isMobile() ? "493px" : "201px",
          position: isMobile() && "relative",
          right: isMobile() && "27px",
          marginBottom: isMobile() && "35px",
        }}
      />
    ),
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <MyMap
    lng={locationAutoComplete ? locationAutoComplete?.lng : props?.lng}
    lat={locationAutoComplete ? locationAutoComplete?.lat : props?.lat}
    isMarkerShown={props.isMarkerShown}
    zoom={2}

  />
));
// eslint-enable-next-line max-lines-per-function
export default MyMapComponent;
