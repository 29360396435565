import React, {ReactNode, useLayoutEffect, useRef, useState} from 'react';
import './Parallax.scss';
import {motion, useReducedMotion, useSpring, useTransform, useViewportScroll} from "framer-motion";
type ParallaxProps = {
    children: ReactNode,
    offset?: number,
    horizontalParallax?: boolean
}
// eslint-disable-next-line max-lines-per-function
const Parallax = ({ children, offset = 20, horizontalParallax = false  }: ParallaxProps): JSX.Element => {
    const prefersReducedMotion = useReducedMotion()
    const [elementTop, setElementTop] = useState(0)
    const [elementLeft, setElementLeft] = useState(0)
    const [clientHeight, setClientHeight] = useState(0)
    const [clientWidth, setClientWidth] = useState(0)
    const ref = useRef(null)

    const { scrollY, scrollX } = useViewportScroll()

    const initial = elementTop - clientHeight
    const initialX = elementLeft - clientWidth
    const final = elementTop + offset
    const finalX = elementTop + offset

    const yRange = useTransform(scrollY, [initial, final], [offset, -offset])
    const xRange = useTransform(scrollX, [initialX, finalX], [offset, -offset])
    const y = useSpring(yRange, { stiffness: 400, damping: 90 })
    const x = useSpring(xRange, { stiffness: 400, damping: 90 })

    useLayoutEffect(() => {
        const element = ref?.current
        const onResize = () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setElementTop(element?.getBoundingClientRect()?.top + window?.scrollY || window?.pageYOffset)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setElementLeft(element?.getBoundingClientRect()?.left + window?.scrollX || window?.pageXOffset)
            setClientHeight(window?.innerHeight)
            setClientWidth(window?.innerWidth)
        }
        onResize()
        window?.addEventListener('resize', onResize)
        return () => window?.removeEventListener('resize', onResize)
    }, [ref])

    // Don't parallax if the user has "reduced motion" enabled
    if (prefersReducedMotion) {
        return <>{children}</>
    }

    return (
        <motion.div ref={ref} className="parallax" style={{ y, x: horizontalParallax ? x : undefined }}>
            {children}
        </motion.div>
    )
};

export default Parallax;
