import {Executor} from "../../Executor";
import { BaseUrl, APIS } from '../../../../core/enums/APIS'
import {store} from "../../index";
import {setRecentList, setSearchList} from "./exploreSlice";

export const getMacAddress = () => {
  const navigator_info = window.navigator;
  const screen_info = window.screen;
  let uid = navigator_info.mimeTypes.length;
  uid += Number(navigator_info.userAgent.replace(/\D+/g, ''));
  uid += navigator_info.plugins.length;
  uid += screen_info.height || '' as unknown as number;
  uid += screen_info.width || '' as unknown as number;
  uid += screen_info.pixelDepth || '' as unknown as number;
  return uid?.toString()
}

export const getKitchenData = () => {
  return Executor({
    method: 'get',
    url:BaseUrl + APIS.EXPLORE.getKitchenData
  })
}

export const getSubCategoryList = (id: number,page:number) => {
  return Executor({
    method: 'get',
    url:BaseUrl + APIS.EXPLORE.getSubCategoryList(id,page)
  })
}
export const searchHome = (string: string) => {
  return Executor({
    method: 'get',
    url:BaseUrl + APIS.EXPLORE.getSearchResult(string),
    successFun: (data: any) => {
      store.dispatch(setSearchList(data?.data))
    },

  })
}
export const setSearchRecent = (data: any) => {
  return Executor({
    method: 'post',
    data,
    url:BaseUrl + APIS.EXPLORE.setSearchRecent(),
    successFun: (data: any) => {
      // store.dispatch(setSearchList(data?.data))
    },
  })
}
// eslint-disable-next-line sonarjs/no-identical-functions
export const getSearchRecent = (string: string) => {
  // TODO To be verified
  return Executor({
    method: 'get',
    url:BaseUrl + APIS.EXPLORE.getSearchRecent(string),
    successFun: (data: any) => {
      store.dispatch(setRecentList(data?.data))
    },
  })
}