import exp from "constants";

export const BaseUrl = process.env.REACT_APP_API_ENDPOINT
export const GOOGLE_API_KEY=process.env.REACT_APP_API_GOOGLE
export const AUTO_COMPLETE_API_KEY=process.env.REACT_APP_API_GOOGLE_AUTO_COMPLETE
export const STRIP_KEY=process.env.REACT_APP_API_STRIP
export const APP_ID_FACEBOOK_AUTHENTIFICATION=process.env.REACT_APP_API_FACEBOOK_ID
export const CLIENT_ID_GOOGLE_AUTHENTIFICATION=process.env.REACT_APP_API_GOOGLE_AUTHENTIFICATION
export const CLIENT_ID_APPLE_AUTHENTIFICATION=process.env.REACT_APP_API_APPLE_AUTHENTIFICATION
export const REDIRECT_URI_APPLE_LOGIN=process.env.REACT_APP_API_APPLE_REDIRECT
export const STRIP_CONFIRM_PAYMENT_REDIRECT=process.env.REACT_APP_API_CONFIRM_PAYMENT_REDIRECT
export const APIS = {
  AUTH: {
    login: "/client/login/step-two",
    register: '/client/register',
    refresh_token: '/token/refresh',
    STEP_ONE_REGISTER: '/register/step-one',
    STEP_TWO_REGISTER: '/register/step-two-new',
    STEP_THREE_REGISTER: '/register/step-three',
    STEP_FOUR_REGISTER: '/register/step-four',
    LOGIN_SOCIAL_ACCOUNT: '/client/login/social-account',
    TERMS:'/client/terms_and_conditions'
  },
  PROFILE: {
    getOrUpdateMyProfile: '/client/me',
    updatePassword: '/client/update-password',
    updateIdentity: '/client/update-identity',
    updatePhoneStepOne: '/client/update-phone-step-one',
    updatePhoneStepTwo: '/client/update-phone-step-two'
  },
  CHEFS: {
    getHome: '/home/list-chef',
    getListChefAlphabet: "/home/search/alphabet",
    getListChefs: (page: number): string => (`/home/?page=${page}`),
    getListChefsAlphabet:(): string => ("/home/search/alphabet"),
    getAllChefAlphabet :(id:string,page:number):string=>(`/home/search/alphabet-paginate/${id}?page=${page}`),

    getListChefsWithStars:(stars:number, page: number):string=>(`/client/cuisine/list-chefs?stars=${stars}&page=${page}`),
    getListRestoPerSubcategory:(id:number):string=>(`/client/cuisine/sub_category/${id}`),
    getChef: (id: number): string => (`/home/chef/${id}`),
    getListMenuChef: (id: number, page: number): string => (`/client/home/chef/${id}/menu?page=${page}`),
    getListPlateChef: (id: number, page: number): string => (`/client/home/chef/${id}/plat?page=${page}`),
    getMenuChef: (IdChef?: number, IdMenu?: number, IdResto?: number): string => (`/home/chef/${IdChef}/restaurant/${IdResto}/menu/${IdMenu}?id_basket=${localStorage.getItem('basket_id')}`),
    getPlateChef: (IdChef?: number, IdPlate?: number, IdResto?: number): string => (`/home/chef/${IdChef}/restaurant/${IdResto}/plate/${IdPlate}?id_basket=${localStorage.getItem('basket_id')}`),
    getListRestoChef: (IdChef: number, page: number): string => (`/home/chef/${IdChef}/restaurant?page=${page}`),
    getRestoChef: (IdChef: number, IdResto: number): string => (`/home/chef/${IdChef}/restaurant/${IdResto}`),
    getCategory: (id: number): string => (`/home/category/${id}`),
    getCategories: (page: number): string => (`/home/category?page=${page}`),

  },
  CART: {
    getOwnCart: '/client/panier',
    addPlateToCart: '/client/panier/plate',
    addMenuToCart: '/client/panier/menu',
    deletePlateFromCart: (id: number): string => (`/client/panier/plate/${id}`),
    deleteMenuFromCart: (id: number): string => (`/client/panier/menu/${id}`),
    getPublicCart: (id?: number): string => (`/public/panier/${id}`),
    createCart: '/public/panier',
    addPlateToCartPublic: (id: number): string => (`/public/panier/${id}/plate`),
    addMenuToCartPublic: (id: number): string => (`/public/panier/${id}/menu`),
    deletePlateFromCartPublic: (id: number, idPlate: number): string => (`/public/panier/${id}/plate/${idPlate}`),
    deleteMenuFromCartPublic: (id: number, idMenu: number): string => (`/public/panier/${id}/menu/${idMenu}`),
    incrDecrPlateCart: (id: number): string => (`/client/panier/plate/${id}/increment-decrement`),
    incrDecrMenuCart: (id: number): string => (`/client/panier/menu/${id}/increment-decrement`),
    incrDecrPublicPlateCart: (idCart: number, id: number): string => (`/public/panier/${idCart}/plate/${id}/increment-decrement`),
    incrDecrPublicMenuCart: (idCart: number, id: number): string => (`/public/panier/${idCart}/menu/${id}/increment-decrement`),
    orderRecap: (idOrder?: number): string => (`/client/recap-command`),
    updateDeliveryDate: '/client/panier/delivred_at',
    updateDeliveryDatePublicBasket: (idBasket: string): string => (`/public/panier/${idBasket}/delivred_at`),
    SET_CUSTOM_MESSAGE: '/client/panier/custom_message'

  },
  PAYMENT: {
    createPaymentIntent: `/client/command/payment-intent`,
    checkoutPayment: (idOrder?: number): string => (`/client/command/chekout-payment`),
    checkValidityPromoCode:(code:number):string => (`/client/promo_code/${code}/validate_promo_code`),
    // checkValidityPromoCode:(code:number,price:any,basket_id:any):string => (`/client/promo_code/${code}/check?total_price=${price}&basket_id=${basket_id}`),
    checkCurrentCurrency:():string=>('/cllient/info_currency')
  },
  ADDRESSES: {
    addAddressOrGetAll: '/client/address',
    setAsDefault: (id: number): string => `/client/address/${id}/set-default`,
    deleteAddress: (id: number): string => `/client/address/${id}`,
  },
  ORDERS: {
    createOrder: '/client/command',
    getMyOrders: (page: number): string => (`/client/command?page=${page}`),
    getOrder: (id: number): string => (`/client/command/${id}`),
    getOrderPreparation: (id: number): string => (`/client/command/${id}/preparation-created`),
    getProductsClaim: (id?: number): string => (`/client/claim/list-produit/${id}`),
    getListClaimTypes: '/client/claim?role_type=client',
    getListCommandToClaim: '/client/contact/command',
    getAvailableDays:(id:number) : any => (`/client/get_list_available_dates/${id}`),
    getAvailableRecapDay:(id:number) : any => (`/restaurants/${id}/check_available_date`)

  },
  HELP: {
    getListProductForClaim: '/client/claim/list-produit',
    postClaim: '/client/claim'
  },
  ABOUT: {
    getAboutData: '/client/about'
  },
  CONTACT: {
    ContactUs: '/client/contact/send'
  },
  FORGETPASSWORD: {
    sendMail: '/forgot-password/send-email-forget-password',
    verifyCode: '/forgot-password/verify-code-email',
    resetPassword: '/forgot-password/reset-password-email'
  },
  HOLIDAYS_API: 'https://public-holiday.p.rapidapi.com/2021/FR',
  NEWS_LETTER: '/client/new-lettre',
  PUBLIC_NEWSLETTER: '/public/new-lettre',
  SYSTEM_DATE:'/public/get_current_date ',

  EXPLORE: {
    getKitchenData: '/client/cuisine',
    getSubCategoryList: (id: number,page:number) => `/client/cuisine/categories/${id}/sub_categories?page=${page}`,
    getSearchResult: (string:string) => `/home/global-search/${string}`,
    setSearchRecent: () => `/home/search-recent`,
    getSearchRecent: (string:string) => `/home/search-recent/${string}`


  },
}
