import React, {useEffect, useState} from "react";
import "./SideBar.scss";
import {NavLink, useLocation} from "react-router-dom";
import { STRINGS } from "../../core/enums/strings";
import TabsAccount from "../../core/mocks/Tabs-Account";
import {
  getMyProfile,
  logout,
} from "../../shared/store/slices/Auth/AuthService";
import {useDispatch, useSelector} from "react-redux";
import { RootState } from "../../shared/store";
import { Modal } from "antd";
import sun from "../../assets/icons/BIENVENUE FR.svg"
import sun2 from "../../assets/icons/WELCOME EN.svg"

import ConfirmBottomSheet from "../confirm-bottom-sheet/ConfirmBottomSheet";
import {setIsBackFromAddress} from "../../shared/store/slices/Root/rootSlice";
import {useTranslation} from "react-i18next";
import {TabAccount} from "../../core/models/TabsAccount";
import {isMobile} from "../../shared/utils/utils-functions";

/* eslint-disable max-lines-per-function */

// eslint-disable-next-line sonarjs/cognitive-complexity
const SideBar = (): JSX.Element => {
  const TabsMobile: TabAccount[] = [

    {
      name: "globalProfileScreenMyOrderText",
      link: isMobile() ? "/orders" : "/account/orders",
      id: 2,
      mobileLink:'/orders',
      desktopLink:'/account/orders'
    },
    {
      name: "globalProfileScreenMyProfileText",
      link: isMobile() ? "/profile" : "/account/profile",
      id: 1,
      mobileLink:"/profile",
      desktopLink:"/account/profile"
    },

    {
      name: "globalProfileScreenMyDeliveryAddressText",
      link: isMobile() ? "/address" : "/account/addresses",
      id: 3,
      mobileLink:"/address" ,
      desktopLink:"/account/addresses",
    },
    {
      name: "globalProfileScreenNewsletters",
      link: isMobile() ? "/newsletters" : "/account/newsletters-setting",
      id: 4,
      mobileLink:"/newsletters",
      desktopLink:"/account/newsletters-setting"
    },
    {
      name: "globalProfileScreenContact",
      link: isMobile() ? "/contact" : "/account/contact",
      id: 5,
      mobileLink:"/contact",
      desktopLink:"/account/contact"
    },
    {
      name: "globalProfileScreenAbout",
      link: isMobile() ? "/about" : "/account/about/cgv",
      id: 6,
      mobileLink:"/about",
      desktopLink:"/account/about/cgv"
    },
  ];
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    })
  }

  useEffect(() => {
    window.addEventListener('resize', setDimension);

    return(() => {
      window.removeEventListener('resize', setDimension);
    })
  }, [screenSize])
  const profile = useSelector(
    (state: RootState) => state.authentication.profile
  );
  const location = useLocation();
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    await getMyProfile().then((res) => {
      // console.log(res);
    });
  };
  const [isConfirmBottomSheet, setIsConfirmBottomSheet] = useState<boolean>(false);
  const [language,setLanguage]=useState<any>()
  useEffect(()=>{
    setLanguage(localStorage.getItem('SUPERNOVA_LANGUAGE'))
  },[])
  const logoutModal = () => {
    setIsConfirmBottomSheet(true);
  };
  const confirmAction = (isYes: boolean) => {
    setIsConfirmBottomSheet(false);
    if (isYes) {
      logout();
    }
  }
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const checkActiveItem = (itemID: number, isActive: boolean) => {
    if (isActive) {
      return isActive
    } else {
      return location.pathname.includes('address') && itemID === 3
          || location.pathname.includes('help') && itemID === 1
          || location.pathname.includes('account/success') && itemID === 1
          || location.state?.orderId && itemID === 2
          // || location.state?.order_id && itemID === 2;
    }
  }
  const {t} = useTranslation();
  const dispatch  = useDispatch();
  const resetBasket = () => {
    dispatch(setIsBackFromAddress(false));
  }
  return (
    <div className="sidebar">
      <ConfirmBottomSheet isLogout={true} action={confirmAction} yesText={t('globalProfileScreenLogout') || STRINGS.ACCOUNT.LOGOUT_ACTION} noText={t('cancel')}
                          title={t('globalProfileScreenLogoutModalQuestion') || STRINGS.ACCOUNT.LOGOUT_MESSAGE} text="" isDelete={true}
                          open={isConfirmBottomSheet}/>
      <div  className="center-horizontally" style={{margin:profile && !profile.firstName ? '0 0 27px' : ''}}>
        <div className="blc-img">
          {
            language === 'fr' ?
                <img src={sun} alt="sun" className="round"/>
                  :
                <img src={sun2} alt="sun" className="round"/>

          }
        </div>
        <div className="center-vertically greeting-content">
          <p className="greeting">{t('globalProfileScreenTitleHello') || STRINGS.ACCOUNT.GREETING.toUpperCase()}</p>
          <p className="name">{profile?.firstName?.toUpperCase()}</p>
        </div>
      </div>
      <div className="center-vertically tabs">
        {
          !isMobile() ?
              TabsAccount?.map((item) => {
                return (
                    <div key={item.id} className="tab-item" onClick={resetBasket}>
                      <NavLink
                          // to={item.link}
                          to={screenSize.dynamicWidth > 768 ? item?.desktopLink : item.mobileLink}
                          className={({ isActive }) =>
                              "tabName" + (checkActiveItem(item.id, isActive) ? " active" : "")
                          }
                      >
                        {t(item.name).toUpperCase()}
                      </NavLink>
                    </div>
                );
              })
            :
              TabsMobile?.map((item) => {
          return (
          <div key={item.id} className="tab-item" onClick={resetBasket}>
          <NavLink
          // to={item.link}
          to={screenSize.dynamicWidth > 768 ? item?.desktopLink : item.mobileLink}
          className={({ isActive }) =>
          "tabName" + (checkActiveItem(item.id, isActive) ? " active" : "")
        }
          >
        {t(item.name).toUpperCase()}
          </NavLink>
          </div>
          );
        })
        }
      </div>
      <div className="center-vertically logout-section">
        <p onClick={logoutModal} className="logout">
          {t('globalProfileScreenLogout') || STRINGS.ACCOUNT.LOGOUT.toUpperCase()}
        </p>
      </div>
    </div>
  );
};
/* eslint-enable max-lines-per-function */

export default SideBar;
