import {languages} from "./json";


let languagesResources:Record<string, any> = {};

export const getLanguagesForI18n = () => {
  languagesResources = {}
  Object.entries(languages).forEach(key => {
    Object.entries(key[1]).forEach(item => {
      if(!languagesResources[item[0].toLowerCase()]) {
        languagesResources[item[0].toLowerCase()] = {
          translation: {}
        }
      }
      languagesResources[item[0].toLowerCase()].translation[key[0]] = item[1] || ''
    })
  })

  return {...languagesResources};
};
export default getLanguagesForI18n;
