import React, {useEffect, useState} from "react";
import "./Home.scss";
import sun from "../../assets/icons/sun2.png";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import ChefCard, {Restaurant} from "../../components/chef-card/ChefCard";
import {STRINGS} from "../../core/enums/strings";
import {useNavigate} from "react-router-dom";
import {PATHS} from "../../core/enums/paths";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../shared/store";
import {
	getChef,
	getListHome,
	getAllCategories
} from "../../shared/store/slices/Chefs/ChefsService";
import {motion} from "framer-motion";
import {isMobile} from "../../shared/utils/utils-functions";
import {setBackRoot, setIsBackFromAddress} from "../../shared/store/slices/Root/rootSlice";
import NewsletterBottomsheet from "../../components/newsletter-bottomsheet/NewsletterBottomsheet";
import Sheet from "../../components/bottom-sheet/Sheet";
import {ImagePlate} from "../../core/models/Menu";
import FirstElementCard from "./firstElementCard/FirstElementCard";
import {useTranslation} from "react-i18next";
import {getMacAddress, getSearchRecent} from "../../shared/store/slices/explore/exploreService";

// eslint-disable-next-line max-lines-per-function,sonarjs/cognitive-complexity
const Home = (): JSX.Element => {
	const chefs = useSelector((state: RootState) => state.chefs.homeList);
	const categories = useSelector((state: RootState) => state.chefs.categories);
	const history = useNavigate();
	// const [listItems, setListItems] = useState<(CategoryHome | ChefHomeType)[]>();
	const [listItems, setListItems] = useState<(any)[]>();

	const [isLoaded, setIsLoaded] = useState<boolean>();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(setIsBackFromAddress(false));
		dispatch(setBackRoot(undefined));
		getData().then();
		// getSearchRecent(getMacAddress()).then();

	}, []);
	useEffect(() => {
		const list: any[] = [];
		chefs.category.map((item) => {
			list?.push({...item, isCategory: true});
		});
		chefs.restaurant.map((item) => {
			list?.push({...item, isChef: true});
		});
		chefs.sub_categories.map((item) => {
			list?.push({...item, isSubCategory: true})
		})
		list?.sort((a, b) => a.home_rank < b.home_rank ? -1 : a.home_rank > b.home_rank ? 1 : 0);
		setListItems(list);
		setIsLoaded(true);
	}, [chefs]);


	const getData = async () => {
		await getListHome().then();
		await getAllCategories(0).then()
	};

	// const goToRestaurant = (item: ChefModel, restaurant: Restaurant) => {
	//     history(`/more-detail/${restaurant.id}`, {state: {item, itemResto: restaurant}})
	// }

	const goToCategoryDetails = (item: any) => {
		history(`/new-chef/category/${item.id}`, {
			state: {id: item.id},
		})
	}
	const goToSubCategoryDetails = (item: any) => {
		history(`/new-chef/subcategory/${item.id}`, {
			state: {id: item.id},
		})
	}
	// eslint-disable-next-line sonarjs/cognitive-complexity
	const goToChef = async (item: any) => {
		item?.number_restaurant > 1
			? history(`/details-chef/chef=${item?.chef?.id}`, {
				state: item,
			}) : item?.id && await getChef(item?.chef?.id).then((data) =>
			history(`/more-detail/chef=${item?.chef?.id}/restaurant=${item?.id}`, {
				state: {
					item: item.chef,
					itemResto: {id: data.data.chef.restaurant[0].id},
					id: item.chef.id
				}
			}));
	}


	const [open, setOpen] = useState(false);
	const [openSheet, setOpenSheet] = useState(false);

	const onOpenMenu = async () => {
		setOpen(true);
		document.body.style.overflow = "hidden";
	};
	const onCloseMenu = () => {
		setOpen(false);
		document.body.style.overflow = "auto";
	};

	const onOpenSheet = async () => {
		setOpenSheet(true);
	}

	const onDismiss = () => {
		setOpenSheet(false);
	};

	const {t} = useTranslation()

	return (
		<>
			{
				isLoaded && (
					<>
						<motion.div
							initial={{y: 0}}
							animate={{
								y: "100vh",
								transitionEnd: {
									display: "none",
								},
							}}
							transition={{type: "spring", delay: 1, duration: 2}}
							className="first-layer center-vertically position-center"
						>
							<img alt="sun-1" src={sun} className="sun-img"/>
						</motion.div>
						<motion.div className="second-layer" initial={{y: "-100vh", height: "-100vh"}}
									animate={{
										height: 0,
										y: "100vh",
										transitionEnd: {
											display: "none",
										},
									}}
									transition={{type: "spring", delay: 1, duration: 2}}/>

						<div
							className="home-page">
							<Header classname={'home-header'} isHome={true} isHomeActive={true}/>
							<div className="home-content">
								{
									listItems?.[0] && (
										<div className="section-top">
											<motion.div className="blc-animation" initial={{scale: 1.5}}
														animate={{scale: 1}}
														transition={{delay: 1, duration: 1}}>
												<div className="mask"/>

												{/* eslint-disable-next-line sonarjs/no-all-duplicated-branches */}
												{(listItems?.[0].isChef) ?
													<FirstElementCard
														idCustom={`${listItems?.[0].id}_item`}
														isChef={true}
														idCat={listItems?.[0].chef.id}
														isGray={listItems?.[0]?.image_chef?.find((item: ImagePlate) => isMobile() ? item.type === "3:4" : item.type === "16:9")?.is_grey }

														className={((listItems?.[0]?.image_chef?.find((item: ImagePlate) => isMobile() ? item.type === "3:4" : item.type === "4:3")?.is_grey || listItems?.[0]?.image?.find((item: ImagePlate) => isMobile() ? item.type === "3:4" : item.type === "4:3")?.is_grey)) ? "gray" : ""}
														item={listItems?.[0]}
														active_option={listItems?.[0].active_option}
														video={listItems?.[0] ? listItems?.[0]?.video?.find((item: ImagePlate) => isMobile() ? item.type === "3:4" : item.type === "4:3")?.url : listItems?.[0]?.image?.find((item: ImagePlate) => isMobile() ? item.type === "3:4" : item.type === "4:3")?.url}
														gif={listItems?.[0] ? listItems?.[0]?.gif?.find((item: ImagePlate) => isMobile() ? item.type === "3:4" : item.type === "4:3")?.url : listItems?.[0]?.image?.find((item: ImagePlate) => isMobile() ? item.type === "3:4" : item.type === "4:3")?.url}
														image={listItems?.[0] ? listItems?.[0]?.image_chef?.find((item: ImagePlate) => isMobile() ? item.type === "3:4" : item.type === "16:9")?.url : listItems?.[0]?.image?.find((item: ImagePlate) => isMobile() ? item.type === "3:4" : item.type === "16:9")?.url}/>

													: listItems?.[0].isSubCategory ?
														<FirstElementCard
															/* eslint-disable-next-line sonarjs/no-all-duplicated-branches */
															image={listItems?.[0] ? listItems?.[0]?.image?.find((item: ImagePlate) => isMobile() ? item.type === "3:4" : item.type === "16:9")?.url : listItems?.[0]?.image?.find((item: ImagePlate) => isMobile() ? item.type === "3:4" : item.type === "16:9")?.url}
															className={((listItems?.[0]?.photos?.find((item: ImagePlate) => isMobile() ? item.type === "3:4" : item.type === "4:3")?.is_grey || listItems?.[0]?.image?.find((item: ImagePlate) => isMobile() ? item.type === "3:4" : item.type === "4:3")?.is_grey)) ? "gray" : ""}
															isSubCategori={true}
															item={listItems?.[0]}
															active_option={listItems?.[0]?.active_option}
															video={listItems?.[0] ? listItems?.[0]?.video?.find((item: ImagePlate) => isMobile() ? item.type === "3:4" : item.type === "4:3")?.url : listItems?.[0]?.image?.find((item: ImagePlate) => isMobile() ? item.type === "3:4" : item.type === "4:3")?.url}
															gif={listItems?.[0] ? listItems?.[0]?.gif?.find((item: ImagePlate) => isMobile() ? item.type === "3:4" : item.type === "4:3")?.url : listItems?.[0]?.image?.find((item: ImagePlate) => isMobile() ? item.type === "3:4" : item.type === "4:3")?.url}
															// idCat={listItems?.[0]?.chef?.id}
															idCustom={`${listItems?.[0].id}_item`}


														/>

														: listItems?.[0].isCategory ?
															// eslint-disable-next-line sonarjs/no-all-duplicated-branches
															<FirstElementCard
																/* eslint-disable-next-line sonarjs/no-all-duplicated-branches */
																image={listItems?.[0] ? listItems?.[0]?.image?.find((item: ImagePlate) => isMobile() ? item.type === "3:4" : item?.type === "16:9")?.url : listItems?.[0]?.image?.find((item: ImagePlate) => isMobile() ? item?.type === "3:4" : item.type === "16:9")?.url}
																isCategory={true}
																idCustom={`${listItems?.[0].id}_item`}
																item={listItems?.[0]}
																isGray={listItems?.[0]?.image?.find((item: ImagePlate) =>  isMobile() ? item.type === "3:4" : item?.type === "16:9")?.is_grey}
																// idCat={listItems?.[0].chef.id}
																/* eslint-disable-next-line sonarjs/no-identical-expressions */
																className={((listItems?.[0]?.image?.find((item: ImagePlate) => isMobile() ? item.type === "3:4" : item.type === "4:3")?.is_grey || listItems?.[0]?.image?.find((item: ImagePlate) => isMobile() ? item?.type === "3:4" : item?.type === "4:3")?.is_grey)) ? "gray" : ""}
																active_option={listItems?.[0]?.active_option}
																video={listItems?.[0] ? listItems?.[0]?.video?.find((item: ImagePlate) => isMobile() ? item.type === "3:4" : item.type === "4:3")?.url : listItems?.[0]?.image?.find((item: ImagePlate) => isMobile() ? item.type === "3:4" : item.type === "4:3")?.url}
																gif={listItems?.[0] ? listItems?.[0]?.gif?.find((item: ImagePlate) => isMobile() ? item.type === "3:4" : item.type === "4:3")?.url : listItems?.[0]?.image?.find((item: ImagePlate) => isMobile() ? item.type === "3:4" : item.type === "4:3")?.url}
															/> : ''


												}

											</motion.div>
											<div className="blc-absolute center-vertically">
												<div className="blc-sun center-horizontally">
													<motion.p
														style={{display: "inline-block", overflow: "hidden"}}
														initial={{height: 0, y: 2000, opacity: 0}}
														animate={{height: "auto", y: 0, opacity: 1}}
														transition={{duration: 2, delay: 0.2}}
														className={`center-vertically align-center justify-center ${listItems?.[0].isCategory && "category-name"}`}
													>
														{listItems?.[0].chef && (
															<>
																<div className="first-name">
																	{listItems?.[0]?.chef?.firstName}
																</div>
																<div className="last-name">
																	{listItems?.[0]?.chef?.lastName}
																</div>
																<div className='resto-name'>
																	{listItems?.[0]?.name}
																</div>
															</>
														)}
														{!listItems?.[0].chef && (
															listItems?.[0]?.name
														)}
													</motion.p>
												</div>
												{listItems?.[0] && (
													<motion.button
														style={{display: "inline-block", overflow: "hidden"}}
														initial={{height: 0, y: 2000, opacity: 0}}
														animate={{height: 44, y: 0, opacity: 1}}
														transition={{duration: 2, delay: 0.4}}
														onClick={() => listItems?.[0]?.isCategory ? goToCategoryDetails(listItems?.[0]) : listItems?.[0]?.isChef ? goToChef(listItems?.[0]) : goToSubCategoryDetails(listItems?.[0])}
													>
														{t('discoverHome') || STRINGS.HOME.BTN.SHOW}
													</motion.button>
												)}
												{/*{isMobile() && listItems?.[0]?.isCategory && (*/}
												{/*	<motion.button*/}
												{/*		onClick={() => history(PATHS.CATEGORIES)}*/}
												{/*		className="btn-cat"*/}
												{/*		initial={{height: 0, y: 1000, opacity: 0}}*/}
												{/*		animate={{height: 44, y: 0, opacity: 1}}*/}
												{/*		transition={{duration: 2, delay: 0.6}}*/}
												{/*	>*/}
												{/*		{t('seeAllCategories') || STRINGS.HOME.BTN.ALL_CAT}*/}
												{/*	</motion.button>*/}
												{/*)}*/}
											</div>
										</div>
									)
								}
								<motion.div initial={{y: 30, opacity: 0}}
											animate={{y: 0, opacity: 1}}
											transition={{duration: 2, delay: 1}}
											className={`list-chef center-horizontally ${!chefs.category && "border-top"}`}>
									{/* eslint-disable-next-line sonarjs/cognitive-complexity,max-lines-per-function */}
									{listItems?.slice(1).map((chef: any, index) => {
										return chef.isCategory ?
											<ChefCard
											isCategory={true}
											isHome={true}
											idCat={chef.id}
											key={index + 'category'}
											isChef={false}
											category={chef}
											image={chef?.image?.find((item: ImagePlate) => item.type === "3:4")?.url as string}
											isGray={chef?.image?.find((item: ImagePlate) => item.type === "3:4")?.is_grey}
											idCustom={`${chef.id}_item`}
											active_option={chef.active_option}
											video={chef?.video?.find((item: ImagePlate) => item.type === "4:3")?.url as string}
											thumbnail={chef?.video?.thumbnail}
											gif={chef?.gif?.find((item: ImagePlate) => item.type === "4:3")?.url as string}
										/> : chef?.isChef ?
											<ChefCard key={index + 'chef'} isChef={true}
													  item={chef.chef}
													  isHome={true}
													  restaurant={{name: chef?.name, id: chef.id}}
													  image={chef?.image_chef?.find((item: ImagePlate) => item.type === "3:4")?.url as string}
													  isGray={chef?.image_chef?.find((item: ImagePlate) => item.type === "3:4")?.is_grey}
													  idCustom={`${chef?.id}_item`}
													  active_option={chef?.active_option}
													  video={chef?.video?.find((item: ImagePlate) => item.type === "4:3")?.url as string}
													  gif={chef?.gif?.find((item: ImagePlate) => item.type === "4:3")?.url as string}
													  thumbnail={chef?.video?.thumbnail}
													  idCat={chef?.chef.id}

											/> : chef?.isSubCategory ?
												<ChefCard key={index + 'sub_category'} isChef={false}
														  isSubCategori={true} subCategory={chef}
														  isHome={true}
														  restaurant={{name: chef?.name, id: chef.id}}
														  image={chef?.image?.find((item: ImagePlate) => item.type === "3:4")?.url as string}
														  isGray={chef?.image?.find((item: ImagePlate) => item.type === "4:3")?.is_grey}
														  idCustom={`${chef?.id}_item`}
														  active_option={chef?.active_option}
														  video={chef?.video?.find((item: ImagePlate) => item.type === "4:3")?.url as string}
														  thumbnail={chef?.video?.thumbnail}
														  gif={chef?.gif?.find((item: ImagePlate) => item.type === "4:3")?.url as string}
														  idCat={chef?.id}

												/>
												: ''
									})}
								</motion.div>
								{/*<div style={{height:'150px'}}/>*/}
								<div className="blc-register">
									<img alt="sun-1" src={sun} className="sun-img"/>
									<h1>{t('homeScreenEndQuestion') || STRINGS.HOME.TXT.REGISTER.TITLE1}</h1>
									<h1>{t('homeScreenEndRegisterText') || STRINGS.HOME.TXT.REGISTER.TITLE2}</h1>
									<p>{t('homeScreenInfoText') || STRINGS.HOME.TXT.REGISTER.INFOS}</p>
									<button className="btn-register" onClick={!isMobile() ?
										onOpenMenu : onOpenSheet}>{t('homeScreenButtonRegister') || STRINGS.HOME.BTN.SIGN_UP}</button>
									<div className={open ? "overlay" : "hide"}>
										<NewsletterBottomsheet isVisible={open} onClose={onCloseMenu}/>
									</div>
									<Sheet open={openSheet} onDismiss={onDismiss}>
										<NewsletterBottomsheet isVisible={openSheet} onClose={onDismiss}/>
									</Sheet>
								</div>
							</div>

						</div>

						<Footer quantity={0} priceOrder={0} isHome={true}/>
					</>
				)
			}
		</>
	);
};
/ eslint-enable max-lines-per-function /

export default Home;
