import React from "react";
import {Outlet } from "react-router-dom";
import "./About.scss";
import HeaderAccount from "../../../components/header-account/HeaderAccount";
import AboutSidebar from "../../../components/about-sidebar/AboutSidebar";
import {useTranslation} from "react-i18next";


/* eslint-disable max-lines-per-function */
const About = () :JSX.Element => {
  const {t} = useTranslation()
  return (
    <div className="about">
        <HeaderAccount title={t('globalProfileScreenAbout') } />
      <div className="about-content">
          <AboutSidebar />
        <div className="right-side">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
/* eslint-enable max-lines-per-function */
export default About;
