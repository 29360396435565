import React from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
interface props {
    open: boolean;
    onDismiss?: ()=>void;
    children?: JSX.Element;
    isCalendar?: boolean;
    className?: string;
}
const Sheet = (props: props) :JSX.Element => {
    return (
        <BottomSheet scrollLocking={false} className={props.isCalendar ? 'bg-white': props.className || ''} open={props.open} onDismiss={props.onDismiss}>
            {props.children}
        </BottomSheet>
    );
};

export default Sheet;
