import React from "react";
import { STRINGS } from "../../../../core/enums/strings";
import whatsapp from "../../../../assets/icons/whatsapp.svg";

import "../Contact.scss";

interface item {
  title: string;
  subTitle: string;
  name?: string;
  tel: string;
  number?:string
}

const ContactTel = (Item: item) => {
    const sendMessage=()=>{
        window.open(`https://wa.me/${Item.number}`,'_blank')
    }
  return (
    <div className="contact-tel">
      <h1 className="sub-title">{Item.title}</h1>
      <div>
        <p>
          {Item.subTitle} &nbsp;<span>{Item.name}</span>
        </p>
        <div onClick={sendMessage} className="phone-nmbr center-horizontally align-center justify-center">
          <img src={whatsapp} alt="whatsapp" />
          <p className="whatsapp">{Item.tel}</p>
        </div>
      </div>
    </div>
  );
};

export default ContactTel;
