import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrderRecap } from "../../../../core/models/Order";
import {AddressDelivery, currency, PaymentIntent, ResponseCheckout} from "../../../../core/models/Payment";

export interface PaymentState {
    paymentIntent: PaymentIntent,
    responseCheckout?: ResponseCheckout,
    orderRecap: OrderRecap,
    currentCurrency:any
    PromoCode:any

}

export const paymentInitialState: PaymentState = {
    paymentIntent: {
        paymentIntent: '',
        ephemeralKey: '',
        customer: '',
        publishableKey: '',
    },
    responseCheckout: undefined,
    orderRecap: { id: 0 },
    currentCurrency:undefined,
    PromoCode:null,

};

export const paymentSlice = createSlice({
    name: 'payment',
    initialState: paymentInitialState,
    reducers: {
        createPaymentIntent: (state, action: PayloadAction<PaymentIntent>): void => {
            state.paymentIntent = action.payload;
        },
        creatCheckoutPayment: (state, action: PayloadAction<ResponseCheckout>): void => {
            state.responseCheckout = action.payload;
        },
        getOrderRecap: (state, action: PayloadAction<OrderRecap>): void => {
            state.orderRecap = action.payload;
        },
        setNewOrderDelevry: (state, action: PayloadAction<any>): void => {
            state.orderRecap.deliver_at = action.payload;
        },
        setDeliveryAdress: (state, action: PayloadAction<AddressDelivery | null>): void => {
            state.orderRecap.address_delivry = action.payload
        },
        setCurrency: (state, action: PayloadAction<any>): void => {
            state.currentCurrency=action.payload
        },
        setPromoCode: (state, action: PayloadAction<any>): void => {
            state.PromoCode=action.payload
        },

    },
});
export const { setPromoCode,setNewOrderDelevry,getOrderRecap, createPaymentIntent, creatCheckoutPayment, setDeliveryAdress , setCurrency} = paymentSlice.actions;
