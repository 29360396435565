import React, {useEffect, useState} from "react";
import HeaderAccount from "../header-account/HeaderAccount";
import {STRINGS} from "../../core/enums/strings";
import ContactTel from "../../pages/my-account/contact/contact-tel/ContactTel";
import ContactForm from "../../pages/my-account/contact/contact-form/ContactForm";
import Footer from "../footer/Footer";
import {useTranslation} from "react-i18next";
import {isMobile} from "../../shared/utils/utils-functions";
import Header from "../header/Header";
import {Link} from "react-router-dom";
import logoBlack from "../../assets/icons/Logo-black.svg";

const Contactsecondary=()=>{
	const {t} = useTranslation()
	const phoneNumber = "+33756800802";
	const [isKeyboardUp, setIsKeyboardUp] = useState<boolean>(false);
	const eventResize = () => {
		setIsKeyboardUp(
			window.screen.height - 300 > window.visualViewport.height && isMobile()
		);
	};
	useEffect(() => {
		window.addEventListener("resize", eventResize);
		return () => {
			isMobile() && window.removeEventListener("resize", eventResize);
		};
	}, []);
	return (
		<div className="contact" style={{padding:'20px'}}>
			{!isMobile() &&
				<div className="blc-center">
					<Link to="" className="logo">
						<img src={logoBlack} alt="logo"/>
					</Link>
				</div>
			}
			<div style={{height:'50px'}}/>
			<HeaderAccount title={t('globalProfileScreenContact') || STRINGS.ACCOUNT.CONTACT.TITLE} />
			<div className="contact-content center-vertically" style={{alignItems:'center'}} >
				<div className="blc center-horizontally space-between" style={{alignItems:'unset'}}>
					<ContactForm secondary={true}/>
				</div>
			</div>
		</div>
	);
}
export default Contactsecondary
