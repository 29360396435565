import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import "../chefs/all-chefs/AllChefs.scss";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import ChefCard from "../../components/chef-card/ChefCard";
import {STRINGS} from "../../core/enums/strings";
import {useSelector} from "react-redux";
import {RootState} from "../../shared/store";
import {
  getAllCategories,
} from "../../shared/store/slices/Chefs/ChefsService";
import EmptyItem from "../../components/empty-item/EmptyItem";
import {CategoryHome} from "../../core/models/Chef";
import sun from "../../assets/icons/Découvrez FR.svg";
import emptyChef from "../../assets/images/chef_empty.png";
import {isMobile} from "../../shared/utils/utils-functions";
import './categories.scss'
import {useTranslation} from "react-i18next";
import sun2 from "../../assets/icons/Discover EN.svg";
/* eslint-disable max-lines-per-function */

// eslint-disable-next-line sonarjs/cognitive-complexity
const Categories = (): JSX.Element => {
  const [isNextPage, setNextPage] = useState(false);
  const currentPage = useRef(0);
  const [categoriesList, setCategoriesList] = useState<CategoryHome[]>([]);
  const isLoading = useSelector((state: RootState) => state.root.isLoading);
  const [isLoadApi,setIsLoadApi]=useState(false)

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    currentPage.current += 1;
    await getAllCategories(currentPage.current).then((res) => {
      setIsLoadApi(true)
      setNextPage(res.data.list.length === 10);
      setCategoriesList(
        currentPage.current === 1
          ? [...res.data.list]
          : [...categoriesList, ...res.data.list]
      );
    });
  };

  const {t} = useTranslation()
  const [scrollY, setScrollY] = useState<number>(0);

  useLayoutEffect(() => {
    const handleScroll = () => {
      setScrollY(Math.min(0, window.scrollY / 3 - 60) - 10);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  }, [scrollY]);
  const [language,setLanguage]=useState<any>()
  useEffect(()=>{
    setLanguage(localStorage.getItem('SUPERNOVA_LANGUAGE'))
  },[])
  return (
    <div className="chefs-page">
      <Header classname={ scrollY === 0 || scrollY < -50 ? '' : 'home-header'} isHome={true} isHomeActive={true} />
      <div className="top-page">
        <div className="container-desk center-horizontally space-between align-center header-page">
          <div className="center-horizontally align-center title-chef">
            <div className="blc-img">
              {
                language === 'fr' ?<img src={sun} className="round" style={{height:'unset'}}/> :
                    <img src={sun2} className="round" style={{height:'unset'}}/>

              }            </div>
            <h1 style={{position:'relative',top:isMobile()?'':'1vw'}}>{t('allCategories')}</h1>
          </div>
          <p>{t('titleSupernovaCategoryDetails')}</p>
        </div>
      </div>
      {isLoadApi && categoriesList.length === 0 && !isLoading && (
        <EmptyItem img={emptyChef}>
          <p className="empty-content">
            {t('noCategory') || STRINGS.ALL_CHEF.EMPTY_ITEM_CATEGORY}
          </p>
        </EmptyItem>
      )}
      {categoriesList && categoriesList.length > 0 && <div className="list-chef center-horizontally">
        {categoriesList?.map((category) => {
          return (
            <ChefCard
              isCategory={true}
              idCat={category.id}
              key={category.id}
              isChef={false}
              category={category}
              image={category.image?.find((item) => isMobile() ? item.type === "3:4" : item.type === "4:3")?.url as string}
              isGray={category.image?.find((item) => isMobile() ? item.type === "3:4" : item.type === "4:3")?.is_grey}
              active_option={'image'}
            />
          );
        })}
      </div>}
      {isNextPage && (
        <button onClick={getData} className="btn-primary">
          {t('showMore') || STRINGS.SHOW_MORE}
        </button>
      )}
      {categoriesList && categoriesList.length === 0 &&       <div style={{height:'150px'}}/>
      }
      {isMobile() &&           <div style={{height:'100px'}}/>  }

      <Footer quantity={0} isHome={true} priceOrder={0}/>
    </div>
  );
};
/* eslint-enable max-lines-per-function */

export default Categories;
