import React from 'react'
import emptyChef from "../../assets/icons/basket-icn-gray.svg";
import {isMobile} from "../../shared/utils/utils-functions";
interface Props{
    children:JSX.Element | JSX.Element[];
    img: string
    center?:any
}

const EmptyItem=(props:Props) : JSX.Element =>{
    return (
        <div style={{alignItems:'center',position:'relative',left:!isMobile() && !props.center && !window.location.pathname.includes('/orders')  ?'50%':''}} className='center-vertically justify-center align-center empty-blc'>
            {props.children}
        </div>
    )
}

export default EmptyItem
