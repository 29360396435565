import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import "./auth.scss";
import "react-tabs/style/react-tabs.css";
import 'react-phone-number-input/style.css'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import AppleSignin from 'react-apple-signin-auth';
import Header from "../../components/header/Header";
import img from "../../assets/icons/image_suppernova.png";
import {STRINGS} from "../../core/enums/strings";
import Input from "../../components/input/Input";
import {PATHS} from "../../core/enums/paths";
import {
	checkAtLeastLength,
	checkDigit,
	checkSpecialCharacter,
	checkUpper,
} from "../../shared/Validator/validation-service";
import check from ".././../assets/icons/check.svg";
import checkValid from ".././../assets/icons/check-valid.svg";

import {
	getListCountry,
	saveDataAuth,
	savePasswordAuth, socialAccountLogin,
	verifyEmailAuth,
	verifyPhoneAuth,
} from "../../shared/store/slices/Auth/AuthService";
import {toast} from "react-toastify";
import {useDispatch, useSelector,} from "react-redux";
import {InputItem} from "../../core/models/Input";
import {errorMessage} from "../../shared/utils/utils-functions";
import {isMobile} from "../../shared/utils/utils-functions";
import {Modal, Select, Switch} from "antd";
import {
	newCountryId,
	setCountrySelected,
	setSocialResponse,
	setStepNow
} from "../../shared/store/slices/Auth/AuthSlice";
import {RootState, store} from "../../shared/store";
import {useTranslation} from "react-i18next";
import ImageContainer from "../../components/imageContainer/imageContainer";
import PhoneInput, {
	isPossiblePhoneNumber,
	isValidPhoneNumber
} from 'react-phone-number-input'
import {debounce} from "../explore/search/search";
import {t} from "i18next";
import {languages} from "../../shared/i18n/json";
import {BottomSheet} from "react-spring-bottom-sheet";
import ModalWidget from "../../components/modal-widget/ModalWidget";
import CalendarWidget from "../../components/calendar-widget/CalendarWidget";
import {GoogleLogin} from "react-google-login";
import {APP_ID_FACEBOOK_AUTHENTIFICATION, CLIENT_ID_GOOGLE_AUTHENTIFICATION} from "../../core/enums/APIS";
import {alertMessage} from "../../shared/store/interceptor";
import jwt_decode from "jwt-decode";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import {enableNewsLetter, publicNewsLetter} from "../../shared/store/slices/Account/AccountService";
interface Error {
	id?: number;
	message?: string;
	icon?: string;
	className?: string;
}

export interface SignUp {
	lastName?: string;
	firstName?: string;
	phone?: string;
	email?: string;
	password?: string;
	code?: number;
	basket_id?: number;
	country?: number
}

interface Title {
	partOne?: string;
	partTwo?: string;
	subTitle?: string;
}
const language=localStorage.getItem('SUPERNOVA_LANGUAGE')
const RegisterSteps: InputItem[][] = [
	[
		{
			label: language==='fr' ? 'Votre Adresse Email' :'Your Email Address',
			rules: [{name: "required"}, {name: "email"}],
			type: "text",
			name: "email",
			value: "",
		},
	],
	[
		{
			label:  language==='fr' ? 'PRénom' :'First Name',
			rules: [{name: "required"}],
			type: "firstName",
			name: "firstName",
			value: "",
		},
		{
			label: language==='fr' ? 'nom' :'last name',
			rules: [{name: "required"}],
			type: "text",
			name: "lastName",
			value: "",
		},


		{
			label: language==='fr' ? 'Votre Adresse Email' :'Your Email Address',
			rules: [{name: "required"}, {name: "email"}],
			type: "text",
			name: "email",
			value: "",
		},
	],
	[
		{
			label: t('phoneScreenInputLabel'),
			type: "tel",
			name: t('phoneScreenInputLabel'),

		},
	],
	[
		{
			label: t('phoneScreenVerificationCode'),
			rules: [{name: "verification-code"}],
			type: "number",
			name: "code",
			value: "",
		},
	],
	[
		{
			label: language==='fr' ? 'Votre mot de passe' : 'YOUR PASSWORD',
			rules: [{name: "required"}],
			type: "text",
			name: "password",
			value: "",
		},
		{
			label: language==='fr' ? 'confirmez Votre mot de passe' : 'confirm your password',
			rules: [{name: "required"}],
			type: "text",
			name: "confirmPassword",
			value: "",
		},
	],
];

// eslint-disable-next-line max-lines-per-function,sonarjs/cognitive-complexity
const TitleText = (props: { step: number }) => {
	const [language,setLanguage]=useState<any>()
	useEffect(()=>{
		setLanguage(localStorage.getItem('SUPERNOVA_LANGUAGE'))
	},[])
	return (
		<div className="title-register">
			{props.step === 0 && (
				<div className="blc-top">
					<h1>
						{t('emailScreenTitleStepOne') + ' ' + t('emailScreenTitleStepTwo')}  <span>{t('emailScreenTitleStepThree')}</span>
					</h1>
				</div>
			)}
			{props.step === 1 && (
				<div className="blc-top">
					<h1 className="italic-txt">{t('personalInformationTitleWelcome')}</h1>
					<p>{t('personnelInformationDescription')}</p>
				</div>
			)}
			{props.step === 2 && (
				<div className="blc-top">
					<h1>
						{t('phoneScreenTitleStepOne')} <span>{t('phoneScreenTitleStepTwo')}</span> {t('phoneScreenTitleStepThree')} {language==='fr' && <span> TÉLÉPHONE</span>}
					</h1>
				</div>
			)}
			{props.step === 3 && (
				<div className="blc-top">
					<h1>
						{t('codeConfirmScreenStepOne')} <span>{t('codeConfirmScreenStepTwo')}</span> {t('codeConfirmScreenStepThree')} {t('withMail')}
					</h1>
				</div>
			)}
			{props.step === 4 && (
				<div className="blc-top">
					<h1>
						{t('createPasswordTitle')} <span>{t('passwordScreenStepTwo')}</span>
					</h1>
				</div>
			)}
		</div>
	);
};
/* eslint-disable max-lines-per-function */

// eslint-disable-next-line sonarjs/cognitive-complexity
const RegisterMobile = () => {

	const {t} = useTranslation()
	const error = t('NotSamePassword');

	const [selectPhoneOpen,setSelectPhoneOpen]= useState(false)
	const [phoneValid,setPhoneValid]=useState(false)

	useEffect(() => {
		getListCountry().then((res: any) => {
			setListCountry(res.data)
			setFilteredList(res.data)
		})
		setData({
			country: 74
		})
	}, [])
	const socialResponse=useSelector((state:RootState)=>state.authentication.socialResponse)
	// useEffect(()=>{
	// 	if (socialResponse){
	// 		setLoginType(socialResponse.data.social_account_method)
	// 		setIsFromSocial(socialResponse.data.is_used_social_account)
	// 	}
	// },[socialResponse])
	const [ListCountry, setListCountry] = useState<Array<any>>([])
	const [filteredList, setFilteredList] = useState<Array<any>>([...ListCountry]);

	// const[ListCountryFilter,setListCountryFilter]=useState<any>([ListCountry])

	const [isError, setIsError] = useState<boolean>(true);
	const [errorsPassword, setErrorsPassword] = useState<Error[]>([
		{
			id: 1,
			message: t('validationPasswordSize'),
			icon: check,
			className: "",
		},
		{
			id: 2,
			message: t('validationMajuscule'),
			icon: check,
			className: "",
		},
		{
			id: 3,
			message: t('validationSpecialChar'),
			icon: check,
			className: "",
		},
		{
			id: 4,
			message: t('validationChiffre'),
			icon: check,
			className: "",
		},
	]);
	const [isPoliticChecked,setPoliticChecked]=useState<any>(false)
	const [isNewsChecked,setNewsChecked]=useState<any>(false)
	const update = (id: number) => {

		const indexToUpdate = errorsPassword.findIndex((todo) => todo.id === id);
		const updatedTodos = [...errorsPassword]; // creates a copy of the array
		updatedTodos[indexToUpdate].className = "valid";
		updatedTodos[indexToUpdate].icon = checkValid;
		setErrorsPassword(updatedTodos);

	};
	const Reset = (id: number) => {
		const indexToUpdate = errorsPassword.findIndex((todo) => todo.id === id);
		const updatedTodos = [...errorsPassword]; // creates a copy of the array
		updatedTodos[indexToUpdate].className = "";
		updatedTodos[indexToUpdate].icon = check;
		setErrorsPassword(updatedTodos);
	};
	const [data, setData] = useState<any | null>({
		basket_id: localStorage.getItem("basket_id")
			? Number(localStorage.getItem("basket_id"))
			: null,
	});


	const [step, setStep] = useState<number>(0);


	const dispatch = useDispatch();
	// eslint-disable-next-line sonarjs/cognitive-complexity
	const onValueChange = (value: string, key: string) => {
		if (step === 4 && key === "password") {
			if (checkAtLeastLength(value, 8)) {
				update(1);
			} else {
				Reset(1);
			}

			if (checkUpper(value)) {
				update(2);
			} else {
				Reset(2);
			}

			if (checkSpecialCharacter(value)) {
				update(3);
			} else {
				Reset(3);
			}
			if (checkDigit(value)) {
				update(4);
			} else {
				Reset(4);
			}
		}

		step === 4 &&
		key === "confirmPassword" &&
		data?.password?.length > 0 &&
		setErrorMessagePassword(data?.password !== value ? error : "");
		step === 4 &&
		key === "password" &&
		data?.confirmPassword?.length > 0 &&
		setErrorMessagePassword(data?.confirmPassword !== value ? error : "");

		setData({...data, [key]: key === "code" ? Number(value) : value});
	};
	const [errorMessagePassword, setErrorMessagePassword] = useState<string>("");
	const [confirmEmptyMessage, setConfirmEmptyMessage] = useState<string>(" ");
	const [isFromSocial,setIsFromSocial]=useState<boolean>()
	const [openLoginModal,setIsOpenModal]=useState<boolean>()
	const [loginType,setLoginType]=useState<string>('')
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [showConfirmPassword, setShowConfirmPassword] =
		useState<boolean>(false);
	const [isSubmit, setIsSubmit] = useState<boolean>(false);
	const history = useNavigate();

	const onShowPassword = () => {
		setShowPassword(!showPassword);
	};
	const onShowConfirmPassword = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};


	// eslint-disable-next-line sonarjs/cognitive-complexity
	const checkCurrentInputValidity = () => {

		const errors = RegisterSteps[step]?.map((item) => {
			return item.rules
				? errorMessage(
					data && item.name && item?.name in data ? data[item?.name] : "",
					item.rules,
					t
				)
				: null;
		});
		return errors?.filter((item) => !!item).length === 0;

	};
	// eslint-disable-next-line sonarjs/cognitive-complexity
	const nextStep = () => {
		const isValid = checkCurrentInputValidity();
		if (isValid && step < RegisterSteps.length ) {
			setIsSubmit(false);
			switch (step) {
				case 0:
					verifyEmail();
					break;
				case 1:
					saveUserData();
					break;
				case 2:

					break;
				case 3:
					verifyCode();


					break;
				case 4:
					savePassword();

					break;

				default:
					break;
			}
		} else {
			setIsSubmit(true);
		}

	};
	// eslint-disable-next-line sonarjs/cognitive-complexity
	const verifyEmail = async () => {
		await verifyEmailAuth(data).then((res) => {
			if (res?.message.includes('Impossible de vous')){
				setIsFromSocial(res.data.social_account_method)
				setLoginType(res.data.social_account_method)
				setIsOpenModal(true)
			}
			if (res.data.email && !res.data.step){
				toast.error(res.message)
			}else{
				if (res.message?.includes("reste")) {
					setData({
						...data,
						firstName: res.data.firstName,
						lastName: res.data.lastName,
					});
					setStep(1);
					setStepNow(1)
				} else if (res.message?.includes("complété")) {
					toast.success(res.message);
					history(PATHS.LOGIN_MAIL, {state: {email: data.email}});
				} else {
					setStep(step + 1);
					setStepNow(step + 1)

				}
			}
		})
	};
	const cart=useSelector((state:RootState)=>state.cart.basket)

	// eslint-disable-next-line sonarjs/cognitive-complexity
	const saveUserData = async () => {
	if (isPoliticChecked && phoneValid){
			publicNewsLetter({active: isNewsChecked, email: data?.email}).then(async () => {
				await saveDataAuth({
					email: data.email,
					firstName: data.firstName,
					lastName: data.lastName,
					country: data.country,
					phone: data?.phone,
				}).then((res) => {
					setStep(3);
					setStepNow(3)

				});
			})
	}else{
		if (isPoliticChecked && !phoneValid){
			toast.error(t('invalidPhoneNumber'))
		}
	}


	};
	const verifyCode = async () => {
		await verifyPhoneAuth(data).then((res) => {
			setStep(step + 1);
			setStepNow(step + 1)
		});
	};

	const savePassword = async () => {
		data.password !== data.confirmPassword
			? setErrorMessagePassword(error)
			: isError
				? toast.error(t('pleaseRespectTheFormat'))
				: await savePasswordAuth({...data,basket_id:cart.id}).then(() => {
					history(PATHS.ACCOUNT);
				});
	};

	useEffect(() => {
		const result = errorsPassword.some((i) => {
			return i.icon === check;
		});
		!result ? setIsError(false) : setIsError(true);
	}, [errorsPassword]);
	const [openModal, setOpenModal] = useState(false)

	const filterList = (value: string) => {
		setFilteredList(ListCountry?.filter((item: any) => item?.name?.trim()?.toLowerCase()?.includes(value?.trim()?.toLowerCase())))
	}
	const selectCountry = (item: any) => {
		dispatch(setCountrySelected(item))
		setData({
			...data,
			country: item.id
		});
		setOpenModal(false)
		setFilteredList(ListCountry)
	}
	const country = useSelector((state: RootState) => state.authentication.countrySelected)
	const checkValidty=(value:any)=>{
		if (!value){
			setConfirmEmptyMessage(t('invalidPhoneNumber'))

		}
		if (value && isValidPhoneNumber(value)  && isPossiblePhoneNumber(value) ){
			setPhoneValid(true)
			setData({...data, phone: value})
			setConfirmEmptyMessage('')

		}else{
			setPhoneValid(false)
			setConfirmEmptyMessage(t('invalidPhoneNumber'))
		}
	}
	const backSteps=()=>{
		setErrorMessagePassword('')
		if((step -1) === 2) {
			setStep(step-2)

		}else{
			setStep(step-1)

		}
	}
	const connectSocialAccount = (data:any) => {
		socialAccountLogin(data).then(() => {
			// eslint-disable-next-line sonarjs/no-duplicate-string
			!isMobile() ? history("/account/profile") : history("/account")  ;
		});
	};
	const responseFacebook = (response:any) => {
		console.warn('response facebook', response);
		if (response?.email){
			const fullName=response?.name;
			let details=[]
			details=fullName?.split(' ');
			let firstName = '';
			let lastName = '';
			if (details?.length > 1) {
				firstName = details[0];
				lastName = details[1];
			}
			const facebookData = {
				firstName,
				lastName,
				email: response?.email,
				provider_id: response?.accessToken,
				provider_name: "Facebook",
				basket_id: localStorage.getItem('basket_id') ? Number(localStorage.getItem('basket_id')) : null
			}
			connectSocialAccount(facebookData);
		}
	};

	const responseApple = (response:any) => {

		if (response?.authorization) {
			const appleData  = {
				firstName: response?.user?.name?.firstName,
				lastName: response?.user?.name?.lastName,
				email:data.email,
				provider_id: response?.authorization?.id_token,
				provider_name: "Apple",
				basket_id: localStorage.getItem('basket_id') ? Number(localStorage.getItem('basket_id')) : null
			}
			connectSocialAccount(appleData);
		}
	}
	const responseGoogle = (response:any) => {
		const googleData = {
			firstName: response?.profileObj?.givenName,
			lastName: response?.profileObj?.familyName,
			email: response?.profileObj?.email,
			provider_id: response?.accessToken,
			provider_name: "Google",
			basket_id: localStorage.getItem('basket_id') ? Number(localStorage.getItem('basket_id')) : null
		}
		connectSocialAccount(googleData);
	};

	return (
		<div className="registerSteps">
			{
				openLoginModal&&isFromSocial && loginType!=='' &&
				<div className={'loginModal'} >
					<button className="close" style={{backgroundSize:'22px',width:'30px',height:'30px',right:'0',top:'-40px',left:'unset'}} onClick={() =>
					{
						setIsOpenModal(false)
						setLoginType('')
						setIsFromSocial(false)
					}
					}
					/>

					<div className={'titleModalLogin'}>
						<span className={'titleText'}>
							{t('mailAlreadyInUse')}
						</span>
					</div>
					<div className={'bodyModalLogin'}>
						<span className={'bodyText'}> {t('otherLoginMethod')}</span>

					</div>
					<div className={'Smedia'}>
						{
							loginType==='Google' ?
								<GoogleLogin
									clientId={`${process.env.REACT_APP_API_GOOGLE_AUTHENTIFICATION}`}
									render={(renderProps) => (
										<button onClick={renderProps.onClick} className="gmail">
											<span>{t('registrationScreenWithGoogle') || STRINGS.LOGIN.LOGIN_GOOGLE}</span>
										</button>
									)}
									onSuccess={responseGoogle}
									onFailure={(res) => res?.details && alertMessage(res.details)}
									cookiePolicy={"single_host_origin"}
								/>
								: loginType=== 'Apple' ?
									<AppleSignin
										authOptions={{
											clientId: "com.supernova.service",
											scope: "email name",
											redirectURI: "https://suppernova.com/login",
											state: "",
											nonce: "nonce",
											usePopup: true,
										}}
										uiType="dark"
										className="apple-auth-btn"
										noDefaultStyle={false}
										onSuccess={responseApple}
										onError={(error:any) => console.error(error)}
										skipScript={false}
										iconProp={{ style: { marginRight: "10px" } }}
										render={(props:any) => (
											<button onClick={props.onClick} className="apple">
												<span>{t('registrationScreenWithApple').toUpperCase() || STRINGS.LOGIN.LOGIN_APPLE.toUpperCase()}</span>
											</button>
										)}
									/> :
									<FacebookLogin
										appId={APP_ID_FACEBOOK_AUTHENTIFICATION}
										callback={responseFacebook}
										onFailure={(res:any) => res?.status && alertMessage(res.status)}
										fields="name,picture,email"
										render={(renderProps:any) => (
											<button onClick={renderProps.onClick} className="fb">
												<span>{t('registrationScreenWithFacebook') || STRINGS.LOGIN.LOGIN_FACEBOOK}</span>
											</button>
										)}
										disableMobileRedirect={true}
										autoLoad={false}
									/>
						}
					</div>

				</div>
			}

			{(!openModal && step === 0) ?
				<button onClick={() => history(-1)} style={{position: 'absolute', top: '-80px'}}
						className={'back-btn'}/>
				:
				<button onClick={backSteps} style={{position: 'relative', top: '-90px'}}
						className={'back-btn'}/>
			}
			{openModal ?
				<><>
					<button onClick={() => setOpenModal(false)}
							style={{position: 'relative', zIndex: '999999', top: '-136px'}}
							className={'back-btn'}/>
				</>
					<div className={'selectCountryContainer'}>
						<span
							className={'title'}> {t('profileScreenCountry') || STRINGS.REGISTER.SELECT_COUNTRY} </span>
						<Input
							item={{
								label: t('profileScreenCountry') || STRINGS.REGISTER.INPUTS.COUNTRY,
								name: "country",
								type: "text",
								onChange: (e) => filterList(e.target.value),
								rules: [{name: "required"}, {name: "country"}],
								value: data.COUNTRY,
							}}/>
						<>


							<div className={'listCountry'}>

								{filteredList?.map((item: any) => (
									// eslint-disable-next-line react/jsx-key
									<div
										key={item.id}
										style={{alignItems: 'center', textAlign: 'left'}}
										onClick={() => selectCountry(item)}
									>

										<img key={item.id} className={'flag'}
											 src={item.flag}
											 alt={''}/> &emsp; <span id={item.id} className={'itemOption'}>
                                            {item.name.toUpperCase()}
                                        </span>
										<hr/>

									</div>


								))}
							</div>
						</>
					</div>
				</>
				:
				<div style={{textAlign: 'left'}}>
					<TitleText step={step}/>
					{RegisterSteps[step] &&
					// eslint-disable-next-line sonarjs/cognitive-complexity
					RegisterSteps[step]?.map((input) => {
						return (
							<>
								<Input

									item={{
										...input,
										type:
											(input.name === "password" && !showPassword) ||
											(input.name === "confirmPassword" && !showConfirmPassword)
												? "password"
												: input.type,

									 	onChange: (e) => onValueChange(e.target.value, input.name),
										value:
											step === 1 && input.name === "email"
												? data.email
												: data[input.name],
									}}
									isSubmit={isSubmit}
									eyes={
										input.name === "password" || input.name === "confirmPassword"
									}
									onShow={
										input.name === "password"
											? onShowPassword
											: onShowConfirmPassword
									}
									isShow={
										input.name === "password" ? showPassword : showConfirmPassword
									}
									isDisabled={
										step === 1 && input.name === "email" && data.email?.length > 0
									}
									key={input.name}
								/>
								{step === 4 && input.name === "password" && isError && (
									<div className="card-content center-vertically">
										{errorsPassword?.map((error, i) => {
											if (error.icon === check) {
												return (
													<div
														key={i}
														className="center-horizontally align-center"
													>
														<ImageContainer alt="check" src={error.icon}/>
														<p className={error.className}>{error.message}</p>
													</div>
												);
											} else {
												null;
											}
										})}
									</div>
								)}
							</>
						);
					})}
					{step === 1 &&
                    <>
						<label htmlFor={'phone'} className={'label'}
							   style={{
								   textAlign: 'left',
								   color: '#c4c4c4',
								   fontSize: '12px'
							   }}>{t('phoneScreenInputLabel')}</label>
						<PhoneInput
							name={'phone'}
							style={{position:'relative'}}
							international={true}
							withCountryCallingCode={true}
							useNationalFormatForDefaultCountryValue={true}
							defaultCountry={'FR'}
							placeholder={t('phoneScreenInputLabel')}
							onChange={(e:any)=>checkValidty(e)}/>
                           <div style={{height:'20px'}}/>
						<label htmlFor={'country'} className={'label'}
                             style={{
								 textAlign: 'left',
								 color: '#c4c4c4',
								 fontSize: '12px'
							 }}>{t('profileScreenCountry')}</label>

                        <Select
                            onClick={() => setOpenModal(true)}
                            id={'country'}
                            className={'selectCountry'}
                            onChange={(e: any) => {
								setData({...data, country: e});
							}}
							/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
							//@ts-ignore
                            value={
								(
									country ?
										<div>
											{/*<option> </option>*/}
											<img key={9999} className={'flag'} style={{
												width: '22px',
												height: '19px',
												position: 'relative',
												bottom: '2px'
											}}
												 src={country?.flag}
												 alt={''}/> &emsp; <span className={'mobileOption'} style={{
											fontFamily: 'Futura med,serif',
											position: 'relative',
											right: '12px',
											fontSize: '12px',


										}}>{country?.name}</span>
										</div> :
										<div>
											{/*<option> </option>*/}
											<ImageContainer className={'flag'} style={{
												width: '22px',
												height: '19px',
												position: 'relative',
												bottom: '2px'
											}}
												 src={'https://cdn.kcak11.com/CountryFlags/countries/fr.svg'}
												 alt={''}/> &emsp; <span className={'mobileOption'} style={{
											fontFamily: 'Futura med,serif',
											position: 'relative',
											right: '12px',
											fontSize: '12px',
										}}>{t('france')}</span>
										</div>)

							}
							/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */

                            notFoundContent={false}
                        >
                        </Select>
						<div style={{height:'50px'}}/>
						<div className={'cguSignUp'}>
							<div className={'cguContainer'}>
								<div className={'cguDetails'}>
									<span>{t('agreeSignUpStep1')}</span>&nbsp;
									<a style={{textDecoration:'underline'}}  rel="noopener noreferrer" target="_blank" href={PATHS.SECONDARY.POLITIQUE}> {t('privecyAndPolicyloginStep2').toUpperCase()}</a>&nbsp;
									<span> {t('agreeSignUpStep2')}</span>
									<a style={{textDecoration:'underline'}}  rel="noopener noreferrer" target="_blank" href={PATHS.SECONDARY.POLITIQUE}> {t('contactScreenCheckboxText2').toUpperCase()}</a>&nbsp;

									<span> {t('agreeSignUpStep3')}</span>&nbsp;
								</div>
								<div className={'cguSwitch'}>
									<Switch onChange={()=>{
										setPoliticChecked(!isPoliticChecked)
									}}
											checked={isPoliticChecked}
									/>

								</div>
							</div>
							<div className={'cguContainer'}>
								<div className={'cguDetails'}>
									<span> {t('agreeSignUpStep4')}</span>
								</div>
								<div className={'cguSwitch'}>
									<Switch onChange={()=>{
										setNewsChecked(!isNewsChecked)

									}}
											checked={isNewsChecked}
									/>
								</div>
							</div>

						</div>

					</>
					}
					<div style={{height: '40px'}}>
					</div>
					{errorMessagePassword.length > 0 && (
						<div style={{backgroundColor:'unset'}}  className="card-content center-vertically">
							<p className="error">{errorMessagePassword}</p>
						</div>
					)}
					{step === 2 && confirmEmptyMessage.length > 0 && (
						<div className="error-container center-vertically">
							<p style={{position:'relative',bottom:'50px', color:'red',fontSize:'12px'}} className="confirm-error">{confirmEmptyMessage}</p>
						</div>
					)}


					<div className="btn-container">
						<button disabled={step===2 && confirmEmptyMessage.length>0 } style={{backgroundColor:(step===1 && confirmEmptyMessage.length > 0 && !isPoliticChecked )? 'gray':''}} onClick={nextStep} className="btn-primary">
							{step === 2 ? t('phoneScreenButtonText'): t('continue')}
						</button>
					</div>

				</div>
			}


		</div>
	);
};
/* eslint-enable max-lines-per-function */

const LeftSide: React.FC = () => {
	const {t} = useTranslation()
	return (
		<div className="left-register">
			<h1 className="title-auth">{t('personalInformationTitleWelcome') || STRINGS.REGISTER.TITLE}</h1>
			<p className="sub-title">{t('personnelInformationDescription') || STRINGS.REGISTER.SUB_TITLE}</p>
			<div className="img-register">
				<img src={img} alt="icon"/>
			</div>
		</div>
	);
};
/* eslint-disable max-lines-per-function*/
// eslint-disable-next-line sonarjs/cognitive-complexity
const RegisterDesktop = () => {
	const country = useSelector((state: RootState) => state.authentication.countryId)
	useEffect(() => {
		setData({
			country: 74
		})
	}, [])

	const [isError, setIsError] = useState<boolean>(true);
	const dispatch = useDispatch();
	const history = useNavigate();
	const {t} = useTranslation()
	const error = t('NotSamePassword');

	const [step, setStep] = useState<number>(1);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [isFromSocial,setIsFromSocial]=useState<boolean>()
	const [openLoginModal,setIsOpenModal]=useState<boolean>()
	const [loginType,setLoginType]=useState<string>('')
	const [errorPassword, setErrorPassword] = useState<Error[]>([
		{
			id: 1,
			message: t('validationPasswordSize'),
			icon: check,
			className: "",
		},
		{
			id: 2,
			message: t('validationMajuscule'),
			icon: check,
			className: "",
		},
		{
			id: 3,
			message: t('validationSpecialChar'),
			icon: check,
			className: "",
		},
		{
			id: 4,
			message: t('validationChiffre'),
			icon: check,
			className: "",
		},
	]);
	const [data, setData] = useState<SignUp>({
		email: "",
		firstName: "",
		lastName: "",
		phone: "",
		password: "",
		code: 0,
		country: 0,
		basket_id: localStorage.getItem("basket_id")
			? Number(localStorage.getItem("basket_id"))
			: undefined,
	});
	const [subTitle, setSubTitle] = useState<string>('');

	const [confirmPassword, setConfirmPassword] = useState<string>("");
	const [confirmErrorMessage, setConfirmErrorMessage] = useState<string>("");
	const [isVerifyCode, setIsVerifyCode] = useState<boolean>(false);
	const [isSubmit, setIsSubmit] = useState<boolean>(false);
	const onShowPassword = (isPassword: boolean) => {
		isPassword
			? setShowPassword(!showPassword)
			: setShowConfirmPassword(!showConfirmPassword);
	};

	const updateItem = (id: number) => {
		const indexToUpdate = errorPassword.findIndex((todo) => todo.id === id);
		const updatedTodos = [...errorPassword]; // creates a copy of the array

		updatedTodos[indexToUpdate].className = "valid";
		updatedTodos[indexToUpdate].icon = checkValid;
		setErrorPassword(updatedTodos);
	};
	const ResetItem = (id: number) => {
		const indexToUpdate = errorPassword.findIndex((todo) => todo.id === id);
		const updatedTodos = [...errorPassword]; // creates a copy of the array

		updatedTodos[indexToUpdate].className = "";
		updatedTodos[indexToUpdate].icon = check;
		setErrorPassword(updatedTodos);
	};
	const socialResponse=useSelector((state:RootState)=>state.authentication.socialResponse)
// eslint-disable-next-line sonarjs/no-identical-functions
// useEffect(()=>{
// 	if (socialResponse){
// 		setLoginType(socialResponse.data.social_account_method)
// 		setIsFromSocial(socialResponse.data.is_used_social_account)
// 	}
// },[socialResponse])
	// eslint-disable-next-line sonarjs/cognitive-complexity
	const onChangePassword = (e: { target: { value: string } }) => {
		setData({...data, password: e.target.value});

		const value = e.target.value;

		if (checkAtLeastLength(value, 8)) {
			updateItem(1);
		} else {
			ResetItem(1);
		}

		if (checkUpper(value)) {
			updateItem(2);
		} else {
			ResetItem(2);
		}

		if (checkSpecialCharacter(value)) {
			updateItem(3);
		} else {
			ResetItem(3);
		}
		if (checkDigit(value)) {
			updateItem(4);
		} else {
			ResetItem(4);
		}
		if (confirmPassword !== value && confirmPassword.length > 0) {
			setConfirmErrorMessage(error);
		} else {
			setConfirmErrorMessage("");
		}
	};
	// eslint-disable-next-line sonarjs/cognitive-complexity
	const onChangeConfirmPassword = (e: { target: { value: string } }) => {
		setConfirmPassword(e.target.value);
		const value = e.target.value;
		data.password !== value
			? setConfirmErrorMessage(error)
			: setConfirmErrorMessage("");
	};
	useEffect(() => {
		const result = errorPassword.some((i) => {
			return i.icon === check;
		});
		!result ? setIsError(false) : setIsError(true);
	}, [errorPassword]);

	const nextStep = () => {
		switch (step) {
			case 1:
				verifyEmail();
				break;
			case 2:
				saveUserData();
				break;
			case 3:
				verifyCode();
				break;
			case 4:
				savePassword();
				break;
			default:
				break;
		}
	};

	// eslint-disable-next-line sonarjs/no-identical-functions
	// eslint-disable-next-line sonarjs/cognitive-complexity
	const verifyEmail =  () => {

		if (
			!errorMessage(data.email || "", [{name: "required"}, {name: "email"}], t)
		) {
			 verifyEmailAuth(data).then((res) => {
				 if (res?.message.includes('Impossible de vous')){
					 setIsFromSocial(res.data.social_account_method)
					 setLoginType(res.data.social_account_method)
					 setIsOpenModal(true)
				 }
			if (res.data.email && !res.data.step){
				toast.error(res.message)
			}else{
				if (res.message?.includes("reste")) {
					setIsSubmit(false);
					setStep(2);
					setData({
						...data,
						firstName: res.data.firstName,
						lastName: res.data.lastName,
					});
				} else if (res.message?.includes("complété")) {
					toast.success(res.message);
					history(PATHS.LOGIN_MAIL, {state: {email: data.email}});
				} else {
					setIsSubmit(false);
					setStep(step + 1);
				}
			}
				// eslint-disable-next-line sonarjs/no-identical-functions
			})
		} else {
			setIsSubmit(true);
		}
	};
	const cart=useSelector((state:RootState)=>state.cart.basket)

	// eslint-disable-next-line sonarjs/cognitive-complexity
	const saveUserData = async () => {

		const isValid =
			!errorMessage(data.firstName || "", [{name: "required"}], t) &&
			!errorMessage(data.lastName || "", [{name: "required"}], t)
		if (!phoneValid && isPoliticChecked) {
			toast.error(t('invalidPhoneNumber'))
		} else {
		if (isValid && isPoliticChecked) {
				publicNewsLetter({active:isNewsChecked,email: data?.email}).then(async () => {
					await saveDataAuth({
						email: data.email,
						firstName: data.firstName,
						lastName: data.lastName,
						country: data.country,
						phone: data?.phone,
						basket_id:cart?.id
					}).then((res) => {
						setIsSubmit(false);
						setIsVerifyCode(true);
						setStep(step + 1);
					});
				})
		} else {
			setIsSubmit(true);
		}
		}
	};
	// eslint-disable-next-line sonarjs/no-identical-functions
	const verifyCode = async () => {
		if (data.code && data.code.toString().length === 4) {
			await verifyPhoneAuth(data).then((res) => {
				setIsSubmit(false);
				setStep(step + 1);
			});
		} else {
			setIsSubmit(true);
		}
	};
	// eslint-disable-next-line sonarjs/cognitive-complexity
	const savePassword = async () => {
		if (
			!errorMessage(data.password || "", [{name: "required"}], t) &&
			!errorMessage(confirmPassword || "", [{name: "required"}], t) &&
			data.password === confirmPassword
		) {
			isError
				? toast.error(t('pleaseRespectTheFormat').toUpperCase)
				: await savePasswordAuth({...data,basket_id:cart.id}).then((res) => {
					history("/account/profile");
				});
		} else {
			setIsSubmit(true);
		}
	};
	useEffect(() => {
		switch (step) {
			case 1: {
				setSubTitle(t('enterYourEmail'));
				break;
			}
			case 2: {
				setSubTitle(t('enterYourNameLastNameETC'));
				break;
			}
			case 3: {
				setSubTitle(t('insertCodeInMail'));
				break;
			}
			case 4: {
				setSubTitle(t('createYourPassword'));
				break;
			}
			default: {
				setSubTitle("");
				break
			}
		}
	}, [step]);
	const [ListCountry, setListCountry] = useState<Array<any>>()
	const [filteredList, setFilterList] = useState<Array<any>>()
	const [filteredList2, setFilterList2] = useState<Array<any>>()

	// eslint-disable-next-line sonarjs/no-identical-functions
	const countryInit='France'
	useEffect(() => {
		getListCountry().then((res: any) => {
			setListCountry(res.data)
			setFilterList(res?.data?.filter((item: any) => item?.name?.trim()?.toLowerCase()?.includes(countryInit?.trim()?.toLowerCase() || '')))
			setFilterList2(res?.data)
		})
	}, [])
	useEffect(() => {
		setFilterList(filteredList)
	}, [filteredList])
	const Debounce = debounce((el: any) => filterList(el));
	const filterList = (value: string) => {
		setClicked(300)
		setOpen(true)
		// setSelect(false)
		setFilterList(ListCountry?.filter((item: any) => item?.name?.trim()?.toLowerCase()?.includes(value?.trim()?.toLowerCase() || '')))
		setFilterList2(ListCountry?.filter((item: any) => item?.name?.trim()?.toLowerCase()?.includes(value?.trim()?.toLowerCase() || '')))

	}
	const [open, setOpen] = useState(false)

	const showListCountry = (e: any) => {
		// setSelect(false)
		setOpen(true)
	}
	const [clicked,setClicked]=useState(73)
   const [countryItem,setCountryItem]=useState<any>()
	const selectCountry = (item: any,index:any) => {
		item && setSelect(false)
		setClicked(index)
		setCountryItem(item)
		setData({...data,country:item.id})
		store.dispatch(newCountryId(item))
		setOpen(false)


	}
	const clickRef = useRef(null)
	const testClickOutside = (ref: any) => {
		const handleClickOutside = (event: any) => {
			if (ref.current && !ref.current.contains(event.target)) {
				setOpen(false)
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}
	useEffect(() => {
		testClickOutside(clickRef)
	}, [clickRef])
	const [value, setValue] = useState()
	useEffect(()=>{
		dispatch(newCountryId(''))
	},[])
     const [phoneValid,setPhoneValid]=useState(false)
	// eslint-disable-next-line sonarjs/no-identical-functions
	const checkValidty=(value:any)=>{
		if (value && isValidPhoneNumber(value) && isPossiblePhoneNumber(value) ) {
			setPhoneValid(true)
			setData({...data, phone: value})
			setSelect(true)
		}else{
			setPhoneValid(false)
		}
	}

	const [phone,setPhone]=useState<any>()
	const [isSelect,setSelect]=useState(false)
	const [isFromPhone,setIsFromPhone]=useState<any>('FR')
	const [isPoliticChecked,setPoliticChecked]=useState<any>(false)
	const [isNewsChecked,setNewsChecked]=useState<any>(false)
	const setCountryFromPhone=(value:any)=>{
		setPhone(ListCountry?.filter((item: any) => item?.isoCode?.trim()?.toLowerCase()?.includes(value?.trim()?.toLowerCase() || '')))
		setFilterList(ListCountry?.filter((item: any) => item?.isoCode?.trim()?.toLowerCase()?.includes(value?.trim()?.toLowerCase() || '')))
		setSelect(true)
		setIsFromPhone(value)
	}
	useEffect(()=>{
		if (isSelect){
			dispatch(newCountryId(phone))
			setData({...data,country:phone?.[0]?.id})
			setClicked(phone?.[0]?.id - 1)
		}
	},[phone])
	const initIso='FR'
	// eslint-disable-next-line sonarjs/cognitive-complexity
    useEffect(()=>{
		isSelect ?  setFilterList(ListCountry?.filter((item: any) => item?.isoCode?.trim()?.toLowerCase()?.includes(isFromPhone?.trim()?.toLowerCase() || '')))
			:   !country ?
			setFilterList(ListCountry?.filter((item: any) => item?.isoCode?.trim()?.toLowerCase()?.includes(initIso?.trim()?.toLowerCase() || '')))
		:
			setFilterList(ListCountry?.filter((item: any) => item?.isoCode?.trim()?.toLowerCase()?.includes(country?.isoCode?.trim()?.toLowerCase() || '')))



	},[open])
	// eslint-disable-next-line sonarjs/no-identical-functions
	const connectSocialAccount = (data:any) => {
		socialAccountLogin(data).then(() => {
			!isMobile() ? history("/account/profile") : history("/account")  ;
		});
	};
	// eslint-disable-next-line sonarjs/no-identical-functions
	const responseApple = (response:any) => {

		if (response?.authorization) {
			const appleData  = {
				firstName: response?.user?.name?.firstName,
				lastName: response?.user?.name?.lastName,
				email:data.email,
				provider_id: response?.authorization?.id_token,
				provider_name: "Apple",
				basket_id: localStorage.getItem('basket_id') ? Number(localStorage.getItem('basket_id')) : null
			}
			connectSocialAccount(appleData);
		}
	}
	// eslint-disable-next-line sonarjs/no-identical-functions
	const responseGoogle = (response:any) => {
		const googleData = {
			firstName: response?.profileObj?.givenName,
			lastName: response?.profileObj?.familyName,
			email: response?.profileObj?.email,
			provider_id: response?.accessToken,
			provider_name: "Google",
			basket_id: localStorage.getItem('basket_id') ? Number(localStorage.getItem('basket_id')) : null
		}
		connectSocialAccount(googleData);
	};
	// eslint-disable-next-line sonarjs/no-identical-functions
	const responseFacebook = (response:any) => {
		console.warn('response facebook', response);
		if (response?.email){
			const fullName=response?.name;
			let details=[]
			details=fullName?.split(' ');
			let firstName = '';
			let lastName = '';
			if (details?.length > 1) {
				firstName = details[0];
				lastName = details[1];
			}
			const facebookData = {
				firstName,
				lastName,
				email: response?.email,
				provider_id: response?.accessToken,
				provider_name: "Facebook",
				basket_id: localStorage.getItem('basket_id') ? Number(localStorage.getItem('basket_id')) : null
			}
			connectSocialAccount(facebookData);
		}
	};

	return (
		<div className="right-register" style={{position:'relative',top:'40px'}}>
			<div className="center-horizontally space-between">
				<div className="center-vertically input-container">
					<div  className="step-title" >
						<h1 className="title-register">{t('step')} {step}</h1>
						<h1 className="sub-title-register">{subTitle}</h1>
					</div>
					{
						step === 1 && (
							<Input
								isHover={step === 1}
								item={{
									label: t('emailScreenPlaceholderText') || STRINGS.REGISTER.INPUTS.EMAIL,
									name: "email",
									type: "email",
									onChange: (e) => setData({...data, email: e.target.value}),
									rules: [{name: "required"}, {name: "email"}],
									value: data.email,
									// readonly:isFromSocial ? true : false
								}}
								isSubmit={step === 1 && isSubmit}
								isDisabled={step !== 1}
							/>
						)
					}
					{
						step === 2 && (
							<>
								<Input
									isHover={step === 2}
									item={{
										label: t('personnelInformationFirstNameInput') || STRINGS.REGISTER.INPUTS.FIRST_NAME,
										name: "firstName",
										type: "text",
										onChange: (e) => setData({...data, firstName: e.target.value}),
										rules: [{name: "required"}],
										value: data.firstName,
									}}
									isSubmit={step === 2 && isSubmit}
									isDisabled={step !== 2}
								/>
								<Input
									isHover={step === 2}
									item={{
										label: t('personnelInformationLastNameInput') || STRINGS.REGISTER.INPUTS.LAST_NAME,
										name: "lastName",
										type: "text",
										onChange: (e) => setData({...data, lastName: e.target.value}),
										rules: [{name: "required"}],
										value: data.lastName,
									}}
									isSubmit={step === 2 && isSubmit}
									isDisabled={step !== 2}
								/>
								<label htmlFor={'phone'} className={'label'}> {t('phoneScreenInputLabel').toUpperCase()}</label>
								<PhoneInput
									onCountryChange={(e:any)=> setCountryFromPhone(e)	}
									style={{position:'relative',bottom:'10px'}}
									international={true}
									placeholder={t('phoneScreenInputLabel')}
									// rules={{ required: true ,message:'azeazeaze', validate:isValidPhoneNumber }}
									withCountryCallingCode={true}
									useNationalFormatForDefaultCountryValue={true}
									defaultCountry={'FR'}
								    onChange={(e:any)=>{
										checkValidty(e)}}
									error={value ? (isValidPhoneNumber(value) ? undefined : 'Invalid phone number') : 'Phone number required'}/>
								<div style={{height:'10px'}}/>
								<Input
									isHover={true}
									item={{
										label: t('profileScreenCountry'),
										name: "country",
										rules: [{name: "required"}, {name: "email"}],
										type: "text",
										onChange: (e) => filterList(e.target.value),
										// rules: [{name: "required"}, {name: "country"}],
										value: isSelect ? phone?.[0]?.name : (data.country === 74 ? 'France' : country?.name) ,
										onClick: (e) => showListCountry(e),
										isOnEdit:false
									}}
								/>


								{open &&
									<Modal  className={'modalCountry'} visible={open}  onCancel={()=>setOpen(false)} footer={false} >
                                        <Input
                                            isHover={true}
                                            item={{

												label: t('profileScreenCountry'),
												name: "countryy",
												type: "text",
												onChange: (e) => filterList(e.target.value),
												rules: [{name: "required"}, {name: "country"}],
												value: isSelect  ? phone?.[0]?.name : (data.country === 74 ? 'France' : country?.name) ,
												onClick: (e) => showListCountry(e),
											}}
                                        />
                                        <div  className={'listCountryDesktopRegister'}
                                             style={{height: '250px', overflowY: 'scroll', width: '100%',zIndex:9999999999999}}>
											{filteredList?.map((item: any,index:any) => (
												<div key={index}>

												<div key={item.index} className={`${clicked===index}`}  onClick={() => selectCountry(item,index)}
													 style={{
														 cursor: 'pointer',
														 alignItems: 'center',
														 textAlign: 'left',
														 display:'flex',
													 }}>

													<img key={item.id} style={{width: '36px'}} className={'flag'}
														 src={item.flag}
														 alt={''}/> &emsp; <span style={{fontSize:'16px'}}
													className={'itemOptionUpdateProfile'}>{item.name.toUpperCase()}</span>

												</div>
                                                <div style={{height:10}}/>
													</div>
											))}
                                        </div>
									</Modal>
                                }
								<div className={'cguSignUp'}>
									<div className={'cguContainer'}>
										<div className={'cguDetails'}>
											<span>{t('agreeSignUpStep1')}</span>&nbsp;
											<a style={{textDecoration:'underline'}}  rel="noopener noreferrer" target="_blank" href={PATHS.SECONDARY.POLITIQUE}> {t('privecyAndPolicyloginStep2').toUpperCase()}</a>&nbsp;
											<span> {t('agreeSignUpStep2')}</span>
											<a style={{textDecoration:'underline'}}  rel="noopener noreferrer" target="_blank" href={PATHS.SECONDARY.POLITIQUE}> {t('contactScreenCheckboxText2').toUpperCase()}</a>&nbsp;

											<span> {t('agreeSignUpStep3')}</span>&nbsp;
										</div>
										<div className={'cguSwitch'}>
											<Switch onChange={()=>{
												setPoliticChecked(!isPoliticChecked)
											}}
													checked={isPoliticChecked}
											/>

										</div>
									</div>
									<div className={'cguContainer'}>
										<div className={'cguDetails'}>
											<span> {t('agreeSignUpStep4')}</span>
										</div>
										<div className={'cguSwitch'}>
											<Switch onChange={()=>{
												setNewsChecked(!isNewsChecked)
											}}
													checked={isNewsChecked}
													 />
										</div>
									</div>

								</div>

							</>

						)
					}
					{step === 3 && (
						<Input
							isHover={step === 3}
							item={{
								label: t('phoneScreenVerificationCode') || STRINGS.REGISTER.INPUTS.VERIFICATION_CODE,
								name: "code",
								type: "number",
								onChange: (e) =>
									setData({...data, code: Number(e.target.value)}),
								rules: [
									{name: "required"},
									{name: "number"},
									{name: "length", max: 4},
									{name: "minLength", min: 4},
								],
								value: data.code ? data.code : "",
							}}
							isSubmit={step === 3 && isSubmit}
							isDisabled={step !== 3}
						/>
					)}
					{
						step === 4 && (
							<>
								<Input
									isHover={step === 4}
									eyes={true}
									onShow={() => onShowPassword(true)}
									isShow={showPassword}
									item={{
										label: t('passwordScreenInputPassword') || STRINGS.REGISTER.INPUTS.PASSWORD,
										name: "password",
										type: !showPassword ? "password" : "text",
										onChange: onChangePassword,
									}}
									isSubmit={step === 4 && isSubmit}
									isDisabled={step !== 4}
								/>


								<Input
									isHover={step === 4}
									eyes={true}
									onShow={() => onShowPassword(false)}
									isShow={showConfirmPassword}
									item={{
										label: t('passwordScreenInputConfirmPassword') || STRINGS.REGISTER.INPUTS.CONFIRM_PASSWORD,
										name: "confirmPassword",
										type: !showConfirmPassword ? "password" : "text",
										onChange: onChangeConfirmPassword,
									}}
									isSubmit={step === 4 && isSubmit}
									isDisabled={step !== 4}
								/>





								{isError && (
									<div className="error-container center-vertically">
										{/* eslint-disable-next-line sonarjs/no-identical-functions */}
										{errorPassword?.map((error, i) => {
											if (error.icon === check) {
												return (
													<div key={i} className="center-horizontally align-center">
														<ImageContainer alt="check" src={error?.icon}/>
														<p className={error?.className}>{error?.message}</p>
													</div>
												);
											} else {
												null;
											}
										})}
									</div>
								)}
								{confirmErrorMessage.length > 0 && (
									<div className="error-container center-vertically">
										<p className="confirm-error">{confirmErrorMessage}</p>
									</div>
								)}
							</>
						)
					}
				</div>
			</div>
			<div className="center-horizontally space-between register-button">
				<div className="btn-container">
					<button
						onClick={nextStep}
						className="btn-primary auth-button"
						style={{minWidth: "250px",backgroundColor : step===2 && !isPoliticChecked ? 'gray' : ''}}
					>
						{step !== 4 && t('validateAndContinue')}
						{step === 4 && t('createMyAccount')}
					</button>
					{

						openLoginModal&&isFromSocial && loginType!=='' &&

						<div className={'loginModal'} >
							<div className={'titleModalLogin'}>
								<button className="close" style={{backgroundSize:'22px',width:'30px',height:'30px',right:'0',top:'-20',left:'unset'}} onClick={() => setIsOpenModal(false)} />

								<span className={'titleText'}>
							{t('mailAlreadyInUse')}
						</span>
							</div>
							<div className={'bodyModalLogin'}>
								<span className={'bodyText'}> {t('otherLoginMethod')}</span>

							</div>
							<div className={'Smedia'}>
								{
									loginType==='Google' ?
										<GoogleLogin
											clientId={`${process.env.REACT_APP_API_GOOGLE_AUTHENTIFICATION}`}
											render={(renderProps) => (
												<button onClick={renderProps.onClick} className="gmail">
													<span>{t('registrationScreenWithGoogle') || STRINGS.LOGIN.LOGIN_GOOGLE}</span>
												</button>
											)}
											onSuccess={responseGoogle}
											onFailure={(res) => res?.details && alertMessage(res.details)}
											cookiePolicy={"single_host_origin"}
										/>
										: loginType=== 'Apple' ?
											<AppleSignin
												authOptions={{
													clientId: "com.supernova.service",
													scope: "email name",
													redirectURI: "https://suppernova.com/login",
													state: "",
													nonce: "nonce",
													usePopup: true,
												}}
												uiType="dark"
												className="apple-auth-btn"
												noDefaultStyle={false}
												onSuccess={responseApple}
												onError={(error:any) => console.error(error)}
												skipScript={false}
												iconProp={{ style: { marginRight: "10px" } }}
												render={(props:any) => (
													<button onClick={props.onClick} className="apple">
														<span>{t('registrationScreenWithApple').toUpperCase() || STRINGS.LOGIN.LOGIN_APPLE.toUpperCase()}</span>
													</button>
												)}
											/> :
											<FacebookLogin
												appId={APP_ID_FACEBOOK_AUTHENTIFICATION}
												callback={responseFacebook}
												onFailure={(res:any) => res?.status && alertMessage(res.status)}
												fields="name,picture,email"
												render={(renderProps:any) => (
													<button onClick={renderProps.onClick} className="fb">
														<span>{t('registrationScreenWithFacebook') || STRINGS.LOGIN.LOGIN_FACEBOOK}</span>
													</button>
												)}
												disableMobileRedirect={true}
												autoLoad={false}
											/>
								}
							</div>

						</div>
					}
				</div>
			</div>
		</div>
	);
};

/* eslint-enable max-lines-per-function */

/* eslint-disable max-lines-per-function */

const Register = () => {
	const [scrollY, setScrollY] = useState<number>(0);

	useLayoutEffect(() => {
		const handleScroll = () => {
			setScrollY(Math.min(0, window.scrollY / 3 - 60) - 10);
		}
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		}
	}, [scrollY]);
	const [screenSize, getDimension] = useState({
		dynamicWidth: window.innerWidth,
		dynamicHeight: window.innerHeight
	});
	const setDimension = () => {
		getDimension({
			dynamicWidth: window.innerWidth,
			dynamicHeight: window.innerHeight
		})
	}

	useEffect(() => {
		window.addEventListener('resize', setDimension);

		return(() => {
			window.removeEventListener('resize', setDimension);
		})
	}, [screenSize])
	return (
		<div className="auth">
			<Header classname={ scrollY === 0 || scrollY < -50 ? '' : 'home-header'} isHome={true} isHomeActive={true}/>
			<div className="auth-content">
				<div className="container-desk register-content center-horizontally mobile-height">
					{!isMobile() ?  (
						<>
							<LeftSide/>
							<RegisterDesktop/>
						</>
					) : (
						<>
							<RegisterMobile/>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

/* eslint-enable max-lines-per-function */

export default Register;
