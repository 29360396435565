import { createSlice } from "@reduxjs/toolkit";
export interface CommonState {
    loading:boolean
}

export const commonInitialState: CommonState = {
    loading:false
};

export const commonSlice = createSlice({
    name: 'common',
    initialState: commonInitialState,
    reducers: {
    },
});

