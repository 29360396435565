import React, {useEffect, useLayoutEffect, useState} from "react";
import "./NewChef.scss";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import sun from "../../assets/icons/orange-sun.svg";
import ChefCard from "../../components/chef-card/ChefCard";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../shared/store";
import {getCategory} from "../../shared/store/slices/Chefs/ChefsSlice";
import {getCategorySeason, getSubCategoryListResto} from "../../shared/store/slices/Chefs/ChefsService";
import {useLocation, useNavigate, useParams} from "react-router";
import EmptyItem from "../../components/empty-item/EmptyItem";
import emptyChef from "../../assets/images/chef_empty.png";
import {STRINGS} from "../../core/enums/strings";
import {useTranslation} from "react-i18next";
import {isMobile} from "../../shared/utils/utils-functions";

/* eslint-disable max-lines-per-function */
// eslint-disable-next-line sonarjs/cognitive-complexity
const NewChef = () => {
  const state = useLocation();
  const params = useParams()
  const category = useSelector((state: RootState) => state.chefs.category);
  const dispatch = useDispatch();
  const [isLoadApi,setIsLoadApi]=useState<any>(false)
  useEffect(() => {
    getData();
    return () => {
      dispatch(
        getCategory({
          description: "",
          id: 0,
          image: [],
          name: "",
          restaurants: [],
          is_best_category: false,
        })
      );
    };
  }, []);
  const nav = useNavigate()
  useEffect(() => {
    if (params.slug !== 'subcategory' && params.slug !== 'category') {
      nav(-1)
    }
  }, [params.slug])

  const getData = async () => {
    if (params.slug !== 'subcategory') {
      await getCategorySeason(Number(params.id)).then(()=>{
        setIsLoadApi(true)

      })
    } else {
      await getSubCategoryListResto(parseInt(params.id as string)).then(()=>{
        setIsLoadApi(true)

      })
    }
  };
  const {t} = useTranslation()
  const width=useSelector((state:RootState)=>state.root.DEVICE_WIDTH)
  const [scrollY, setScrollY] = useState<number>(0);

  useLayoutEffect(() => {
    const handleScroll = () => {
      setScrollY(Math.min(0, window.scrollY / 3 - 60) - 10);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  }, [scrollY]);
  return (

    <>
      {category && (
        <>
          <div className="newChef-page">
            <Header classname={'home-header'} isHome={true} isHomeActive={true}/>
            <div className="section-top">
              <img
                src={
                  (category?.image &&
                  category.image.find((item) => item.type === "16:9")?.url) || ''
                }
                alt=""
                className={ (category?.image &&
                    category.image.find((item) => item.type === "16:9")?.is_grey) ? 'gray' : ''}
                // style={{filter: 'grayscale(1)'}}
              />
              <div className="blc-absolute center-vertically space-between">
                <div className="relative-blc">
                  <img src={sun} alt="sun"/>
                </div>
              </div>
            </div>
            <div className="top-page">
              <div className="container-desk center-horizontally space-between align-center">
                <div className="center-horizontally space-between align-center">
                  <h1>{category?.name}</h1>
                </div>
                <p style={{height:'113px',overflow:'auto',wordBreak:'break-all'}}>{category?.description}</p>
              </div>
            </div>
            <div
              // style={{
              //     gridTemplateColumns: width>768 ?  category?.restaurants?.length === 0 ? '1fr' : 'repeat(2, 1fr)' : '1fr'
              // }}
              className="list-chef center-horizontally"
            >

              {category?.restaurants?.map((chef: any) => {
                return (
                  <ChefCard
                    key={chef.id}
                    isChef={true}
                    item={chef.chef_restaurant}
                    image={
                      chef.image_chef.find((item: any) => item.type === "4:3")
                        ?.url as string
                    }
                    active_option={'image'}
                    idCat={chef.chef_restaurant.id}
                    restaurant={{name: chef.name, id: chef.id}}
                    itemResto={{id: chef.id, description: chef.description}}
                    isGray={
                      chef.image_chef.find((item: any) => item.type === "4:3")
                        ?.is_grey
                    }
                  />
                );
              })}
              {(isLoadApi && (category?.restaurants?.length === 0 )|| (category?.restaurants === null ))&& (
                <EmptyItem img={emptyChef}>
                  <p  className="empty-content">{t('NoRestaurantFound') || STRINGS.ALL_CHEF.EMPTY_ITEM}</p>
                </EmptyItem>
              )}
            </div>
            {isMobile() &&           <div style={{height:'100px'}}/>  }

            <Footer quantity={0} priceOrder={0} isHome={true}/>
          </div>
        </>
      )}
    </>
  );
};
/* eslint-enable max-lines-per-function */
export default NewChef;
