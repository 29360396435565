import React, {useCallback, useEffect, useRef, useState} from 'react';
import {STRINGS} from '../../core/enums/strings';
import Newslettres from '../../pages/my-account/newsletters/Newslettres';
import Input from '../input/Input';
import "./NewsletterBottomsheet.scss";
import {errorMessage, isMobile} from "../../shared/utils/utils-functions";
import {publicNewsLetter} from "../../shared/store/slices/Account/AccountService";
import {useTranslation} from "react-i18next";
import {getMyProfile} from "../../shared/store/slices/Auth/AuthService";
import {useSelector} from "react-redux";
import {RootState} from "../../shared/store";

interface Props {
    onClick?: () => void;
    onClose?: () => void;
    isVisible: boolean;
}

function useOnClickOutside(ref: any, handler: { (): void; (arg0: { target: any; }): void; }) {
    useEffect(() => {
        const listener = (event: { target: any; }) => {
            if (!ref.current || ref.current?.contains(event.target)) {
                return;
            }

            handler(event);
        };

        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);

        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [ref, handler]);
}

/* eslint-disable max-lines-per-function */
// eslint-disable-next-line sonarjs/cognitive-complexity
const NewsletterBottomsheet = (Props: Props): JSX.Element => {
    const [isStepTwo, setIsStepTwo] = useState<boolean>(false);
    const divRef = useRef();
    const [isCheckedNewslettres, setIsCheckedNewslettres] = useState<boolean>();
    const [errorMessageNewsletter, setErrorMessageNewsletter] = useState<string | null>(null);
    const [isSubmit, setIsSubmit] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [defaultMail,setDefaultMail]=useState<string>('')
    const [isFollow, setIsFollow] = useState<boolean>(true);
    const isLoggedIn=useSelector((state:RootState)=>state.authentication.isLoggedIn)
    const me = useSelector((state:RootState)=>state.authentication.profile)

    const handler = useCallback(() => {
        if (!isMobile()) {
            close();
        }
    }, [Props.isVisible]);
    useOnClickOutside(divRef, handler);
    const validateEmail = (value: string) => {
        setEmail(value);
        setDefaultMail(value)
        const errorMsg = errorMessage(value, [{name: "email"}, {name: "required"}], t);
        setErrorMessageNewsletter(errorMsg);
    }
    // eslint-disable-next-line sonarjs/cognitive-complexity
    const validateNewsletter = (isFollow: boolean) => {
        if (isCheckedNewslettres && !errorMessageNewsletter ) {
            setIsFollow(isFollow);
            publicNewsLetter({email:email.length > 0 ? email : me?.email, active: isFollow}).then(() => {
                setEmail('');
                setDefaultMail('')
                setErrorMessageNewsletter(null);
                setIsCheckedNewslettres(!isCheckedNewslettres)
                setIsStepTwo(true);
                setEmail("");
                setDefaultMail('')
                setIsSubmit(false);
            });
        } else if (email.length === 0) {
            setIsSubmit(true);
        }
    }

    const close = () => {
        if (!isMobile()){
            setIsStepTwo(false);

        }
        setEmail("");
        setErrorMessageNewsletter(null);
        setDefaultMail('')
        setIsSubmit(false);
      
        Props.onClose && Props.onClose();
    }
    useEffect(()=>{
        // eslint-disable-next-line sonarjs/no-collapsible-if
          if (me?.email){
              setIsCheckedNewslettres(me?.news_letter_block)
              setEmail(me.email)
              setErrorMessageNewsletter(null);
              setIsSubmit(false);
      }

    },[me])

    const {t} = useTranslation()
    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <div className="card-bottomsheet newsletter-sheet" ref={divRef}>
            <div className="content-card">
                <div className="top-blc center-horizontally align-center space-between">
                    <h1>{!isStepTwo ? (t('newsletter') || STRINGS.ACCOUNT.NEWSLETTERS.SUB_TITLE2) : t('thanks') || STRINGS.THANKS} </h1>
                    {
                        !isStepTwo && !isMobile() && <button className="close-button-sheet" onClick={close}/>
                    }
                </div>
                <p className={` txt-descript ${isStepTwo && "txt-thanking"}`}>{!isStepTwo ? (t('modalNewsletterScreenHeader') || STRINGS.ACCOUNT.NEWSLETTERS.TXT2) :
                    (isFollow ? (t('modalNewsletterScreenSuccess') || STRINGS.ACCOUNT.NEWSLETTERS.TXT_THANKING_SUBSCRIBE) : (t('message_desabonner') || STRINGS.ACCOUNT.NEWSLETTERS.TXT_THANKING_UNSUBSCRIBE) )}</p>
                {
                    !isStepTwo && <div className="card-newsletter">
                        <div className="blc-input">
                            <Input
                                item={{
                                    label: t('emailScreenPlaceholderText') ||  STRINGS.REGISTER.INPUTS.EMAIL,
                                    name: "email",
                                    type: "email",
                                    onChange: (e) => validateEmail(e.target.value),
                                    rules: [{name: "required"}, {name: "email"}] ,
                                    value: isLoggedIn ? me?.email : email,
                                }}
                                isSubmit={isSubmit}
                            />
                        </div>
                        <Newslettres isHome={true} isChecked={setIsCheckedNewslettres}/>
                    </div>}
                <div className="bottom-blc">
                    {
                        !isStepTwo ?
                            <div className="center-horizontally space-between align-center">
                                <span className={!(isCheckedNewslettres ) || !email ? 'disabled-span' : ''} onClick={() => validateNewsletter(false)}>{t('modalNewsletterScreenUnsubscribe') || STRINGS.HOME.BTN.UNFOLLOW}</span>
                                {/* eslint-disable-next-line sonarjs/no-collection-size-mischeck */}
                                <button className={(!isCheckedNewslettres) || !email ? 'disabled' : ''}  onClick={() => validateNewsletter(true)}>{t('accept') || STRINGS.HOME.BTN.ACCEPT}</button>
                            </div>
                            :
                            <button className={window.innerWidth > 768 ? "close-btn" : 'btn_close_newsLettreMobile'} onClick={close}>{t('close') || STRINGS.HOME.BTN.CLOSE}</button>
                    }

                </div>
            </div>
        </div>
    );
};
/* eslint-enable max-lines-per-function */
export default NewsletterBottomsheet;

