import React, {useEffect, useLayoutEffect, useState} from "react";
import {isMobile} from "../../shared/utils/utils-functions";
import Header from "../header/Header";
import HeaderAccount from "../header-account/HeaderAccount";
import {t} from "i18next";
import {useLocation} from "react-router";
import {useSelector} from "react-redux";
import {RootState} from "../../shared/store";
import {getListAbout} from "../../shared/store/slices/Account/AccountService";
import {getTerms} from "../../shared/store/slices/Auth/AuthService";
import {Link} from "react-router-dom";
import logoBlack from "../../assets/icons/Logo-black.svg";

// eslint-disable-next-line max-lines-per-function
const PolitqueSecondary=()=>{
	const terms = useSelector((state: RootState) => state.authentication.terms)
	useEffect(() => {
		getTerms().then()
	}, [])

	const [scrollY, setScrollY] = useState(0);

	useLayoutEffect(() => {
		const handleScroll = () => {
			setScrollY(Math.min(0, window.scrollY / 3 - 60) - 10);
		}
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		}
	}, [scrollY])
	const [text,setText]=useState<any>("")
	// eslint-disable-next-line sonarjs/cognitive-complexity
	return(
		<div className={'about-page'} style={{height:'unset',overflow:"unset",padding:'20px',maxWidth:'unset'}} >
			{!isMobile() &&
				<div className="blc-center">
					<Link to="" className="logo">
						<img src={logoBlack} alt="logo"/>
					</Link>
				</div>
			}
			<div style={{height:'40px'}}/>
			<HeaderAccount title={t('newsLettreTitle')} />


			{
				terms?.map((item: any) => (
					<div style={{height:'100%'}} className={'regleContainer'} key={item?.id}>
                            <span dangerouslySetInnerHTML={{__html: item?.text}} className={'regleText'}>
                        </span>
					</div>
				))
			}


		</div>
	)
}
export default PolitqueSecondary
