import React, {useEffect, useRef, useState} from "react";
import notFoundImage from '../../assets/images/LinearGradient.png';
import {motion} from "framer-motion";

const ImageContainer:React.FC<{
  src: string,
  className?: string,
  alt?: string,
  draggable?: boolean,
  style?: React.CSSProperties,
  onClick?: () => void,
  transition?: any,
  animate?: any,
  initial?: any,
// eslint-disable-next-line max-lines-per-function,sonarjs/cognitive-complexity
}> = ({src, className, alt, draggable, style,onClick, transition, animate, initial}) => {
  const [validSrc, setValidSrc] = useState(src)
  const imageRef = useRef<HTMLImageElement>(null)
  useEffect(() => {
    if(imageRef.current && !!src) {
      setValidSrc(src)
      if(imageRef.current.complete && [imageRef.current.naturalHeight, imageRef.current.naturalWidth].includes(0)){
        setValidSrc(notFoundImage);
      } else if (!imageRef.current.complete) {
        imageRef.current.onerror = () => {
          setValidSrc(notFoundImage);
        }
      }
    }
  }, [src])

  return (
      !!src ? (
          (transition && animate && initial) ? (
              <motion.img
                  id={Math.floor(Math.random() * 100000).toString()}
                  ref={imageRef}
                  src={validSrc}
                  style={style}
                  className={className}
                  alt={alt}
                  draggable={draggable}
                  onClick={onClick}
                  initial={initial}
                  animate={animate}
                  transition={transition}
              />
          ) : (
              <img
                  id={Math.floor(Math.random() * 100000).toString()}
                  ref={imageRef}
                  src={validSrc}
                  style={style}
                  className={className}
                  alt={alt}
                  draggable={draggable}
                  onClick={onClick}
              />
          )
      ) : (
          <img
              id={Math.floor(Math.random() * 100000).toString()}
              src={notFoundImage}
              style={style}
              className={className}
              alt={alt}
              draggable={draggable}
              onClick={onClick}
          />
      )
  )
}

export default ImageContainer;
