import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/es/storage';
import {rootSlice} from "./slices/Root/rootSlice";
import {commonSlice} from "./slices/Common/commonSlice";
import {authenticationSlice} from './slices/Auth/AuthSlice';
import {chefSlice} from './slices/Chefs/ChefsSlice';
import {cartSlice} from './slices/Cart/CartSlice';
import {accountSlice} from './slices/Account/AccountSlice';
import {paymentSlice} from './slices/Payment/PaymentSlice';
import {exploreSlice} from './slices/explore/exploreSlice';

const rootReducer = combineReducers({
  root: rootSlice.reducer,
  common: commonSlice.reducer,
  authentication: authenticationSlice.reducer,
  chefs: chefSlice.reducer,
  cart: cartSlice.reducer,
  account: accountSlice.reducer,
  payment: paymentSlice.reducer,
  explore: exploreSlice.reducer
});

export type RootState = ReturnType<typeof rootReducer>;
const persistConfig = {
  key: 'root',
  storage
};
const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

