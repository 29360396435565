import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Address } from "../../../../core/models/Address";
import { OrderRecap } from "../../../../core/models/Order";
import { Preparation, PreparationItem } from "../../../../core/models/PreparationItem";
import Adresses from "../../../../core/mocks/Adresses";
import { ClaimType, ProductClaim } from "../../../../core/models/Claim";
import { About } from "../../../../core/models/About";
export interface Position {
    lat: number,
    lng: number

}
export interface AccountState {
    orderList: OrderRecap[],
    Order?: OrderRecap,
    availableDays:any
    Preparation:Preparation,
    addresses:Address[],
    totalOrders?:number,
    productsForClaim?: ProductClaim;
    claimTypes: ClaimType[];
    about:About[],
    type?:number,
    autoComplete?: Position | undefined,
    position?: Position | undefined,
    isOnEditAddress?:boolean

}



export const accountInitialState: AccountState = {
    orderList: [],
    Order: undefined,
    availableDays:[],
    isOnEditAddress:false,
    Preparation:{
        list_menu:[],
        list_plate:[]
    },
    addresses:Adresses,
    totalOrders:undefined,
    productsForClaim: undefined,
    claimTypes: [],
    about:[],
    type:undefined,
    autoComplete: undefined,
    position: undefined

};

export const accountSlice = createSlice({
    name: 'account',
    initialState: accountInitialState,
    reducers: {
        getOrders: (state, action: PayloadAction<OrderRecap[]>): void => {
            state.orderList = action.payload;
        },
        getOrder: (state, action: PayloadAction<OrderRecap>): void => {
            state.Order = action.payload;
        },
        getPreparationList: (state, action: PayloadAction<Preparation>): void => {
            state.Preparation = action.payload;
        },
        getAddresses: (state, action: PayloadAction<Address[]>): void => {
            state.addresses = action.payload;
        },
        setAddresses: (state, action: PayloadAction<Address[]>): void => {
            state.addresses = action.payload;
        },
        addAddress: (state, action: PayloadAction<Address>): void => {
            state.addresses=state.addresses.concat(action.payload)
        },
        removeAddress: (state, action: PayloadAction<number>): void => {
            state.addresses.splice(state.addresses.findIndex(item => item.id === action.payload),1);
        },
        setTotalOrders: (state, action: PayloadAction<number>): void => {
            state.totalOrders = action.payload;
        },
        setProductsForClaim: (state, action: PayloadAction<ProductClaim>): void => {
            console.warn('setProductsForClaim', action.payload)
            state.productsForClaim = action.payload;
        },
        setListClaimType: (state, action: PayloadAction<ClaimType[]>): void => {
            state.claimTypes = action.payload;
        },
        getAbout: (state, action: PayloadAction<About[]>): void => {
            state.about = action.payload;
        },
        setTypeId: (state, action: PayloadAction<number | undefined>): void => {
            state.type = action.payload;
        },
        setAutoComplete: (state, action: PayloadAction<Position | undefined>): void => {
            state.autoComplete = action.payload;
        },
        setAvailableDaysState: (state, action: PayloadAction<any | undefined>): void => {
            state.availableDays = action.payload;
        },
        setPositionState: (state, action: PayloadAction<Position | undefined>): void => {
            state.position = action.payload;
        },
        setIsOnEditAddress: (state, action: PayloadAction<boolean>): void => {
            state.isOnEditAddress = action.payload;
        },
    },
});
export const {setIsOnEditAddress,setPositionState,setTypeId,getAbout, setTotalOrders,getOrders, getOrder ,getPreparationList,getAddresses,setAddresses,
    setProductsForClaim, setListClaimType, setAutoComplete,setAvailableDaysState} = accountSlice.actions;
