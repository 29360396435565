import { store } from "../..";
import { BaseUrl, APIS } from "../../../../core/enums/APIS";
import { PATHS } from "../../../../core/enums/paths";
import { Profile } from "../../../../core/models/Profile";
import { LoginSocialAccount } from "../../../../core/models/User";
import { Login } from "../../../../pages/auth/LoginMail";
import { SignUp } from "../../../../pages/auth/Register";
import { Executor } from "../../Executor";
import {
  disconnect,
  getProfile,
  setErrorMessage, setListCountry,
  setLoggedInUser,
  setSignUpUser, setTerms,
} from "./AuthSlice";

const setToken = (data: { token: string; refreshToken: string }) => {
  localStorage.setItem("supernova_token", data.token);
  localStorage.setItem("supernova_refresh_token", data.refreshToken);
};

export const login = (data: Login) => {
  return Executor({
    method: "post",
    url: BaseUrl + APIS.AUTH.login,
    data,
    successFun: (data: any) => {

      if (!data.message.includes('Impossible de vous identifier avec ce compte')){
        saveUserData(data);
      }
    },
    withoutToast: true
  });
};

export const socialAccountLogin = (data: LoginSocialAccount) => {
  return Executor({
    method: "post",
    url: BaseUrl + APIS.AUTH.LOGIN_SOCIAL_ACCOUNT,
    data,
    successFun: (data: any) => {
      saveUserData(data);
    },
  });
};
export const getListCountry=()=>{
  return Executor({
    method:'get',
    url:BaseUrl+"/countries",
    successFun : (res:any) => {
      store.dispatch(setListCountry(res.data))
  }
  })
}

const saveUserData = (data: any) => {
  store.dispatch(setLoggedInUser(data.data));
  setToken({ token: data.data.token, refreshToken: data.data.refresh_token ? data.data.refresh_token : data.data.refreshToken });
};

export const refreshMyToken = (data: any) => {
  return Executor({
    method: "post",
    url: BaseUrl + "/token/refresh",
    data,
    successFun: (data: any) => {
      localStorage.setItem("supernova_token", data.data.token);
      localStorage.setItem("supernova_refresh_token", data.data.refresh_token ? data.data.refresh_token : data.data.refreshToken);
    },
  });
};

export const getMyProfile = (isSilent?:boolean) => {
  return Executor({
    method: "get",
    url: BaseUrl + APIS.PROFILE.getOrUpdateMyProfile,
    successFun: (data: any) => {
      store.dispatch(getProfile(data.data));
    },
    isSilent:isSilent
  });
};
export const editPassword = (data: {
  password: string;
  new_password: string;
}) => {
  return Executor({
    method: "put",
    data,
    url: BaseUrl + APIS.PROFILE.updatePassword,
    successFun: (data: any) => {
      store.dispatch(setLoggedInUser(data.data));
      setToken({
        token: data.data.token,
        refreshToken: data.data.refresh_token,
      });
    },
  });
};

export const editIdentity = (data: { firstName: any; lastName: any }) => {
  return Executor({
    method: "put",
    data,
    url: BaseUrl + APIS.PROFILE.updateIdentity,
    successFun: (data: any) => {
      store.dispatch(getProfile(data.data));
    },
  });
};

export const editCountry=(data:any)=>{
  return Executor({
    method: "put",
    data,
    url: BaseUrl + APIS.PROFILE.getOrUpdateMyProfile,
    successFun: (data: any) => {
      store.dispatch(getProfile(data.data));
    },
  });
}


export const editPhoneStepOne = (data: { phone: string }) => {
  return Executor({
    method: "post",
    data,
    url: BaseUrl + APIS.PROFILE.updatePhoneStepOne,
    isSilent:true
  });
};
export const editPhoneStepTwo = (data: { phone: string;}) => {
  return Executor({
    method: "put",
    data,
    url: BaseUrl + APIS.PROFILE.updatePhoneStepTwo,
    successFun: (data: any) => {
      store.dispatch(getProfile(data.data));
    },
  });
};

export const logout = (isLoginPage = false) => {
  store.dispatch(disconnect());
  localStorage.removeItem("supernova_token");
  localStorage.removeItem("supernova_refresh_token");
  localStorage.removeItem("persist:root");
  !isLoginPage && window.location.replace(PATHS.LOGIN);
};

export const verifyEmailAuth = (data: SignUp) => {
  return Executor({
    method: "post",
    url: BaseUrl + APIS.AUTH.STEP_ONE_REGISTER,
    data,
    errorFun: (data: any) => {
      store.dispatch(setErrorMessage(data)
      )
    }
  });
};
export const getTerms = () => {
  return Executor({
    method: "get",
    url: BaseUrl + APIS.AUTH.TERMS,
    successFun: (res: any) => { store.dispatch(setTerms(res.data)) }
  });
};

export const saveDataAuth = (data: SignUp) => {
  return Executor({
    method: "post",
    url: BaseUrl + APIS.AUTH.STEP_TWO_REGISTER,
    data,
    isSilent:true
  });
};

export const savePasswordAuth = (data: SignUp) => {
  return Executor({
    method: "post",
    url: BaseUrl + APIS.AUTH.STEP_FOUR_REGISTER,
    data,
    successFun: (data: any) => {
      saveUserData(data);
    },
  });
};

export const verifyPhoneAuth = (data: SignUp) => {
  return Executor({
    method: "post",
    url: BaseUrl + APIS.AUTH.STEP_THREE_REGISTER,
    data,
  });
};

export const sendMail = (data: {email:string}) => {
  return Executor({
    method: "post",
    url: BaseUrl + APIS.FORGETPASSWORD.sendMail,
    data,
  });
};

export const verifyCode = (data: {email:string,code:number}) => {
  return Executor({
    method: "post",
    url: BaseUrl + APIS.FORGETPASSWORD.verifyCode,
    data,
  });
};

export const resetPassword = (data: {email:string,token:string,password:string}) => {
  return Executor({
    method: "post",
    url: BaseUrl + APIS.FORGETPASSWORD.resetPassword,
    data,
  });
};
