import React, {useEffect, useRef, useState} from 'react';
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import './CalendarWidget.scss'
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {RootState, store} from "../../shared/store";
import {convertDateToValidFormat} from "../../shared/utils/utils-functions";
import {STRINGS} from '../../core/enums/strings';
import {WeekDays} from "../../core/models/Chef";
import {useTranslation} from "react-i18next";
import {Available} from "../../shared/store/slices/Account/AccountService";
import {
	setListHolidayFromOrder,
	setObjectOfCalendarDate,
	setServiceType
} from "../../shared/store/slices/Cart/CartSlice";

interface Props {
	value?: string;
	show?: boolean;
	onChange: (value: string) => void;
	isAvailableWeekDays: boolean | undefined;
	daysAvailable: WeekDays | undefined;
	idResto?: any
	isOrder?: any
	defaultValue?:any
	params?:any
	adress?:any
}

/* eslint-disable max-lines-per-function */
// eslint-disable-next-line sonarjs/cognitive-complexity
const CalendarWidget = (props: Props): JSX.Element => {
	const [language,setLanguage]=useState<any>()
	useEffect(()=>{
		setLanguage(localStorage.getItem('SUPERNOVA_LANGUAGE'))
	},[])

	const [view, setView] = useState<string>('month');
	const [year, setYear] = useState<string>(new Date().getFullYear().toString());
	const nextSixMonthsDate = moment(new Date()).add(6, 'M').endOf('month').toDate();
	const listHolidaaRecap = useSelector((state:RootState)=>state.cart.listHoliday)
	const [listHolidays, setListHolidays] = useState<any[]>([listHolidaaRecap]);

useEffect(()=>{
	setListHolidays(listHolidaaRecap)
},[listHolidaaRecap])
	const getDates = () => {
		const paramDate = new Date();
		if (paramDate.getMonth() === 12) {
			return new Date(paramDate.getFullYear() + 1, 1, 1);
		} else {
			return new Date(paramDate.getFullYear(), paramDate.getMonth() + 1, 1);
		}
	};
	const order = useSelector((state: RootState) => state.payment.orderRecap);

	const [selectedDate, setSelectedDate] = useState<string>("");
	// eslint-disable-next-line sonarjs/cognitive-complexity
	useEffect(() => {
		Available(props?.idResto,{is_from_basket_details_screen:true}).then((res: any) => {
			if (res.data.first_day_available){
				setListHolidays(res?.data?.list_days_by_status.filter((item: any) => !item?.is_available_ups || !item?.is_available_pickup || !item?.service))
				// dispatch(setObjectOfCalendarDate(res?.data?.list_days_by_status))
				dispatch(setListHolidayFromOrder(res?.data?.list_days_by_status.filter((item: any) => !item?.is_available_ups || !item?.is_available_pickup || !item?.service)))
				dispatch(setObjectOfCalendarDate(res?.data?.list_days_by_status))
				// dispatch(setServiceType(res?.data?.infos_first_day_available?.service?.name ? res?.data?.infos_first_day_available?.service?.name : 'liv'))
			}else{

				Available(props?.idResto, {
					month: new Date().getMonth() + 2,
					year: new Date().getMonth()+1 === 12 ?  new Date().getFullYear()+1 : new Date().getFullYear() ,
					is_from_basket_details_screen: true
				}).then((res: any) => {
					if (res.data.first_day_available){
						setListHolidays(res?.data?.list_days_by_status.filter((item: any) => !item?.is_available_ups || !item?.is_available_pickup || !item?.service))
						dispatch(setListHolidayFromOrder(res?.data?.list_days_by_status.filter((item: any) => !item?.is_available_ups || !item?.is_available_pickup || !item?.service)))
						dispatch(setObjectOfCalendarDate(res?.data?.list_days_by_status))
						// dispatch(setServiceType(res?.data?.infos_first_day_available?.service?.name ? res?.data?.infos_first_day_available?.service?.name : 'liv'))
					}
				})
			}

		})
	}, []);
	const convertDate = (value: Date) => {
		setSelectedDate(value.getDate().toString().padStart(2, "0") + '/' + (value.getMonth() + 1).toString().padStart(2, "0") + '/' + value.getFullYear());
	}
		const isDisabledDate = (date: Date) => {
			return view === 'month' && (listHolidaaRecap.some((item: any) =>
				((new Date(item?.delivery_date).getDate() === date?.getDate()) &&
					(new Date(item?.delivery_date).getMonth() + 1 === date?.getMonth() + 1) &&
					(new Date(item?.delivery_date).getFullYear() === date?.getFullYear()))
			));
		}
	const isWeekendDaysDisabled = (date: Date) => {
		return (date.getDay() === 0 || date.getDay() === 1) ;
	}
	const dispatch=useDispatch()
	const changeDate = (value: any) => {
		Available(props?.idResto, {
			month: value.activeStartDate.getMonth() + 1,
			year: value.activeStartDate.getFullYear(),
			is_from_basket_details_screen:true
			// eslint-disable-next-line sonarjs/no-identical-functions
		}).then((res: any) => {
			console.log('mel calendar')
			console.log(res)
			setListHolidays(res?.data?.list_days_by_status.filter((item: any) => !item?.is_available_ups || !item?.is_available_pickup || !item?.service))
			dispatch(setListHolidayFromOrder(res?.data?.list_days_by_status.filter((item: any) => !item?.is_available_ups || !item?.is_available_pickup || !item?.service)))
			dispatch(setObjectOfCalendarDate(res?.data?.list_days_by_status))
			// dispatch(setServiceType(res?.data?.infos_first_day_available?.service?.name ? res?.data?.infos_first_day_available?.service?.name : 'liv'))

		})
		value.view === "month" && (value.activeStartDate.getFullYear().toString() !== year) && setYear(value.activeStartDate.getFullYear().toString())
	}
	const {t} = useTranslation()
	const newBasket = useSelector((state: RootState) => state.cart.basketDate)
	const cart = useSelector((state: RootState) => state.cart)
	const [dateFinal, setDateFinal] = useState(cart.basketDate?.deliver_at)
	useEffect(() => {
		setDateFinal(newBasket?.deliver_at)
	}, [newBasket?.deliver_at])
	return (
		<div className="calendar-blc">
			<h1 style={{textAlign:"center"}} className="title-calendar">{t('basketScreenDaysTitle') || STRINGS.TITLE_CALENDAR}</h1>

			{
				 props.isOrder  ?

					<Calendar
						formatShortWeekday={(locale, value) => value.toLocaleString(language === 'fr' ? 'fr-fr' : 'en-en', {weekday: 'narrow'})}
						tileDisabled={({date}) => isDisabledDate(date)}
						tileClassName={({date}) => isDisabledDate(date) ? 'disabledDate' : ''}
						minDate={new Date()}
						minDetail={"month"}
						onChange={convertDate}
						maxDate={nextSixMonthsDate}
						onClickYear={(value) => setYear(value.getFullYear().toString())}
						defaultValue={new Date(convertDateToValidFormat( newBasket?.deliver_at))}
						onViewChange={(value) => setView(value.view)}
						defaultView={'month'}
						locale={language}
						key={newBasket?.deliver_at}
						onActiveStartDateChange={(value) => changeDate(value)}
					/> :
					<Calendar
						formatShortWeekday={(locale, value) => value.toLocaleString(language === 'fr' ? 'fr-fr' : 'en-en', {weekday: 'narrow'})}
						tileDisabled={({date}) => isDisabledDate(date)}
						tileClassName={({date}) => isDisabledDate(date) ? 'disabledDate' : ''}
						minDate={new Date()}
						minDetail={"month"}
						onChange={convertDate}

						maxDate={nextSixMonthsDate}
						onClickYear={(value) => setYear(value.getFullYear().toString())}
						defaultValue={new Date(convertDateToValidFormat( cart?.basket.deliver_at))}
						onViewChange={(value) => setView(value.view)}
						defaultView={'month'}
						locale={language}
						key={cart?.basket.deliver_at}
						onActiveStartDateChange={(value) => changeDate(value)}
					/>
			}
			<button className="btn-primary btn-calendar"
					onClick={() => selectedDate?.length > 0 ? props.onChange(selectedDate) : props.onChange(cart.basket.deliver_at)}>
				{t('calendarValidateBtn') || STRINGS.BTN_CALENDAR}
			</button>
		</div>
	);
}

export default CalendarWidget;
