import Interceptor, { alertMessage } from "./interceptor";
import { store } from "./index";
import {setReCorrect, setRootLoading} from "./slices/Root/rootSlice";
import { AxiosResponse } from "axios";
import { ExecutorInterface } from "../../core/models/Executor";

// eslint-disable-next-line sonarjs/cognitive-complexity
export function Executor(config: ExecutorInterface): Promise<any> {
    !config.isSilent && store.dispatch(setRootLoading(true));
    // eslint-disable-next-line sonarjs/cognitive-complexity
    return new Promise((resolve, reject) => {
        Interceptor[config.method](config.url, config.data).then((res: AxiosResponse<any>) => {
            if (res?.data?.success) {
                config?.successFun && config.successFun(res?.data);
                store.dispatch(setReCorrect())
                resolve(res?.data);
            } else {
                config?.errorFun && config.errorFun(res?.data?.message);
                reject(res)
                res?.data?.message && res?.data?.message?.includes('Impossible de vous identifier avec ce compte') && !res?.data?.message.includes('step') && !res?.data?.message.includes("deliver") && !res?.data?.message.includes('panier') && !res?.data?.message.includes('basket')  &&   alertMessage(res?.data?.message, false)
            }

            !config.isSilent && store.dispatch(setRootLoading(false));
        }).catch((err: any) => {
            !config.isSilent && store.dispatch(setRootLoading(false));
            reject(err)
        })
    });
}
