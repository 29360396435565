import React from 'react';
import "../About.scss";

const Mail = () :JSX.Element => {
    return (
        <div className="about-page">
            
        </div>
    );
};

export default Mail;
