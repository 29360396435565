import React from "react";
import {useLocation} from "react-router-dom";
import BackBtn from "../../../../../../components/back-btn/BackBtn";
import { STRINGS } from "../../../../../../core/enums/strings";
import './SuccessRequestHelp.scss'
import {PATHS} from "../../../../../../core/enums/paths";
import { isMobile } from "../../../../../../shared/utils/utils-functions";
import {t} from "i18next";
const SuccessRequestHelp = () => {

    const location = useLocation();
    const pathTo = (location?.state?.fromContact ? (isMobile() ? "/account" : "/account/profile") : (
        !isMobile() ? PATHS.MY_ORDERS_WEB : PATHS.MY_ORDERS_MOBILE
    )) as string;
    return (
    <div className="help-success-content center-vertically">
      <div className="container-btn center-horizontally align-center">
        <BackBtn pathTo={pathTo} />
        <p>{t('return').toUpperCase()}</p>
      </div>
      <div className='txt-success'>
          <h1>
              {t('yourRequestContactPart1').toUpperCase()}
          </h1>
          <h1>{t('yourRequestContactPart3').toUpperCase()} <span>{t('yourRequestContactPart2').toUpperCase()}</span></h1>
          <p className="descript">
              {t('sorryRequest')}
          </p>

      </div>
    </div>
  );
};

export default SuccessRequestHelp;
