import React from 'react'
import './Card.scss'

interface Props{
    children:JSX.Element
}

const Card = (props:Props) :JSX.Element =>{
    return (
        <div className='card-container center-vertically'>
            {props.children}
        </div>
    )
}

export default Card
