import React, { useEffect, useState } from "react";
import { OrderRecap } from "../../core/models/Order";
import arrowRight from "../../assets/icons/arrow-right.svg";
import { STRINGS } from "../../core/enums/strings";
import { MoreOutlined } from "@ant-design/icons";
import {
  getDateFromPlusDays,
  getDateFromUnix,
  isMobile,
} from "../../shared/utils/utils-functions";

import "./OrderItemAccount.scss";
import { useNavigate } from "react-router-dom";

import casaron_icn from "../../assets/icons/casaron.svg";
import ImageContainer from "../imageContainer/imageContainer";
import {t} from "i18next";
import {useTranslation} from "react-i18next";
import moment from "moment";

interface Item {
  item: OrderRecap;
  onClick: () => void;
}
/* eslint-disable max-lines-per-function */
// eslint-disable-next-line sonarjs/cognitive-complexity
const OrderItemAccount = (props: Item): JSX.Element => {
  const history = useNavigate();
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    })
  }

  useEffect(() => {
    window.addEventListener('resize', setDimension);

    return(() => {
      window.removeEventListener('resize', setDimension);
    })
  }, [screenSize])
  const [language,setLanguage]=useState<any>()
  const {t}=useTranslation()
  useEffect(()=>{
    setLanguage(localStorage.getItem('SUPERNOVA_LANGUAGE'))
  },[language])
  const [statusClass, setStatusClass] = useState<string>("");
  const [status, setStatus] = useState<string>("");

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const onSetStatus = (status?: string) => {
    switch (status) {
      case "To_prepare":
        setStatus(t('toPrepare'));
        setStatusClass("to-prepare");
        break;
      case "Waiting":
        setStatus(t('waiting'));
        setStatusClass("waiting");
        break;
      case "Sended":
        setStatus(t('sent'));
        setStatusClass("sended");
        break;
      case "Finish":
        setStatus(t('finished'));
        setStatusClass("finish");
        break;
      case "Canceled":
        setStatus(t('statusCanceled'));
        setStatusClass("canceled");
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    onSetStatus(props.item?.status);
  }, [props.item?.status]);

  const isGray = props.item.restaurant?.chef_restaurant?.image_profile?.find(
    (item) => (screenSize.dynamicWidth <= 768 ? item.type === "3:4" : item.type === "4:3")
  )?.is_grey;

  return (
    <div className="blc-item-order cursor" key={props.item.id} onClick={props.onClick}>
      <div className="item-order center-horizontally ">
        <div
          className={`${props.item.status !== "delivered" && "border"} blc-img`}
        >
          <ImageContainer
            className={isGray ? "gray cursor" : "cursor"}
            src={props.item.restaurant?.chef_restaurant?.image_profile?.find(
              (item) => (screenSize.dynamicWidth <= 768 ? item.type === "3:4" : item.type === "4:3")
            )?.url as string}
            alt="food"
            style={{width:'100%',height:'100%'}}

          />
        </div>
        <div className="blc-infos center-horizontally space-between">
          <div className="infos center-vertically">
            <div className="order-header ">
              <div className="center-horizontally space-between">
                <div className="order-date-section center-horizontally align-center justify-center">
                  <p style={{position:'relative',top:'2px'}}>
                    {t('myOrderScreenItemDateOrder').toUpperCase()}
                    &nbsp;
                    {props.item.created_at &&
                      getDateFromUnix(props.item.created_at)}
                  </p>
                </div>

                <div className="blc-right-order-item center-horizontally align-center">
                  {screenSize.dynamicWidth > 768 && (
                    <div
                      className={`date-delivery center-horizontally align-center justify-center ${statusClass}`}
                    >
                      <img src={casaron_icn} alt="icn" />
                      <p style={{position:'relative',top:'2px'}}>{status}</p>
                    </div>
                  )}
                  <MoreOutlined
                    color="black"
                    className="icon"
                    onClick={() =>
                        screenSize.dynamicWidth > 768
                        ? history("/account/help", {
                            state: { order_id: props.item?.id },
                          })
                        : history("/help-suggestion", {
                            state: { order_id: props.item?.id },
                          })
                    }

                  />
                </div>
              </div>
              {screenSize.dynamicWidth <= 768 && (
                <div
                  className={`date-delivery center-horizontally align-center justify-center ${statusClass}`}
                >
                  <img src={casaron_icn} alt="icn" />
                  <p style={{position:'relative',top:'2px'}}>{status}</p>
                </div>
              )}
            </div>
            <h1 className="name">{props.item.restaurant?.name}</h1>
            <h2 className="type">
              {props.item.restaurant?.chef_restaurant?.firstName +
                " " +
                props.item.restaurant?.chef_restaurant?.lastName}
            </h2>
            <div className=" blc-action center-horizontally align-center">
              <div
                onClick={props.onClick}
                className={`${
                  props.item.status !== "delivered" && "white-bg"
                } delivery-date-section center-horizontally align-center`}
              >
                <p>
                  {t('myOrderScreenItemDateDelivered').toUpperCase()}
                  &nbsp;
                  {props?.item.deliver_at && moment(props.item.deliver_at.toString().substring(0,10).split('-').join('/')).format('DD/MM/YYYY')}
                </p>
              </div>
              <div
                onClick={props.onClick}
                className="action-button center-horizontally align-center"
              >
                <h3>
                  {t('detailsAndPreparation').toUpperCase()}
                </h3>
                <img src={arrowRight} alt="row-right" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
/* eslint-enable max-lines-per-function */
export default OrderItemAccount;
