import React, {useEffect, useState} from "react";
import "./AboutSidebar.scss";
import {NavLink} from "react-router-dom";
import Footer from "../footer/Footer";
import BackBtn from "../back-btn/BackBtn";
import {STRINGS} from "../../core/enums/strings";
import {TabAccount} from "../../core/models/TabsAccount";
import {RootState} from "../../shared/store";
import {getListAbout} from "../../shared/store/slices/Account/AccountService";
import {useSelector} from "react-redux";
import {isMobile} from "../../shared/utils/utils-functions";
import {useTranslation} from "react-i18next";

/* eslint-disable max-lines-per-function */
const AboutSidebar = (): JSX.Element => {
	const about = useSelector((state: RootState) => state.account.about);
	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		await getListAbout().then((res) => {
			// console.log(res);
		});
	};

	const {t} = useTranslation()

	const TabsAbout: TabAccount[] = [
		// {
		//   name: about[0]?.name,
		//   link: "/",
		//   id: about[0]?.id,
		//   exact: false,
		// },
		{
			name: about[1]?.name,
			link: "/account/about/mail",
			id: about[1]?.id,
			exact: false,
		},
		{
			name: about[3]?.name,
			link: isMobile() ? "/cgv" : "/account/about/cgv",
			id: about[3]?.id,
			exact: true,
		},
	];
	const [language, setLanguage] = useState<any>()
	useEffect(() => {
		setLanguage(localStorage.getItem('SUPERNOVA_LANGUAGE'))
	}, [])

	return (
		<div className="sidebar about">
			<div className="blc-top">
				<BackBtn/>
				<h1>
					{language === 'fr' ? t('aboutScreenTitleStepOne') || STRINGS.ACCOUNT.ABOUT.PART1 : ''} &nbsp;
					<span>{t('aboutScreenTitleStepTwo') || STRINGS.ACCOUNT.ABOUT.PART2}</span>
				</h1>
			</div>
			<div className="center-vertically tabs">
				{
					// eslint-disable-next-line sonarjs/cognitive-complexity
					TabsAbout.map((item) => {
						return (
							<div key={item.id} className="tab-item">
								<NavLink
									to={item.link}
									className={({isActive}) =>
										"tabName" + (isActive ? " active" : "")
									}
									target={item.id === 1 ? '_blank' : undefined}
									onClick={() => {
										return item.id === 2
												? window.open(`mailto:?subject=Suppernova&body=${
													(t('MailToBody'))}`)
											: item.id === 1
												? window.open(item.link, "_blank")
												: null;
									}}
								>
									{item?.name?.toUpperCase()}
								</NavLink>
							</div>
						);
					})
				}
			</div>
			<div className="blc-footer">
				<Footer quantity={0} priceOrder={0} isHome={true} isOrder={false}/>
			</div>
		</div>
	);
};
/* eslint-enable max-lines-per-function */
export default AboutSidebar;
