import React from "react";
import { STRINGS } from "../../../../../core/enums/strings";
import "./Help.scss"
import { PATHS } from "../../../../../core/enums/paths";
import BackBtn from "../../../../../components/back-btn/BackBtn";
import HelpSuggestion from "../../../../../components/help-suggestion/HelpSuggestion";
import HelpCheckbox from "../../../../../components/help-checkbox/HelpCheckbox";
import {useLocation, useParams} from "react-router";
import {t} from "i18next";
import {useTranslation} from "react-i18next";
/* eslint-disable max-lines-per-function */
const Help = () => {
   const params= useParams()
    const {t} = useTranslation()
const state=useLocation()
  return (
    <div className="help-content center-vertically">
      <div className="container-btn center-horizontally align-center">
        <BackBtn />
        <p>{t('return').toUpperCase()}</p>
      </div>
      <div className='title-container center-vertically'>
        <h1>
            {t('claimScreenHeaderQuestion')}
        </h1>
      </div>
      <div className='center-horizontally action-container'>
          <HelpSuggestion orderId={state.state.order_id} />
          <HelpCheckbox />
      </div>
    </div>
  );
};
/* eslint-enable max-lines-per-function */

export default Help;
