import moment from "moment";
import validator from "validator";
import {rule} from "../../core/models/Input";
import {RootState, store} from "../store";
import notFoundImage from '../../assets/images/LinearGradient.png'
import {TFunction} from "i18next";
import axios from "axios";
import {useEffect} from "react";
import {useSelector} from "react-redux";

export function isEnglishLang() {
  return localStorage.getItem("LANGUAGE") === "en"
}

const frenshFormat = "DD/MM/YYYY";
// UTILS

export const capitalizeFirstLetter = (name: string) => {
  return name.charAt(0).toUpperCase() + name.slice(1)
}

export const getDateFromUnix = (unix: number, format = frenshFormat) => {
  return moment.unix(unix).format(format);
}

export const getDateFromPlusDays = (unix: number, days: number) => {
  return moment.unix(unix).add(days, "days").format(frenshFormat);
}

export const getRemainingDaysName = (remaining: number) => {
  if (remaining === 1) {
    return {number: null, string: 'DAYS.1'};
  } else if (remaining === 2) {
    return {number: null, string: 'DAYS.2'}
  } else if (remaining <= 10 && remaining > 2) {
    return {number: remaining, string: 'DAYS.THREE_TO_TEN'}
  } else {
    return {number: remaining, string: 'DAYS.DEFAULT'}
  }
}

export const isMobile = () => {
  const width = store.getState().root.DEVICE_WIDTH;
  return width <= 767;
}

export const validateImage = (url: string) => {
  const img = document.createElement("img");
  img.src = url;
  return img.complete
}
/* eslint-disable max-lines-per-function */
// eslint-disable-next-line max-lines-per-function
export const errorMessage = (value: string | number, rules: rule[], t: TFunction, confirmPassword?: string): string | null => {
  let errorMsg: string | null = null;
  // eslint-disable-next-line sonarjs/cognitive-complexity
  rules?.forEach((rule: rule) => {

    switch (rule?.name) {
      case "email":
        value?.toString()?.length > 1 &&
        !validator?.isEmail(value?.toString() || '') &&
        (errorMsg = t('emailMustBeValid'));
        break;
      case "required":
        !validator?.isLength(value?.toString() || '' , {min: 2}) &&
        (errorMsg = t('fieldIsRequired'));
        break;
      case "length":
        !validator?.isLength(value?.toString() || '', {max: rule?.max as number}) &&
        (errorMsg = `${t('maximum')} ${rule?.max} ${t('characters')}`);
        break;
      case "minLength":
        !validator?.isLength(value?.toString() || '', {min: rule?.min as number}) &&
        (errorMsg = `${t('minimum')} ${rule?.min} ${t('characters')}`)
        break;
      case "phone":
        !/^(\+?33|0?|\+?330)[1-9]\d{8}$/.test(value.toString() || '') &&
        (errorMsg = t('invalidPhoneNumber'));
        break;
      case "number":
        !validator?.isNumeric(value?.toString()|| '') &&
        (errorMsg = t('fieldError'));
        break;
      case "verification-code":
        value?.toString()?.length < 4 && (errorMsg = t('verificationCodeError'));
        break;
      case "confirmPassword":
        value?.toString() !== confirmPassword && (errorMsg = t('NotSamePassword'));
        break;
      case "min":
        !validator?.isLength(value?.toString() || '', {min: 1}) &&
        (errorMsg = t('fieldIsRequired'));
        break;
      default:
        errorMsg = null;
        break;
    }
  });
  return errorMsg;
};
export const convertDateToLeftYear = (date:any) => {
  return date.getDate() + '/' + (date.getMonth() + 1).toString().padStart(2, "0") + '/' + date.getFullYear();
}
// eslint-disable-next-line sonarjs/cognitive-complexity
export const currency = (value:any) =>{
  const test= localStorage.getItem('currency')

  if (test){
    const val=JSON.parse(test) || '{}'
    if (val?.value_by_unit === 0 || val?.value_by_unit === '' ||val?.value_by_unit===null || val?.value_by_unit === '0'|| val?.symbol_currency_utf8 ===''){
      return `${value} €`
    }else {
      return `${Math.round(value * val?.value_by_unit)} ${val?.symbol_currency_utf8} `
    }
  }
}
// eslint-disable-next-line sonarjs/cognitive-complexity
export const tomorrowStringDate = () => {
  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1).toString();
  return tomorrow.getDate() + '/' + (tomorrow.getMonth() + 1).toString().padStart(2, "0") + '/' + tomorrow.getFullYear();
}

export const convertDate = (date: string) => {
  return moment(date, frenshFormat,'fr').format('YYYY-MM-DD').toString()
}
export const getPickUpDate=(listDate:any,dateToSearch:any)=>{
  // listDate.map((item:any)=>{
  //   if (item.delivery_date === dateToSearch){
  //     return item?.pickup_date
  //   }
  // })
   return listDate.filter((item:any)=>  item.delivery_date === dateToSearch  )
}
export const isFrenshDate = (value: string) => {
  const date = value.split('-');
  return date.length === 3 && date[0].length === 4;
}
export  const checkIfDifferentAddress=(selectedAdrres:any,addressFromGeoCode:any)=>{
  const adress= addressFromGeoCode.address_components[0].long_name + ' ' +addressFromGeoCode.address_components[1].long_name
  return (!((selectedAdrres?.position?.latitude?.toString()?.substring(0, 5) === addressFromGeoCode?.geometry?.location?.lat?.toString()?.substring(0, 5)) &&
      (selectedAdrres?.position?.longitude?.toString()?.substring(0, 5) === addressFromGeoCode.geometry.location?.lng?.toString()?.substring(0, 5)) &&
      (selectedAdrres?.position.address !== adress)));

}

export const convertDateToValidFormat = (date: string) => {
  let validDate = '';
  if (isFrenshDate(date)) {
    validDate = date.replace('-', '/');
  } else {
    const dateParts = date.split('/');
    dateParts[0].length === 4 ? validDate = dateParts[0] + '/' + dateParts[1] + '/' + dateParts[2] : validDate = dateParts[2] + '/' + dateParts[1] + '/' + dateParts[0]
  }
  return validDate;
}
