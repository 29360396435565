import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {getChefListAlphabet} from "../../../shared/store/slices/Chefs/ChefsService";
import {setSelectedChef} from "../../../shared/store/slices/Chefs/ChefsSlice";
import {useNavigate} from "react-router-dom";
import ChefCardExplorer from "./components/chefCardExplorer/chefCardExplorer";
import goRow from '../../../assets/icons/explore/rightArrow.svg'
import './chef.scss'
import {setBackRoot} from "../../../shared/store/slices/Root/rootSlice";
import {useTranslation} from "react-i18next";

// eslint-disable-next-line max-lines-per-function
const Chef: React.FC = () => {

	const dispatch = useDispatch()
	const [chefs, setChefList] = useState<any[]>([]);

	useEffect(() => {
		getChefListAlphabet().then((res) => {
			setChefList(res.data)
		})
	}, [])
	const goToAll = (list: any) => {
		const path = `/all-chef/chefs/${list}`;
		const state = {
			from: location.pathname,
			state: {
				Props:1
			},
		}
		dispatch(setSelectedChef(list))
		dispatch(setBackRoot({path: location.pathname, ...state.state}))
		navigate(path, state);
	}

	const navigate = useNavigate()

	const {t} = useTranslation()

	return (
		<div className={'chefCard'}>

			{Object.keys(chefs).map((chef: any) => {
					return (
						<div key={chef} className={'Cards'}>
							<div className={'alphabetic'}>
								<div style={{display: "flex", justifyContent: 'space-between'}}>
									<span> {chef}</span>
									<div className={'divNumber'}>
										<div className={'number'}><h2> {chefs[chef].total}</h2>
										</div>
										{chefs[chef].list?.length >= 3 &&
                                        <div className={'voir-tous'}>
                                            <span className={'see_all'} onClick={() => goToAll(chef)}> {t('seeAll')}</span>
											{/*<span className={'see_all'}> TOUT</span>*/}
                                            <img className={'go-row'} src={goRow}
                                                 onClick={() => goToAll(chef)}
                                                 alt={''}/>

                                        </div>
										}
									</div>
								</div>
								<div className={'line'}/>
							</div>
							<div className={'list-chef center-horizontally chefs'}>
								<ChefCardExplorer key={chef.id} item={chefs[chef].list}
												  isChef={true}
												  image={
													  chef?.image_profile?.find((item: any) => item.type === "4:3")
														  ?.url as string
												  }
												  isGray={
													  chef?.image_profile?.find((item: any) => item.type === "4:3")
														  ?.is_grey
												  }
								/>
							</div>
						</div>
					)
				}
			)
			}

			<div style={{height:'150px' +
					''}}/>
		</div>
	)
}

export default Chef