export const desc = "SUPPERNOVA VOUS PROPOSE DE PLONGER DANS L'UNIVERS DES PLUS GRANDS CHEFS INTERNATIONAUX, ET DE COMMANDER LES CRÉATIONS DE CEUX QUI INVENTENT LA GASTRONOMIE D'AUJOURD'HUI ET DE DEMAIN."
export const STRINGS = {
    LOGIN: {
        TXT_SUN: "connexion - inscription -",
        TXT_SUN1: "Mot de passe oublié - Mot de passe oublié - ",
        TITLE: "inscription ou ",
        PART2: "connexion",
        LOGIN_APPLE: "S'identifier avec apple",
        LOGIN_GOOGLE: "S'identifier avec google",
        LOGIN_FACEBOOK: "S'identifier avec Facebook",
        LOGIN_MAIL: "S'identifier avec Email",
        OR: "ou",
    },
    LOGIN_MAIL: {
        TITLE: "connexion par email",
        FORGET_PASSWORD: "Mot de passe oublié ?",
        LOGIN: "S'identifier",
    },
    REGISTER: {
        TITLE: "BIENVENUE",
        SELECT_COUNTRY:'PAYS',
        SUB_TITLE: "VOUS DEVEZ CRÉER UN COMPTE POUR CONTINUER",
        NEXT: "Suivant",
        BACK: "Précédent",
        BTN: "CRÉER VOTRE COMPTE",
        ERRORS: {
            ERR_1: "8 CARACTÈRES MINIMUM",
            ERR_2: "1 MAJUSCULE",
            ERR_3: "1 CARACTÈRE SPECIAL",
            ERR_4: "1 CHIFFRE",
        },
        INPUTS: {
            LAST_NAME: "NOM",
            FIRST_NAME: "PRÉNOM",
            EMAIL: "VOTRE ADRESSE EMAIL",
            PHONE: "NUMÉRO DE TÉLÉPHONE",
            PASSWORD: "VOTRE MOT DE PASSE",
            CONFIRM_PASSWORD: "CONFIRMEZ VOTRE MOT DE PASSE",
            VERIFICATION_CODE: "CODE DE VÉRIFICATION",
            NEW_PASSWORD: 'VOTRE NOUVEAU MOT DE PASSE',
            CONFIRM_NEW_PASSWORD: 'CONFIRMEZ VOTRE NOUVEAU MOT DE PASSE',
            COUNTRY:'CHERCHER UN PAYS'
        },
    },
    HOME: {
        TXT: {
            IN_SEOSON: "in season",
            PUR: "pur",
            REGISTER: {
                TITLE1: "plus de plaisir ?",
                TITLE2: "inscrivez vous",
                INFOS: "SOYEZ INFORMÉ EN AVANT-PREMIÈRE DES NOUVEAUX CHEFS QUI NOUS REJOIGNENT",
                PLACEHOLDER: "votre adresse email",
            },
        },
        BTN: {
            ALL_CAT: "VOIR Toutes les catégories",
            SHOW: "DÉCOUVRIR",
            SIGN_UP: "s'inscrire",
            ORDER: "passer commande",
            DELETE: "supprimer",
            DISCOVER: "découvrir",
            READ_MORE: "Lire la suite",
            READ_LESS: "AFFICHER MOIN",
            BUY: "acheter",
            ALREADY_BUY: "déjà acheté",
            SEND: "envoyer",
            PAYMENT: "Paiement de la commande",
            RETURN_CART: 'RETOUR PANIER',
            ACCEPT: "Accepter",
            CLOSE: "Fermer",
            UNFOLLOW: "Se désabonner ",
        },
    },
    HEADER: {
        LINK: {
            CAT: "Catégories",
            CHEFS: "chefs",
            KITCHEN: "cuisine",
            MY_ACCOUNT: "mon compte",
            BASKET: "panier",
            SEARCH: "recherche"
        },
        TXT1: "Votre panier",
        TXT2: "articles",
        TXT3: "Livraison garantie le",
        TXT4: "Récapitulatif commande",
        QTE: "quantité",
        EMPTY_BASKET: 'Votre panier d’achat est vide',
        RECAP: {
            ORDER: "Commande",
            DELIVERY: 'Livraison',
            ADDRESS_DELIVERY: 'Adresse de livraison',
            RESUME_ORDER: "RÉSUMÉ DE LA COMMANDE",
            DELIVERY_PERSON: "METHODE D'EXPÉDITION",
            MESSAGE: "MESSAGE",
            CODE_PROMO:{
                TITLE:"AVEZ VOUS UN CODE  ? REMPLISSEZ CE CHAMP ET CONFIRMER",
                PLACEHOLDER:'CODE',
                CONFIRMER:'CONFIRMER',
                ANNULER:'ANNULER'
            }
        }
    },
    FOOTER: {
        LINK: {
            DELIVERY: "livraison",
            ORDER_TRACKING: "suivie de commande",
            FREQUENTLT_QUESTIONS: "questions fréquentes",
            TERMS_SERVICE: "conditions générales d'utilisation",
            FOR_YOUR_RESTAURANT: "suppernova pour votre restaurant",
            CONTAT_US: "nous contacter",
            CREDIT_LEGAL_NOTICES: "crédit - mentions légales",
        },
        COPY_RIGHT: "©2022 suppernova",
    },
    NEW_CHEF: {
        TITLE: "LA NOUVELLE GARDE",
        TXT:
            " La Nouvelle Garde c’est une bande de jeunes franchouillards qui défendent frénétiquement l’art de vivre à la française à coup de brasseries traditionnelles. Des brasseries où l’on s’adonne aux plaisirs simples de la vie et où les moeurs sont libres, comme notre cuisine.",
    },
    DETAILS_CHEF: {
        RESTAURANTS: "restaurants",
        EMPTY_ITEM: "IL N'Y A AUCUN RESTAURANT "
    },
    ALL_CHEF: {
        CHEFS: 'CHEF.FES',
        TITLE: "tous les chefs",
        TXT_SUN: "decouvrez - decouvrez - decouvrez -",
        TXT: desc,
        EMPTY_ITEM: "PAS DE CHEFS",
        EMPTY_ITEM_CATEGORY: "IL N'Y A AUCUNE CATEGORIE ",
    },
    CATEGORIES: {
      TITLE: "TOUTES LES CATÉGORIES",
    },
    MORE_DETAILS: {
        TITLE: {
            PART1: "stéphanie",
            PART2: "le quellec",
        },
        ALL_CHEF: {
            TITLE: "tous les chef.fes",
            TXT:
                " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut\n" +
                "                        labore et dolore magna aliqua.",
        },
        TXT1: "la scène",
        TXT2: "Le chef de cuisine, ou chef cuisinier est le seul patron de la cuisine d'un restaurant. Seul ou à la tête de sa brigade, il opère derrière les fourneaux et se charge de la réalisation des plats à servir en salle. C'est lui qui imagine les plats proposés à la carte, en fonction de son imagination et de ses goûts.",
        IN_CARD: "à la carte",
        TITLE2: "stéphanie le quelle apparait dans :",
        TXT_SUN: "chef etoiles - chef etoiles - chef etoiles -",
        EMPTY_PLATES: "IL N'Y A AUCUN PLAT ",
        EMPTY_MENUES: "IL N'Y A AUCUN MENU "
    },

    DISH_PAGE: {
        DISH: "plat",
        TITLE1: "lieu jaune de ligne étuvé au thym citron",
        TITLE2: "préparation",
        TITLE3: "USTENSILES",
        STEP1: "Etape 01",
        STEP2: "Etape 02",
        STEP3: "Etape 03",
        TITLE4: "composition du colis",
        TITLE5: "Allergènes",
        TITLE6: "Conservation",
        TITLE7: "Autres plats du meme chef",
        TITLE8: "Autres menus du meme chefs",
        TXT1: "ravioles d’oignons doux & citron confit, bouillon moussé au fromage blanc, girolles du plateau",
        TXT2: "LIVRAISON GARANTIE LE",
        TXT3: "Four",
        TXT4: "poele",
        TXT5: "Prasent consequat ege sapien eupos erucons equat",
        TXT6: desc,
        TXT7:
            "Nos emballage exlusifs sont spécialement conçus pour conserver votre\n" +
            "                            commande au frais pendant 48h.",
        TXT8:
            "A conserver au réfrigérateur, et consommer sous 10 jours après livraison.\n" +
            "                            Ne pas congeler. ",
        TXT_SUN: "best seller - best seller - best seller -",
    },

    DETAILS_MENU: {
        TITLE: "Carnet de voyage",
        TITLE_MENU: "Les plats inclus",
        TXT1: "Menus 5 plats",
        TXT2: desc,
        TXT3: "LIVRAISON GARANTIE LE",
    },
    ACCOUNT: {
        LOGOUT_MESSAGE: "ÊTES-VOUS CERTAIN DE VOULOIR VOUS DÉCONNECTER",
        LOGOUT_ACTION: "DÉCONNECTER",
        GREETING: "bonjour",
        NAME: "harry",
        LOGOUT: "se déconnecter",
        ORDER_ITEM: {
            STATUS: "STATUS",
            ORDER: "commandé le",
            DELIVERY: "livré le",
            DETAILS_AND_PREPARATION: "détails & préparation",
        },
        MY_ORDERS: {
            EMPTY: "VOUS N'AVEZ PAS DE COMMANDE POUR LE MOMENT",
            TITLE: "MES COMMANDES",
            MY_ORDER_DETAILS: {
                TAB_1: "DÉTAILS DE LA COMMANDE",
                TAB_2: "PRÉPARATION",
                ORDERS: "COMMANDE",
                DELIVERY: "LIVRAISON",
                ADDRESS: "ADRESSE DE LIVRAISON",
                DOWNLOAD: "TÉTÉCHARGER LE reçu",
                WATCH_VIDEO: "VOIR LA VIDEO DE CHEF",
                TOTAL_UNIT: "TOTAL UNITAIRE",
                TOTAL: "TOTAL",
                FREE: "GRATUIT",
                SHIPPING_COST: "frais de livraison",
                SEEPREPARATION: "VOIR LA PRÉPARATION",
                HELPS: {
                    TITLE: "COMMENT POUVONS-NOUS VOUS AIDER ?",
                    SUGGESTION: [
                        "UN PRODUIT N'A PAS ÉTÉ LIVRÉ ",
                        "UN PRODUIT EST ARRIVÉ ENDOMMAGÉ",
                        "AUTRE DEMANDE",
                    ],
                    SUBTITLE: "SÉLÉCTIONNEZ LE(S) PRODUIT(S) CONCERNÉ(S)",
                    PART1: "VOTRE DEMANDE A BIEN ",
                    PART2: "ÉTÉ",
                    PART3: "ENVOYÉE",
                    SUBSUCCESSMESSAGE:
                        "NOUS NOUS EXCUSONS DU DÉSAGRÉMENT, LE SERVICE CLIENT REVIENDRA VERS VOUS AU PLUS VITE.",
                },
            },
        },
        NEWSLETTERS: {
            TITLE: "Gérer les newsletters",
            SUB_TITLE1: "Newsletter hebdomadaire",
            SUB_TITLE2: "Newsletter",
            // eslint-disable-next-line react/react-in-jsx-scope
            TXT: `j'ai lu et compris l'information sur le traitement de mes données personnelles énoncée dans ${<span
                className='underline'> la politique de confidentialité </span>} et j'accepte de recevoir des communications commerciales personnalisées de Suppernova par e-mail ou d'autres moyens`,
            MANAGE: "Gérer les",
            NEWSLETTERS: "newsletters",
            ENABLE_NEWS_LETTERS: 'Souscription au Newsletter',
            DISABLE_NEWS_LETTERS: 'Désabonnement de Newsletter',
            TXT2: "Souscrivez à notre newsletter et nous vous enverrons des informations sur les nouveautés.",
            TXT_THANKING_SUBSCRIBE: "Merci de vous être abonné\n" +
                "à notre newsletter !",
            TXT_THANKING_UNSUBSCRIBE: "VOUS ÊTES MAINTENANT DÉSABONNÉ\n" +
                "de notre newsletter !"
        },
        CONTACT: {
            TITLE: "contact",
            SUB_TITLE1: "En cas d’urgence ( indisponibilitÉ de plat, panne...)",
            SUB_TITLE2: "Ici nous répondrons à toutes vos questions",
            NAME: "marcellus wallas",
            TXT: "le Service support",
            TXT2: "Utiliser le formulaire ci-dessous",
            NMBR1: "06 32 23 54 45",
            NMBR2: "WHATSAPP",
            PLACEHOLDER1: "Objet de votre demande",
            PLACEHOLDER2: "MESSAGE",
            PLACEHOLDER3:"CATÉGORIE",
            PLACEHOLDER4:"PRÉNOM",
            PLACEHOLDER5:"NOM",
            PLACEHOLDER6:'EMAIL',
            PLACEHOLDER7:'NUMÉRO DE COMMANDE',
            CHECKBOX:"J'AI LU ET COMPRIS L'INFORMATION SUR L'UTILISATION DE MES DONNÉES PERSONNELLES ÉNONCÉE DANS LA POLITIQUE DE CONFIDENTIALITÉ"
        },
        EDITPROFILE: {
            MOBILE_TITLE: {
                PART1: "MODIFIER",
                PART2: "votre",
                PART3: "Numéro",
                PART4: "de",
                PART5: "télephone"
            },
            TITLE: "MON COMPTE",
            IDENTITY_TXT: "IDENTITÉ",
            IDENTITY: "VOTRE IDENTITÉ",
            IDENTITY_TITLE: "VOTRE <span>IDENTITÉ</span>",
            PHONE_NUMBER: "NUMÉRO DE TÉLÉPHONE",
            COUNTRY:"PAYS",
            PHONE_NUMBER_TITLE: "modifier <br> votre <span>NUMÉRO</span> DE <span>TÉLÉPHONE</span>",
            ADDRESS_MAIL: "VOTRE ADRESSE EMAIL",
            PASSWORD: "TAPEZ MOT DE PASSE",
            OLD_PASSWORD: "VOTRE MOT DE PASSE ACTUEL",
            PASSWORD2: "MOT DE PASSE",
            CODE: "CODE DE VÉRIFICATION",
            VERIFICATION_CODE: "SAISSIEZ <span> LE CODE </span>ENVOYÉ",
            NEW_PASSWORD: 'VOTRE MOT DE PASSE',
            FIRST_NAME: "PRÉNOM",
            LAST_NAME: "NOM",
            MY: "MON",
            ACCOUNT: "COMPTE",
            CODE_VERIFICAION_MESSAGE: "UN CODE DE VÉRIFICATION SERA ENVOYÉ À CE NUMÉRO"
        },
        ABOUT: {
            TITLE: "À propos",
            PART1: "À",
            PART2: "propos",
            CGV: {
                PART1: "conditions",
                PART2: "générales",
                PART3: "de vente"
            }
        },
        EDIT_ADRESSES: {
            RETURN_RECAP: "CONTINUER MA COMMANDE",
            TITLE: "GÉRER MES ADRESSES DE LIVRAISON",
            SUB_TITLE1: "MODIFIER L'ADRESSE",
            SUB_TITLE2: "AJOUTER UNE ADRESSE",
            INPUT_1: "ADRESSE",
            INPUT_6: "CODE POSTAL",
            INPUT_2: "NOM DE L'ADRESSE (DOMICILE / BUREAU ...)",
            INPUT_3: "DIGICODE",
            INPUT_4: "NUMÉRO DE L'APPARTEMENT, PORTE, ÉTAGE",
            INPUT_5: "NOM DE L'ENTREPRISE",
            EMTPTY: "VOUS N'AVEZ PAS ENCORE AJOUTÉ D'ADRESSE.",
            PART1: "GÉRER MES",
            PART2: "ADRESSES",
            PART3: "DE LIVRAISON",
            CITY: "VILLE",
            STATE: "RÉGION",
            COUNTRY: "PAYS",
            FORM: {
                SUB_TITLE1: {
                    PART1: "AJOUTER",
                },
                SUB_TITLE2: {
                    PART1: "MODIFIER",
                },
                PART2: "UNE ADRESSE"
            }
        },
    },
    EDIT: "MODIFIER",
    DELETE: "SUPPRIMER",
    BACK: "RETOUR",
    HELP: "AIDE",
    SEND: "ENVOYER",
    CANCEL: "ANNULER",
    VALIDATE: "VALIDER",
    SAVE: "ENREGISTRER",
    ADD: "Ajouter",
    TXT_COUNTER: "à partir du",
    SHOW_MORE: 'AFFICHER PLUS',
    CONTINUER: 'Continuer',
    CODE_VERIFICAION: 'ENVOYER LE CODE DE VÉRIFICATION',
    WELCOME: "BIENVENUE - BIENVENUE -",
    BY_DEFAULT: "par défaut",
    MODAL: {
        BASKET_NOT_EMPTY: "Vider le panier ?"
    },
    TOASTMAP1:"VOUS POUVEZ SÉLÉCTIONNER VOTRE ADRESSE",
    TOASTMAP2:"SAISIR VOTRE ADRESSE PUIS APPUYER SUR AJUSTER LE REPÈRE POUR SÉLÉCTIONNER VOTRE ADRESSE PRÉCISE",
    ERRORPOSITION:"VOTRE NAVIGATEUR NE SUPPORTE PAS LA GÉOLOCALISATION",
    ERRORGEO:"veuillez autoriser le navigateur à accéder à votre position",
    TITLE_MSG: "AJOUTER UN MESSAGE POUR UN CADEAU PERSONNALISÉ",
    PLACEHOLDER_MSG: "votre message personnalisé",
    TITLE_CALENDAR: "date de livraison",
    BTN_CALENDAR: "valider ma date de livraison",
    UPDATE: "METTRE À JOUR",
    UPDATE_PHONE_NUMBER: "METTRE À JOUR LE NUMÉRO DE TÉLÉPHONE",
    UPDATE_PASSWORD: "MODIFIER LE MOT DE PASSE",
    UPDATE_COUNTRY:"MODIFIER LE PAYS",
    EDIT_PASSWORD: "Modifier votre <span>Mot de passe</span>",
    EDIT_COUNTRY:"Modifier votre <span>Pays</span>",
    NEW_BASKET: "NOUVEAU PANIER",
    TEXT_NEW_BASKET: "VOTRE PANIER CONTIENT DÉJÀ UN PLAT D'UN AUTRE CHEF. VOULEZ-VOUS VIDER VOTRE PANIER ET AJOUTER CE PLAT À LA PLACE ?",
    TITLE_NEW_BASKET: "NOUVELLE COMMANDE ?",
    DELETE_ADDRESS_TITLE: "VOULEZ-VOUS SUPPRIMER CETTE ADRESSE ?",
    THANKS: "merci",
    PHONE_NUMBER_REQUIRED: "VEUILLEZ AJOUTER UN NUMÉRO DE TÉLÉPHONE À VOTRE COMPTE",
};
