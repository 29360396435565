
import React, {useState} from "react";
import {SearchOutlined} from "@ant-design/icons";
import {Input} from "antd";
import './searchChef.scss';
import {useSelector} from "react-redux";
import {RootState} from "../../../../../shared/store";
import {useNavigate} from "react-router";
import backRow from "../../../../../assets/icons/arrow-prev.svg";
import '../../../../chefs/details-chef/DetailsChef.scss'
import {checkData, debounce, RecentSearchList} from "../../../search/search";
import sunImage from "../../../../../assets/icons/orange-sun.svg";
import SearchCard from "../../../search/searchCard";
import {searchHome} from "../../../../../shared/store/slices/explore/exploreService";
import loader from "../../../../../assets/icons/explore/loading-svgrepo-com.svg"
import {useTranslation} from "react-i18next";
// eslint-disable-next-line max-lines-per-function,sonarjs/cognitive-complexity
const Recherche_Chef = () => {
  const listResult = useSelector((state: RootState) => state.explore.listSearch)
  const recentList = useSelector((state: RootState) => state.explore.listRecent)
  const [list, setList] = useState(true)
  const [resullt, setResult] = useState(false)
  const [data, setData] = useState<any>()
  const navigate = useNavigate()
  const [load, setLoading] = useState(false)
  const [noData, setNodata] = useState(false)
  const back = () => {
    navigate(-1)
  }
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const saveInput = (el: any) => {
    if (el.length > 2) {
      setLoading(true)
      searchHome(el.toUpperCase()).then((res: any) => {
        if (checkData(res)) {
          setLoading(false)
          setResult(true)
          setData(false)
          setNodata(checkData(res))
        } else {
          setLoading(false)
          setResult(true)
          setNodata(checkData(res))
          setData(true)

        }
      })
    } else
      setLoading(false)
    setResult(false)
  }
  const Debounce = debounce((el: any) => saveInput(el));
  const ChangeList = (value: any) => {
    if (value.target.value.length > 2) {
      setList(false)
      setResult(true)
      setNodata(false)

    } else {
      setList(true)
      setNodata(false)
      setResult(false)
    }

  }
  const returnRecent = (values: any) => {
    if (values.target.value.length < 3 || values.target.value === '') {
      setResult(false)
      setList(true)
      setNodata(false)
    }

    /*** GOOD ***/

    /*** ***/

  }

  const {t} = useTranslation();
  return (
    <div className={'recherche-mobile'}>
      <div className={'top-recherche'}>
        <h2>
          <div className={'div'}><p className={'row_back_div'}><img onClick={() => back()}
                                                                    src={backRow} alt={''} className={'row_back_img'}/>
          </p>
          </div>
        </h2>
        <div>
          <span className={'rech'}>{t('searchLink')}</span>
        </div>
      </div>
      <div style={{position: "relative"}}>
        <Input size="large" className={'recherche-input-mobile'} placeholder={t('searchScreenPlaceholderText')}
               prefix={<SearchOutlined/>} onInput={(e: any) => ChangeList(e)}
               onKeyUp={(e: any) => Debounce(e.target.value)} onChange={returnRecent}
        />
        <img src={loader} className={`loaderr ${load}`}/>
      </div>
      <div>
        {(resullt && data) ?
          <div className={'contain'}>
            {
              (listResult.chef_restaurants?.chefs?.length > 0 ) &&  (
                <div style={{width: '100%'}}>
                  {/* eslint-disable-next-line sonarjs/no-duplicate-string */}
                  <div className={'container-detail'}>
                    <div className={'categoryCont'}>
                      <img src={sunImage} alt="" draggable={false}/>
                      <span>{"CHEFS"}</span>
                    </div>
                    {
                      listResult?.chef_restaurants?.chefs.map((item: any, indexRestaurant: number) => (
                              <SearchCard key={item.id} item={{
                                firstSpan: item?.firstName + ' ' +item?.lastName,
                                number_restaurant: item?.number_restaurant,
                                idFirstSpan: item?.id,
                                typeFirstSpan: 'chef',
                                lastName: item?.lastName,
                                SecondSpan: item.restaurant.length > 1 ? `${item.number_restaurant} Restaurants` : item.restaurant?.[0]?.name,
                                idSecondSpan: item?.restaurant?.map((el: any) => {
                                  return el.name;
                                }),
                                typeSecondSpan: 'restaurant',
                                type: "chef_restaurants"
                              }} index={indexRestaurant}/>
                          )
                      )
                    }
                  </div>
                </div>
              )
            }
            {

              (listResult?.chef_restaurants?.restaurants?.length > 0) &&  (
                  <div style={{width: '100%'}}>
                    {/* eslint-disable-next-line sonarjs/no-duplicate-string */}
                    <div className={'container-detail'}>
                      <div className={'categoryCont'}>
                        <img src={sunImage} alt="" draggable={false}/>
                        <span>{"RESTAURANTS"}</span>
                      </div>
                      {
                        // eslint-disable-next-line sonarjs/no-identical-functions
                        listResult?.chef_restaurants?.restaurants.map((item: any, indexRestaurant: number) => (
                                <SearchCard key={item.id} item={{
                                  firstSpan: item?.name,
                                  number_restaurant: 1,
                                  idFirstSpan: item.chef_restaurant.id,
                                  typeFirstSpan: 'restaurant',
                                  lastName: item?.lastName,
                                  SecondSpan: item.chef_restaurant.firstName + ' ' + item.chef_restaurant.lastName,
                                  idSecondSpan: item.id,
                                  typeSecondSpan: 'restaurant',
                                  type: "restaurant"
                                }} index={indexRestaurant}/>
                            )
                        )
                      }
                    </div>
                  </div>
              )
            }
            {/*    THEMES*/}
            {
              listResult?.themes?.categories.length > 0 && (
                <div style={{width: '100%'}}>
                  <div className={'container-detail'}>

                    <div className={'categoryCont'}>
                      <img src={sunImage} alt="" draggable={false}/>
                      <span>{"THEMES"}</span>
                    </div>
                    {
                      listResult?.themes?.categories?.map((item: any, indexRestaurant: number) => (
                          <div style={{width: '100%'}} key={item.id}>
                            <SearchCard key={item.id} item={{
                              firstSpan: item?.name,
                              idFirstSpan: item?.id,
                              typeFirstSpan: 'category',
                              typeSecondSpan: 'restaurant_category',
                              number_restaurant: item.restaurants.length > 1 ? `${item.restaurants.length} ` : 1,
                              SecondSpan: `${item.restaurants.length} Restaurants`,
                              idSecondSpan: item?.subCategories.map((el: any) => {
                                return el.id;
                              }),
                              type: "themes"
                            }} index={indexRestaurant}/>
                          </div>
                        )
                      )
                    }
                  </div>
                </div>
              )
            }
            {/*    PLATS*/}
            {
              listResult?.plates?.plates?.length > 0 && (
                <div style={{width: '100%'}}>
                  <div className={'container-detail'}>
                    <div className={'categoryCont'}>
                      <img src={sunImage} alt="" draggable={false}/>
                      <span>{"PLATS"}</span>
                    </div>
                    {
                      listResult?.plates?.plates?.map((item: any, indexRestaurant: number) => (
                          <SearchCard key={item.id} item={{
                            firstSpan: item?.title,
                            idFirstSpan: item?.id,
                            typeFirstSpan: 'plate',
                            restaurantID: item?.restaurant?.id,
                            SecondSpan: item?.restaurant.chef_restaurant.firstName + ' ' + item?.restaurant.chef_restaurant.lastName ,
                            idSecondSpan: item?.restaurant.chef_restaurant.id,
                            typeSecondSpan: 'chef',
                            type: "plates"
                          }} index={indexRestaurant}/>
                        )
                      )
                    }
                  </div>
                </div>
              )
            }
            {/*    MENU*/}
            {
              listResult?.plates?.menus.length > 0 && (
                <div className={'container-detail'}>
                  <div className={'categoryCont'}>
                    <img src={sunImage} alt="" draggable={false}/>
                    <span>{"MENU"}</span>
                  </div>
                  {
                    listResult?.plates?.menus.map((item: any, indexRestaurant: number) => (
                        <SearchCard key={item.id} item={{
                          firstSpan: item?.title,
                          idFirstSpan: item?.id,
                          typeFirstSpan: 'menu',
                          typeSecondSpan: 'chef',
                          SecondSpan: item?.restaurant.chef_restaurant.firstName + ' ' + item?.restaurant.chef_restaurant.lastName ,
                          idSecondSpan: item?.restaurant?.chef_restaurant?.id,
                          restaurantID: item?.restaurant?.id,
                          type: "menu"
                        }} index={indexRestaurant}/>
                      )
                    )
                  }
                </div>
              )
            }
          </div> : noData ?
            <div className={'listContainer'}>
              <div className={'listScrollContainer'}>
                <div className={'category'}>
                  <span style={{color: '#eb7537', fontSize: '18px'}} className={'noData'}>{t('exploreChefsScreenEmptyMessage').toUpperCase()}</span>
                </div>
              </div>
            </div> : list ?
              <div className={'listContainer'}>
                <div className={'listScrollContainer'}>
                  <RecentSearchList data={[...(recentList || [])]} length={recentList?.length}/>
                </div>
              </div> : ''
        }
      </div>
    </div>
  )
}
export default Recherche_Chef
