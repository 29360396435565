import React from "react";
import {Item} from "../../../components/chef-card/ChefCard";
import {motion} from "framer-motion";
import {getChef} from "../../../shared/store/slices/Chefs/ChefsService";
import {setBackRoot} from "../../../shared/store/slices/Root/rootSlice";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import ImageContainer from "../../../components/imageContainer/imageContainer";
// eslint-disable-next-line max-lines-per-function
const AllRestoPerChef= (Props: Item): JSX.Element => {
	const dispatch=useDispatch()
	const history=useNavigate()
	// eslint-disable-next-line @typescript-eslint/no-empty-function,sonarjs/cognitive-complexity
	const  goToMoroDetail=async () => {


		Props.item?.id && await getChef(Props?.idCat).then((data) => {
			dispatch(setBackRoot({
				path: location.pathname,
				item: Props.item,
				itemResto: {id: Props?.itemResto ? Props.itemResto.id : data.data.chef.restaurant[0].id}
			}));
			history(`/more-detail/chef=${Props.idCat}/restaurant=${Props.item?.id}`, {
				state: {
					from: location.pathname,
					item: Props.item,
					itemResto: {id: Props?.itemResto ? Props.itemResto.id : data.data.chef.restaurant[0].id},
					id: Props.idCat
				}
			})
		});

	}
	const MotionComponent = motion(ImageContainer)
	return(
		<div
			onClick={()=>goToMoroDetail()}
			className="chef-card"
			key={Props?.item?.id}
			id={Props.idCustom}
		>
			<motion.div className="mask"/>
			{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
			{/*@ts-ignore*/}
			<MotionComponent
				// style={{y: shouldReduceMotion ? 0 : y}}
				className={Props.isGray ? "gray" : ""}
				src={
					Props.image as string
				}
				alt=""

			/>
			<div className={`blc-absolute ${!Props.isChef && "resto-absolute"}`}>
				<>
					<p className="restoo center-horizontally align-center justify-center">
						{Props?.itemResto?.name
							? Props?.itemResto?.name
							: Props.subCategory?.name}
					</p>
					<span>
              {" "}
						{Props?.item?.chefName} {" "} {Props?.item?.chefLastName}
            </span>
				</>
			</div>
		</div>
	)
}
export default AllRestoPerChef
