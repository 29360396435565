import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import "./DetailsChef.scss";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import ChefCard from "../../../components/chef-card/ChefCard";
import { STRINGS } from "../../../core/enums/strings";
import { useSelector } from "react-redux";
import { RootState } from "../../../shared/store";
import {
    getChef,
    getChefRestoList,
} from "../../../shared/store/slices/Chefs/ChefsService";
import { RestoModel } from "../../../core/models/Resto";
import {useLocation, useNavigate} from "react-router-dom";
import EmptyItem from "../../../components/empty-item/EmptyItem";
import emptyChef from "../../../assets/images/chef_empty.png";
import {ImagePlate} from "../../../core/models/Menu";
import {useParams} from "react-router";
import AllRestoPerChef from "../../explore/chef/allRestoPerChef";
import {PATHS} from "../../../core/enums/paths";
import {isMobile} from "../../../shared/utils/utils-functions";

/* eslint-disable max-lines-per-function */

// eslint-disable-next-line sonarjs/cognitive-complexity
const DetailsChef = ():JSX.Element => {
  const state = useLocation();
  const isLoading = useSelector((state: RootState) => state.root.isLoading);
  const totalResto = useSelector((state: RootState) => state.chefs.totalRestos);
  const [isNextPage, setNextPage] = useState(false);
  const [restoList, setRestoList] = useState<RestoModel[]>([]);
  const params=useParams<any>()
  const [chefDetail,setChefDetail]=useState<any>()
  const currentPage = useRef(0);
  const width=useSelector((state:RootState)=>state.root.DEVICE_WIDTH)
    const [isLoadApi,setIsLoadApi]=useState(false)

    const navigate=useNavigate()
    useEffect(() => {
    if(params.id) {
      getData();
    }else {
      navigate(-1)
    }
  }, []);
  useEffect(()=>{
     getChef(Number(params.id)).then((res:any)=>{
         setChefDetail(res?.data?.chef)

     })
  },[])
  const getData = async () => {
    currentPage.current += 1;
    await getChefRestoList( params.id ,currentPage.current).then(
      (res) => {
          setIsLoadApi(true)
        setNextPage(res.data.list.length === 10);
        setRestoList(
          currentPage.current === 1
            ? [...res.data.list]
            : [...restoList, ...res.data.list]
        );
      }
    );
  };
    const [scrollY, setScrollY] = useState<number>(0);

    useLayoutEffect(() => {
        const handleScroll = () => {
            setScrollY(Math.min(0, window.scrollY / 3 - 60) - 10);
        }
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    }, [scrollY]);
  return (
    <div className="details-chef">
        <Header classname={ scrollY === 0 || scrollY < -50 ? '' : 'home-header'} isHome={true} isHomeActive={true}  />
        <div className="top-page">
            {width > 768 &&  <button style={{position:'relative',left:'50px',bottom:'20px'}}   className="back-btn"  onClick={()=> navigate(-1)} />}
            <div className="container-desk center-horizontally space-between">
          <div className="center-horizontally space-between align-center">

              <div className={'title'} style={{alignItems:isMobile() ? 'center' : '',width:'100%'}}>
                  <span className={'titleDetailChef'}>
                  {chefDetail?.firstName}
                 </span>
                  <span className={'titleDetailChef'}>
                  {chefDetail?.lastName}
               </span>
              </div>

          </div>
          <p>
            {totalResto} {STRINGS.DETAILS_CHEF.RESTAURANTS}
          </p>
        </div>
      </div>
      {isLoadApi && restoList.length === 0 && !isLoading && (
        <EmptyItem img={emptyChef}>
          <p className="empty-content">{STRINGS.DETAILS_CHEF.EMPTY_ITEM}</p>
        </EmptyItem>
      )}

      <div className="list-restau center-horizontally">
          {restoList?.map((chef:any)=>{
              return <AllRestoPerChef  isChef={true} image={chef.image?.find((item: ImagePlate) => item.type === "4:3")?.url as string}
                                itemResto={chef}
                                key={chef.id}
                                item={{id:chef.id,firstName:chef.name,lastName:chef.name,chefName:chefDetail?.firstName,chefLastName:chefDetail?.lastName}}
                                active_option={'image'}
                                idCat={params.id}
                                restaurant={chef}

              />
          })}
      </div>
      {isNextPage && (
        <button onClick={getData} className="btn-primary">
          {STRINGS.SHOW_MORE}
        </button>
      )}
      <Footer quantity={0} priceOrder={0} isHome={true} />
    </div>
  );
};
/* eslint-enable max-lines-per-function */

export default DetailsChef;
