import React from 'react';
import "../About.scss";

const Sms = () :JSX.Element => {
    return (
        <div className="about-page">
           
        </div>
    );
};

export default Sms;
