import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {useNavigate, useParams} from "react-router";
import { PATHS } from "../../core/enums/paths";
import { STRINGS } from "../../core/enums/strings";
import {RootState} from "../../shared/store";
import {
    deleteMenuFromOwnCart,
    deleteMenuFromPublicCart,
    deletePlateFromOwnCart,
    deletePlateFromPublicCart,
    increDecrItemCart,
    setCustomMessage,
    updateDeliveryDate,
} from "../../shared/store/slices/Cart/CartService";
import {
  checkPromoCodeValidity,
  paymentIntent,
  recapOrder,
} from "../../shared/store/slices/Payment/PaymentService";
import {convertDate, currency, getPickUpDate, isMobile} from "../../shared/utils/utils-functions";
import EmptyItem from "../empty-item/EmptyItem";
import ItemOrder from "../item-order/ItemOrder";
import "./OrderBottomsheet.scss";
import CalendarWidget from "../calendar-widget/CalendarWidget";
import ModalWidget from "../modal-widget/ModalWidget";
import PersonalMsg from "../personal-msg/PersonalMsg";
import emptyChef from "../../assets/icons/basket-icn-gray.svg";
import ConfirmBottomSheet from "../confirm-bottom-sheet/ConfirmBottomSheet";
import { setIsBackFromAddress } from "../../shared/store/slices/Root/rootSlice";
import Input from "../input/Input";
import {useTranslation} from "react-i18next";

import moment from "moment";
import "moment/locale/fr";
import "moment/locale/en-au";
import {alertMessage} from "../../shared/store/interceptor";
import {Available, getRecapAvailableDate} from "../../shared/store/slices/Account/AccountService";
import {
    setListHolidayFromOrder,
    setObjectOfCalendarDate, setPickUpDate, setServiceCode,
    setServiceType
} from "../../shared/store/slices/Cart/CartSlice";


interface Props {
  open?: boolean;
  onClick?: () => void;
  onClose?: () => void;
  showCalendar?: (isVisible: boolean) => void;
  isRecap?: boolean;
  onChangeView?: (isRecap: boolean) => void;
}
function useOnClickOutside(
  ref: any,
  handler: { (): void; (arg0: { target: any }): void }
) {
  useEffect(() => {
    const listener = (event: { target: any }) => {
      if (!ref.current || ref.current?.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}
/* eslint-disable max-lines-per-function */
// eslint-disable-next-line sonarjs/cognitive-complexity
const OrderBottomsheet = (Props: Props): JSX.Element => {
  const divRef = useRef();
  const [isVisibleCalendar, setIsVisibleCalendar] = useState<boolean>(false);
  const [isConfirmBottomSheet, setIsConfirmBottomSheet] =
    useState<boolean>(false);
  const [codePromo, setCodePromo] = useState<string>("");

  const handler = useCallback(() => {
    if (!isMobile() && !isVisibleCalendar && !isConfirmBottomSheet) {
      Props.onClose && Props.onClose();
      setIsRecap(false);
    }
  }, [isVisibleCalendar, isConfirmBottomSheet]);
  useOnClickOutside(divRef, handler);
  const [isRecap, setIsRecap] = useState<boolean>(false);
  const cart = useSelector((state: RootState) => state.cart);
  const order = useSelector((state: RootState) => state.payment.orderRecap);
  const [isValidAdress,setIsValidAdress]=useState<any>(false)

  const isLoggedIn = useSelector(
    (state: RootState) => state.authentication.isLoggedIn
  );
  const currentUser = useSelector(
    (state: RootState) => state.authentication.profile
  );
  const history = useNavigate();
  const isBackFromAddress = useSelector(
    (state: RootState) => state.root.isBackFromAddress
  );
  const selectedChef = useSelector(
    (state: RootState) => state.cart.selectedChef
  );
  const [countBasket, setCountBasket] = useState<number>(0);
  const [isConfirmPhoneNumberBottomSheet, setIsConfirmPhoneNumberBottomSheet] =
    useState<boolean>(false);
  const deletePlatFromBasket = async (id: number) => {
    if (isLoggedIn) {
      await deletePlateFromOwnCart(id).then();
    } else {
      await deletePlateFromPublicCart(
        Number(localStorage.getItem("basket_id")),
        id
      ).then();
    }
  };
  const deleteMenuFromBasket = async (id: number) => {
    if (isLoggedIn) {
      await deleteMenuFromOwnCart(id).then();
    } else {
      await deleteMenuFromPublicCart(
        Number(localStorage.getItem("basket_id")),
        id
      ).then();
    }
  };
  const [loadPage,setIsLoadPage]=useState<any>(false)

    const [myNumber,setMyNumber]=useState<any>('')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    useEffect(()=>{
        if (localStorage.getItem('supernova_token') && isRecap){
                setMyNumber(currentUser && currentUser.phone)
               if (order.address_delivry){
                   Available(order?.restaurant?.id,{is_from_basket_details_screen:true}).then((res: any) => {
					   if (res.data.first_day_available){
						   if (!res?.data?.is_ups_available){
							   setIsValidAdress(false)
                               dispatch(setServiceType(t('DeliveryNotAvailable')))
                               setIsLoadPage(true)

                           }else{
							   setIsValidAdress(true)

							   dispatch(setListHolidayFromOrder(res?.data?.list_days_by_status.filter((item: any) => !item?.is_available_ups || !item?.is_available_pickup || !item?.service)))
							   dispatch(setObjectOfCalendarDate(res?.data?.list_days_by_status))
							   dispatch(setServiceType(res?.data?.infos_first_day_available?.service?.name ? res?.data?.infos_first_day_available?.service?.name : t('DeliveryNotAvailable')))
                               // eslint-disable-next-line sonarjs/no-duplicate-string
                               updateDeliveryDate(moment(res?.data?.infos_first_day_available?.delivery_date).format('DD/MM/YYYY'),
                                                     res.data.infos_first_day_available.pickup_date,
                                                     res?.data?.infos_first_day_available.service?.code).then(()=>{

                                   dispatch(setServiceCode(res?.data?.infos_first_day_available.service?.code))
                                   dispatch(setPickUpDate(res.data.infos_first_day_available.pickup_date))
                                   setIsLoadPage(true)
                               })

						   }
					   }else {
						   Available(order?.restaurant?.id,{
							   month: new Date().getMonth()+2,
                               year: new Date().getMonth()+1 === 12 ?  new Date().getFullYear()+1 : new Date().getFullYear() ,
							   is_from_basket_details_screen:true}).then((res:any)=>{
							   if (!res.data.first_day_available || !res?.data?.is_ups_available ){
								   setIsValidAdress(false)
                                   dispatch(setServiceType(t('DeliveryNotAvailable')))
                                   setIsLoadPage(true)

                               }else{
								   setIsValidAdress(true)
                                   updateDeliveryDate(moment(res?.data?.infos_first_day_available?.delivery_date).format('DD/MM/YYYY'),
                                       res.data.infos_first_day_available.pickup_date,
                                       res?.data?.infos_first_day_available.service?.code).then(()=>{
                                       dispatch(setServiceCode(res?.data?.infos_first_day_available.service?.code))
                                       dispatch(setPickUpDate(res.data.infos_first_day_available.pickup_date))
                                       // setIsLoadPage(true)

                                   })
								   dispatch(setListHolidayFromOrder(res?.data?.list_days_by_status.filter((item: any) => !item?.is_available_ups || !item?.is_available_pickup || !item?.service)))
								   dispatch(setObjectOfCalendarDate(res?.data?.list_days_by_status))
								   dispatch(setServiceType(res?.data?.infos_first_day_available?.service?.name ? res?.data?.infos_first_day_available?.service?.name : t('DeliveryNotAvailable')))
                                   setIsLoadPage(true)

							   }
						   })
					   }

                   })
               }else{
                   dispatch(setServiceType(t('DeliveryNotAvailable')))
                   setIsLoadPage(true)

               }
        }

    },[isRecap])
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const incrementDecrementItemCart = async (id: number, increment: boolean,isMenu: boolean,quantity: number | undefined ) => {
    if (!increment && quantity && quantity === 1) {
      // isMobile() && Props.onClose && Props.onClose();
      isMenu ? showDeleteConfirmMenu(id) : showDeleteConfirmPlat(id);
    } else {
      await increDecrItemCart(id, { increment }, isMenu, isLoggedIn).then();
    }
  };
  const [language,setLanguage]=useState<any>()
 useEffect(()=>{
   setLanguage(localStorage.getItem('SUPERNOVA_LANGUAGE'))
 },[])
  const showDeleteConfirmMenu = (id: number) => {
    setTextModal(t('basketScreenDeleteItemModalMessageConfirm'));
    setIsConfirmBottomSheet(true);
    setSelectedItemToDelete({ isPlate: false, id });
  };
  const [textModal, setTextModal] = useState<string>("");
  const [selectedItemToDelete, setSelectedItemToDelete] = useState<{
    isPlate: boolean;
    id: number;
  }>();
  const showDeleteConfirmPlat = (id: number) => {
    setTextModal(t('basketScreenDeleteItemModalMessageConfirm'));
    setIsConfirmBottomSheet(true);
    setSelectedItemToDelete({ isPlate: true, id });
  };
  const confirmAction = (isYes: boolean) => {
    if (selectedItemToDelete && isYes) {
      selectedItemToDelete.isPlate
        ? deletePlatFromBasket(selectedItemToDelete.id).then()
        : deleteMenuFromBasket(selectedItemToDelete.id).then();
    }
    setIsConfirmBottomSheet(false);
  };

 const pickUp=useSelector((state:RootState)=>state.cart.pickUp)
 const serviceCode=useSelector((state:RootState)=>state.cart.serviceCode)

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const PaymentIntent = async (id: number) => {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      // getRecapAvailableDate(order.restaurant?.id,moment(newBasket?.deliver_at, 'DD/MM/YYYY').format('YYYY-MM-DD').toString(),pickUp,serviceCode).then(async () => {
          // eslint-disable-next-line sonarjs/no-duplicate-string

              if (message.length > 0) {
                  await setCustomMessage(message);
              }
              await paymentIntent(id, codePromo).then((res) => {
                    if (res.success) {
                      setIsRecap(false);
                      Props.onClose && Props.onClose();
                        console.log(res.data.id)
                        console.log(res)
                      if (res?.data?.id){
                          !isMobile() ? window.open('/account/orders', '_self') : window.open('/orders', '_self');
                      }
                      // window.open(res?.data?.session_link,'_blank','noreferrer')
                  }
              });
      // })

  };
  const goToAccount = () => {
    Props.onClose && Props.onClose();
    setIsConfirmPhoneNumberBottomSheet(false);
    dispatch(setIsBackFromAddress(true));
    setIsRecap(false);
    !isMobile() ? history("/account/profile") : history("/profile");
  };

    // eslint-disable-next-line sonarjs/cognitive-complexity
  const getRecapOrder = async (id: number) => {
      if (isLoggedIn) {
      //     if (isMobile()) {
      //         await recapOrder(id).then(() => {
      //             getMyProfile().then((result: any) => {
      //                 Available(result.data.basket.restaurant.id, {is_from_basket_details_screen: true}).then((res: any) => {
      //                     if (res.data.first_day_available) {
      //                         dispatch(setServiceType(res?.data?.infos_first_day_available?.service?.name))
      //                         // eslint-disable-next-line sonarjs/no-duplicate-string
      //                         updateDeliveryDate(moment(res?.data?.infos_first_day_available?.delivery_date).format('DD/MM/YYYY'),
      //                             res.data.infos_first_day_available.pickup_date,
      //                             res?.data?.infos_first_day_available.service?.code).then(async () => {
      //                             setIsRecap(true);
      //                             dispatch(setServiceCode(res?.data?.infos_first_day_available.service?.code))
      //                             dispatch(setPickUpDate(res.data.infos_first_day_available.pickup_date))
      //                             dispatch(setListHolidayFromOrder(res?.data?.list_days_by_status.filter((item: any) => !item?.is_available_ups || !item?.is_available_pickup || !item?.service)))
      //                             dispatch(setObjectOfCalendarDate(res?.data?.list_days_by_status))
      //                             dispatch(setServiceType(res?.data?.infos_first_day_available?.service?.name ? res?.data?.infos_first_day_available?.service?.name : t('DeliveryNotAvailable')))
      //                             setIsLoadPageMobile(true)
      //                         })
      //                     } else {
      //                         Available(result.data.basket.restaurant.id, {
      //                             month: new Date().getMonth() + 2,
      //                             year: new Date().getMonth() + 1 === 12 ? new Date().getFullYear() + 1 : new Date().getFullYear(),
      //                             is_from_basket_details_screen: true
      //                         }).then((res: any) => {
      //                             if (res.data.first_day_available) {
      //                                 dispatch(setServiceType(res?.data?.infos_first_day_available?.service?.name))
      //                                 // eslint-disable-next-line sonarjs/no-duplicate-string
      //                                 updateDeliveryDate(moment(res?.data?.infos_first_day_available?.delivery_date).format('DD/MM/YYYY'),
      //                                     res.data.infos_first_day_available.pickup_date,
      //                                     // eslint-disable-next-line sonarjs/no-identical-functions
      //                                     res?.data?.infos_first_day_available.service?.code).then(async () => {
      //                                     setIsRecap(true);
      //                                     dispatch(setServiceCode(res?.data?.infos_first_day_available.service?.code))
      //                                     dispatch(setPickUpDate(res.data.infos_first_day_available.pickup_date))
      //                                     dispatch(setListHolidayFromOrder(res?.data?.list_days_by_status.filter((item: any) => !item?.is_available_ups || !item?.is_available_pickup || !item?.service)))
      //                                     dispatch(setObjectOfCalendarDate(res?.data?.list_days_by_status))
      //                                     dispatch(setServiceType(res?.data?.infos_first_day_available?.service?.name ? res?.data?.infos_first_day_available?.service?.name : t('DeliveryNotAvailable')))
      //                                     setIsLoadPageMobile(true)
      //
      //                                 })
      //                             } else {
      //                                 dispatch(setServiceType(t('DeliveryNotAvailable')))
      //                                 setIsLoadPageMobile(true)
      //
      //                             }
      //                         })
      //                     }
      //                 })
      //             })
      //
      //
      //         })
      //     }else{
              recapOrder(id).then(()=>{
                      setIsRecap(true)

              })
          // }

    } else {
      Props.onClose && Props.onClose();
      history(PATHS.LOGIN);
    }
  };
  const  newBasket=useSelector((state:RootState)=>state.cart.basketDate)
  const dispatch = useDispatch();
  const Redirect = () => {
    Props.onClose && Props.onClose();
    dispatch(setIsBackFromAddress(true));
    setIsRecap(false);
    !isMobile() ? history("/account/addresses") : history("/address");
  };
  const [message, setMessage] = useState<string>("");
    // eslint-disable-next-line sonarjs/cognitive-complexity
  const openCalendar = () => {
     if (order.address_delivry && isValidAdress){
         // Available(order?.restaurant?.id,{is_from_basket_details_screen:true}).then((res: any) => {
         //     dispatch(setListHolidayFromOrder(res?.data?.list_days_by_status.filter((item: any) => !item?.is_available_ups || !item?.is_available_pickup || !item?.service)))
         //     dispatch(setObjectOfCalendarDate(res?.data?.list_days_by_status))
         //
         // }).then(()=>{
             if (isMobile()) {
                 Props.showCalendar && Props.showCalendar(true);
             } else {
                 setIsVisibleCalendar(true);
             }
         // })
     }else if (!order.address_delivry){
         alertMessage(t('addAdressBasket'),false)
     }else{
         alertMessage(t('modifierAdressBasket'))
     }
  };
  const params= useParams()
    const objectOfWhenChangeDate=useSelector((state:RootState)=>state.cart.objectOfDateWhenChangeCalendar)

    const selectDate = (value: string) => {
      //       Available(order.restaurant?.id,{is_from_basket_details_screen:true}).then((res:any)=>{
      //     if (res.data.first_day_available){
              const objectOfDate = getPickUpDate(objectOfWhenChangeDate,moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD').toString())

              // getRecapAvailableDate(order.restaurant?.id,moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD').toString(),objectOfDate?.[0]?.pickup_date,objectOfDate?.[0]?.service?.code).then(async () => {
                  updateDeliveryDate(value,objectOfDate[0].pickup_date,objectOfDate?.[0]?.service.code,true).then(()=>{
                          dispatch(setServiceType(objectOfDate?.[0]?.service?.name))
                          dispatch(setServiceCode(objectOfDate?.[0]?.service?.code))
                          dispatch(setPickUpDate(objectOfDate?.[0]?.pickup_date))
                      setIsVisibleCalendar(false);

                  });
              // })

        //     }
      //
      // })
  };
  const closeBasket = () => {
    Props.onClose && Props.onClose();
      setIsVisibleCalendar(false);
      setIsLoadPage(false)
      setIsRecap(false);
  };
  useEffect(() => {
    if (isMobile()) {
      Props.onChangeView && Props.onChangeView(isRecap);
    }
  }, [isRecap]);

  useEffect(() => {
    if (isMobile()) {
      Props.isRecap &&
        cart?.basket?.id &&
        getRecapOrder(cart?.basket?.id).then();
    }
    return () => {
      setIsRecap(false);
    };
  }, []);

  useEffect(() => {
    if (Props.open) {
      isBackFromAddress ? setIsRecap(true) : setIsRecap(false);
      dispatch(setIsBackFromAddress(false));
    }
  }, [Props.open]);
  const {t} = useTranslation()
  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (cart.basket) {
      let total = 0;
      cart.basket?.plates.forEach(
        (item) => (total += item.quantite ? item.quantite : 0)
      );
      cart.basket?.menus.forEach(
        (item) => (total += item.quantite ? item.quantite : 0)
      );
      setCountBasket(total);
    }
  }, [cart]);
  useEffect(()=>{
      if(isRecap){
          document.body.style.overflow='hidden'
      }else{
          document.body.style.overflow='auto'
      }
  },[isRecap])


  const [newTotalPrice,setNewTotalPrice]=useState(order.total_price)
  const [isCodePromo,setIsCodePromo]=useState(false)

  const checkValidity=(code:any,price:any)=>{
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    checkPromoCodeValidity(code).then((res:any)=>{
        if (!res?.data?.is_free_off){
            setNewTotalPrice(res.data.total_price)
            setIsCodePromo(true)
        }else{
            setNewTotalPrice(0)
            setIsCodePromo(true)
        }

    })
  }
  const recentDate=useSelector((state:RootState)=>state.cart.recentDate)
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(()=>{
    setNewTotalPrice(order.total_price)
  },[order])
  const testCount=(number:any)=>{
    if (number !== 1 ){
      return  (
          `${String(number).padStart(2, "0")} ${t('article')}`
      )
    }
    else {
      return  (
          `${String(number).padStart(2, "0")} ${t('articleSing')}`
      )
    }

  }
  useEffect(()=>{
        if(Props.open){
            document.body.style.overflow='hidden'
        }else{
            document.body.style.overflow='auto'
        }

  },[Props.open])
    const [dateFinal,setDateFinal]=useState(cart?.basket?.deliver_at)
    useEffect(()=>{
        setDateFinal(cart?.basket?.deliver_at)
    },[cart?.basket?.deliver_at])
    const serviceName=useSelector((state:RootState)=>state.cart.serviceType)
  return (
    <div className="card-bottomsheet" >
      {!isMobile() && (
        <ModalWidget
          isVisible={isVisibleCalendar}
          onCancel={setIsVisibleCalendar}
        >
          <CalendarWidget
            onChange={selectDate}
            defaultValue={newBasket && newBasket.deliver_at}
            isAvailableWeekDays={selectedChef?.is_available_days_week}
            daysAvailable={selectedChef?.available_days_week}
            idResto={cart.basket?.restaurant?.id}
            adress={order.address_delivry}
            isOrder={true}
          />
        </ModalWidget>
      )}
      <ConfirmBottomSheet
        action={confirmAction}
        yesText={t('manageMyDeliveryAddressesModalMessageConfirmDelete')}
        noText={""}
        title={textModal}
        text=""
        isDelete={true}
        open={isConfirmBottomSheet}
      />
      <ConfirmBottomSheet
        action={goToAccount}
        yesText={t('updateButton')}
        noText={""}
        title={textModal}
        text=""
        isDelete={false}
        open={isConfirmPhoneNumberBottomSheet}
      />
      {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
      {/* @ts-ignore */}
      <div className="content-card" >
        <div className="top-blc center-horizontally align-center space-between">
          <div className="title-blc center-horizontally align-center">
            <h1>{!isRecap ? (t('basketScreenTitle') || STRINGS.HEADER.TXT1) : (t('orderSummaryScreenTitle') || STRINGS.HEADER.TXT4)} </h1>
            {!isRecap &&
              cart.basket.plates.length + cart.basket.menus.length > 0 && (
                <p className="nbr-items">
                  {/*{countBasket}*/}
                  {testCount(countBasket)}
                </p>
              )}
          </div>
          <button className="close-button-sheet" onClick={closeBasket} />
        </div>
        {!isRecap ? (
            <>
                <div id={'list-order'} className="list-order">
                    {cart?.basket?.restaurant && (
                        <div key={cart?.basket?.id}>
                            <h3 className="title-resto">{cart.basket?.restaurant?.name}</h3>
                            {cart?.basket?.menus?.map((menu) => {
                                return (
                                    <ItemOrder
                                        key={menu.id}
                                        menu={menu.menu}
                                        quantity={menu.quantite}
                                        onClick={() => {
                                            // isMobile() && Props.onClose && Props.onClose();
                                            showDeleteConfirmMenu(menu.id);
                                        }}
                                        increment={() => incrementDecrementItemCart(
                                            menu.id,
                                            true,
                                            true,
                                            menu.quantite
                                        )}
                                        decrement={() => incrementDecrementItemCart(
                                            menu.id,
                                            false,
                                            true,
                                            menu.quantite
                                        )}/>
                                );
                            })}
                            {cart?.basket?.plates?.map((plate) => {
                                return (
                                    <ItemOrder
                                        key={plate.id}
                                        plate={plate.plate}
                                        quantity={plate.quantite}
                                        onClick={() => {
                                            // isMobile() && Props.onClose && Props.onClose();
                                            showDeleteConfirmPlat(plate.id);
                                        }}
                                        increment={() => incrementDecrementItemCart(
                                            plate.id,
                                            true,
                                            false,
                                            plate.quantite
                                        )}
                                        decrement={() => incrementDecrementItemCart(
                                            plate.id,
                                            false,
                                            false,
                                            plate.quantite
                                        )}/>
                                );
                            })}
                        </div>
                    )}
                    {!cart?.basket?.restaurant && (
                        <EmptyItem center={true} img={emptyChef}>
                            <label className="empty-content fs-14">
                                {t('basketScreenEmpty') || STRINGS.HEADER.EMPTY_BASKET}
                            </label>
                        </EmptyItem>
                    )}
                </div>
                {cart?.basket?.nbr_article !== 0 && (
                    <div className="absolute-bottom">
                        {/*<div className="blc-delivery-date center-horizontally space-between">*/}
                        {/*    <div>*/}
                        {/*        <p>{t('basketScreenDeliveryFree') || STRINGS.HEADER.TXT3}</p>*/}
                        {/*        <p>*/}
                        {/*            {moment(convertDate(newBasket && newBasket.deliver_at)).locale(language === 'fr' ? 'fr' : 'en').format('DD/MMMM/YYYY').split('/').join(' ')}*/}
                        {/*        </p>*/}
                        {/*    </div>*/}
                        {/*    <button onClick={openCalendar}/>*/}
                        {/*</div>*/}
                        <div className="bottom-blc center-horizontally space-between align-center">
                            <button
                                onClick={() => cart?.basket?.id && getRecapOrder(cart?.basket?.id)}
                            >
                                <span>{t('basketScreenButtonOrder') || STRINGS.HOME.BTN.ORDER}</span>
                            </button>
                        </div>
                    </div>
                )}

            </>
        ) : (
          <div className="list-order">
            <div
              className={`list-recap center-vertically ${!isMobile() && ""}`}
            >
              <div className="item center-horizontally space-between">
                <p className="title">{t('detailsOrderScreenOrder') || STRINGS.HEADER.RECAP.ORDER}</p>
                <div className="blc-right">
                  <p className="description">#{order.id}</p>
                </div>
              </div>
              {/*<div className="item center-horizontally space-between">*/}
              {/*  <p className="title">{t('orderSummaryScreenDelivery') || STRINGS.HEADER.RECAP.DELIVERY}</p>*/}
              {/*  <div className="blc-right">*/}
              {/*    <p className="description order-date">*/}
              {/*      {t('myOrderScreenItemDateDelivered')} {newBasket?.deliver_at}*/}
              {/*    </p>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="item center-horizontally space-between">
                <p className="title">{t('deliveryAdress') || STRINGS.HEADER.RECAP.ADDRESS_DELIVERY}</p>
                <div className="center-vertically blc-right">
                  {order?.address_delivry && (
                    <>
                        <p className="description">
                            {order.address_delivry.last_name} {order.address_delivry.first_name}
                        </p>
                      <p className="description">
                          {
                          order?.address_delivry?.position?.address}
                      </p>

                        {
                            order?.address_delivry?.pivot_code &&
                            <p className="description">
                                {
                                    order?.address_delivry?.pivot_code
                                }
                            </p>
                        }
                        {
                            <p className="description">
                                {order?.address_delivry?.postal_code} {order?.address_delivry?.city}
                            </p>
                        }
                        {
                            <p className="description">
                                {order?.address_delivry?.country}
                            </p>
                        }
                        {
                            <p className="description">
                                {order?.address_delivry?.phone_receiver}
                            </p>
                        }

                      <div
                        className="text-button active center-horizontally align-center justify-center"
                        onClick={Redirect}
                      >
                        {t('edit')}
                      </div>
                    </>
                  )}

                  {!order?.address_delivry && (
                    <div
                      className="text-button active center-horizontally align-center justify-center"
                      onClick={Redirect}
                    >
                      {t('add')}
                    </div>
                  )}
                </div>
              </div>
              <div className="item center-horizontally space-between">
                <p className="title">{`${t('orderSummaryDetailsOrder')} ${t('orderSummaryScreenOrder')}` || STRINGS.HEADER.RECAP.RESUME_ORDER}</p>
                <div className=" center-vertically">
                  {order?.menus?.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="blc-right center-vertically"
                      >
                        <p className="description">
                          {item.quantite} X {item.menu.title}
                        </p>
                      </div>
                    );
                  })}
                  {order?.plates?.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="blc-right center-vertically"
                      >
                        <p className="description">
                          {item.quantite} X {item.plate.title}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="item center-horizontally space-between">
                <p className="title">{t('orderSummaryDeliveryMan') || STRINGS.HEADER.RECAP.DELIVERY_PERSON}</p>
                <div className="blc-right">
                  <p className="description">{ order?.address_delivry ? loadPage && serviceName : ''}</p>
                </div>
              </div>
                <div className="msg-card" style={{height:'100px'}}>
                    <div className="blc-delivery-date center-horizontally space-between">

                            <div>
                                <p>{t('guarantedDelivryBasket') || STRINGS.HEADER.TXT3}</p>
                                {
                                     !loadPage ? <p> </p> :
                                    order.address_delivry && isValidAdress ?
                                        <p>
                                            { loadPage && moment(convertDate(newBasket && newBasket.deliver_at)).locale(language === 'fr' ? 'fr' : 'en').format('DD/MMMM/YYYY').split('/').join(' ')}
                                        </p> :
                                        !order.address_delivry ? <p> </p> :
                                        <p>
                                             { loadPage && t('dateBasketNotFound')}
                                        </p>
                                }
                            </div>

                        <button onClick={openCalendar}/>
                    </div>
                </div>
              <PersonalMsg onChange={setMessage} />
               <div className="msg-card">
            <h1 className="msg-title">{t('orderSummaryCodePromoText')}</h1>
            <Input
                item={{

                    type: "text",
                    label: t('orderSummaryCodePromoPlaceholder'),
                    id: 1,
                    onChange: (e) => {
                        setCodePromo(e.target.value);
                    },
                    name:t('placeholder_code_promo')
                }}
            />

               <div className={'btn_contain'}>
                 <button className={'btn'} onClick={()=>checkValidity(codePromo,order.total_price)}>
                   {t('confirmed')}
                 </button>
               </div>
        </div>
            </div>

            <div className="total-section center-vertically space-between align-center">
              <div className="content-section center-horizontally space-between align-center">
                <p>{t('orderSummaryUnitTotal') || STRINGS.ACCOUNT.MY_ORDERS.MY_ORDER_DETAILS.TOTAL_UNIT}</p>
                <p>{currency(newTotalPrice)} </p>
              </div>
              <div className="content-section center-horizontally space-between align-center">
                <p>
                  {t('shippingCost') || STRINGS.ACCOUNT.MY_ORDERS.MY_ORDER_DETAILS.SHIPPING_COST}
                </p>
                <p>          { order?.price_calculate_delivery ? currency(order?.price_calculate_delivery) : t('orderSummaryTypeDelivery')}</p>
              </div>

                <div className="content-section center-horizontally space-between align-center">
                    <p>{t('orderSummaryTotalText') || STRINGS.ACCOUNT.MY_ORDERS.MY_ORDER_DETAILS.TOTAL}</p>
                    <p>{currency(newTotalPrice + order?.price_calculate_delivery)} </p>
                </div>
            </div>
            <div className="bottom-order-blc center-horizontally space-between align-center">
              <button className="return-cart" onClick={() =>{
                  setIsRecap(false)
                  setIsLoadPage(false)
              }}>
                <span>{t('returnCart') || STRINGS.HOME.BTN.RETURN_CART}</span>
              </button>
              <button disabled={!isValidAdress} style={{backgroundColor:!isValidAdress ? 'gray' : 'black'}} onClick={() => PaymentIntent(order?.id)}>
                <span>{t('orderSummaryButton') || STRINGS.HOME.BTN.PAYMENT}</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
/* eslint-enable max-lines-per-function */
export default OrderBottomsheet;
