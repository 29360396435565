import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getMacAddress} from "./exploreService";

export interface SubCategoryInterface {
  id: number,
  subCategories?:Array<any>
  name: string,
  image: Array<{
    url: string,
    type: string,
    is_grey: boolean
  }> ,
}

export interface CategoryInterface {
  id: number,
  name: string,
  description: string,
  subCategories:Array<SubCategoryInterface>
  image: Array<{
    url: string,
    type: string,
    is_grey: boolean
  }>,
  icon: string,
  count: number,

}

export interface KitchenListInterface {
  [key:string]: CategoryInterface | undefined,
}

export interface ExploreState {
  // kitchenList: undefined | KitchenListInterface,
  kitchenList:any
  subCategoryList: Array<SubCategoryInterface>
  listSearch:any,
  listRecent: Array<any>
  Mac:string
}

export const exploreInitialState: ExploreState = {
  kitchenList: undefined,
  subCategoryList: [],
  listSearch:[],
  listRecent:[],
  Mac: '',

};

export const exploreSlice = createSlice({
  name: 'explore',
  initialState: exploreInitialState,
  reducers: {
    setKitchenList: (state, action: PayloadAction<KitchenListInterface | undefined>): void => {
      state.kitchenList = action.payload;
    },
    setSubCategoryList: (state, action: PayloadAction<Array<SubCategoryInterface>>): void => {
      state.subCategoryList = action.payload;
    },
    setSearchList: (state, action: PayloadAction<Array<any>>): void => {
      state.listSearch = action.payload;
    },
    setRecentList: (state, action: PayloadAction<Array<any>>): void => {
      state.listRecent = action.payload;
    },
    setMac: (state, action: PayloadAction<string>): void => {
      state.Mac = action.payload;
    },
  },
});

export const {setKitchenList, setSubCategoryList,setSearchList,setMac, setRecentList} = exploreSlice.actions;
