import React from "react";
import "./Loader.scss";
import Spinner from "react-spinner-material";
import logo from "../../assets/icons/Logo-white-3.png";
import { Rings, ThreeDots, Watch } from "react-loader-spinner";

const Loader: React.FC<{ isLoading: boolean }> = ({isLoading}) => {
    return (
        <>
            {isLoading && (
                <div className="loader center-vertically align-center space-between">
                    <div className="loader-content center-horizontally"/>
                    <div className="loader-content bottom"/>
                     {/* <ThreeDots color="#f7fec8" height={50} width={50} /> */}
                </div>
                
            )}
        </>
    );
};

export default Loader;
