import React, {useEffect, useState} from "react";
import {Collapse} from "antd";
import "./DishPage.scss";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import food2 from "../../assets/images/food-2.jpg";
import Slider from "react-slick";
import MenuItem from "../../components/menu-item/MenuItem";
import icn1 from "../../assets/icons/icn-1.svg";
import {STRINGS} from "../../core/enums/strings";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../shared/store";
import {getChefPlateDetails} from "../../shared/store/slices/Chefs/ChefsSlice";
import { getPlateChef} from "../../shared/store/slices/Chefs/ChefsService";
import {
	addPlatetoOwnCart,
	addPlatetoPublicCart,
	createBasket, increDecrItemCart
} from "../../shared/store/slices/Cart/CartService";
import tabChef from "../../assets/icons/icn-chef-basket.svg";
import {convertDate, currency, isMobile} from "../../shared/utils/utils-functions";
import ModalWidget from "../../components/modal-widget/ModalWidget";
import {PlateInCart} from "../../core/models/Cart";
import {CategorieModel, PreparationArray} from "../../core/models/Menu";
import {PATHS} from "../../core/enums/paths";
import basket from "../../assets/icons/basket-icn-black.svg"
import plusButton from "../../assets/icons/plus-btn.svg";
import lessButton from "../../assets/icons/moins.svg";
import ConfirmBottomSheet from "../../components/confirm-bottom-sheet/ConfirmBottomSheet";
import {motion, useAnimation} from "framer-motion";
import {useInView} from "react-intersection-observer";
import sun from "../../assets/icons/best-seller.png";
import debounce from 'lodash.debounce';
import {useTranslation} from "react-i18next";
import ImageContainer from "../../components/imageContainer/imageContainer";
import {Available} from "../../shared/store/slices/Account/AccountService";
import moment from "moment";
import {useParams} from "react-router";
import {setBackRoot} from "../../shared/store/slices/Root/rootSlice";
import BackBtn from "../../components/back-btn/BackBtn";
import CalendarMenuPlat from "../../components/calendarMenuPlat/CalendarMenuPlat";


const settings = {
	dots: true,
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	cssEase: "linear",

	responsive: [
		{
			breakpoint: 767,
			settings: {
				vertical: true,
				verticalSwiping: true,
				swipeToSlide: true,
				infinite: true,
				dots: true,
				autoplay: false,
				autoplaySpeed: 2000,
			},
		},
	],
};
const settings3 = {
	dots: true,

	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	cssEase: "linear",

	responsive: [
		{
			breakpoint: 767,
			settings: {
				horizontal: true,
				horizontalSwiping: true,
				swipeToSlide: true,
				infinite: true,
				dots: true,
				autoplay: false,
				autoplaySpeed: 2000,
			},
		},
	],
};

const setting2 = {
	dots: false,
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: true,
	speed: 5000,
	autoplaySpeed: 3000,
	cssEase: "linear",
	responsive: [
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 1.5,
			},
		},
	],
};
const debounceCallback = debounce((callback: (show: boolean) => void) => {
	callback(false)
}, 2000);
/* eslint-disable max-lines-per-function */
// eslint-disable-next-line sonarjs/cognitive-complexity
const DishPage = (): JSX.Element => {
		const params=useParams()
		const plate = useSelector((state: RootState) => state.chefs.plate);
		const cart = useSelector((state: RootState) => state.cart);
		const history = useNavigate();
		const [count, setCount] = useState<number>(0);
		const {Panel} = Collapse;
		const state = useLocation();
		const [basketPlate, setBasketPlate] = useState<PlateInCart | null>(null);
		const [quantity, setQuantity] = useState<number>(0);
		const isLoggedIn = useSelector(
			(state: RootState) => state.authentication.isLoggedIn
		);
		const selectedChef = useSelector((state: RootState) => state.cart.selectedChef);
		const dispatch = useDispatch();
		const [scrollPosition, setScrollPosition] = useState(0);
		const handleScroll = () => {
			const position = window.pageYOffset;
			setScrollPosition(position);
		};
		const newBasket = useSelector((state: RootState) => state.cart.basketDate)

		const [dateFinal,setDateFinal]=useState<any>()
		const [isValidAdress,setIsValidAdress]=useState<any>(false)


		// eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(()=>{
	  Available(Number(params.id2),{is_from_basket_details_screen:false}).then((res:any)=>{
		  if (res.data.first_day_available) {
			  setDateFinal(moment(res.data.infos_first_day_available.delivery_date).format('DD/MM/YYYY'))
			  setIsValidAdress(true)
		  }else{
			  Available(Number(params.id2),{
				  month: new Date().getMonth()+2,
				  year: new Date().getMonth()+1 === 12 ?  new Date().getFullYear()+1 : new Date().getFullYear() ,
				  is_from_basket_details_screen:false
			  }).then((res:any)=>{
				  if (res.data.first_day_available) {
					  setDateFinal(moment(res.data.infos_first_day_available.delivery_date).format('DD/MM/YYYY'))
					  setIsValidAdress(true)
				  }else{
					  setIsValidAdress(false)

				  }
			  })
		  }

	  })
  },[params.id2])
		useEffect(() => {
			window.addEventListener('scroll', handleScroll, {passive: true});

			getData().then();
			return () => {
				window.removeEventListener('scroll', handleScroll);
				dispatch(
					getChefPlateDetails({
						plate: {
							id: 0,
							price: 0,
							description: "",
							title: "",
							categorie: "",
							utensils: [],
							allergenes: "",
							preparation_array: [],
							imagePlats: [],
							preparation_stage: [],
							composition_package: [],
							conservation_plate: [],
							quantity: 0,
							id_product_basket: 0
						},
						same_chef: [],
					})
				);

			};
		}, [params.id2 || params.id3]);


		// eslint-disable-next-line sonarjs/cognitive-complexity
		const getData = async () => {
			setListVideos([]);
				await getPlateChef(
					Number(params.id),
					Number(params.id2),
					Number(params.id3)
				).then((res) => {
					setCount(0);
					setListVideos(res.data.plate.preparation_array);
					const request = new XMLHttpRequest();
					request.open('GET', `${res.data.plate.preparation_array?.[0]?.translate_content_link}`, true);
					request.send(null);
					request.onreadystatechange = function () {
						if (request.readyState === 4 && request.status === 200) {
							const type = request.getResponseHeader('Content-Type');
							if (type?.indexOf("text") !== 1) {
								setText(request.responseText)
								return request.responseText;

							}
						}
					}
				}).catch(() => history(PATHS.HOME));

		};
		const [isConfirmBottomSheet, setIsConfirmBottomSheet] = useState<boolean>(false);
		const checkRestaurant = async () => {
			if (!cart?.basket?.restaurant || Number(params.id2) === cart?.basket?.restaurant?.id) {
				await addPlateToPanier();
			} else {
				setIsConfirmBottomSheet(true);
			}
		}
		const [isLoaded, setIsLoaded] = useState<boolean>(true);
		const navigateToPlat = (Plat: CategorieModel) => {
			window.scrollTo({top: 0, left: 1000, behavior: "auto"})

			if (Plat.id !== plate.plate.id) {
				setIsLoaded(false);
				setListVideos([]);
				getPlateChef(
					Plat?.chef_id,
					Plat?.restaurant_id,
					Plat.id
				).then((res) => {
					resetData();
					setIsLoaded(true);
					setListVideos(res.data.plate.preparation_array);
					history(`/dish-details/chef=${params.id}/restaurant=${params.id2}/dish=${Plat.id}`, {
						state: {
							idChef: Plat.chef_id,
							idResto: Plat.restaurant_id,
							idPlate: Plat.id
						}
					})
				}).catch(() => history(PATHS.HOME))

			}
		}
// eslint-disable-next-line sonarjs/cognitive-complexity
		const addPlateToPanier = async (override = false) => {
			// const {t} = useTranslation()
			if (isLoggedIn) {
				await addPlatetoOwnCart({
					plate_id:  Number(params.id3),
					restaurant_id: Number(params.id2),
					quantite: count === 0 ? count + 1 + quantity : count + quantity,
					override,
					deliver_at: convertDate(dateFinal)
				}).then(() => showCart());
			} else if (localStorage.getItem("basket_id")) {
				await addPlatetoPublicCart(Number(localStorage.getItem("basket_id")), {
					plate_id: Number(params.id3),
					restaurant_id: Number(params.id2),
					quantite: count === 0 ? count + 1 + quantity : count + quantity,
					override,
					deliver_at: convertDate(dateFinal)
				}).then(() => showCart());
			} else {
				await createBasket().then(async (res) => {
					await addPlatetoPublicCart(res.data.id, {
						plate_id: Number(params.id3),
						restaurant_id: Number(params.id2),
						quantite: count === 0 ? count + 1 + quantity : count + quantity,
						deliver_at: convertDate(dateFinal),
						override
					}).then(() => showCart());
				});
			}
			await getData();
		};
		const incrementDecrementItemCart = async (increment: boolean, isMenu: boolean) => {
			if (count === 0 && (cart?.basket?.restaurant && state.state.idResto !== cart?.basket?.restaurant?.id)) {
				setIsConfirmBottomSheet(true);
			} else {
				basketPlate?.id && await increDecrItemCart(basketPlate.id, {increment}, isMenu, isLoggedIn).then(() => showCart());
			}
		};
		const confirmBasket = (isYes: boolean) => {
			isYes && addPlateToPanier(true);
			setIsConfirmBottomSheet(false);
		}
		const [isVisibleCalendar, setIsVisibleCalendar] = useState<boolean>(false);


		const selectDate = (value: string) => {
			setIsVisibleCalendar(false);
		}
		const [listVideos, setListVideos] = useState<PreparationArray[] | undefined>([]);
		const file = new FileReader()
		// eslint-disable-next-line sonarjs/cognitive-complexity

		// eslint-disable-next-line sonarjs/cognitive-complexity
		useEffect(() => {
			if (!isMobile()) {
				plate?.same_chef?.length < 3
					? (setting2.slidesToShow = 1.5)
					: plate?.same_chef?.length < 6
						? (setting2.slidesToShow = 2.5)
						: (setting2.slidesToShow = 4.5);
			} else {
				setting2.responsive[0].settings.slidesToShow =
					plate?.same_chef?.length === 1 ? 1 : 1.5;
			}
			setListVideos(plate.plate.preparation_array);
		}, [plate]);
		useEffect(() => {
			// setIsLoaded(true);
			const item = cart.basket.plates.find((item) => item.plate.id === plate.plate.id);
			item && setBasketPlate(item);
			item ? setQuantity(item.quantite ? item.quantite : 0) : setQuantity(0);
			setCount(0);
			if (!plate) {
				history(PATHS.HOME);
			}
		}, [cart, plate]);
		// eslint-disable-next-line sonarjs/cognitive-complexity
	const  [text,setText]=useState<any>('')
		// eslint-disable-next-line sonarjs/cognitive-complexity
	const [blobb,setBlobb]=useState<any>()
		useEffect(()=>{
			setBlobb(new Blob([text],
				{ type: "text/plain;charset=utf-8" }))
		},[text])
		const [inViewRef, inView1] = useInView({
			threshold: 0.5,
		})
		const [inVewRef2, inView2] = useInView();

		const [inViewRef3, inView3] = useInView();

		const anim_card1 = useAnimation();
		const anim_card2 = useAnimation();
		const anim_card3 = useAnimation();
		const anim_step = useAnimation();
		const anim_rows = useAnimation();
		const anim_rows2 = useAnimation();


		const [isAnimatedOne, setIsAnimatedOne] = useState<boolean>(false);
		const [isAnimatedTwo, setIsAnimatedTwo] = useState<boolean>(false);
		const [isAnimatedThree, setIsAnimatedThree] = useState<boolean>(false);

		const resetData = () => {
			setIsAnimatedOne(false);
			setIsAnimatedTwo(false);
			setIsAnimatedThree(false);
		}
		// eslint-disable-next-line sonarjs/cognitive-complexity
		useEffect(() => {
			if (inView1 && !isAnimatedOne && scrollPosition > 20) {
				anim_card1.start({
					y: 0,
					opacity: 1,
					transition: {
						delay: 0.2,
						type: 'spring',
					}
				}).then()
				anim_card2.start({
					y: 0,
					opacity: 1,
					transition: {
						delay: 0.5,
						type: 'spring',

					}
				}).then()
				anim_card3.start({
					y: 0,
					opacity: 1,
					transition: {
						delay: 0.7,
						type: 'spring',

					}
				}).then()
				setIsAnimatedOne(true);
			}
			if (!inView1 && !isAnimatedOne) {
				anim_card1.start({y: '50px', opacity: 0}).then()
				anim_card2.start({y: '50px', opacity: 0}).then()
				anim_card3.start({y: '50px', opacity: 0}).then()
			}
			if (inView2 && !isAnimatedTwo) {
				anim_step.start({
					y: 0,
					opacity: 1,
					transition: {
						delay: 0.4,
						type: 'spring',
					}
				}).then();
				setIsAnimatedTwo(true);

			}
			if (!inView2 && !isAnimatedTwo) {
				anim_step.start({y: '200px', opacity: 0}).then()
			}

			if (inView3 && !isAnimatedThree) {
				anim_rows.start({
					y: 0,
					opacity: 1,
					transition: {
						delay: 0.1,
						type: 'spring',
					}
				}).then()
				anim_rows2.start({
					y: 0,
					opacity: 1,
					transition: {
						delay: 0.6,
						type: 'spring',
					}
				}).then()
				setIsAnimatedThree(true);
			}
			if (!inView3 && !isAnimatedThree) {
				anim_rows.start({y: '50px', opacity: 0}).then()
				anim_rows2.start({y: '50px', opacity: 0}).then()
			}

		}, [inView1, inView2, inView3, scrollPosition])

		const [countCart, setCountCart] = useState<boolean>(false);
		const showCart = () => {
			setCountCart(true)
			debounceCallback(setCountCart);
		}

		const goToChefDetails = () => {
			history(`/more-detail/chef=${params.id}/restaurant=${params.id2}`)
		}
         const navigateToMenu =()=>{
			 setBackRoot({
				 isFromDish:true
			 })
			return history(`/more-detail/chef=${params.id}/restaurant=${params.id2}`)

		}

		const getSrc=(value:any)=>{
			return window.URL.createObjectURL(value||'')
		}
		const {t} = useTranslation()
		const checkPlatName=(item:any)=>{
			if (item === 'Dessert'){
				return ('Dessert')
			}else if (item === 'Plat'){
				return (t('plate'))
			}else{
				return (t('starter'))
			}
		}
		return (
			<>
				{
					isLoaded &&
                    <>
                        <motion.div
                            initial={{y: 0}}
                            animate={{
								y: "100vh",
								transitionEnd: {
									display: "none",
								},
							}}
                            transition={{type: "spring", delay: 1, duration: 2}}
                            className="first-l center-vertically position-center"
                        />
                        <motion.div className="second-l" initial={{y: "-100vh", height: "-100vh"}}
                                    animate={{
										height: 0,
										y: "100vh",
										transitionEnd: {
											display: "none",
										},
									}}
                                    transition={{type: "spring", delay: 1, duration: 2}}/>
                        <div className="dish-page">
                            <Header classname={'home-header'} isHome={true} isHomeActive={true} />

                            <div className="top-page center-horizontally">
								{
									plate?.plate?.is_best_seller && (
										<motion.div  className="blc-absolute" initial={{opacity: 0}} animate={{opacity: 1}}
													transition={{delay: 2, duration: 2, bounce: 0.3}}>
											{/*<SunIcn text={STRINGS.DISH_PAGE.TXT_SUN}/>*/}
											<img alt={''} src={sun} className="round"/>
										</motion.div>
									)
								}
                                <div className="container-btn center-horizontally space-between align-center">
                                    <div
										key={count+quantity}
                                        className={`in-cart center-horizontally space-between align-center mob ${countCart ? "animate-in" : "animate-out"}`}>
                                        <img src={tabChef} alt=""/>
                                        <p>{count + quantity} {t('inTheCart').toUpperCase()}</p>
                                    </div>
                                    <BackBtn  pathTo={state?.state?.fromMenu && isMobile() ? `/menu-details/chef=${params.id}/restaurant=${params.id2}/menu=${Number(state.state.idMenu)}` : `/more-detail/chef=${params.id}/restaurant=${params.id2}`} />
                                </div>

                                <motion.div className="blc left">
                                    <motion.div className="blc-yellow" initial={{scale: 0}} animate={{scale: 1}}
                                                transition={{delay: 0.7, duration: 1}}>
                                        <motion.div className="blc-slid" initial={{scale: 0.2}} animate={{scale: 1}}
                                                    transition={{delay: 1, duration: 1}}>
                                            <Slider {...settings} className="dish-carousel">
												{plate?.plate?.imagePlats?.map((img, i) => {
													return (
														<div key={i} className="item">
															<div className="mask"/>
															<ImageContainer
																className={img.photos_versions.find((item) => item.type === "4:3")?.is_grey ? 'gray' : ""}
																src={img ? img.photos_versions.find((item) => item.type === "4:3")?.url as string : food2}
																alt=""/>
														</div>
													);
												})}
                                            </Slider>
                                        </motion.div>
                                    </motion.div>
                                </motion.div>
                                <div className="blc right">
                                    <motion.div
                                        className={` center-horizontally space-between align-center info ${isMobile() && "justify-center"}`}>
                                        <div style={{overflow: "hidden"}}>
                                            <motion.span initial={{height: 0, y: 600, opacity: 0}}
                                                         animate={{height: 30, y: 0, opacity: 1}}
                                                         transition={{duration: 1, delay: 1}}>{checkPlatName(plate?.plate?.categorie)}
                                            </motion.span>
                                        </div>
                                        <div
                                            className={`in-cart center-horizontally space-between align-center ${countCart ? "animate-in" : "animate-out"}`}>
                                            <img src={tabChef} alt=""/>
                                            <div className="text">{count + quantity} {t('inTheCart').toUpperCase()}</div>
                                        </div>
                                    </motion.div>

                                    <div style={{overflow: "hidden"}}>
                                        <motion.h1 initial={{minHeight: 0, y: 500, opacity: 0}}
                                                   animate={{minHeight: 80, y: 0, opacity: 1}}
                                                   transition={{duration: 1, delay: 1.2}}>{plate?.plate?.title}</motion.h1>
                                    </div>
                                    <div style={{overflow: "hidden"}}>
                                        <motion.p initial={{minHeight: 0, y: 500, opacity: 0}}
                                                  animate={{minHeight: 88, y: 0, opacity: 1}}
                                                  transition={{
													  duration: 1,
													  delay: 1.4
												  }}>{plate?.plate?.description}</motion.p>
                                    </div>
                                    <div style={{overflow: "hidden"}}>
                                        <motion.div initial={{height: 0, y: 500, opacity: 0}}
                                                    animate={{height: 88, y: 0, opacity: 1}}
                                                    transition={{duration: 1, delay: 1.6}}
                                                    className="blc-counter center-horizontally">


                                            <div className="blc-qte center-horizontally align-center">
                                                <p>{t('quantite_item') || STRINGS.HEADER.QTE}</p>
                                                <div className="counter center-horizontally space-between">
                                                    <div
                                                        onClick={() => count < 1 ? setCount(1) : setCount((x) => x - 1)}
                                                    >
                                                        <img alt="lessBtn" src={lessButton}/>
                                                    </div>
                                                    <p>{String(count < 1 ? 1 : count).padStart(2, '0')}</p>
                                                    <div
                                                        onClick={() => count < 1 ? setCount(() => 2) : setCount((x) => x + 1)}>
                                                        <img alt="plusBtn" src={plusButton}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <button onClick={checkRestaurant} className="btn-right">
                                                <img src={basket} alt="basket"/>
                                                <span>{t('buyButton') || STRINGS.HOME.BTN.BUY} - {currency(count < 1 ? plate?.plate?.price : plate?.plate?.price * count)}</span>
                                            </button>
                                        </motion.div>
                                    </div>
                                    <div style={{overflow: "hidden"}}>
                                        <motion.p className="date" initial={{height: 0, y: 200, opacity: 0}}
                                                  animate={{height: "auto", y: 0, opacity: 1}}
                                                  transition={{duration: 1, delay: 1.8}}>
											{t('guarantedDelivryFrom')}
                                            <span style={{fontSize:'11px'}}> {
												dateFinal?.length === 9 ? dateFinal?.substring(0,4): dateFinal?.substring(0,5)
											}</span>
                                            <button className="button-delivery-date"
                                                    onClick={() => isValidAdress && setIsVisibleCalendar(true)}/>
                                        </motion.p>
                                    </div>
                                </div>
                            </div>
							{
								isLoaded && <div className="container-desk">
                                    <motion.div className="grid-top" ref={inViewRef}>
                                        <motion.div animate={!isMobile() && !isAnimatedOne && anim_card1} className="card">
                                            <div className="title-card">
                                                <span>01</span>
                                                <h1>{t('detailOrderScreenPreparation') || STRINGS.DISH_PAGE.TITLE2}</h1>
                                            </div>
                                            <div className="card-content">
                                                    <div
                                                        className={`blc-video center-horizontally ${listVideos && listVideos.length === 1 && "justify-center border-bottom"}`}>
                                                        <Slider {...settings3} className="vid-carousel" >

														{listVideos && listVideos?.map((url, i) => {
															return (
																<div key={i}>
																	<video playsInline={true} loop={true}
																		// width={width > 1024 ? 2800 : 1300}
																		   style={{width:'100%',height:'100%',objectFit:'contain'}}
																		   controls={true}
																		   key={url.video
																			? url.video + "#t=0.001"
																			: "https://www.youtube.com/watch?v=dA0VGEbbw4g"}
																	>
																		<source  type={'video/mp4'} src={url.video
																			? url.video + "#t=0.001"
																			: "https://www.youtube.com/watch?v=dA0VGEbbw4g"}/>
																		<track  src={URL.createObjectURL(blobb)}
																				kind="captions"  default/>
																	</video>
																</div>
															);
														})}
                                                        </Slider>

													</div>
                                            </div>
                                        </motion.div>
                                        <motion.div animate={!isMobile() && !isAnimatedOne && anim_card2} className="card">
                                            <div className="title-card">
                                                <span>02</span>
                                                <h1>{t('ustensils') || STRINGS.DISH_PAGE.TITLE3}</h1>
                                            </div>
											{plate?.plate?.utensils && plate?.plate?.utensils.length > 0 &&
                                            <div className="card-content">
                                                <div
                                                    className={` center-horizontally align-center ustentils ${plate?.plate?.utensils.length < 3 && "justify-center"}`}>
													{plate?.plate?.utensils?.map((item) => {
														return (
															<div key={item.id} className="item-logo">
																<div
																	className="blc-img center-horizontally position-center">
																	<ImageContainer src={item.image} alt={item.title}/>
																</div>
																<p>{item.title}</p>
															</div>
														);
													})}
                                                </div>
                                            </div>}

                                        </motion.div>
                                        <motion.div animate={!isMobile() && !isAnimatedOne && anim_card3} className="card">
                                            <div className="title-card">
                                                <span>03</span>
                                                <h1>{t('composition') || STRINGS.DISH_PAGE.TITLE4}</h1>
                                            </div>
											{plate?.plate?.composition_package && plate?.plate?.composition_package?.length >0 && <div style={{height:'300px',overflow:"auto"}} className="card-content">
												{plate?.plate?.composition_package?.map((item) => {
													return (
														<div
															key={item.id}
															className="center-horizontally align-center composition-blc"

														>
															<div
																className="nmbr center-horizontally align-center justify-center">
																{item.number} <span>x</span>
															</div>
															<p>{item.name_colis}</p>
														</div>
													);
												})}
                                            </div>
											}

                                        </motion.div>
                                    </motion.div>
                                </div>
							}
                            <div className="container-desk">
								{plate?.plate?.preparation_stage?.length &&
                                <motion.div ref={inVewRef2} animate={!isMobile() && !isAnimatedTwo && anim_step}
                                            className={`blc-steps ${plate?.plate?.preparation_stage?.length === 2 && "two-item", plate?.plate?.preparation_stage?.length === 1 && "border-bottom"}`}>
									{plate?.plate?.preparation_stage?.map((item) => {
										return (
											<div key={item.id} className="step">
												<ImageContainer src={item.image} alt="food"/>
												<div className="step-infs">
													<p className="title-step">{item.title}</p>
													<p>{item.description}</p>
												</div>
											</div>
										);
									})}
                                </motion.div>}

                                <motion.div className="rows center-horizontally space-between" ref={inViewRef3}
                                            animate={!isMobile() && !isAnimatedThree && anim_rows}>
                                    <div className="title-card center-horizontally" style={{alignItems:'unset'}}>
                                        <span>04</span>
                                        <h1>{t('allergens') || STRINGS.DISH_PAGE.TITLE5}</h1>
                                    </div>
                                    <p style={{whiteSpace:'pre-line'}}>{plate?.plate?.allergenes ? plate?.plate?.allergenes : (t('noAllergenes') || "AUCUNE ALLERGÈNE")}</p>
                                </motion.div>
                                <motion.div className="rows center-horizontally space-between"
                                            animate={!isMobile() && !isAnimatedThree && anim_rows2}>
                                    <div className="title-card center-horizontally" style={{alignItems:'unset'}}>
                                        <span>05</span>
                                        <h1>{t('conservation') || STRINGS.DISH_PAGE.TITLE6}</h1>
                                    </div>
									{plate?.plate?.conservation_plate?.length ? <div style={{height:'400px',overflow:"auto"}} className="txt-blc">
										{plate?.plate?.conservation_plate?.map((item) => {
											return (
												<div key={item.id} className="center-horizontally align-center">
													<ImageContainer
														alt="icon-1"
														src={item.icon_conservation ? item.icon_conservation : icn1}
													/>
													<p>{item.name_conservation}</p>
												</div>
											);
										})}
									</div> : <div className="txt-blc"><p>{t('noAllergenes')}</p></div>}

                                </motion.div>
                                <div className="collapse">
                                    <Collapse defaultActiveKey={["1"]}>
                                        <Panel header={t('allergens') || STRINGS.DISH_PAGE.TITLE5} key="1">
                                            <p style={{whiteSpace:'pre-line'}}>{plate?.plate?.allergenes ? plate?.plate?.allergenes : (t('noAllergenes') || "AUCUNE ALLERGÈNE")}</p>
                                        </Panel>
                                        <Panel className="composition"
                                               header={t('conservation') || STRINGS.DISH_PAGE.TITLE6} key="2">
											{plate?.plate?.conservation_plate?.length ? (<> {plate?.plate?.conservation_plate?.map((item) => {
												return (
													<div
														key={item.id}
														className="center-horizontally align-center content-infs"
													>
														<div className="blc-icn center-horizontally position-center">
															<ImageContainer
																alt="icon-1"
																src={
																	item.icon_conservation ? item.icon_conservation : icn1
																}
															/>
														</div>
														<p>{item.name_conservation}</p>
													</div>
												);
											})} </>) : <div className="txt-blc"><p>{t('noConservation')}</p></div>}

                                        </Panel>
                                    </Collapse>
                                </div>
								{plate.same_chef.length > 1 && (
									<h1 className="title-menu">{t('otherPlatesSameChef') || STRINGS.DISH_PAGE.TITLE7}</h1>
								)}
                            </div>
							{plate?.same_chef && plate?.same_chef?.length > 1 && <div className="scroll-content">
                                <div className="blc-scroll">

									{plate?.same_chef?.filter((item) => item.id !== plate.plate.id).map((plat) => {
										return (
											<div key={plat.id}>
												<MenuItem
													item={{
														plates: plat,
														isCarousel: true,
														inCard: false,
														onClick: () => {
															navigateToPlat(plat)
														},
														isGray: plat.imagePlats[0].photos_versions.find((item) => isMobile() ? item.type === "1:1" : item.type === "4:3")?.is_grey

													}}
													image={plat.imagePlats[0].photos_versions.find((item) => isMobile() ? item.type === "1:1" : item.type === "4:3")?.url as string}
												/>
											</div>
										);
									})}
                                </div>
                            </div>}
                            <ModalWidget isVisible={isVisibleCalendar} onCancel={setIsVisibleCalendar}>
                                <CalendarMenuPlat
                                    onChange={selectDate}
                                    isAvailableWeekDays={selectedChef?.is_available_days_week}
                                    daysAvailable={selectedChef?.available_days_week}
                                    idResto={Number(params.id2)}
                                    defaultValue={dateFinal}
                                    isOrder={false}
                                />

                            </ModalWidget>
                            <Footer
                                removeQuantity={() => incrementDecrementItemCart(false, false)}
                                addQuantity={() => incrementDecrementItemCart(true, false)}
                                quantity={count + quantity}
                                onClick={checkRestaurant}
                                isHome={true}
                                isOrder={!state?.state?.fromMenu}
                                priceOrder={count || quantity < 1 ? plate?.plate?.price : plate?.plate?.price * quantity}
                            />
                            <ConfirmBottomSheet action={confirmBasket}
                                                title={t('newOrder') || STRINGS.TITLE_NEW_BASKET}
                                                text={t('yourBasketIncludesAnotherChefPlate') || STRINGS.TEXT_NEW_BASKET}
                                                open={isConfirmBottomSheet}
                                                yesText={t('newBasket') || STRINGS.NEW_BASKET}/>
                        </div>
                    </>
				}
			</>
		);
	}
;
/* eslint-enable max-lines-per-function */

export default DishPage;
