import React, {useEffect, useState} from "react";
import {STRINGS} from "../../../../../core/enums/strings";
import download from "../../../../../assets/icons/download.svg";
import {Order, OrderRecap} from "../../../../../core/models/Order";
import "./CommandeDetails.scss";
import {currency, getDateFromUnix, isMobile} from "../../../../../shared/utils/utils-functions";
import ReactPlayer from "react-player";
import casaron_icn from "../../../../../assets/icons/casaron.svg";
import {useTranslation} from "react-i18next";
import {getProfile} from "../../../../../shared/store/slices/Auth/AuthSlice";
import {getMyProfile} from "../../../../../shared/store/slices/Auth/AuthService";
import moment from "moment";

interface Item {
	item?: OrderRecap;
	onClickPreparation?: (isOpen: boolean) => void;
}

/* eslint-disable max-lines-per-function */
// eslint-disable-next-line sonarjs/cognitive-complexity
const CommandeDetails = (props: Item): JSX.Element => {
	const [language, setLanguage] = useState<any>()
	useEffect(() => {
		setLanguage(localStorage.getItem('SUPERNOVA_LANGUAGE'))
	}, [])
	const [videoIsVisible, setVideoIsVisible] = useState<boolean>(false);
	const [videoIsVisibleMobile, setVideoIsVisibleMobile] =
		useState<boolean>(false);
	const [statusClass, setStatusClass] = useState<string>("");
	const [status, setStatus] = useState<string>("");

	useEffect(() => {
		onSetStatus(props.item?.status);
	}, [props.item?.status]);

	// eslint-disable-next-line sonarjs/cognitive-complexity
	const onSetStatus = (status?: string) => {
		switch (status) {
			case "To_prepare":
				setStatus(t('toPrepare'));
				setStatusClass("to-prepare");
				break;
			case "Waiting":
				setStatus(t('statusWaiting'));
				setStatusClass("waiting");
				break;
			case "Sended":
				setStatus(t('statusSended'));
				setStatusClass("sended");
				break;
			case "Finish":
				setStatus(t('statusEnded'));
				setStatusClass("finish");
				break;
			case "Canceled":
				setStatus(t('statusCanceled'));
				setStatusClass("canceled");
				break;

			default:
				break;
		}
	};

	const DATE_FORMAT = "DD/MM/YYYY";
	const {t} = useTranslation()
	const [myDate, setMyDate] = useState<any>()
	useEffect(() => {
		getMyProfile().then((res: any) => {
			setMyDate(res)
		})
	}, [])
	return (
		<div className="order-details center-vertically">
			<div className="center-horizontally">
				<div className="date-delivery center-horizontally align-center justify-center">
					<p style={{position: 'relative', top: '2px'}}>
						{t('myOrderScreenItemDateOrder').toUpperCase()}
						&nbsp;
						{props?.item?.created_at &&
						getDateFromUnix(props?.item?.created_at, DATE_FORMAT)}
					</p>
				</div>
				<div
					className={`date-delivery center-horizontally align-center justify-center ${statusClass}`}
				>
					<img src={casaron_icn} alt="icn"/>
					<p style={{position: 'relative', top: '2px'}}>{status}</p>
				</div>
			</div>
			<div className="center-horizontally  container-details">
				<div className="center-vertically card">
					<div className="blc">
						<p className="title-card">
							{t('detailsOrderScreenOrder')}
						</p>
						<p>
							{props?.item?.created_at &&
							getDateFromUnix(props?.item?.created_at, DATE_FORMAT)}
						</p>
					</div>
					<div className="blc">
						<p className="title-card">
							{t('detailsOrderScreenDelivery')}
						</p>
						<p>
							{t('myOrderScreenItemDateDelivered').toUpperCase()}&nbsp;{" "}
							{props?.item?.deliver_at &&
							moment(props.item.deliver_at.toString().substring(0, 10).split('-').join('/')).format('DD/MM/YYYY')}
						</p>
					</div>
					<div className="blc">
						<p className="title-card">
							{t('detailsOrderScreenDeliveryAddress')}
						</p>
						<div className="center-vertically">
							<p>
								{
									props?.item?.address_delivry?.first_name + ' ' + props?.item?.address_delivry?.last_name
								}
							</p>
							<p>
								{props?.item?.address_delivry?.position?.address}
							</p>
							<p>
								{props?.item?.address_delivry?.pivot_code}
							</p>
							<p>
								{props?.item?.address_delivry?.postal_code + ' ' + props?.item?.address_delivry?.city}
							</p>
							<p>
								{props?.item?.address_delivry?.country}
							</p>

							<p>
								{props?.item?.address_delivry?.phone_receiver}
							</p>

						</div>
					</div>
					<div
						onClick={() => window.open(props.item?.invoice_pdf)}
						className="blc-download center-horizontally align-center justify-center"
					>
						<img src={download} alt="download"/>
						<p>{t('detailsOrderScreenDownloadInvoice')}</p>
					</div>
				</div>
				<div className="center-vertically card">
					<div className="blc-item center-vertically"
						 style={{height: !isMobile() ? '224px' : '', overflowY: !isMobile() ? 'scroll' : 'auto'}}>
						{props?.item?.menus?.map((item) => {
							return (
								<div
									key={item.id}
									className="item center-horizontally space-between"
								>
									<p>
										{item.quantite}X {item.menu.title}
									</p>
									<p>{currency(item?.quantite && item.menu.total_price * item?.quantite)}  </p>
								</div>
							);
						})}
						{props?.item?.plates?.map((item) => {
							return (
								<div
									key={item.id}
									className="item center-horizontally space-between"
								>
									<p>
										{item.quantite}X {item.plate.title}
									</p>
									<p>{currency(item?.quantite && item.plate.price * item?.quantite )}  </p>
								</div>
							);
						})}
						<div>
							<div>
								<div style={{bottom: '93px'}}
									 className="blc-bottom center-horizontally space-between top">
									<p>{t('shippingCost')}</p>
									{props?.item?.price_calculate_delivery === null ?
										<p> {t('orderSummaryTypeDelivery').toUpperCase()}</p> :
										<p>   {currency(props.item?.price_calculate_delivery)} </p>
									}
								</div>
								<div className="blc-bottom center-horizontally space-between">
									<p>{t('orderSummaryTotalText')}</p>
									{props?.item?.discounted_price === null ?
										<p> {currency(props.item?.total_price)} </p> :
										<p>   {currency(props?.item?.total_price && props?.item?.discounted_price && props?.item?.total_price - props?.item?.discounted_price + props.item?.price_calculate_delivery) }    </p>
									}
								</div>
							</div>
						</div>

						<div className="btns-mobile">
							<div
								onClick={() => window.open(props.item?.invoice_pdf)}
								className="blc-download center-horizontally align-center justify-center"
							>
								<img src={download} alt="download"/>
								<p>{t('detailsOrderScreenDownloadInvoice')}</p>
							</div>
						</div>
					</div>
				</div>
				{props.item?.video && (
					<div className="center-vertically card ml-5">
						<div className="center-vertically">
							<div className="video-text">
								{t('discoverVideo')}
							</div>
							<div className="">
								<ReactPlayer
									url={props.item?.video}
									width="100%"
									height="100%"
									style={{minWidth: "250px"}}
									playing={false}
									controls={true}
								/>
							</div>
						</div>
						<div>
							<button
								style={{position: "initial"}}
								onClick={() =>
									props.onClickPreparation && props.onClickPreparation(false)
								}
								className="btn-primary center-horizontally align-center justify-center mt-40"
							>
								{t('detailsOrderScreenSeePreparationButton') || STRINGS.ACCOUNT.MY_ORDERS.MY_ORDER_DETAILS.SEEPREPARATION}
							</button>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
/* eslint-enable max-lines-per-function */
export default CommandeDetails;
