import {store} from '../..';
import { APIS} from '../../../../core/enums/APIS'
import {Data} from '../../../../pages/my-account/contact/Contact';
import {Executor} from '../../Executor';
const BaseUrl = process.env.REACT_APP_API_ENDPOINT
import {
	getAbout,
	getOrder,
	getOrders,
	getPreparationList,
	setAddresses,
	setAvailableDaysState,
	setListClaimType,
	setProductsForClaim,
	setTotalOrders
} from './AccountSlice';
import {setIsBlockedNewsLetter} from "../Auth/AuthSlice";
import {ContactFormInterface} from "../../../../core/models/Contact";
import {Address} from '../../../../core/models/Address';
import {setDeliveryDate} from "../Cart/CartSlice";

export interface ProductClaim {
	id_menu: number | null,
	id_plate: number[]
}

export const getMyOrders = (page: number) => {
	return Executor({
		method: "get",
		url: BaseUrl + APIS.ORDERS.getMyOrders(page),
		successFun: (data: any) => {
			store.dispatch(getOrders(data.data))
			store.dispatch(setTotalOrders(data.data.total))
		},
	});
}
export const getMyOrder = (id: number) => {
	return Executor({
		method: "get",
		url: BaseUrl + APIS.ORDERS.getOrder(id),
		successFun: (data: any) => {
			store.dispatch(getOrder(data.data))

		},
	});
}

export const getPreparationOrder = (id: number) => {
	return Executor({
		method: "get",
		url: BaseUrl + APIS.ORDERS.getOrderPreparation(id),
		successFun: (data: any) => {
			store.dispatch(getPreparationList(data.data))

		},
	});
}

export const getAddressesList = () => {
	return Executor({
		method: "get",
		url: BaseUrl + APIS.ADDRESSES.addAddressOrGetAll,
		successFun: (data) => store.dispatch(setAddresses(data.data))
	})
}

export const updateCreateAddress = (data: Address, id?: number) => {
	return Executor({
		method: id ? 'put' : 'post',
		url: `${BaseUrl}${APIS.ADDRESSES.addAddressOrGetAll}${!!id ? '/' + id : ''}`,
		data: {
			...data,
			first_name: data.first_name,
			last_name: data.last_name,
			pivot_code: data.pivot_code ? data.pivot_code : '',
			// short_name: data.state && data.state?.length > 5 ? '' : data.state,
			state: data.state && data.state?.length > 5 ? data.state.toString().substring(0,4).toUpperCase() : data.state,
			state_name:data.state_name
		}
	})
}

export const setAsDefaultAddress = (id: number) => {
	return Executor({
		method: 'put',
		url: `${BaseUrl}${APIS.ADDRESSES.setAsDefault(id)}`,
	})
}

export const deleteAddress = (id: number) => {
	return Executor({
		method: "delete",
		url: BaseUrl + APIS.ADDRESSES.deleteAddress(id)
	});
}

export const getProductsForClaim = (id: number) => {
	return Executor({
		method: 'get',
		url: `${BaseUrl}${APIS.ORDERS.getProductsClaim(id)}`,
		successFun: (data) => store.dispatch(setProductsForClaim(data.data))
	})
}

export const Available = (id: number, data?: any) => {

	return Executor({
		method: 'post',
		data: data,
		url: `${BaseUrl}${APIS.ORDERS.getAvailableDays(id)}`,
		successFun: (data) => {
			store.dispatch(setAvailableDaysState(data.data),
			)
		}
	})
}
export const getRecapAvailableDate = (id: number, deliver: any,pickUp:any,service_code?:any) => {

	return Executor({
		method: 'post',
		isSilent: false,
		data: {delivery_date:deliver,pickup_date:pickUp,service_code:service_code},
		url: `${BaseUrl}${APIS.ORDERS.getAvailableRecapDay(id)}`,
	})
}

export const getListClaim = () => {
	return Executor({
		method: 'get',
		url: `${BaseUrl}${APIS.ORDERS.getListClaimTypes}`,
		successFun: (data) => store.dispatch(setListClaimType(data.data))
	})
}
export const getListAbout = () => {
	return Executor({
		method: 'get',
		url: `${BaseUrl}${APIS.ABOUT.getAboutData}`,
		successFun: (data) => store.dispatch(getAbout(data.data))
	})
}
export const contactUs = (data: ContactFormInterface) => {
	return Executor({
		method: 'post',
		url: BaseUrl + APIS.CONTACT.ContactUs,
		data,
	})
}

export const getListCommandToClaim = () => {
	return Executor({
		method: 'get',
		url: BaseUrl + APIS.ORDERS.getListCommandToClaim,
	});
}

export const sendClaim = (data: { id_command?: number, id_type?: number, products: ProductClaim[] }) => {
	return Executor({
		method: 'post',
		url: BaseUrl + APIS.HELP.postClaim,
		data,
	});
}

export const enableNewsLetter = (data: { active: boolean }) => {
	return Executor({
		method: 'put',
		url: BaseUrl + APIS.NEWS_LETTER,
		data,
		successFun: () => {
			store.dispatch(setIsBlockedNewsLetter(data.active));
		}
	})
}

export const publicNewsLetter = (data: { email:  string| undefined, active: boolean }) => {
	return Executor({
		method: 'post',
		url: BaseUrl + APIS.PUBLIC_NEWSLETTER,
		data,
	})
}
export const getDateSystem = () => {
	return Executor({
		method: 'get',
		url: BaseUrl + APIS.SYSTEM_DATE,
		isSilent:true
	})
}
