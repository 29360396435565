import React, {useEffect, useState} from "react";
import "./DetailsMenu.scss";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import MenuItem from "../../components/menu-item/MenuItem";
import {STRINGS} from "../../core/enums/strings";
import {useLocation, useNavigate} from "react-router-dom";
import {CategorieModel, MenuModel} from "../../core/models/Menu";
import {useDispatch, useSelector} from "react-redux";
import {RootState, store} from "../../shared/store";
import {
	getChefMenuDetails,
	getChefPlatDetails,
	getChefPlateDetails,
} from "../../shared/store/slices/Chefs/ChefsSlice";
import {getMenuChef} from "../../shared/store/slices/Chefs/ChefsService";
import BottomSheetDish from "../../components/bottomsheet-dish/BottomSheetDish";
import {
	addMenutoCart,
	createBasket,
	increDecrItemCart,

} from "../../shared/store/slices/Cart/CartService";
import BackBtn from "../../components/back-btn/BackBtn";
import tabChef from "../../assets/icons/icn-chef-basket.svg";
import {motion} from "framer-motion";
import {
	convertDate, currency,
	isMobile,
} from "../../shared/utils/utils-functions";
import CalendarWidget from "../../components/calendar-widget/CalendarWidget";
import ModalWidget from "../../components/modal-widget/ModalWidget";
import {MenuInCart} from "../../core/models/Cart";
import {PATHS} from "../../core/enums/paths";
import plusButton from "../../assets/icons/plus-btn.svg";
import lessButton from "../../assets/icons/moins.svg";
import ConfirmBottomSheet from "../../components/confirm-bottom-sheet/ConfirmBottomSheet";
import debounce from "lodash.debounce";
import {useTranslation} from "react-i18next";
import {Available} from "../../shared/store/slices/Account/AccountService";
import {setRestaurantId} from "../../shared/store/slices/Cart/CartSlice";
import ImageContainer from "../../components/imageContainer/imageContainer";
import moment from "moment";
import {useParams} from "react-router";
import CalendarMenuPlat from "../../components/calendarMenuPlat/CalendarMenuPlat";

const settings = {
	dots: false,
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: true,
	speed: 5000,
	autoplaySpeed: 3000,
	cssEase: "linear",
	responsive: [
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 1.5,
			},
		},
	],
};
const debounceCallback = debounce((callback: (show: boolean) => void) => {
	callback(false);
}, 2000);
/* eslint-disable max-lines-per-function */
// eslint-disable-next-line sonarjs/cognitive-complexity
const DetailsMenu = (): JSX.Element => {
	const menu = useSelector((state: RootState) => state.chefs.menuChef);
	const cart = useSelector((state: RootState) => state.cart);
	const days = useSelector((state: RootState) => state.account.availableDays)
	const [count, setCount] = useState<number>(1);
	const [open, setOpen] = useState(false);


	const state = useLocation();
	const history = useNavigate();
	const params = useParams()
	const dispatch = useDispatch();
	const [isVisibleCalendar, setIsVisibleCalendar] = useState<boolean>(false);
	const [basketMenu, setBasketMenu] = useState<MenuInCart | null>(null);
	const [countCart, setCountCart] = useState<boolean>(false);
	const [quantity, setQuantity] = useState<number>(0);
	const showCart = () => {
		setCountCart(true);
		debounceCallback(setCountCart);
	};
	const isLoggedIn = useSelector(
		(state: RootState) => state.authentication.isLoggedIn
	);
	const [isValidAdress,setIsValidAdress]=useState<any>(false)

	// eslint-disable-next-line sonarjs/cognitive-complexity
	useEffect(()=>{
		Available(Number(params.id2),{is_from_basket_details_screen:false}).then((res:any)=>{
			if (res.data.first_day_available) {
				setDateFinal(moment(res.data.infos_first_day_available.delivery_date).format('DD/MM/YYYY'))
				setIsValidAdress(true)
			}else{
				Available(Number(params.id2),{
					month: new Date().getMonth()+2,
					year: new Date().getMonth()+1 === 12 ?  new Date().getFullYear()+1 : new Date().getFullYear() ,
					is_from_basket_details_screen:false
				}).then((res:any)=>{
					if (res.data.first_day_available) {
						setDateFinal(moment(res.data.infos_first_day_available.delivery_date).format('DD/MM/YYYY'))
						setIsValidAdress(true)
					}else{
						setIsValidAdress(false)

					}
				})
			}

		})
	},[params.id2])
	const selectedChef = useSelector(
		(state: RootState) => state.cart.selectedChef
	);
	useEffect(() => {
		getData();

		return () => {
			dispatch(
				getChefMenuDetails({
					menu: {
						id: 0,
						title: "",
						description: "",
						total_price: 0,
						cover_picture: [],
						desserts: [],
						plates: [],
						entries: [],
						quantity: 0,
						id_product_basket: 0,
					},
					same_chef: [],
				})
			);
		};
	}, [params.id2]);

	const getData = async () => {
		await getMenuChef(
			Number(params.id),
			Number(params.id2),
			Number(params.id3)
		)
		.then((res) => {
			setCount(0);
		})
		.catch(() => history(PATHS.HOME));
	};

	const navigateToMenu = (Menu: MenuModel) => {
		if (Menu.id !== menu.menu.id) {
			setIsLoaded(false);
			getMenuChef(Menu.chef_id, Menu.restaurant_id, Menu.id)
			.then(async (res) => {
				history(`/menu-details/chef=${params.id}/restaurant=${params.id2}/menu=${Menu.id}`, {
					state: {
						idChef: Menu.chef_id,
						idResto: Menu.restaurant_id,
						idMenu: Menu.id,
					},
				});
				window.scrollTo({top: 0, left: 1000, behavior: "smooth"});
			})
			.catch(() => history(PATHS.HOME));
		} else window.scrollTo({top: 0, left: 1000, behavior: "auto"});
	};

	const onOpenMenu = (plat: CategorieModel) => {
		dispatch(getChefPlatDetails(plat));

		if (!isMobile()) {
			setOpen(true);
			document.body.style.overflow = "hidden";
		} else {
			dispatch(getChefPlateDetails({plate: plat, same_chef: []}));
			history(`/dish-details/chef=${params.id}/restaurant=${params.id2}/dish=${plat?.id}`, {
				state: {
					idChef: plat.chef_id,
					idResto: plat.restaurant_id,
					idPlate: plat.id,
					idMenu:params.id3,
					fromMenu: true,
				},
			});
		}
	};
	const onCloseMenu = () => {
		setOpen(false);
		document.body.style.overflow = "auto";
	};
	const [isConfirmBottomSheet, setIsConfirmBottomSheet] =
		useState<boolean>(false);
	const checkSameRestaurant = async () => {
		console.log(cart.basket.restaurant?.id)
		if (!cart?.basket?.restaurant?.id || cart?.basket?.restaurant?.id === Number(params.id2)) {
			addMenu().then(()=>{
				setQuantity(0)
			});
		} else {
			setIsConfirmBottomSheet(true);
		}
	};

	// eslint-disable-next-line sonarjs/cognitive-complexity
	const addMenu = async (override = false) => {
		if (!localStorage.getItem("basket_id")) {
			await createBasket().then();
		}
		postAddMenu(override).then();
		getData();
	};

	const confirmAction = (isYes: boolean) => {
		isYes && addMenu(true);
		setIsConfirmBottomSheet(false);
	};

	const postAddMenu = async (override = false) => {
		await addMenutoCart(
			{
				menu_id: Number(params.id3),
				restaurant_id: Number(params.id2),
				quantite: count === 0 ? count + 1 + quantity : count + quantity,
				override,
				deliver_at: convertDate(dateFinal),
			},
			isLoggedIn
		).then(() => {
			showCart();
			setCount(1);
			setQuantity(0)
		});
	};
	const incrementDecrementItemCart = async (
		increment: boolean,
		isMenu: boolean
	) => {
		if (
			count === 0 &&
			cart?.basket?.restaurant &&
			Number(params.id2) !== cart?.basket?.restaurant?.id
		) {
			setIsConfirmBottomSheet(true);
		} else {
			basketMenu?.id &&
			(await increDecrItemCart(
				basketMenu.id,
				{increment},
				isMenu,
				isLoggedIn
			).then(() => showCart()));
		}
	};
	const [dateFinal, setDateFinal] = useState<any>()

	const selectDate = (value: string) => {
		// updateDeliveryDate(value, false).then();
		setIsVisibleCalendar(false);
	};
	const [totalPlates, setTotalPlates] = useState<number>();
	// eslint-disable-next-line sonarjs/cognitive-complexity
	useEffect(() => {
		menu?.same_chef.filter((item) => menu.menu.id === item.id);
		setIsLoaded(true);
		setTotalPlates(
			menu?.menu?.plates.length +
			(menu?.menu?.entries ? menu?.menu?.entries.length : 0) +
			(menu?.menu?.desserts ? menu?.menu?.desserts.length : 0)
		);
		if (!isMobile()) {
			menu?.same_chef?.length < 3
				? (settings.slidesToShow = 1.5)
				: menu?.same_chef?.length < 6
					? (settings.slidesToShow = 2.5)
					: (settings.slidesToShow = 4.5);
		} else {
			settings.responsive[0].settings.slidesToShow =
				menu?.same_chef?.length === 1 ? 1 : 1.5;
		}
	}, [menu]);
	const [isLoaded, setIsLoaded] = useState<boolean>(true);
	useEffect(() => {
		const item = cart.basket.menus.find(
			(item) => item.menu.id === menu.menu.id
		);
		item && setBasketMenu(item);
		item ? setQuantity(item.quantite ? item.quantite : 0) : setQuantity(0);
		setCount(0);
		if (!menu) {
			history(PATHS.HOME);
		}
	}, [cart, menu]);
	const backRouteData = useSelector(
		(state: RootState) => state.root.backRootData
	);
	const goToChefDetails = () => {
		history("/more-detail/" + backRouteData?.itemResto.id, {
			state: {item: backRouteData?.item, itemResto: backRouteData?.itemResto, id: state.state.idChef},
		});
	};
	const myDate = useSelector((state: RootState) => state.cart.basketDate)
	const getSrc=(item:any)=>{
		return Math.abs(window.innerWidth - window.innerHeight) > 500  ?  '16:9' :   '4:3'
	}

	const {t} = useTranslation()
	return (
		<>
			<motion.div
				initial={{y: 0}}
				animate={{
					y: "100vh",
					transitionEnd: {
						display: "none",
					},
				}}
				transition={{type: "spring", delay: 1, duration: 2}}
				className="first-l center-vertically position-center"
			/>
			<motion.div
				className="second-l"
				initial={{y: "-100vh", height: "-100vh"}}
				animate={{
					height: 0,
					y: "100vh",
					transitionEnd: {
						display: "none",
					},
				}}
				transition={{type: "spring", delay: 1, duration: 2}}
			/>
			{isLoaded && menu?.menu && (
				<div className="details-menu">
					<Header classname={'home-header'} isHome={true} isHomeActive={true}>
						<div
							className={`in-cart center-horizontally space-between align-center  ${
								countCart ? "animate-in" : "animate-out"
							}`}
						>
							<img src={tabChef} alt=""/>
							<p>{count + quantity} AU PANIER</p>
						</div>
					</Header>

					<div className="menu-content">
						<div className="blc-top">
							<div className="section-top">
								<div className="container-btn">
									<BackBtn pathTo={`/more-detail/chef=${params.id}/restaurant=${params.id2}`} />
									<div
										className={`in-cart center-horizontally space-between align-center  ${
											countCart ? "animate-in" : "animate-out"
										}`}
									>
										<img src={tabChef} alt=""/>
										<p>{count + quantity} {t('inTheCart').toUpperCase()}</p>
									</div>
								</div>
								<div className="mask"/>
								<motion.div
									className="blc-yellow"
									initial={{scale: 0}}
									animate={{scale: 1}}
									transition={{delay: 0.7, duration: 1}}
								>
									<img
										className={menu?.menu?.cover_picture && menu?.menu?.cover_picture.find(
											(item) =>  isMobile() ? item.type === "3:4" : item.type === getSrc(item)
										)?.is_grey ? "gray" : ""}
										src={
											(menu?.menu?.cover_picture &&
												menu?.menu?.cover_picture.find(
													(item) =>  isMobile() ? item.type === "3:4" : item.type === getSrc(item)
												)?.url) || ''
										}
										alt=""
									/>

								</motion.div>
								<div className="blc-absolute center-vertically">
									<motion.span
										initial={{y: 500, opacity: 0}}
										animate={{y: 0, opacity: 1}}
										transition={{
											duration: 2,
											delay: 0.2,
										}}
									>
										{
											`${t('menuIn')} ${totalPlates} ${t(totalPlates && totalPlates < 2 ? (t('dishMoreDetatail')) : (t('searchScreenTypeDish')))}`
										}
									</motion.span>
									<motion.h1
										initial={{y: 500, opacity: 0}}
										animate={{y: 0, opacity: 1}}
										transition={{
											duration: 2,
											delay: 0.4,
										}}
									>
										{menu?.menu?.title}
									</motion.h1>
									<motion.p
										initial={{y: 500, opacity: 0}}
										animate={{y: 0, opacity: 1}}
										transition={{
											duration: 2,
											delay: 0.6,
										}}
									>
										{menu?.menu?.description}
									</motion.p>
									<motion.div
										initial={{y: 500, opacity: 0}}
										animate={{y: 0, opacity: 1}}
										transition={{duration: 2, delay: 0.8}}
										className="blc-counter center-horizontally"
									>
										<div className="blc-qte center-horizontally align-center">
											<p>{t("quantite_item") || STRINGS.HEADER.QTE}</p>
											<div className="counter center-horizontally space-between">
												<div
													onClick={() =>
														count < 1 ? setCount(1) : setCount((x) => x - 1)
													}
												>
													<img alt="lessBtn" src={lessButton}/>
												</div>
												<p>{String(count < 1 ? 1 : count).padStart(2, "0")}</p>
												<div
													onClick={() =>
														count < 1
															? setCount((x) => 2)
															: setCount((x) => x + 1)
													}
												>
													<img alt="lessBtn" src={plusButton}/>
												</div>
											</div>
										</div>
										<button onClick={checkSameRestaurant} className="btn-right">
                      <span>
                        {t('buyButton') || STRINGS.HOME.BTN.BUY} -{" "}
						  {currency(count < 1
							  ? menu?.menu?.total_price
							  : menu?.menu?.total_price * count)}
                      </span>
										</button>
									</motion.div>

									<motion.p
										className="date"
										initial={{y: 500, opacity: 0}}
										animate={{y: 0, opacity: 1}}
										transition={{duration: 2, delay: 1}}
									>
										{t('guarantedDelivryFrom')}{" "}
										<span>

                      {
						  // cart?.basket?.deliver_at
						  dateFinal?.length === 9 ? dateFinal?.substring(0, 4) : dateFinal?.substring(0, 5)
					  }
                    </span>
										<button
											className="button-delivery-date"
											onClick={() => isValidAdress && setIsVisibleCalendar(true)}
										/>
									</motion.p>
								</div>
							</div>
							<div className="blc-absolute mobile center-vertically space-between">
								<div className="position-relative">
									<motion.span
										initial={{y: 100, opacity: 0}}
										animate={{y: 0, opacity: 1}}
										transition={{
											duration: 0.5,
											delay: 0.2,
										}}
									>
										{
											`${t('menuIn')} ${totalPlates} ${t(totalPlates && totalPlates < 2 ? (t('dishMoreDetatail')) : (t('searchScreenTypeDish')))}`
										}
									</motion.span>
									<motion.h1
										initial={{y: 100, opacity: 0}}
										animate={{y: 0, opacity: 1}}
										transition={{
											duration: 0.5,
											delay: 0.4,
										}}
									>
										{menu?.menu?.title}
									</motion.h1>
									<motion.p
										initial={{y: 100, opacity: 0}}
										animate={{y: 0, opacity: 1}}
										transition={{
											duration: 0.5,
											delay: 0.6,
										}}
									>
										{menu?.menu?.description}
									</motion.p>
								</div>
							</div>
						</div>
						<div className="menu-included">
							<div className="container-desk">
								<h1 className="title-menu first">
									{t('includedPlates') || STRINGS.DETAILS_MENU.TITLE_MENU}
								</h1>
								<div className="menus">
									{menu?.menu?.entries &&
									menu?.menu?.entries?.length > 0 &&
									menu?.menu?.entries?.map((entrie) => {
										return (
											<div key={entrie.id}>
												<MenuItem
													item={{
														plates: entrie,
														inCard: false,
														isMenu: true,
														open: () => onOpenMenu(entrie),
														isPlateFromMenu: true,
														isGray: entrie.imagePlats[0].photos_versions.find(
															(item) => item.type === "4:3"
														)?.is_grey
													}}
													image={
														entrie.imagePlats[0].photos_versions.find(
															(item) => item.type === "4:3"
														)?.url
													}
													key={entrie.id}
												/>
											</div>
										);
									})}
									{menu?.menu?.plates?.length > 0 &&
									menu?.menu?.plates?.map((item) => {
										return (
											<div key={item.id}>
												<MenuItem
													item={{
														plates: item,
														inCard: false,
														isMenu: true,
														open: () => onOpenMenu(item),
														isPlateFromMenu: true,
														isGray: item.imagePlats[0].photos_versions.find(
															(item) => item.type === "4:3"
														)?.is_grey
													}}
													image={
														item.imagePlats[0].photos_versions.find(
															(item) => item.type === "4:3"
														)?.url
													}
													key={item.id}
												/>
											</div>
										);
									})}
									{menu?.menu?.desserts &&
									menu?.menu?.desserts?.length > 0 &&
									menu?.menu?.desserts?.map((dessert) => {
										return (
											<div key={dessert.id}>
												<MenuItem
													item={{
														plates: dessert,
														inCard: false,
														isMenu: true,
														open: () => onOpenMenu(dessert),
														isPlateFromMenu: true,
														isGray: dessert.imagePlats[0].photos_versions.find(
															(item) => item.type === "4:3"
														)?.is_grey
													}}
													image={
														dessert.imagePlats[0].photos_versions.find(
															(item) => item.type === "4:3"
														)?.url
													}
													key={dessert.id}
												/>
											</div>
										);
									})}
								</div>
								{menu?.same_chef.length > 1 && (
									<h1 className="title-menu">{t('otherMenusSameChef') || STRINGS.DISH_PAGE.TITLE8}</h1>
								)}
								<div className="scroll-content no-padding">
									<div className="blc-scroll">
										{menu?.same_chef && menu?.same_chef.length > 1 && (
											<>
												{menu.same_chef
												?.filter((item) => item.id !== menu.menu.id)
												.map((menu) => {
													return (
														<div key={menu.id}>
															<MenuItem
																item={{
																	menus: menu,
																	inCard: false,
																	isCarousel: true,
																	onClick: () => {
																		navigateToMenu(menu);
																	},
																	isGray: menu?.cover_picture?.find(
																		(item) => item.type === "4:3"
																	)?.is_grey
																}}
																key={menu.id}
															/>
														</div>
													);
												})}
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<ModalWidget
						isVisible={isVisibleCalendar}
						onCancel={setIsVisibleCalendar}
					>
						<CalendarMenuPlat
							onChange={selectDate}
							isAvailableWeekDays={selectedChef?.is_available_days_week}
							daysAvailable={selectedChef?.available_days_week}
							idResto={Number(params.id2)}
							defaultValue={dateFinal}
							params={params.id2}
							isOrder={false}
						/>
					</ModalWidget>
					<Footer
						// eslint-disable-next-line sonarjs/no-identical-functions
						removeQuantity={() => incrementDecrementItemCart(false, true)}
						addQuantity={() => incrementDecrementItemCart(true, true)}
						quantity={count + quantity}
						onClick={checkSameRestaurant}
						isHome={true}
						isOrder={true}
						priceOrder={
							count || quantity < 1
								? menu?.menu?.total_price
								: menu?.menu?.total_price * quantity
						}
					/>
					<div className={` blc-bottomsheet ${open ? "overlay" : "hide"}`}>
						<BottomSheetDish onClose={onCloseMenu}/>
					</div>
					<ConfirmBottomSheet
						action={confirmAction}
						title={STRINGS.TITLE_NEW_BASKET}
						text={STRINGS.TEXT_NEW_BASKET}
						open={isConfirmBottomSheet}
						yesText={STRINGS.NEW_BASKET}
					/>
				</div>
			)}
		</>
	);
};
/* eslint-enable max-lines-per-function */

export default DetailsMenu;
