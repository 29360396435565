import React from "react";
import { useSelector } from "react-redux";
import "./Preparation.scss";
import PreparationItemOrder from "../../../../../components/item-preparation/PreparationItemOrder";
import { PATHS } from "../../../../../core/enums/paths";
import {
  Preparation,
  PreparationItem,
} from "../../../../../core/models/PreparationItem";
import { RootState } from "../../../../../shared/store";
import MenuItem from "../../../../../components/menu-item/MenuItem";
import { useNavigate } from "react-router";
import { isMobile } from "../../../../../shared/utils/utils-functions";

interface Item {
  item: Preparation;
}
/* eslint-disable max-lines-per-function */
const PreparationList = (props: any) => {
  const history = useNavigate();
  const order = useSelector((state: RootState) => state.account.Order);

  const redirectToPlate = (item: any) => {

    history(`/dish-details/chef=${props?.item?.chef_Command?.id}/restaurant=${props?.item?.restaurant?.id}/dish=${item.id}`, {
      state: {
        // idChef: order?.restaurant?.chef_restaurant?.id,
        // idResto: order?.restaurant?.id,
        // idPlate: id,
        // fromCommande: true
      },
    });
  };
  const redirectToMenu = (item: any) => {
      history(`/menu-details/chef=${props?.item?.chef_Command?.id}/restaurant=${props?.item?.restaurant?.id}/menu=${item?.id}`, {
      // state: {
      //   idChef: order?.restaurant?.chef_restaurant?.id,
      //   idResto: order?.restaurant?.id,
      //   idMenu: id,
      //   fromCommande: true
      // },
    });
  };
  return (
    <div className="preparation">
      <div className="web-version center-vertically">
        {props?.item?.list_plate?.map((item:any) => {
          return (
            <PreparationItemOrder
              key={item.id}
              item={{ plate: item, onClick: () => redirectToPlate(item) }}
            />
          );
        })}
        {props?.item?.list_menu?.map((item:any) => {
          return (
            <PreparationItemOrder
              key={item.id}
              item={{ menu: item, onClick: () => redirectToMenu(item) }}
            />
          );
        })}
      </div>
      <div className="mobile-version center-vertically">
        <div className="menus">
          {props?.item?.list_plate?.map((plate:any) => {
            return (
              <div key={plate.id}>
                <MenuItem
                  item={{
                    plates: plate,
                    inCard: false,
                    isMenu: true,
                    onClick: () => redirectToPlate(plate),
                    descMenu: false,
                    isGray:plate?.imagePlats[0].photos_versions.find((item:any) => isMobile() ? item.type === "1:1" : item.type === "4:3")?.is_grey
                  }}
                  key={plate.id}
                  image={plate?.imagePlats[0].photos_versions.find((item:any) => isMobile() ? item.type === "1:1" : item.type === "4:3")?.url as string}
                />
              </div>
            );
          })}
          {props?.item?.list_menu?.map((menu:any) => {
            return (
              <div key={menu.id}>
                <MenuItem
                  item={{
                    menus: menu,
                    inCard: false,
                    isMenu: true,
                    onClick: () => redirectToMenu(menu),
                    descMenu: true,
                    isGray:menu?.cover_picture?.find(
                      (item:any) => item.type === "4:3"
                    )?.is_grey
                  }}
                  image={menu?.cover_picture?.find(
                    (item:any) => item.type === "4:3"
                  )?.url as string}
                  key={menu.id}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
/* eslint-enable max-lines-per-function */
export default PreparationList;
