import React from 'react';
import {useNavigate} from 'react-router-dom';
import BackBtn from '../back-btn/BackBtn';
import "./HeaderAccount.scss";
import {isMobile} from "../../shared/utils/utils-functions";

interface Props {
	title: string;
	backRoute?: boolean;
}

const HeaderAccount = (Props: Props) => {
	const history = useNavigate();

	return (
		<div className="blc-title center-horizontally align-center">
			{
				!window.location.pathname.includes('/politique-support') && !window.location.pathname.includes('/cgu-support')&& !window.location.pathname.includes('contact-support')&&
				<div className="blc-btn">
					{
						Props?.backRoute ?
							<button onClick={() => history("/account")} className="back-btn"/>
							: <BackBtn/>
					}

				</div>
			}
			<p style={isMobile() && window.location.pathname.includes('/help-checkbox') ? {top:'20px'}:{}}
			   className="title">{Props.title}</p>
		</div>
	);
};

export default HeaderAccount;
