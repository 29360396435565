import React, { useEffect, useState } from "react";
import { STRINGS } from "../../../../core/enums/strings";
import { MoreOutlined } from "@ant-design/icons";
import "./OrderDetails.scss";
import { useLocation, useNavigate } from "react-router-dom";
import CommandeDetails from "./commande-details/CommandeDetails";
import { useSelector } from "react-redux";
import { RootState } from "../../../../shared/store";
import BackBtn from "../../../../components/back-btn/BackBtn";
import Footer from "../../../../components/footer/Footer";
import {getDateFromUnix, isMobile} from "../../../../shared/utils/utils-functions";
import { getMyOrder, getPreparationOrder } from "../../../../shared/store/slices/Account/AccountService";
import PreparationList from "./preparation/Preparation";
import {t} from "i18next";
import moment from "moment";

/* eslint-disable max-lines-per-function */
// eslint-disable-next-line sonarjs/cognitive-complexity
const OrderDetails = (): JSX.Element => {

  const history = useNavigate();
  const [displayDetails, setDisplayDetails] = useState<boolean>(true);
  const state = useLocation();
  const onDisplayDetails = () => {
    setDisplayDetails(!displayDetails);
  };

  const preparation = useSelector(
    (state: RootState) => state.account.Preparation
  );
  const order = useSelector((state: RootState) => state.account.Order);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await getMyOrder(state?.state?.orderId).then();
    await getPreparationOrder(state?.state?.orderId).then()
  };

  return (
    <div className="order-details-content center-vertically">
      <div className="container-btn center-horizontally align-center">
        <BackBtn pathTo={!isMobile() ? '/account/orders' : '/orders'} />
        <p>{t('return').toUpperCase()}</p>
      </div>
      <div className="header-container center-horizontally space-between">
        <div className="center-horizontally align-center">
          <BackBtn />
          <div className="blc-1">
            <p>{order?.restaurant?.name}</p>
            <p>{order?.restaurant?.chef_restaurant?.firstName + ' ' +order?.restaurant?.chef_restaurant?.lastName }</p>
          </div>
        </div>
        <div className="blc-2 center-horizontally align-center">
          <div className="delivery-date-section center-horizontally align-center">
            <p>
              {t('detailsOrderDelivryIn').toUpperCase()}
              &nbsp;
              {order?.deliver_at && moment(order.deliver_at.toString().substring(0,10).split('-').join('/')).format('DD/MM/YYYY')}
            </p>
          </div>
          <div className="help-btn center-horizontally center-horizontally" onClick={() =>
              !isMobile()
                  ? history("/account/help",{state:{order_id:order?.id}})
                  : history("/help-suggestion",{state:{order_id:order?.id}})
                }>
            <p style={{position:'relative',top:'1px'}}>
              {t('detailsOrderScreenHelp')}
            </p>
            <MoreOutlined color="black" className="icon" />
          </div>
        </div>
      </div>
      <div className="tab-container center-horizontally align-center">
        <p
          onClick={onDisplayDetails}
          className={displayDetails ? "active" : undefined}
        >
          {t('detailsOrderScreenTitle')}
        </p>
        <p
          onClick={onDisplayDetails}
          className={!displayDetails ? "active" : undefined}
        >
          {t('detailOrderScreenPreparation')}
        </p>
      </div>
      {displayDetails ? (
        <CommandeDetails item={order} onClickPreparation={setDisplayDetails}/>
      ) : (
        <PreparationList item={{...preparation , ...order}}   />
      )}
      {isMobile() && <Footer quantity={0} priceOrder={0} isHome={true}/>}

    </div>
  );
};
/* eslint-enable max-lines-per-function */

export default OrderDetails;
