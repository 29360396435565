export const PATHS = {
    SECONDARY :{
      CONTACT : '/contact-support',
      CGU : '/cgu-support',
      POLITIQUE :'/politique-support',
        MENTION:'/mentions-legales',
        CONDITION_VENTE:'/conditions-general-de-vente',
        SUSTAINABILITY:'/sustainability'
    },
    MAINTENANCE: '/maintenance',
    HOME: "/home",
    CATEGORIES:'/all-categories',
    LOGIN:'/login',
    LOGIN_MAIL: '/login-mail',
    REGISTER:'/register',
    FORGET_PASSWORD:'/forget-password',
    MORE_DETAILS:'/more-detail/:slug1=:id/:slug2=:id2',
    // ALL_CHEFS:'/all-chef/:slug/:id',
    ALL_CHEFS:'/all-chef/',
    CHEF_DETAILS:'/details-chef/:slug=:id',
    NEW_CHEF:'/new-chef/:slug/:id',
    DISH_DETAILS:'/dish-details/:slug=:id/:slug2=:id2/:slug3=:id3',
    MENU_DETAILS:'/menu-details/:slug=:id/:slug2=:id2/:slug3=:id3',
    CHECKOUT:'/checkout/:id',
    RECHERCHE:'search',
    EXPLORE: {
        MAIN: 'explore',
        CHEF: 'chefs',
        CATEGORY_DETAIL: 'kitchen/categoryDetail/:slug/:id',
        KITCHEN: 'kitchen',
        LOCATION_SEARCH_DESKTOP_CHEF:'/explore/chefs',
        LOCATION_SEARCH_DESKTOP_KITCHEN:'/explore/kitchen',
        LOCATION_SEARCH_MOBILE:'/search'
    },
    MY_ACCOUNT:{
        MAIN:'account',
        MY_ORDERS:'orders',
        MY_PROFILE:'profile',
        CONTACT:'contact',
        ABOUT: {
            MAIN:'about',
            MAIL: 'mail',
            SMS: 'sms',
            CGV: 'cgv'
        },
        MY_ADDRESSES:'addresses',
        ADD_EDIT_ADDRESS:'add-edit-address',
        NEWSLETTERS:'newsletters-setting',
        ORDER_DETAILS:':id',
        HELP:'help',
        CLAIM_PRODUCTS: '/help-checkbox',
        SUCCESSHELP:'success'
    },
    MY_ORDERS_WEB: '/account/orders',
    //paths for mobile //
    MY_ORDERS_MOBILE:'/orders',
    ORDERS_DETAIL_MOBILE: '/order-details/:id',
    HELP_SUGGESTION_MOBILE:'/help-suggestion',
    HELP_CHECKBOX_MOBILE: '/help-checkbox',
    HELP_SUCCESS_MOBILE: '/help-success',
    EDIT_PROFILE_MOBILE:'/profile',
    EDIT_ADDRESSES_MOBILE:'/address',
    ADD_ADDRESSES_MOBILE:'/add-address',
    NEWSLETTERS_MOBILE:'/newsletters',
    ABOUT_MOBILE:'/about',
    CONTACT_MOBILE:'/contact',
    CGV_MOBILE: '/cgv',
    MAP_MOBILE:'/map',
    ACCOUNT : '/account',
    SHOW_MORE: {
        NAVIGATE:'/explore/kitchen/categoryDetail',

    }
    //end paths mobile//
}
