import React, {useEffect, useState} from "react";
import {STRINGS} from "../../core/enums/strings";
import {InputItem, rule} from "../../core/models/Input";
import {EyeOutlined, EyeInvisibleOutlined} from "@ant-design/icons";
import france from "../../assets/icons/france.svg";
import "./Input.scss";
import {errorMessage} from "../../shared/utils/utils-functions";
import InputCode from "../input-code/inputCode";
import {Menu, Dropdown, Space} from 'antd';
import {getListCountry} from "../../shared/store/slices/Auth/AuthService";
import {useTranslation} from "react-i18next";
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";

interface Item {
	item: InputItem;
	action?: () => void;
	onShow?: () => void;
	isShow?: boolean;
	eyes?: boolean;
	isSubmit?: boolean;
	isDisabled?: boolean;
	isHover?: boolean;
	maxLength?: number


}

const ShowPassword = (Props: Item): JSX.Element => {
	const show = () => {
		Props.onShow && Props.onShow();
	};
	return (
		<>
			{Props.eyes && Props.isShow ? (
				<EyeOutlined onClick={show} className="eyes"/>
			) : Props.eyes && !Props.isShow ? (
				<EyeInvisibleOutlined onClick={show} className="eyes"/>
			) : null}
		</>
	);
};
const EditInput = (Props: Item) => {

	const {t} = useTranslation()
	return (
		<>
			{Props.item.isEditable && (
				<p
					onClick={!Props.item.isOnEdit ? Props.action : undefined}
					className={Props.item.isOnEdit ? "is-clicked" : "edit"}
				>
					{t('edit').toUpperCase() || STRINGS.EDIT}
				</p>
			)}
		</>
	);
};

const StyleLabelInput = (itemType: string) => {
	return itemType === "tel" ? "label-phone " : "";
};
const StyleInput = (Props: Item) => {
	return Props.item.type === "tel" ? "input-tel" : Props.eyes ? "pw-input" : "";
};

/* eslint-disable max-lines-per-function */

// eslint-disable-next-line sonarjs/cognitive-complexity
const Input = (Props: Item): JSX.Element => {
	const [error, setError] = useState<string>("");
	const [value, setValue] = useState<string | number | undefined>(Props.item?.value);
	// eslint-disable-next-line sonarjs/cognitive-complexity
	const validateInput = (value: any) => {
		const error = getErrors(value);
		Props.item?.rules && setError(error || "");
		Props.item.onChange && Props.item.onChange({target: {value}});
		Props.item?.isValid &&
		Props.item.isValid(error ? error?.length === 0 : true);
		setValue(value);
	};
	const ChangeList = (value: any) => {
		Props.item.onClick && Props.item.onClick({target: {value}});
	}

	const {t} = useTranslation()
	const getErrors = (value: any) => {
		return Props?.item?.rules
			? errorMessage(value, Props.item.rules, t)
			: "";
	}

	useEffect(() => {
		Props.item?.rules &&
		Props.isSubmit &&
		setError(errorMessage(Props.item?.value ?? "", Props.item.rules, t) || "");
	}, [Props.isSubmit]);
	useEffect(() => {
		if (Props.item?.value && Props.item?.value.toString().length > 0) {
			const error = getErrors(Props.item.value)
			Props.item?.isValid && Props.item.isValid(error ? error?.length === 0 : true);
		}
		setValue(Props.item?.value);
	}, [Props.item?.value]);

	return (
		<div className={`input-item ${(Props.isDisabled ? " isDisabled" : "")}`}>
			<div className="input-blc">
				<EditInput
					action={Props.action}
					eyes={Props.eyes}
					isShow={Props.isShow}
					onShow={Props.onShow}
					item={Props.item}
				/>
				<ShowPassword
					item={Props.item}
					eyes={Props.eyes}
					isShow={Props.isShow}
					onShow={Props.onShow}
				/>
				{Props.item.type === "tel" && (
					<div className="center-horizontally align-center flag-container">

					</div>
				)}

				{Props.item.type==='phone' && (
					<>
						<div>
							<PhoneInput
								name={'phone'}
								international={true}
								withCountryCallingCode={true}
								useNationalFormatForDefaultCountryValue={true}
								defaultCountry={'RU'}
								placeholder="Enter phone number"
								onChange={(e:any)=>{
									console.log('s')}}
								/>
						</div>
					</>
				)}
				{Props.item.name !== "code" ? (
					<>
						<input
							onChange={(e) => validateInput(e.target.value)}
							onClick={(e) => ChangeList(e)}
							value={value}
							type={Props.item.type}
							name={Props.item.name}
							id={Props.item.name}
							placeholder={Props.item.label}
							className={Props.item.name ! == t('pickNumberPlaceholder') ?  "display":(Props.item.isOnEdit ? "is-on-edit" : StyleInput(Props)) }
							disabled={Props.isDisabled}
							autoComplete="off"
							readOnly={Props.item.readonly}
							maxLength={Props.maxLength}
						/>

						<label
							className={`${StyleLabelInput(Props.item.type)}${
								Props.item.rules && error.length > 0 ? "input-item-error" : ""
							}`} style={{color: Props?.isHover ? "black" : ""}}
							htmlFor={Props.item.name}
						>
							{Props.item?.label && Props.item.label?.length > 0 ? Props.item.label : Props.item.name}
						</label>
					</>
				) : (
					<InputCode
						length={4}
						onComplete={(code) => validateInput(code)}
					/>
				)}
			</div>
			{  Props.item.name !== 'Pays' && Props.item.name !== 'région' && Props.item.name !== 'ville' && Props.item.name !== 'code postal' && Props.item?.rules && error.length > 0 && (
				<label className="error-msg">{error}</label>
			)}

			{ Props.item.name === 'région' && Props?.item?.value?.toString().length === 0  && (
				<label className="error-msg">{error}</label>
			)}
			{ Props.item.name === 'Pays' && Props?.item?.value?.toString().length === 0  && (
				<label className="error-msg">{error}</label>
			)}
			{ Props.item.name === 'ville' && Props?.item?.value?.toString().length === 0  && (
				<label className="error-msg">{error}</label>
			)}
			{ Props.item.name === 'code postal' && Props?.item?.value?.toString().length === 0  && (
				<label className="error-msg">{error}</label>
			)}
		</div>
	);
};
/* eslint-enable max-lines-per-function */
export default Input;
