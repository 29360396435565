import React, {useEffect, useRef} from "react";
import './kitchen.scss'
import BlockContainer, {BlockContainerInterface} from "./components/blockContainer/blockContainer";
import starIcon from '../../../assets/icons/explore/star.svg'

import Stars from "./components/stars/stars";
import Trends from "./components/trends/trends";
import DesiresAndDiets from "./components/desiresAndDiets/desiresAndDiets";
import Destinations from "./components/destinations/destinations";
import {getKitchenData} from "../../../shared/store/slices/explore/exploreService";
import {setKitchenList} from "../../../shared/store/slices/explore/exploreSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../shared/store";
import {PATHS} from "../../../core/enums/paths";
import {useTranslation} from "react-i18next";
import {isMobile} from "../../../shared/utils/utils-functions";

// eslint-disable-next-line max-lines-per-function,sonarjs/cognitive-complexity
const Kitchen: React.FC = () => {
  const {t} = useTranslation()
  const [blockData, setBlockData] = React.useState<{ [key: string]: BlockContainerInterface }>({
    stars: {
      id: 1,
      name: t('exploreKitchenScreenStarsText'),
      title: t('exploreKitchenScreenStarsText'),
      icon: starIcon,
      content: <Stars/>,
      noPadding: true
    },
  })

  const dispatch = useDispatch();
  const listKitchen = useSelector((state: RootState) => state.explore.kitchenList)

  React.useEffect(() => {
    getKitchenData().then((res) => {
      dispatch(setKitchenList(res.data))
    })
  }, [])

  const getDisplaySettings = (item: any): { showAllCondition: boolean, content: JSX.Element } => {
    if (item?.type_of_display?.trim().toUpperCase() === 'VERTICAL'.trim().toUpperCase())
      return {content: <Trends data={item}/>, showAllCondition: item.count >3};
    if (item?.type_of_display?.trim().toUpperCase() === 'HORIZONTAL'.trim().toUpperCase())
      return {content: <DesiresAndDiets data={item}/>, showAllCondition: item.count >5};
    // if(item?.type_of_display?.trim().toUpperCase() === 'GRID'.trim().toUpperCase())
    return {content: <Destinations  showAll={item} data={item}/>, showAllCondition: !isMobile() ? item.count >12 : item.count >=6};
  }
  return (
    <div className={'kitchenMainContainer'}>
      <BlockContainer content={<Stars/>} config={blockData['stars']} index={0}/>
      {
        // eslint-disable-next-line max-lines-per-function
        listKitchen?.map((item: any, index: number) => {
          return (
            <BlockContainer
              key={item.id}
              config={item}
              showAll={getDisplaySettings(item).showAllCondition}
              showMoreLink={PATHS.SHOW_MORE.NAVIGATE + '/' + item.name + '/'}
              content={getDisplaySettings(item).content}
              index={index + 1}
            />
          )
        })
      }
      {listKitchen?.length === 0 && <div style={{height:'150px'}}/>}
    </div>
  )
}


export default Kitchen;
