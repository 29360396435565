import React, {useEffect, useState} from "react";
import "./MyAccountForm.scss";
import {InputItem} from "../../../../core/models/Input";
import {STRINGS} from "../../../../core/enums/strings";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../shared/store";
import Input from "../../../../components/input/Input";
import Footer from "../../../../components/footer/Footer";
import BackBtn from "../../../../components/back-btn/BackBtn";
import {
	editCountry,
	editIdentity,
	editPassword,
	editPhoneStepOne,
	editPhoneStepTwo, getListCountry,
	getMyProfile,
} from "../../../../shared/store/slices/Auth/AuthService";
import {errorMessage, isMobile} from "../../../../shared/utils/utils-functions";
import check from ".././../../../assets/icons/check.svg";
import checkValid from ".././../../../assets/icons/check-valid.svg";
import {
	checkAtLeastLength,
	checkDigit,
	checkSpecialCharacter,
	checkUpper,
} from "../../../../shared/Validator/validation-service";
import {toast} from "react-toastify";
import OrderBottomsheet from "../../../../components/order-bottomsheet/OrderBottomsheet";
import img from "../../../../assets/images/chef2.jpg";
import {newCountryId} from "../../../../shared/store/slices/Auth/AuthSlice";
import {useTranslation} from "react-i18next";
import ImageContainer from "../../../../components/imageContainer/imageContainer";
import PhoneInput, {isValidPhoneNumber, parsePhoneNumber} from "react-phone-number-input";

interface Error {
	id: number;
	message: string;
	icon: string;
	className: string;
}

interface SelectedInputs {
	id: number;
	title: string;
	inputs: InputItem[];
	buttonText: string;
}

const error = "MOT DE PASSE NON SIMILAIRE"


/* eslint-disable max-lines-per-function */
// eslint-disable-next-line sonarjs/cognitive-complexity
const MyAccountForm = () => {
	useEffect(()=>{
		getMyProfile().then((res) => {
			if (res?.data.firstName=== undefined ||res?.data.lastName===undefined|| res?.data.firstName=== null ||res?.data.lastName===null|| res?.data.firstName=== ''||res?.data.lastName==='' || res?.data.firstName === 'null' || res?.data.lastName ==='null' || res?.data.firstName === 'Null' || res?.data.lastName ==='Null' ){
				editIdentity({firstName:' ', lastName: ' '}).then()
			}
		})
	},[])
	const country = useSelector((state: RootState) => state.authentication.countryId)
	const [selectedInputs, setSelectedInputs] = useState<SelectedInputs>({
		id: 1,
		title: "",
		inputs: [],
		buttonText: '',
	});
	const isPassword = useSelector(
		(state: RootState) => state.authentication.loggedInUser
	);

	const profile = useSelector(
		(state: RootState) => state.authentication.profile
	);
	const [isError, setIsError] = useState<boolean>(true);
	const [errorsPassword, setErrorsPassword] = useState<Error[]>([
		{
			id: 1,
			message: STRINGS.REGISTER.ERRORS.ERR_1,
			icon: check,
			className: "",
		},
		{
			id: 2,
			message: STRINGS.REGISTER.ERRORS.ERR_2,
			icon: check,
			className: "",
		},
		{
			id: 3,
			message: STRINGS.REGISTER.ERRORS.ERR_3,
			icon: check,
			className: "",
		},
		{
			id: 4,
			message: STRINGS.REGISTER.ERRORS.ERR_4,
			icon: check,
			className: "",
		},

	]);
	useEffect(() => {
		getData();
	}, []);
	const {t} = useTranslation()
	// eslint-disable-next-line sonarjs/cognitive-complexity
	const getData = async () => {
		await getMyProfile().then((res) => {
			setInputs([
				{
					id: 1,
					type: "text",
					label: t('profileScreenIdentify') || STRINGS.ACCOUNT.EDITPROFILE.IDENTITY_TXT,
					value: ( res?.data.firstName === undefined || res.data.lastName === undefined || res?.data.firstName === null || res.data.lastName === null || res?.data.firstName === '' || res.data.lastName === '' || res?.data.firstName !== 'null' || res?.data.lastName !=='null' || res?.data.lastName !=='Null' || res?.data.firstName !=='Null') ? res?.data?.firstName + " " + res?.data?.lastName : ' '   ,
					isEditable: true,
					isOnEdit: false,
					name: "identity",
					readonly: true,
				},
				{
					id: 2,
					type: "phone2",
					label: t('phoneScreenInputLabel') || STRINGS.ACCOUNT.EDITPROFILE.PHONE_NUMBER,
					value: res?.data?.phone ,
					isEditable: true,
					isOnEdit: false,
					name: "phone",
					readonly: true,
				},
				{
					id: 3,
					type: "email",
					label: t('emailScreenPlaceholderText') || STRINGS.ACCOUNT.EDITPROFILE.ADDRESS_MAIL,
					value: res?.data?.email,
					isEditable: false,
					isOnEdit: false,
					name: "email",
					readonly: true,
				},
				{
					id: 4,
					type: "password",
					label: t('passwordScreenStepTwo') || STRINGS.ACCOUNT.EDITPROFILE.PASSWORD2,
					value: "0000000",
					isEditable: true,
					isOnEdit: false,
					name: "password",
					readonly: true,
				},
				{
					id: 5,
					type: "select",
					label: t('profileScreenCountry') || STRINGS.ACCOUNT.EDITPROFILE.COUNTRY,
					value: res?.data?.country?.name,
					isEditable: true,
					isOnEdit: false,
					name: "country",
					readonly: true,
				},
			]);
		});
	};
	const [data, setData] = useState<any>({
		code:''
	});
	const [isSubmit, setIsSubmit] = useState<boolean>(false);
	const [name, setName] = useState<string>("");
	const [errorMessagePassword, setErrorMessagePassword] = useState<string>("");
	const [isCode, setIsCode] = useState<boolean>(false);

	const [inputs, setInputs] = useState<InputItem[]>([
		{
			id: 1,
			type: "text",
			label: t('profileScreenIdentify') || STRINGS.ACCOUNT.EDITPROFILE.IDENTITY,
			value: profile?.firstName + " " + profile?.lastName,
			isEditable: true,
			isOnEdit: false,
			name: "identity",
		},
		{
			id: 2,
			type: "phone2",
			label: t('phoneScreenInputLabel') || STRINGS.ACCOUNT.EDITPROFILE.PHONE_NUMBER,
			value: profile?.phone,
			isEditable: true,
			isOnEdit: false,
			name: "phone2",
		},
		{
			id: 3,
			type: "email",
			label: t('emailScreenPlaceholderText') || STRINGS.ACCOUNT.EDITPROFILE.ADDRESS_MAIL,
			value: profile?.email,
			isEditable: false,
			isOnEdit: false,
			name: "email",
		},
		{
			id: 4,
			type: "password",
			label: t('passwordScreenStepTwo') || STRINGS.ACCOUNT.EDITPROFILE.PASSWORD2,
			value: "0000000",
			isEditable: true,
			isOnEdit: false,
			name: "password",
		},
		{
			id: 5,
			type: "select",
			label: t('profileScreenCountry') || STRINGS.ACCOUNT.EDITPROFILE.COUNTRY,
			value: profile?.country?.name,
			isEditable: true,
			isOnEdit: false,
			name: "country",
			readonly: true,
		},
	]);
	const model: SelectedInputs[] = [
		{
			id: 1,
			title: `${t('yours')} <span>${t('profileScreenIdentify')}</span}` || STRINGS.ACCOUNT.EDITPROFILE.IDENTITY_TITLE,
			inputs: [
				{
					id: 1,
					type: "text",
					label: t('personnelInformationFirstNameInput') || STRINGS.ACCOUNT.EDITPROFILE.FIRST_NAME,
					value: profile?.firstName,
					rules: [{name: "required"}],
					name: "firstName",
				},
				{
					id: 2,
					type: "text",
					label: t('personnelInformationLastNameInput') || STRINGS.ACCOUNT.EDITPROFILE.LAST_NAME,
					value: profile?.lastName,
					rules: [{name: "required"}],
					name: "lastName",
				},

			],
			buttonText: t('updateButton') || STRINGS.UPDATE,
		},
		{
			id: 2,
			title: `${t('passwordScreenUpdateHeaderStepOne')} ${t('phoneScreenTitleStepTwo')}  <span> ${t('phoneScreenTitleStepThree')} ${t('phoneScreenTitleStepFour')} </span>`,
			inputs: [
				{
					// eslint-disable-next-line sonarjs/no-duplicate-string
					type: t('pickNumberPlaceholder'),
					name: t('pickNumberPlaceholder'),
				},
			],
			buttonText: t('phoneScreenButtonUpdated') || STRINGS.UPDATE_PHONE_NUMBER,
		},
		{
			id: 3,
			title: `${t('codeConfirmScreenStepOne')} <span>${t('codeConfirmScreenStepTwo')}</span> ${t('codeConfirmScreenStepThree')}`,
			inputs: [
				{
					id: 2,
					label: t('phoneScreenVerificationCode') || STRINGS.REGISTER.INPUTS.VERIFICATION_CODE,
					rules: [{name: "verification-code"}],
					type: "number",
					name: "code",
					value: "",
				},
			],
			buttonText: t('validate') || STRINGS.VALIDATE
		},

		{
			id: 4,
			title: `${t('passwordScreenUpdateHeaderStepOne')} <span>${t('passwordScreenStepTwo')}</span>`,
			inputs: [
				{
					id: 1,
					type: "password",
					label: t('passwordScreenUpdateOldPassword') || STRINGS.ACCOUNT.EDITPROFILE.OLD_PASSWORD,
					value: "",
					rules: [{name: "required"}],
					name: "password",
				},
				{
					id: 2,
					type: "password",
					label: t('passwordScreenInputPassword') || STRINGS.ACCOUNT.EDITPROFILE.NEW_PASSWORD,
					value: "",
					name: "new_password",
				},
				{
					id: 3,
					type: "password",
					label: t('passwordScreenInputConfirmPassword') || STRINGS.REGISTER.INPUTS.CONFIRM_PASSWORD,
					value: "",
					name: "confirmPassword",
				},
			],
			buttonText: t('passwordScreenUpdateButtonPassword') || STRINGS.UPDATE_PASSWORD
		},
		{
			id: 5,
			title: `${t('passwordScreenUpdateHeaderStepOne')} <span>${t('profileScreenCountry')}</span>`,
			inputs: [
				{
					id: 1,
					type: "select",
					label: t('profileScreenCountry') || STRINGS.ACCOUNT.EDITPROFILE.COUNTRY,
					value: profile?.country?.name,
					rules: [{name: "required"}],
					name: "country",
				},
			],
			buttonText: t('countryScreenBtn') || STRINGS.UPDATE_COUNTRY

		},
	];
	useEffect(() => {
		selectedInputs.inputs.length > 0 &&
		selectedInputs.inputs.map((item) => {
			setData(
				Object.assign(data, {
					[item.name]:
						item.name === "phone" ? item?.value?.toString() : item?.value,
				})
			);
		});
	}, [selectedInputs]);
	const update = (id: number) => {
		const indexToUpdate = errorsPassword.findIndex((todo) => todo.id === id);
		const updatedTodos = [...errorsPassword]; // creates a copy of the array
		updatedTodos[indexToUpdate].className = "valid";
		updatedTodos[indexToUpdate].icon = checkValid;
		setErrorsPassword(updatedTodos);
	};
	const Reset = (id: number) => {
		const indexToUpdate = errorsPassword.findIndex((todo) => todo.id === id);
		const updatedTodos = [...errorsPassword]; // creates a copy of the array
		updatedTodos[indexToUpdate].className = "";
		updatedTodos[indexToUpdate].icon = check;
		setErrorsPassword(updatedTodos);

	};

	// eslint-disable-next-line sonarjs/cognitive-complexity
	const onValueChange = (value: any, key: string) => {
		setData({...data,code:value})
		if (key === 'new_password') {
			if (checkAtLeastLength(value, 8)) {
				update(1);
			} else {
				Reset(1);
			}

			if (checkUpper(value)) {
				update(2)
			} else {
				Reset(2);
			}

			if (checkSpecialCharacter(value)) {
				update(3);
			} else {
				Reset(3);
			}
			if (checkDigit(value)) {
				update(4);
			} else {
				Reset(4);
			}
		}
		key === "confirmPassword" &&
		data?.new_password?.length > 0 &&
		setErrorMessagePassword(
			data?.new_password !== value ? error : ""
		);
		key === "new_password" &&
		data?.confirmPassword?.length > 0 &&
		setErrorMessagePassword(
			data?.confirmPassword !== value ? error : ""
		);
		setData({...data, [key]: key === "code" ? Number(value) : value});
	};
	const toggleIsOnEdit = (id: number) => {
		const updatedList = inputs?.map((item) => {
			if (item.id !== id) {
				return {...item, isOnEdit: !item.isOnEdit};
			}
			return item;
		});

		setInputs(updatedList);
	};
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const edit = (id: number, name: string) => {
		setName(name);
		setIsEdit(true);
		toggleIsOnEdit(id);
		setSelectedInputs(model[id - 1]);
	};

	const editMyPassword = async () => {
		data.new_password !== data.confirmPassword
			? setErrorMessagePassword(t('NotSamePassword'))
			: isError
				? toast.error(t('passwordScreenErrorToast'))
				:
				await editPassword(data).then((res) => {
					const updatedList = inputs?.map((item) => {
						return {...item, isOnEdit: false};
					});

					setInputs(updatedList);
					setErrorMessagePassword("");
					setName("");
					setIsEdit(false)
					setErrorsPassword([
						{
							id: 1,
							message: STRINGS.REGISTER.ERRORS.ERR_1,
							icon: check,
							className: "",
						},
						{
							id: 2,
							message: STRINGS.REGISTER.ERRORS.ERR_2,
							icon: check,
							className: "",
						},
						{
							id: 3,
							message: STRINGS.REGISTER.ERRORS.ERR_3,
							icon: check,
							className: "",
						},
						{
							id: 4,
							message: STRINGS.REGISTER.ERRORS.ERR_4,
							icon: check,
							className: "",
						},
					])
				})
	};
	const [errorCountryMessage,setErrorCountryMessage]=useState('')


	const editMyCountry = async () => {
		if (!isSelectCountry || errorCountryMessage.length>0){
			setErrorCountryMessage(t('messageSlectCountry').toUpperCase())
		}else {
			editCountry({
				country: country?.id
			}).then((res: any) => {
				const Country = inputs?.find((item) => item.id === 5);
				Country &&
				(Country.value = res.data?.country.name);
				setInputs([...inputs]);
				const updatedList = inputs?.map((item) => {
					return {...item, isOnEdit: false};
				});

				setInputs(updatedList);
				setIsEdit(false);
				setClicked(300)
				setErrorCountryMessage('')
				setIsSelectCountry(false)
				setFilteredList(ListCountry?.filter((item: any) => item?.name?.trim()?.toLowerCase()?.includes(country?.name?.trim()?.toLowerCase() || '')))
				setErrorCountryMessage('')

			});
		}

	}

	const editMyIdentity = async () => {
		await editIdentity(data)
		.then((res) => {
			const fullName = inputs?.find((item) => item.id === 1);
			fullName &&
			(fullName.value = res.data?.firstName + " " + res.data?.lastName);
			setInputs([...inputs]);
			const updatedList = inputs?.map((item) => {
				return {...item, isOnEdit: false};
			});

			setInputs(updatedList);
			setIsEdit(false);
			setName("");
		});
	};
	const editMyPhoneStepTwo = async () => {
		const countryObject = parsePhoneNumber(data?.phone)

		await editPhoneStepTwo({...data, phone: data.phone,code:data.code}).then((res) => {
			if (res.success) {
				const phone = inputs.find((item) => item.id === 2);
				phone && (phone.value = res.data.phone);
				setInputs([...inputs]);
				const updatedList = inputs?.map((item) => {
					return {...item, isOnEdit: false};
				});

				setInputs(updatedList);
				setIsEdit(false);
				setIsCode(false);
				setName("");
				getmyCountryId(countryObject?.country)
				setData({...data,code:''})

			}
		}).catch((err) => {
			if (!err.data.success) {
				setName("code");
				setIsCode(true);
			}
		});
	};
	const editMyPhoneStepOne = async () => {
		if (!validtyPhone){
			setErrorMessagePhone(t('invalidPhoneNumber'))
		}else{


		await editPhoneStepOne({...data, phone: data.phone}).then((res) => {
			if (res.success) {
				setIsEdit(false);
				setName("code");
				setIsCode(true);
			}
		});
		}
	};
	// eslint-disable-next-line sonarjs/cognitive-complexity
	const checkCurrentInputValidity = () => {
		const errors = selectedInputs?.inputs?.map((item) => {
			return item.rules && (!item.value || item.rules)
				? errorMessage(
					data && item.name in data ? data[item.name] : "",
					item.rules,
					t
				)
				: null;
		});
		return errors?.filter((item) => !!item).length === 0

	};
	const isValid = checkCurrentInputValidity();

	// eslint-disable-next-line sonarjs/cognitive-complexity
	const validate = (name: string) => {
		if (isValid) {
			setIsSubmit(false);
			switch (name) {
				case "identity":
					editMyIdentity();
					break;
				case "phone":
					editMyPhoneStepOne();
					break;
				case "code":
					editMyPhoneStepTwo();
					break;
				case "password":
					editMyPassword();
					break;
				case "country":
					editMyCountry();
					break;
				default:
					break;
			}
		} else {
			setIsSubmit(true);
		}
	};
	useEffect(() => {
		const result = errorsPassword.some((i) => {
			return i.icon === check
		})
		!result ? setIsError(false) : setIsError(true)
	}, [errorsPassword])

	const [showPassword, setShowPassword] = useState(false);
	const [newPassword, setNewPassword] = useState<boolean>(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const onIsShow = (label?: string): boolean => {
		if (label === STRINGS.ACCOUNT.EDITPROFILE.OLD_PASSWORD) {
			return showPassword
		} else if (label === STRINGS.ACCOUNT.EDITPROFILE.NEW_PASSWORD) {
			return newPassword
		} else {
			return showConfirmPassword
		}
	}
	const onShowPassword = (label?: string) => {
		switch (label) {
			case STRINGS.ACCOUNT.EDITPROFILE.OLD_PASSWORD:
				setShowPassword(!showPassword);
				break;
			case STRINGS.ACCOUNT.EDITPROFILE.NEW_PASSWORD:
				setNewPassword(!newPassword);
				break;
			case STRINGS.REGISTER.INPUTS.CONFIRM_PASSWORD:
				setShowConfirmPassword(!showConfirmPassword);
				break;
			default:
				break;
		}
	};
	const [isSelectCountry, setIsSelectCountry] = useState(false)
	const [itemSelected,setItemSelected]=useState<any>()

	// const[ListCountryFilter,setListCountryFilter]=useState<any>([ListCountry])
	useEffect(() => {
		getListCountry().then((res: any) => {
			setListCountry(res.data)
		})
	}, [])
	const [open, setOpen] = useState<boolean>(false);
	const [openRecap, setOpenRecap] = useState<boolean>(false);
	const isFromRecap = useSelector((state: RootState) => state.root.isBackFromAddress);

	const onCloseMenu = () => {
		setOpen(false);
		document.body.style.overflow = "auto";
	};
	useEffect(() => {
		isFromRecap && setOpenRecap(false);
	}, [isFromRecap]);
	useEffect(() => {
		getListCountry().then((res: any) => {
			setListCountry(res.data)
			setFilteredList(res.data?.filter((item: any) => item?.name?.trim()?.toLowerCase()?.includes(profile?.country?.name?.trim()?.toLowerCase() || '')))
		})

	}, [])
	const [ListCountry, setListCountry] = useState<Array<any>>([])
	const [filteredList, setFilteredList] = useState<Array<any>>([...ListCountry]);
	const [clicked,setClicked]=useState<any>()
    const selectCountry=(item:any,index:any)=>{
		setClicked(index)
		setIsSelectCountry(true)
		setErrorCountryMessage('')
		dispatch(newCountryId(item))
		setItemSelected(item)
	}
	const filterList = (value: string) => {
		if (!value){
			setErrorCountryMessage(t('messageSlectCountry').toUpperCase())
		}else {
			setFilteredList(ListCountry?.filter((item: any) => item?.name?.trim()?.toLowerCase()?.includes(value?.trim()?.toLowerCase())))
		}
	}
	useEffect(() => {
		setFilteredList(filteredList)
	}, [filteredList])

	const dispatch = useDispatch()
	const [validtyPhone,setValidtyPhone]=useState(false)
	const [errorMessagePhone, setErrorMessagePhone] = useState<string>("");

	const checkValidty=(value:any)=>{
		setErrorMessagePhone('')
		if (value && isValidPhoneNumber(value) ){
			setData({...data, phone: value})
			setValidtyPhone(true)
		}else{
			setValidtyPhone(false)
		}
	}
	const  getmyCountryId= async (value:any)=>{
		const countryId = ListCountry?.filter((item: any) => item?.isoCode?.trim()?.toLowerCase()?.includes(value?.trim()?.toLowerCase() || ''))
		if (!profile.country){
			editCountry({
				country:countryId[0].id
			})
		}
	}
// eslint-disable-next-line sonarjs/cognitive-complexity
	const returnToRecap =()=>{
			// getMyProfile().then((result:any)=>{
			// 	Available(result.data.basket.restaurant.id,{is_from_basket_details_screen:true}).then((res:any)=>{
			// 		updateDeliveryDate(moment(res.data.first_day_available).format('DD/MM/YYYY'),true).then(()=>{
						return isMobile() ? setOpenRecap(true) : setOpen(true)
			//
			// 		})
			// 	})
			// })
	}
	return (
		<div className="my-account">
			<div className="blc-top" style={{top:"10px",position:'relative'}}>
				<BackBtn/>
			</div>

			{isEdit ? (
				<>
					<div className="title-profile">
						<div className="blc-top">
							<h2 dangerouslySetInnerHTML={{__html: selectedInputs.title}}/>
						</div>
					</div>
					<div className="inputs-container center-vertically">
						{/* eslint-disable-next-line sonarjs/cognitive-complexity */}
						{/* eslint-disable-next-line sonarjs/cognitive-complexity */}
						{selectedInputs?.inputs?.map((item) => {
							return (
								<>

									{item.type !== 'select' ?
										<Input
											key={item.id}
											item={{
												...item,
												type: (item.name === "password" && !showPassword) ||
												(item.name === "confirmPassword" && !showConfirmPassword) || (item.name === "new_password" && !newPassword) ? "password" : item.name === "phone" ? "tel" : "text",
												onChange: (e) => onValueChange(e.target.value, item.name),
											}}
											isSubmit={isSubmit}
											eyes={item.type === "password" ? true : false}
											onShow={() => onShowPassword(item.label)}
											isShow={onIsShow(item.label)}
										/> :

										<>
											<Input
												item={{
													label: STRINGS.REGISTER.INPUTS.COUNTRY,
													name: "country",
													type: "text",
													onChange: (e) => filterList(e.target.value),
													rules: [{name: "required"}, {name: "country"}],
													value: isSelectCountry ? itemSelected?.name : profile?.country?.name,

												}}

											/>
											{errorCountryMessage.length >0 && <p style={{alignSelf:'baseline',color:'var(--red-color)',position:'relative',bottom:'17px',fontSize:'10px'}}> {errorCountryMessage}</p>}
											<div className={'listCountry'}>
												{filteredList?.map((item: any,index) => (
													// eslint-disable-next-line react/jsx-key
													<div className={`${clicked === index}`} onClick={() => selectCountry(item,index)}
														 style={{alignItems: 'center', textAlign: 'left'}}>

														<img key={item.id} className={'flag'}
															 src={item.flag}
															 alt={''}/> &emsp; <span
														className={'itemOption'}>{item.name.toUpperCase()}</span>
														<hr/>

													</div>

												))}
											</div>


											<div style={{height: '30px'}}></div>
										</>
									}
									{item.name===t('pickNumberPlaceholder')&&(
										<PhoneInput
											name={'phone'}
											value={profile.phone}
											international={true}
											withCountryCallingCode={true}
											defaultCountry={'FR'}

											useNationalFormatForDefaultCountryValue={true}
											placeholder={t('phoneScreenInputLabel')}
											onChange={(e:any)=>checkValidty(e)}/>

									)}
									{selectedInputs.id === 2 && (<label
										className="verificationMessage">{STRINGS.ACCOUNT.EDITPROFILE.CODE_VERIFICAION_MESSAGE}</label>)}
									{item.name === "new_password" && isError && (
										<div className="card-content center-vertically">
											{errorsPassword?.map((error, i) => {
												if (error.icon === check) {
													return (
														<div key={i} className="center-horizontally align-center">
															<ImageContainer alt="check" src={error.icon}/>
															<p className={error.className}>{error.message}</p>
														</div>)
												} else {
													null
												}
											})}
										</div>
									)}
								</>
							);
						})}
						{errorMessagePassword.length > 0 && (
							<div className="error-margin-top card-content center-vertically">
								<p className="error">{errorMessagePassword}</p>
							</div>
						)}
						{
							errorMessagePhone.length > 0 && (
									<p style={{alignSelf:'baseline',backgroundColor:'unset',position:'relative',bottom:'60px',color:'red'}} className="">{errorMessagePhone}</p>
								// </div>
							)
						}
					</div>
					<div className="btn-container">
						<button
							onClick={() => validate(name)}
							className={`btn-primary`}
						>
							{selectedInputs.buttonText}
						</button>
					</div>
				</>
			) : isCode ? (
				<>
					<div className="title-profile">
						<div className="blc-top">
							<h2 dangerouslySetInnerHTML={{__html: model[2].title}}>
							</h2>
						</div>
					</div>
					<div className="inputs-container center-vertically">
						{model[2]?.inputs?.map((item) => {
							return (
								<Input
									key={item.id}
									item={{
										...item,
										onChange: (e) => onValueChange(e.target.value, item.name),
									}}
									isSubmit={isSubmit}
								/>
							);
						})}
					</div>
					<div className="btn-container">
						<button
							onClick={() => validate(name)}
							className={`btn-primary `}
						>
							{t('continue')}
						</button>
					</div>
				</>
			) : (
				<>
					<div className="blc-top">
						<h1>
							{t('profileScreenTitleStepOne')}{" "}
							<span>{t('profileScreenTitleStepTwo')}</span>
						</h1>
					</div>
					<div className="inputs-container center-vertically">
						{inputs?.map((item) => {
							return (
								<div key={item.id}>
									{!isPassword?.user?.has_password && item?.id === 4 ? (
										""
									) : (
										<Input
											key={item.id}
											item={item}
											action={() => item.id && edit(item.id, item.name)}
										/>
									)}
								</div>
							);
						})}
					</div>
					{
						isFromRecap && profile?.phone &&
						(
							<div>
								<button onClick={() => returnToRecap()}
										className="btn-primary ">
									{t('profileScreenContinueOrder')}
								</button>
							</div>
						)
					}
				</>
			)}
			<div className={open ? "overlay" : "hide"}>
				<OrderBottomsheet onClose={onCloseMenu} open={open}/>
			</div>
			<div className="blc-footer">
				<Footer quantity={0} priceOrder={0} isHome={true} returnToRecap={openRecap}/>
			</div>
		</div>
	);
};
/* eslint-enable max-lines-per-function */

export default MyAccountForm;
