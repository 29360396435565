import { store } from '../..';
import { BaseUrl, APIS } from '../../../../core/enums/APIS'
import { Executor } from '../../Executor';
import {createPaymentIntent, creatCheckoutPayment, getOrderRecap, setCurrency, setPromoCode} from './PaymentSlice';
import moment from "moment";

export const paymentIntent = (id:any,code?:any) => {
    return Executor({
        method: "post",
        url: BaseUrl + APIS.PAYMENT.createPaymentIntent,
        data:{promo_code_stripe:code},
        successFun: (res: any) => {
            // store.dispatch(createPaymentIntent(res.data))
            window.open(res.data.session_link,'_self')
            // store.dispatch(setIsChanged(false))
        },
    });
}

export const checkoutPayment = (data:{session_id:string}) => {
    return Executor({
        method: "post",
        url: BaseUrl + APIS.PAYMENT.checkoutPayment(),
        data,
        successFun: (data: any) => {
            // store.dispatch(creatCheckoutPayment(data.data))
            // store.dispatch(setPromoCode(null))
        },
    });
}
export const checkPromoCodeValidity = (code:number) => {
    return Executor({
        method: "get",
        url: BaseUrl + APIS.PAYMENT.checkValidityPromoCode(code),
        successFun: () => {
            store.dispatch(setPromoCode(code))
        },
    });
}


export const getMyCurrency = () => {
    return Executor({

        method: "get",
        url: BaseUrl + APIS.PAYMENT.checkCurrentCurrency(),
        successFun: (data: any) => {
            store.dispatch(setCurrency(data.data))
        },
    });
}

export const recapOrder = (id?: number) => {
    const cart=store.getState().cart?.basketDate?.deliver_at
    return Executor({
        method: "get",
        url: BaseUrl + APIS.CART.orderRecap(id),
        isSilent:false,
        successFun: (data: any) => {
            store.dispatch(getOrderRecap({...data.data,deliver_at:moment(cart).unix()}))
        },
    });
}
