
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Footer.scss";
import logo from "../../assets/icons/Logo-yellow.svg";
import { STRINGS } from "../../core/enums/strings";
import Sheet from "../bottom-sheet/Sheet";
import OrderBottomsheet from "../order-bottomsheet/OrderBottomsheet";
import {useDispatch, useSelector} from "react-redux";
import {RootState, store} from "../../shared/store";
import {
  createBasket,
  getPublicOwnCart, updateDeliveryDate,
} from "../../shared/store/slices/Cart/CartService";
import { PATHS } from "../../core/enums/paths";
import {currency, getPickUpDate, isMobile} from "../../shared/utils/utils-functions";
import CalendarWidget from "../calendar-widget/CalendarWidget";
import lessBtn from "../../assets/icons/moins.svg";
import plusBtn from "../../assets/icons/plus-btn.svg";
import {useTranslation} from "react-i18next";
import {
  setPickUpDate,
  setRecentRestaurant,
  setServiceCode,
  setServiceType
} from "../../shared/store/slices/Cart/CartSlice";
import {useParams} from "react-router";
import CalendarMenuPlat from "../calendarMenuPlat/CalendarMenuPlat";
import moment from "moment";
import {Available, getRecapAvailableDate} from "../../shared/store/slices/Account/AccountService";
import {recapOrder} from "../../shared/store/slices/Payment/PaymentService";
import ContactTel from "../../pages/my-account/contact/contact-tel/ContactTel";
import {t} from "i18next";

interface Props {
  isHome?: boolean;
  isOrder?: boolean;
  priceOrder?: number;
  onClick?: () => void;
  addQuantity?: () => void;
  removeQuantity?: () => void;
  quantity: number;
  isBasket?: boolean;
  withCalendar?: boolean;
  openCalendar?: (value: boolean) => void;
  returnToRecap?: boolean;
}

/* eslint-disable max-lines-per-function */
// eslint-disable-next-line sonarjs/cognitive-complexity
const Footer = (Props: Props): JSX.Element => {

  const history = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const isLoggedIn = useSelector(
    (state: RootState) => state.authentication.isLoggedIn
  );
  const [isRecap, setIsRecap] = useState<boolean>(false);
  const cart = useSelector((state: RootState) => state.cart);
  const [countBasket, setCountBasket] = useState<number>();
  const selectedChef = useSelector((state: RootState) => state.cart.selectedChef);
  // useEffect(() => {
  //   // TODO: CALL ws getBasket
  //
  //   if (isMobile()) {
  //     if (localStorage.getItem('supernova_token')) {
  //       getPublicOwnCart();
  //     } else if (localStorage.getItem("basket_id")) {
  //       getPublicOwnCart(Number(localStorage.getItem("basket_id")));
  //     } else {
  //       createBasket().then(async (res) => {
  //         await getPublicOwnCart(res.data.id);
  //       });
  //     }
  //   }
  // }, []);
  const onOpen = async () => {
    !Props.isBasket && setOpen(true);
    // TODO: CALL ws getBasket

    if (localStorage.getItem('supernova_token')) {
      getPublicOwnCart();
    } else if (localStorage.getItem("basket_id")) {
      getPublicOwnCart(Number(localStorage.getItem("basket_id")));
    } else {
      createBasket().then(async (res) => {
        await getPublicOwnCart(res.data.id);
      });
    }
  };
  const [isVisibleCalendar, setIsVisibleCalendar] = useState<boolean>(false);
  const onDismiss = () => {
    setOpen(false);
  };
const closeCalendar = ()=>{
  setIsVisibleCalendar(false);

}
  const order = useSelector((state: RootState) => state.payment.orderRecap);
  const objectOfWhenChangeDate=useSelector((state:RootState)=>state.cart.objectOfDateWhenChangeCalendar)
  const dispatch=useDispatch()
  const objectSearchDate=useSelector((state:RootState)=>state.cart.objectOfDateWhenChangeCalendar)
  const selectDate = (value: string) => {
    // store.dispatch(setNewCart({id:cart.basket?.restaurant?.id,deliver_at:value}))
    // store.dispatch(getCart({...cart.basket,deliver_at:value}))
    // updateDeliveryDate(value,false);

    // eslint-disable-next-line sonarjs/no-duplicate-string
    const objectOfDate = getPickUpDate(objectOfWhenChangeDate,moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD').toString())
    // getRecapAvailableDate(order.restaurant?.id,moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD').toString(),objectOfDate?.[0]?.pickup_date,objectOfDate?.[0]?.service?.code).then(async () => {
      updateDeliveryDate(value,objectOfDate[0].pickup_date,objectOfDate?.[0]?.service.code,true).then(()=>{
        dispatch(setServiceType(objectOfDate?.[0]?.service?.name))
        dispatch(setServiceCode(objectOfDate?.[0]?.service?.code))
        dispatch(setPickUpDate(objectOfDate?.[0]?.pickup_date))
          setIsVisibleCalendar(false);
      // });
    })
  }
  const goToPage = (path: string) => {
    history(path);
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (cart.basket) {
      let total = 0;
      cart.basket?.plates.forEach((item) => total += item.quantite ? item.quantite : 0)
      cart.basket?.menus.forEach((item) => total += item.quantite ? item.quantite : 0);
      setCountBasket(total);
        setRecentRestaurant(cart?.basket.restaurant?.id)
    }
  }, [cart]);

  useEffect(() => {
    if (Props.returnToRecap) {
      setOpen(true);
      setIsRecap(true);
    }
  }, [Props.returnToRecap]);
  const newBasket = useSelector((state: RootState) => state.cart.basketDate)

  const {t} = useTranslation()
    const params=useParams()
  const [dateFinal, setDateFinal] = useState<any>()
  const [isValidAdress,setIsValidAdress]=useState<any>(false)

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(()=>{
    // eslint-disable-next-line sonarjs/no-collapsible-if
  if (isMobile()){
    if  (window.location.pathname.includes('menu-details') || window.location.pathname.includes('dish-details')){
      Available(Number(params.id2),{is_from_basket_details_screen:false}).then((res:any)=>{
        if (res.data.first_day_available) {
          setDateFinal(moment(res.data.infos_first_day_available.delivery_date).format('DD/MM/YYYY'))
          setIsValidAdress(true)
        }else{
          Available(Number(params.id2),{
            month: new Date().getMonth()+2,
            year: new Date().getMonth()+1 === 12 ?  new Date().getFullYear()+1 : new Date().getFullYear() ,
            is_from_basket_details_screen:false
          }).then((res:any)=>{
            if (res.data.first_day_available) {
              setDateFinal(moment(res.data.infos_first_day_available.delivery_date).format('DD/MM/YYYY'))
              setIsValidAdress(true)
            }else{
              setIsValidAdress(false)
            }
          })
        }

      })
    }
  }
  },[params.id2])
  const phoneNumber = "+33756800802";

  const redirectToWhats=()=>{
    window.open(`https://wa.me/${phoneNumber}`,'_blank')

  }
    return (
    <>
      <div className="footer">
        <div className="container-desk">
          <div className="blc-top center-horizontally space-between">
            <div className="left-blc center-horizontally space-between">
              <div className="center-vertically">
                  <Link to={isLoggedIn ? "/account/orders" : PATHS.LOGIN} className="item-link">
                    {t('commandsTracker') || STRINGS.FOOTER.LINK.ORDER_TRACKING}
                  </Link>
                  {/*<Link to={''} onClick={()=>{window.open("mailto:pro@suppernova.com")}} className="item-link">*/}
                  {/*    {t('forYourRestaurantSuppernova') || STRINGS.FOOTER.LINK.FOR_YOUR_RESTAURANT}*/}
                  {/*</Link>*/}

                  <span style={{cursor:'pointer'}} onClick={redirectToWhats}  className="item-link">
                      {t('contactUs') || STRINGS.FOOTER.LINK.CONTAT_US}
                  </span>
                   <a href={'/politique-support'}   rel="noopener noreferrer" target="_blank"  className="item-link">
                      {t('privacyPolicy') }
                  </a>
                <a href={PATHS.SECONDARY.CONDITION_VENTE}   rel="noopener noreferrer" target="_blank"  className="item-link">
                      {(t('generalConditionStepOne') + ' ' + t('generalConditionStepTwo')+ ' '+ t('generalConditionStepThree'))}
                  </a>
                <a href={PATHS.SECONDARY.SUSTAINABILITY}   rel="noopener noreferrer" target="_blank"  className="item-link">
                      {t('SUSTAINABILITY') }
                  </a>

              </div>
              <div className="center-vertically">
              </div>
            </div>
            <div className="right-blc">
              <Link to="/" className="logo">
                <img src={logo} alt="logo" />
              </Link>
            </div>
          </div>
          <a href={"https://www.instagram.com/wearesuppernova/?hl=fr"}  rel="noopener noreferrer" target="_blank" className="insta-footer"/>
          <div className="blc-bottom center-horizontally space-between">
            <a href={'/mentions-legales'} rel="noopener noreferrer" className="item-link" target="_blank">
              {`${t('credit')} - ${t('legalNotice')}` || STRINGS.FOOTER.LINK.CREDIT_LEGAL_NOTICES}
            </a>
            <p className="copy-right">{STRINGS.FOOTER.COPY_RIGHT}</p>
          </div>
        </div>
      </div>
      <div
        className={` footer-mobile center-vertically position-center ${
          Props.isHome && "tab-home"
        }`}
      >
        <div className="tabs-content">
          {Props.isOrder ? (
            <div className="tab-order">
              <div className="txt center-horizontally align-center">
                <p className="price">{currency(Props.priceOrder)} </p>
              </div>
               <div className="txt">
                 <p className="date" > {t('guarantedDelivryFrom')} <span>{
                     // cart?.basket.deliver_at.length === 9 ? cart?.basket.deliver_at.substring(0,4): cart?.basket.deliver_at.substring(0,5)
                   // moment().format('DD/MM/YYYY').toString().substring(0,5)
                   dateFinal?.length === 9 ? dateFinal?.substring(0, 4) : dateFinal?.substring(0, 5)

                 } <button onClick={() => isValidAdress && setIsVisibleCalendar(true)}/></span></p>
              </div>
              <div  className={`counter-blc center-horizontally align-center ${Props.quantity < 1 && "width-counter"}`}>
                <div className="counter center-horizontally align-center" >
                  {Props.quantity >= 1 && (
                    <>
                      <div onClick={Props.removeQuantity}>
                        <img alt="lessBtn" src={lessBtn}/>
                      </div>
                      <p>{Props.quantity}</p>
                    </>
                  )}

                  <div onClick={Props.quantity >= 1 ? Props.addQuantity : Props.onClick}>
                    <img alt="lessBtn" src={plusBtn}/>
                  </div>
                </div>
                <span onClick={Props.onClick} >{t('add')}</span>
              </div>
            </div>
          ) : (
            <div className="container center-horizontally space-evenly align-center">
              <button
                className={`home ${
                  location.pathname === PATHS.HOME && "active"
                }`}
                onClick={() => goToPage(PATHS.HOME)}
              />
              <button
                  className={`chef ${
                      location.pathname === PATHS.ALL_CHEFS && "active"
                  }`}
                  onClick={() => goToPage(PATHS.ALL_CHEFS)}
              />
              <div className="blc-basket">
                <button
                  className={`basket ${open && "active"}`}
                  onClick={onOpen}
                />
                {cart?.basket?.nbr_article > 0 && (
                  <span className="count">
                    {countBasket}
                  </span>
                )}
              </div>
                <button
                    className={`account ${
                        (location.pathname === '/about' || location.pathname==='/newsletters' || location.pathname === "/account" || location.pathname === '/profile' || location.pathname === "/login" || location.pathname === "/contact" || location.pathname.includes('order')
                            || location.pathname.includes("address")) && "active"
                    }`}
                    onClick={() => goToPage(isLoggedIn ? "/account" : PATHS.LOGIN)}
                />
            </div>
          )}
        </div>
      </div>

      <Sheet open={isVisibleCalendar} isCalendar={true} onDismiss={() =>setIsVisibleCalendar(false)}>
        {
         window.location.pathname.includes('/menu-details') || window.location.pathname.includes('/dish-details') ?
             <CalendarMenuPlat
                 onChange={closeCalendar}
                 daysAvailable={selectedChef?.available_days_week}
                 isAvailableWeekDays={selectedChef?.is_available_days_week}
                 defaultValue={dateFinal}
                 idResto={ Number(params.id2) ? Number(params.id2) !== Number(cart?.basket.restaurant?.id) ? Number(params.id2) : cart?.basket.restaurant?.id  :cart?.basket.restaurant?.id }
                 isOrder={!(window.location.pathname.includes('menu-details') || window.location.pathname.includes('more-detail') || window.location.pathname.includes('dish-details'))}
             /> :
             <CalendarWidget
                 onChange={selectDate}
                 daysAvailable={selectedChef?.available_days_week}
                 isAvailableWeekDays={selectedChef?.is_available_days_week}
                 idResto={ Number(params.id2) ? Number(params.id2) !== Number(cart?.basket.restaurant?.id) ? Number(params.id2) : cart?.basket.restaurant?.id  :cart?.basket.restaurant?.id }
                 isOrder={true}

             />
        }
      </Sheet>
      <Sheet open={open} onDismiss={onDismiss}>
        <OrderBottomsheet onClose={onDismiss} showCalendar={setIsVisibleCalendar} isRecap={isRecap} onChangeView={setIsRecap} open={open}/>
      </Sheet>
    </>
  );
};
/* eslint-enable max-lines-per-function */
export default Footer;
