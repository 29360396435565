import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { STRINGS } from "../../core/enums/strings";
import "./HelpCheckbox.scss";
import HeaderAccount from "../header-account/HeaderAccount";
import { RootState, store } from "../../shared/store";
import { useSelector } from "react-redux";
import {
  ProductClaim,
  sendClaim,
} from "../../shared/store/slices/Account/AccountService";
import { isMobile } from "../../shared/utils/utils-functions";
import Collapse from "antd/lib/collapse/Collapse";
import {t} from "i18next";
import {useTranslation} from "react-i18next";
/* eslint-disable max-lines-per-function */
const HelpCheckbox = () => {
  const history = useNavigate();
  const products = useSelector(
    (state: RootState) => state.account.productsForClaim
  );
  const { Panel } = Collapse;

  const order_id = useSelector((state: RootState) => state.account.Order);
  const type_id = useSelector((state: RootState) => state.account.type);
  const [checkedItems, setCheckedItems] = useState<ProductClaim[]>([
    { id_menu: null, id_plate: [] },
  ]);
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleChangeMenu = (event: any, idMenu: number) => {
    const menuIndex = checkedItems.findIndex((item) => item.id_menu === idMenu);

    if (event.target.checked) {
      if (menuIndex !== -1) {
        checkedItems[menuIndex].id_plate.push(Number(event.target.id));
      } else {
        checkedItems.push({
          id_menu: idMenu,
          id_plate: [Number(event.target.id)],
        });
      }
      // setCheckedItems([...checkedItems, Number(event.target.id)]);
    } else {
      const plateIndex = checkedItems.findIndex((item) =>
        item.id_plate.includes(Number(event.target.id))
      );
      if (plateIndex !== -1) {
        // checkedItems[plateIndex].id_plate.splice(
        //   checkedItems[plateIndex].id_plate.indexOf(Number(event.target.id), 1)
        // );

        checkedItems[plateIndex].id_plate = checkedItems[
          plateIndex
        ].id_plate.filter((el, index) => {
          return el !== Number(event.target.id);
        });

        checkedItems[plateIndex].id_plate.length === 0 &&
          checkedItems.splice(menuIndex);
      }
    }
    setCheckedItems(checkedItems);
  };
 const {t}=useTranslation()
  const handleChangePlate = (event: any) => {
    if (event.target.checked) {
      setCheckedItems((prev) =>
        prev.map((item) => {
          return {
            id_menu: item.id_menu,
            id_plate: item.id_plate.concat(Number(event.target.id)),
          };
        })
      );
    } else {
      setCheckedItems((prev) =>
        prev.map((item) => {
          return {
            id_menu: item.id_menu,
            id_plate: item.id_plate.filter(
              (item) => item !== Number(event.target.id)
            ),
          };
        })
      );
    }
  };

  const sendDemande = async (data: {
    id_command?: number;
    id_type?: number;
    products: ProductClaim[];
    message:string
  }) => {
    (checkedItems[0].id_plate.length !== 0 || checkedItems.length >= 2) &&
      (await sendClaim(data).then((res) => {
        !isMobile() ? history("/account/success") : history("/help-success");
      }));
  };

  return (
    <div className="checkboxes-container center-vertically">
      <HeaderAccount
         title={STRINGS.ACCOUNT.MY_ORDERS.MY_ORDER_DETAILS.HELPS.SUGGESTION[0]}
      />
      <p className="title">
        {t('claimScreenHeaderProduct')}
      </p>
      <div className="center-vertically">
        {products?.menus?.length !== 0 && (
          <>
            {products?.menus?.map((menu) => {
              return (
                <>
                  <div className="collapse">
                    <Collapse>
                      <Panel header={menu.title} key={menu.id}>
                        {menu?.menuPlates?.map((item: any) => {
                          return (
                            <div
                              key={item.id}
                              className="blc center-horizontally space-between align-center"
                            >
                              <label>
                                {item.title}
                                <input
                                  onChange={(e) => handleChangeMenu(e, menu.id)}
                                  type="checkbox"
                                  id={item.id}
                                />
                              </label>
                            </div>
                          );
                        })}
                      </Panel>
                    </Collapse>
                  </div>
                </>
              );
            })}
          </>
        )}

        {products?.plates?.length !== 0 &&
          products?.plates?.map((item: any) => {
            return (
              <div
                key={item.id}
                className="blc center-horizontally space-between align-center"
              >
                <label>
                  {item.title}
                  <input
                    onChange={handleChangePlate}
                    type="checkbox"
                    id={item.id}
                  />
                </label>
              </div>
            );
          })}
      </div>
      <button
        onClick={() => {
          sendDemande({
            id_command: order_id?.id,
            id_type: type_id,
            message:'',
            products:
              checkedItems[0].id_plate.length !== 0
                ? checkedItems
                : checkedItems.slice(1),
          });
        }}
        className={`btn-primary`}
      >
        {t('send')}
      </button>
    </div>
  );
};
/* eslint-enable max-lines-per-function */
export default HelpCheckbox;
