import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import './categoryDetail.scss'
import {getSubCategoryList} from "../../../../../shared/store/slices/explore/exploreService";
import {useDispatch, useSelector} from "react-redux";
import {setSubCategoryList} from "../../../../../shared/store/slices/explore/exploreSlice";
import TrendItem from "../trends/trendItem/trendItem";
import {STRINGS} from "../../../../../core/enums/strings";
import {RootState} from "../../../../../shared/store";


// eslint-disable-next-line max-lines-per-function
const CategoryDetail: React.FC = () => {

  const navigate = useNavigate()
  const currentPage=useRef(1)
  const [isNextPage, setNextPage] = useState(true);
  const [data,setData]=useState<any>([])
  const params = useParams()
  const dispatch = useDispatch()
  const width=useSelector((state:RootState)=>state.root.DEVICE_WIDTH)
  useEffect(() => {
   getData()
  }, [])
   const getData=()=>{
     if (params.id) {
       getSubCategoryList(parseInt(params.id),currentPage.current).then((res) => {
         currentPage.current+=1;
         setNextPage(res.data.list.length===10)
         setData([ ...data , ...res.data.list ])
         dispatch(setSubCategoryList(res.data?.list))
       })
     } else {
       navigate('/explore/kitchen')
     }
   }
  return (
    <div className={'subCategoriesShowCaseMainContainer'}>
      {
        <div className={'trends'}>
          {data?.map((item:any, index:any) => <TrendItem index={index} showAll={item} key={index} item={item}/>)}
        </div>
      }

      {isNextPage &&
       <button 	className="btn-primary" onClick={getData}>
         {STRINGS.SHOW_MORE}
       </button>
      }
      {width <768 &&
      <div style={{height:'100px'}}/>
      }
    </div>
  )
}

export default CategoryDetail;
