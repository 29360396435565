import React, {useEffect, useLayoutEffect, useState} from "react";
import "./auth.scss";
import { Link, useNavigate } from "react-router-dom";
import supernovaIcon from '../../assets/icons/Logo-01.png'
import Header from "../../components/header/Header";
import { STRINGS } from "../../core/enums/strings";
import { PATHS } from "../../core/enums/paths";
import AppleSignin from "react-apple-signin-auth";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import {logout, socialAccountLogin} from "../../shared/store/slices/Auth/AuthService";
import { useSelector } from "react-redux";
import {RootState} from '../../shared/store';
import Footer from "../../components/footer/Footer";
import {isMobile} from '../../shared/utils/utils-functions';
import  sun from '../../assets/icons/INSCRIPTION CONNEXION FR.svg'
import  sun2 from '../../assets/icons/SIGN IN LOG IN EN.svg'

import jwt_decode from "jwt-decode";
import {alertMessage} from "../../shared/store/interceptor";
import { useTranslation } from "react-i18next";
import {
  APP_ID_FACEBOOK_AUTHENTIFICATION,
  APP_ID_FACEBOOK_LOGIN, CLIENT_ID_APPLE_AUTHENTIFICATION,
  CLIENT_ID_GOOGLE_AUTHENTIFICATION, REDIRECT_URI_APPLE_LOGIN
} from "../../core/enums/APIS";
/* eslint-disable max-lines-per-function */
// eslint-disable-next-line sonarjs/cognitive-complexity
const Login = () => {
  const {t} = useTranslation()
  const isLoggedIn = useSelector(
    (state) => state.authentication.isLoggedIn
  );
  useEffect(() => {
    console.warn('here');
    logout(true);
  }, []);
  const history = useNavigate();
  const responseGoogle = (response) => {
    console.warn('response google', response);
    const googleData = {
      firstName: response?.profileObj?.givenName,
      lastName: response?.profileObj?.familyName,
      email: response?.profileObj?.email,
      provider_id: response?.accessToken,
      provider_name: "Google",
      basket_id: localStorage.getItem('basket_id') ? Number(localStorage.getItem('basket_id')) : null
    }
    connectSocialAccount(googleData);
  };

  const responseFacebook = (response) => {
    console.warn('response facebook', response);
    if (response?.email){
      const fullName=response?.name;
      let details=[]
      details=fullName?.split(' ');
      let firstName = '';
      let lastName = '';
      if (details?.length > 1) {
        firstName = details[0];
        lastName = details[1];
      }
      const facebookData = {
        firstName,
        lastName,
        email: response?.email,
        provider_id: response?.accessToken,
        provider_name: "Facebook",
        basket_id: localStorage.getItem('basket_id') ? Number(localStorage.getItem('basket_id')) : null
      }
      connectSocialAccount(facebookData);
    }
  };
  const responseApple = (response) => {
    console.log(response)
    if (response?.authorization) {
      console.log(response)
      console.log(jwt_decode(response?.authorization?.id_token))
      const appleData = {
        firstName: response?.user?.name?.firstName,
        lastName: response?.user?.name?.lastName,
        email: jwt_decode(response?.authorization?.id_token)?.email,
        provider_id: response?.authorization?.id_token,
        provider_name: "Apple",
        basket_id: localStorage.getItem('basket_id') ? Number(localStorage.getItem('basket_id')) : null
      }
      connectSocialAccount(appleData);
    }
  }
  const connectSocialAccount = (data) => {
    socialAccountLogin(data).then(() => {
     !isMobile() ? history("/account/profile") : history("/account")  ;
    });
  };
  const [scrollY, setScrollY] = useState(0);

  useLayoutEffect(() => {
    const handleScroll = () => {
      setScrollY(Math.min(0, window.scrollY / 3 - 60) - 10);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  }, [scrollY]);
  const [language,setLanguage]=useState()
  useEffect(()=>{
    setLanguage(localStorage.getItem('SUPERNOVA_LANGUAGE'))
  },[])
  return (
    <div className="auth login login-page ">
      {
         !isMobile()  &&
         <Header classname={ scrollY === 0 || scrollY < -50 ? '' : 'home-header'} isHome={true} isHomeActive={true} />
      }
      <div className="auth-content">
        <div className="container-desk">
          {
            language === 'fr' ?
                <div className="img-top">
                  <img src={sun} className="round"/>
                </div>
                :
                <div className="img-top">
                  <img src={sun2} className="round"/>
                </div>

          }
          <h1 className="title">{t('registrationScreenTitleRegistrationStepOne') || STRINGS.LOGIN.TITLE} <span>{t('registrationScreenTitleRegistrationStepTwo') || STRINGS.LOGIN.PART2}</span></h1>
          <div className="Smedia center-vertically space-evenly" >
            <AppleSignin
              authOptions={{
                clientId: "com.supernova.service",
                scope: "email name",
                redirectURI: "https://suppernova.rdmc.dev/login",
                state: "",
                nonce: "nonce",
                usePopup: true,
              }}
              uiType="dark"
              className="apple-auth-btn"
              noDefaultStyle={false}
              onSuccess={responseApple}
              onError={(error) => console.error(error)}
              skipScript={false}
              iconProp={{ style: { marginRight: "10px" } }}
              render={(props) => (
                <button onClick={props.onClick} className="apple">
                  <span>{t('registrationScreenWithApple').toUpperCase() || STRINGS.LOGIN.LOGIN_APPLE.toUpperCase()}</span>
                </button>
              )}
            />
            <GoogleLogin
              clientId={CLIENT_ID_GOOGLE_AUTHENTIFICATION}
              render={(renderProps) => (
                <button onClick={renderProps.onClick} className="gmail">
                  <span>{t('registrationScreenWithGoogle') || STRINGS.LOGIN.LOGIN_GOOGLE}</span>
                </button>
              )}
              onSuccess={responseGoogle}
              onFailure={(res) => res?.details && alertMessage(res.details)}
              cookiePolicy={"single_host_origin"}
            />
            <FacebookLogin
              appId={APP_ID_FACEBOOK_AUTHENTIFICATION}
              callback={responseFacebook}
              onFailure={(res) => res?.status && alertMessage(res.status)}
              fields="name,picture,email"
              render={(renderProps) => (
                <button onClick={renderProps.onClick} className="fb">
                  <span>{t('registrationScreenWithFacebook') || STRINGS.LOGIN.LOGIN_FACEBOOK}</span>
                </button>
              )}
              disableMobileRedirect={true}
              autoLoad={false}
            />

            <p className="or-txt">{t('registrationScreenOr') || STRINGS.LOGIN.OR}</p>
            <Link to={PATHS.LOGIN_MAIL}>
              <button className="mail">
                <span>{t('registrationScreenWithEmail') || STRINGS.LOGIN.LOGIN_MAIL}</span>
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div style={{height:'150px'}}/>
      {!isLoggedIn && isMobile() && (
        <div className="blc-footer">
          <Footer quantity={0} priceOrder={0} isHome={true} isOrder={false} />
        </div>
      )}
    </div>
  );
};
/* eslint-enable max-lines-per-function */
export default Login;
