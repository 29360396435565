import React, {useEffect, useRef, useState} from "react";
import {Item} from "../../../components/chef-card/ChefCard";
import '../Home.scss'
import './firstElementCard.scss'
import {motion} from "framer-motion";
import {useSelector} from "react-redux";
import {RootState} from "../../../shared/store";
import ImageContainer from "../../../components/imageContainer/imageContainer";
// eslint-disable-next-line sonarjs/cognitive-complexity,max-lines-per-function
const FirstElementCard = (Props: Item): JSX.Element => {
	const width = useSelector((state: RootState) => state.root.DEVICE_WIDTH)
	const [play, setPlay] = useState<boolean>(false)

	const videoRef = useRef<HTMLVideoElement>(null)
	useEffect(() => {
		if (play) {
			videoRef?.current?.play()
		} else {
			videoRef?.current?.pause()
		}
	}, [play])


	const callback = (entries: any, observer: any) => {
		entries.forEach((entry: any) => {
			setPlay(entry.isIntersecting);
		});
	};

	const options = {
		root: document.querySelector('#section-top'),
		rootMargin: '0px',
		threshold: 0.6
	}

	const observer = new IntersectionObserver(callback, options);


	useEffect(() => {
		const target = document.getElementById(Props?.idCustom as string);
		// eslint-disable-next-line sonarjs/no-collapsible-if
		if ((width < 1024) && (target)) {
			observer.observe(target);
		}


	}, [])
	const MotionComponent = motion(ImageContainer)
	return (
		<div id={Props.idCustom}  style={{height: '100%'}}>
			{(!Props.isHome || Props.isChef || Props.isSubCategori || Props.isCategory) && Props.active_option === 'image' ?
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				<MotionComponent
					// style={{y: shouldReduceMotion ? 0 : y}}
					className={Props.isGray ? "gray" : ""}
					src={
						Props.image as string
					}
					alt=""
					// id={Props?.idCustom}
				/> : !Props.isHome && Props.active_option === 'video' ?
					<video playsInline={true} loop={true}
						// width={width > 1024 ? 2800 : 1300}
						   className={Props.isGray ? "gray" : ""} id={Props?.item?.id?.toString()}
						   ref={width > 1024 ? null : videoRef}
						   autoPlay={width > 1024} style={{width:'100%',height:'100%',objectFit:'fill'}} controls={false} muted={true}>
						<source type={'video/mp4'} src={Props.video}/>
					</video> : !Props.isHome && Props.active_option === 'gif' ?
						<ImageContainer src={Props.gif as string} alt={''}/> :
						''
			}

		</div>
	)


}
export default FirstElementCard
