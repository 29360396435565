import React, { useEffect, useState } from "react";
import "./HelpSuggestion.scss";
import HeaderAccount from "../header-account/HeaderAccount";
import { RootState } from "../../shared/store";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getListClaim,
  getProductsForClaim,
} from "../../shared/store/slices/Account/AccountService";
import { useDispatch, useSelector } from "react-redux";
import { ClaimType } from "../../core/models/Claim";
import HelpItem from "../help-checkbox/helpItem";
import { PATHS } from "../../core/enums/paths";
import { setTypeId } from "../../shared/store/slices/Account/AccountSlice";
import { isMobile } from "../../shared/utils/utils-functions";
import {useTranslation} from "react-i18next";

interface Props {
  orderId?: number;
}
/* eslint-disable max-lines-per-function */
const HelpSuggestion = (props: Props) => {
  const state = useLocation();
  const history = useNavigate();
  const {t}=useTranslation()
  const claimTypes = useSelector(
    (state: RootState) => state.account.claimTypes
  );
  const [selectedType, setSelectedType] = useState<ClaimType | null>(null);
  const dispatch = useDispatch();
  const selectType = (type: ClaimType) => {
    if (type.id !== 4) {
      setSelectedType(type);
      dispatch(setTypeId(type.id));
      isMobile() && history(PATHS.MY_ACCOUNT.CLAIM_PRODUCTS);
    } else {
      isMobile() ? history(PATHS.CONTACT_MOBILE) : history("/account/contact");
    }
  };
  useEffect(() => {
    !isMobile() && selectType({ id: 1 });
    dispatch(setTypeId(1));
    getProductsForClaim(
      state.state.order_id ? state.state.order_id : props.orderId
    ).then();
    getListClaim().then();
    console.log(claimTypes)
  }, []);
  return (
    <div className="center-vertically suggestion-container">
      <HeaderAccount
        title={t('claimScreenHeaderQuestion')}
      />
      {claimTypes &&
        claimTypes.map((item: ClaimType) => {
          return (
            <HelpItem
              key={item.id}
              item={item}
              onClick={(type) => selectType(type)}
              isChecked={!!selectedType && selectedType.id === item.id}
            ></HelpItem>
          );
        })}

    </div>
  );
};
/* eslint-enable max-lines-per-function */
export default HelpSuggestion;
