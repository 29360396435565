import React from 'react';
import "./BackBtn.scss"
import { useNavigate, useLocation } from 'react-router-dom';
import {PATHS} from "../../core/enums/paths";
import {useSelector} from "react-redux";
import {RootState} from "../../shared/store";
import {setStepNow} from "../../shared/store/slices/Auth/AuthSlice";
interface  BackBtnProps {
    pathTo?: string | null ;
}
const BackBtn = (props: BackBtnProps) => {
    const history = useNavigate();
    const location = useLocation();
    const backRouteData = useSelector((state: RootState) => state.root.backRootData);
    const isFromAllChefs = location.pathname.includes('more-detail') && props.pathTo
    const dataWithRoot = () => {
        return {item: backRouteData?.item, itemResto: backRouteData?.itemResto}
    }
    const step=useSelector((state:RootState)=>state.authentication.step)
    return (
        <>
            {
                // eslint-disable-next-line sonarjs/no-all-duplicated-branches
                location.pathname!=='/register' &&
                    // eslint-disable-next-line sonarjs/cognitive-complexity
                <button onClick={() => props.pathTo ? history(props.pathTo, { state: props.pathTo.includes("more-detail") ? dataWithRoot(): {}}) :
                    location.pathname === PATHS.LOGIN ? history(PATHS.HOME) : history(-1)} className="back-btn" />

            }

        </>
    );
};

export default BackBtn;
