import React from "react";
import { STRINGS } from "../../core/enums/strings";
import { PreparationItem } from "../../core/models/PreparationItem";
import arrowRight from "../../assets/icons/arrow-right.svg";
import "./PreparationItemOrder.scss";
import { CategorieModel, MenuModel } from "../../core/models/Menu";
import ImageContainer from "../imageContainer/imageContainer";
import {t} from "i18next";

interface Item {
  plate?: CategorieModel;
  menu?: MenuModel;
  onClick: () => void;
}
interface Props {
  item: Item;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const PreparationItemOrder = (props: Props): JSX.Element => {
  const isGray=props.item.menu?.cover_picture
  ? props.item.menu?.cover_picture.find((item) => item.type === "4:3")?.is_grey
  : props.item.plate?.imagePlats[0]?.photos_versions.find((item) => item.type === "4:3")?.is_grey
  return (
    <div className="preparation-item center-horizontally">
      <div className="blc-img">
        <ImageContainer
        className={isGray ? 'gray' : ''}
          src={
            props.item.menu?.cover_picture
              ? props.item.menu?.cover_picture.find((item) => item.type === "4:3")?.url as string
              : props.item.plate?.imagePlats[0]?.photos_versions.find((item) => item.type === "4:3")?.url as string
          }
          alt="food"
        />
      </div>
      <div className="info-content center-vertically">
        <p className="title">
          {props.item.menu?.title
            ? props.item.menu?.title
            : props.item.plate?.title}
        </p>
        <p>
          {props.item.menu?.description
            ? props.item.menu?.description
            : props.item.plate?.description}
        </p>
        <div
          onClick={props.item?.onClick}
          className="action-content center-horizontally align-center"
           style={{position:"unset"}}
        >
          <p>{t('detailsOrderScreenSeePreparationButton') ||STRINGS.ACCOUNT.MY_ORDERS.MY_ORDER_DETAILS.SEEPREPARATION}</p>
          <img src={arrowRight} alt="row-right" />
        </div>
      </div>
    </div>
  );
};

export default PreparationItemOrder;
