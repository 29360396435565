import React, {useLayoutEffect, useState} from "react";
import { Outlet } from "react-router-dom";
import Header from "../../components/header/Header";
import "./myAccount.scss";
import SideBar from "../../components/sidebar/SideBar";
import Footer from "../../components/footer/Footer";


/* eslint-disable max-lines-per-function */
const MyAccount = (): JSX.Element => {
    const [scrollY, setScrollY] = useState<number>(0);

    useLayoutEffect(() => {
        const handleScroll = () => {
            setScrollY(Math.min(0, window.scrollY / 3 - 60) - 10);
        }
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    }, [scrollY]);
  return (
    <div className="account-page" onScroll={e => {
        e.preventDefault();
        e.stopPropagation();
    }}>
      <Header classname={ scrollY === 0 || scrollY < -50 ? '' : 'home-header'} isHome={true} isHomeActive={true}  isAccount={true} />
      <div className="container-desk">
        <div className="center-horizontally content">
          <SideBar />
          <div className="right-side">
            <Outlet />
          </div>
        </div>
      </div>
        <div className="blc-footer">
            <Footer quantity={0} priceOrder={0} isHome={true} isOrder={false}/>
        </div>
    </div>
  );
};
/* eslint-enable max-lines-per-function */

export default MyAccount;
