import React, {useEffect, useLayoutEffect, useState} from "react";
import "./Header.scss";
import {Link, useLocation} from "react-router-dom";
import logoHome from "../../assets/icons/Logo.svg";
import logoBlack from "../../assets/icons/Logo-black.svg";
import {STRINGS} from "../../core/enums/strings";
import {PATHS} from "../../core/enums/paths";
import {useDispatch, useSelector} from "react-redux";
import {RootState, store} from "../../shared/store";
import OrderBottomsheet from "../order-bottomsheet/OrderBottomsheet";
import {
	createBasket,
	getPublicOwnCart,
} from "../../shared/store/slices/Cart/CartService";
import BackBtn from "../back-btn/BackBtn";
import {isMobile} from "../../shared/utils/utils-functions";
import {motion} from "framer-motion";
import {useTranslation} from "react-i18next";
import {setIsOpen} from "../../shared/store/slices/Cart/CartSlice";
import {closeSearching} from "../../shared/store/slices/Chefs/ChefsSlice";
import {useParams} from "react-router";

interface Props {
	isHome?: boolean;
	isAccount?: boolean;
	children?: JSX.Element;
	pathTo?: any;
	isHomeActive?: boolean
	classname?: string
}

/* eslint-disable max-lines-per-function */
// eslint-disable-next-line sonarjs/cognitive-complexity
const Header = (Props: Props): JSX.Element => {
	const {t} = useTranslation()
	const [open, setOpen] = useState(false);
	const close =useSelector((state:RootState)=>state.chefs.closeSearch)
	const isLoggedIn = useSelector(
		(state: RootState) => state.authentication.isLoggedIn
	);
	const [countBasket, setCountBasket] = useState<number>();
	const [scrollY, setScrollY] = useState<number>(0);

	useLayoutEffect(() => {
		const handleScroll = () => {
			setScrollY(Math.min(0, window.scrollY / 3 - 60) - 10);
		}
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		}
	}, []);

	useEffect(() => {
		window.scrollTo({top: 0, left: 1000, behavior: "auto"});
		setScrollY(0);
		if (!isMobile()) {
			getCart();
		}
	}, []);
	const cart = useSelector((state: RootState) => state.cart);
	const onOpenMenu = async () => {
		store.dispatch(setIsOpen(true))
		setOpen(true);
		// getCart();
		document.body.style.overflow = "hidden";
	};
	const getCart = () => {
		if (isLoggedIn) {
			getPublicOwnCart();
		} else if (localStorage.getItem("basket_id")) {
			getPublicOwnCart(Number(localStorage.getItem("basket_id")));
		} else {
			createBasket().then(async (res) => {
				await getPublicOwnCart(res.data.id);
			});
		}
	}
	const onCloseMenu = () => {
		setOpen(false);
		document.body.style.overflow = "auto";
	};
	// eslint-disable-next-line sonarjs/cognitive-complexity
	useEffect(() => {
		if (cart.basket) {
			let total = 0;
			cart.basket?.plates.forEach((item) => total += item.quantite ? item.quantite : 0)
			cart.basket?.menus.forEach((item) => total += item.quantite ? item.quantite : 0);
			setCountBasket(total);
		}
		// getAllCategories(0).then();
		// getChefList(0).then()
	}, [cart]);

	const [isSearching, setIsSerching] = useState(close);
	const setSearching = () => {
		store.dispatch(closeSearching(true))
	}
	const params=useParams()
	const testClassname = (classname: any) => {
		if (classname === 'header') {
			return 'header'
		} else if (classname === 'home-header') {
			return 'home-header'
		} else if (classname === 'headerItem')
			return `header ${Props.isHomeActive}`
		else {
			return 'header'
		}
	}
	return (
		<div className={` ${Props.isAccount && "header-account"}`} id={"header-desktop"}>
			<motion.div
				initial={{
					transform: window.location.pathname === '/explore/kitchen' ? 'translateY(-70px)' : ''
				}}
				animate={{
					transform: 'translateY(0px)'
				}}
				transition={{
					delay: 0,
					duration: 0.5
				}}
				className={testClassname(Props?.classname)}
				// className={Props.isHome && !Props.isHomeActive ? "home-header" : !Props.isHome  ? 'header' : Props.isHome && Props.isHomeActive ? `home-header ${Props.isHomeActive}` :''  }
			>
				{scrollY > -70 && scrollY !== 0 && <div style={
					{
					background: "#1c1c1c",
					position: "fixed",
					height: "80px",
					width: "100%",
					top: scrollY,
					zIndex: "970"
				}}
                />}
				<div className="container-desk center-horizontally space-between align-center"
					 style={{
						 position: "fixed",
						 // position: scrollY < -40 ? "relative" : "fixed",
						 height: scrollY < -40 ? '100%' : '70px',
						 top: 0
						 // top: scrollY < -40 ? scrollY + 70 : scrollY + 10,
						 // zIndex: scrollY < -40 ? "940" : "999"
					 }
					 }>
					{/* eslint-disable-next-line sonarjs/no-duplicate-string */}
					{!window.location.pathname.includes('/contact-support') && !(window.location.pathname.includes('/cgu-support')) &&
					! (window.location.pathname.includes('/politique-support')) &&

                    <div className="blc-left">
						{/*<Link className={`link-item ${window.location.pathname.includes('explore/kitchen')}`}*/}
						{/*	  to="/explore/kitchen">*/}
						{/*	{t('kitchenLink') || STRINGS.HEADER.LINK.KITCHEN}*/}
						{/*</Link>*/}

                        <Link className={`link-item ${window.location.pathname.includes('explore/chefs')}`}
                              to={PATHS.ALL_CHEFS}>
							{t('titleTousLesChefs') || STRINGS.HEADER.LINK.CHEFS}
                        </Link>

						{/*<Link className="link-item" to={PATHS.CATEGORIES}>*/}
						{/*	{t('categoriesLink') || STRINGS.HEADER.LINK.CAT}*/}
						{/*</Link>*/}

                    </div>
					}
					{!window.location.pathname.includes('/contact-support') && !(window.location.pathname.includes('/cgu-support')) &&
					! (window.location.pathname.includes('/politique-support')) &&
					<div className="blc-right center-horizontally">

                        <Link
                            className="link-item"
                            to={!isLoggedIn ? PATHS.LOGIN : "/account/profile"}
                        >
							{t('myAccountLink') || STRINGS.HEADER.LINK.MY_ACCOUNT}
                        </Link>
                        <div
                            className="link-item"
                            onClick={() => {
								onOpenMenu();
							}}
                        >
							{t('basketLink') || STRINGS.HEADER.LINK.BASKET} ({countBasket})
                        </div>

                    </div>
					}
					<div className="blc-center">
						<Link to="/home" className="logo">
							{!Props.isHome || ((['/login','/register','/account/add-edit-address','' +
							'cgu-support','/all-chef/', '/explore/kitchen', '/explore/chefs',
								'/all-categories'].includes(window.location.pathname) || window.location.pathname.includes('/more-detail/') || window.location.pathname.includes('/explore/kitchen/categoryDetail/') || window.location.pathname.includes('/account') ||window.location.pathname.includes('/details-chef')  || window.location.pathname.includes('/all-chef/chefs') ) && (scrollY === 0 || scrollY < -50)) ? (
								<img src={logoBlack} alt="logo"/>
							) : (
								<img src={logoHome} alt="logo"/>
							)}
						</Link>
					</div>
				</div>
			</motion.div>
			<div className={open ? "overlay" : "hide"}>
				<OrderBottomsheet onClose={onCloseMenu}/>
			</div>
			<div className="mobile-header">
				<div className="container-desk center-horizontally space-between align-center">
					<BackBtn pathTo={window.location.pathname.includes('/menu-details') ? `/more-detail/chef=${params.id}/restaurant=${params.id2}` : Props?.pathTo ? Props.pathTo : null}/>
					{Props.children}
				</div>
			</div>
		</div>
	);
};
/* eslint-enable max-lines-per-function */
export default Header;
