import React from 'react';
import "./ItemOrder.scss";
import {STRINGS} from "../../core/enums/strings";
import { CategorieModel, MenuModel } from '../../core/models/Menu';
import lessBtn from "../../assets/icons/moins-btn-black.svg";
import plusBtn from "../../assets/icons/plus-btn-black.svg";
import ImageContainer from "../imageContainer/imageContainer";
import {currency} from "../../shared/utils/utils-functions";
import {t} from "i18next";

interface Item{
    menu?:MenuModel,
    plate?:CategorieModel,
    onClick?:()=>void,
    quantity?:number,
    increment?:()=>void,
    decrement?:()=>void
}


// eslint-disable-next-line sonarjs/cognitive-complexity
const ItemOrder = (props:Item) :JSX.Element => {
    const isGray=props.menu?.cover_picture ? props.menu.cover_picture.find((item) => item.type === '3:4')?.is_grey : props.plate?.imagePlats[0].photos_versions.find((item) => item.type === "4:3")?.is_grey
    return (
        <div className="item-order center-horizontally" key={props.menu?.id ? props.menu.id : props.plate?.id}>
            <div className="blc-img">
                <ImageContainer className={isGray ? "gray" : ""} src={props.menu?.cover_picture ? props.menu.cover_picture.find((item) => item.type === '3:4')?.url as string : props.plate?.imagePlats[0].photos_versions.find((item) => item.type === "4:3")?.url as string} alt="food"/>
            </div>
            <div className="infos">
                <h1 className="title">{props.menu?.title ? props.menu.title : props.plate?.title}</h1>
                <p className="price">  {currency(props.menu?.total_price && props.quantity ? (props.menu.total_price * props.quantity) : props.plate?.price && props.quantity && (props.plate?.price * props.quantity))}</p>
                <div className="bottom-item center-horizontally space-between">
                <div className="counter center-horizontally space-between align-center">
                <div
                  onClick={props.decrement}
                >
                  <img alt="lessBtn" src={lessBtn}/>
                </div>
                <p>{String(props.quantity).padStart(2, '0')}</p>
                <div onClick={props.increment} >
                    <img alt="plusBtn" src={plusBtn}/>
                </div>
              </div>
                    <button className="delete" onClick={props.onClick}>{t('manageMyDeliveryAddressesModalMessageConfirmDelete')}</button>
                </div>
            </div>
        </div>
    );
};

export default ItemOrder;
