import React from 'react';
import Sheet from "../bottom-sheet/Sheet";
import "./ConfirmBottomSheet.scss"
import {STRINGS} from "../../core/enums/strings";
import {t} from "i18next";
interface ConfirmBottomSheetInterface {
    title: string;
    text: string;
    yesText: string;
    noText?: string;
    open: boolean;
    action: (isYes: boolean) => void;
    isDelete?: boolean;
    isLogout?:boolean;
}
const ConfirmBottomSheet = (props: ConfirmBottomSheetInterface) : JSX.Element =>  {
    return (
        <Sheet open={props.open} className={'bottomSheet'} onDismiss={() => props.action(false)}>
            <div className="container">
                <div className="top-blc">
                    <h1 className="title">{props.title}</h1>
                    {
                        !props.isLogout &&
                        <button className="close-button-sheet" onClick={() => props.action(false)} />
                    }
                </div>
                <div className="content">
                    {props.text}
                </div>
                <div className="actions">
                    <button className="btn-primary" style={{backgroundColor: props.isDelete ? "var(--red-color-secondary) " : ""}}
                            onClick={() => props.action(true)}>
                        {props.yesText}
                    </button>
                    <button className="btn-primary cancel" onClick={() => props.action(false)}>
                        {t('cancel')}
                    </button>
                </div>
            </div>
        </Sheet>
    );
}

export default ConfirmBottomSheet;
