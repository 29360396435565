import { store } from '../..';
import { BaseUrl, APIS } from '../../../../core/enums/APIS'
import { Executor } from '../../Executor';
import {
    getChefs,
    getRestos,
    setTotalChefs,
    setTotalRestos,
    getChefDetails,
    getChefMenuDetails,
    getChefPlateDetails,
    getCategory,
    getCategories,
    getHomeChefs, setChefListWithStars, setSelectedChef
} from './ChefsSlice';
import {setRootLoading} from "../Root/rootSlice";

export const getChefList = (page: number) => {
    return Executor({
        method: "get",
        url: BaseUrl + APIS.CHEFS.getListChefs(page),
        successFun: (data: any) => {
            store.dispatch(getChefs(data.data))
            store.dispatch(setTotalChefs(data.data.total))
        },
        isSilent:true

    });
}
export const getChefListAlphabet = () => {
    return Executor({
        method: "get",
        url: BaseUrl + APIS.CHEFS.getListChefsAlphabet(),
        successFun: (data: any) => {
            store.dispatch(getChefs(data.data))
            store.dispatch(setTotalChefs(data.data.total))
        },
        isSilent:true

    });
}
export const getAllChefAlphabet = (id:string,page:number) => {
    return Executor({
        method: "get",
        url: BaseUrl + APIS.CHEFS.getAllChefAlphabet(id,page),
        successFun: (data: any) => {
            store.dispatch(setSelectedChef(data.data))
            store.dispatch(setTotalChefs(data.data.total))
        },
        isSilent:true

    });
}
export const getChefListWithStars = (stars: number, page: number) => {
    return Executor({
        method: "get",
        url: BaseUrl + APIS.CHEFS.getListChefsWithStars(stars, page),
        isSilent:false,

    });

}
export const getSubCategoryListResto = (id: number) => {
    return Executor({
        method: "get",
        url: BaseUrl + APIS.CHEFS.getListRestoPerSubcategory(id),
        successFun: (data: any) => {
            store.dispatch(getCategory(data.data))
        },
        isSilent:true

    });
}


export const getAllCategories = (page: number) => {
    return Executor({
        method: "get",
        url: BaseUrl + APIS.CHEFS.getCategories(page),
        successFun: (data: any) => {
            store.dispatch(getCategories(data.data.list))
        },
        isSilent:true
    });
}
export const getListHome = () => {
    return Executor({
        method: "get",
        url: BaseUrl + APIS.CHEFS.getHome,
        successFun: (data: any) => {
            store.dispatch(getHomeChefs(data.data))

        },
        isSilent:true

    });
}

export const getCategorySeason = (id:number) => {
    return Executor({
        method: "get",
        url: BaseUrl + APIS.CHEFS.getCategory(id),
        successFun: (data: any) => {
            store.dispatch(getCategory(data.data))
        },


    });
}


export const getChefRestoList = (IdChef: any, page:number) => {
    return Executor({
        method: "get",
        url: BaseUrl + APIS.CHEFS.getListRestoChef(IdChef, page),
        successFun: (data: any) => {
            store.dispatch(getRestos(data.data.list))
            store.dispatch(setTotalRestos(data.data.total))
        },
        isSilent:false


    });
}

export const getRestoChef = (idChef: number, idResto: number) => {
    return Executor({
        method: "get",
        url: BaseUrl + APIS.CHEFS.getRestoChef(idChef, idResto),
        successFun: (data: any) => {
            store.dispatch(getChefDetails(data.data))
        },
        isSilent:true


    });
}

export const getMenuChef = (idChef?: number, idResto?: number, idMenu?: number) => {
    return Executor({
        method: "get",
        url: BaseUrl + APIS.CHEFS.getMenuChef(idChef, idMenu, idResto),
        successFun: (data: any) => {
            store.dispatch(getChefMenuDetails(data.data))
        },
        isSilent:true


    });
}

export const getPlateChef = (idChef?: number, idResto?: number, idPlate?: number) => {
    return Executor({
        method: "get",
        url: BaseUrl + APIS.CHEFS.getPlateChef(idChef, idPlate, idResto),
        successFun: (data: any) => {
            store.dispatch(getChefPlateDetails(data.data))
        },
        isSilent:false


    });
}


export const getChef = (idChef: number) => {
    return Executor({
        method: "get",
        url: BaseUrl + APIS.CHEFS.getChef(idChef),
        isSilent:true
    })
}
