import React, {useEffect, useRef, useState} from "react";
import BackBtn from "../../../../components/back-btn/BackBtn";
import GooglePlacesAutoComplete from "../../../../components/googlePlacesAutoComplete/googlePlacesAutoComplete";
import Input from "../../../../components/input/Input";
import {STRINGS} from "../../../../core/enums/strings";
import {Address} from "../../../../core/models/Address";
import {InputItem} from "../../../../core/models/Input";
import {
    setAsDefaultAddress,
    updateCreateAddress,
} from "../../../../shared/store/slices/Account/AccountService";
import "./AddAddressForm.scss";
import Geocode from "react-geocode";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../shared/store";
import MyMapComponent from "../../../../components/map/Map";
import {checkIfDifferentAddress, isMobile} from "../../../../shared/utils/utils-functions";
import {
    setAddressName, setIsFromMap,
    setSelectedAddress,
} from "../../../../shared/store/slices/Root/rootSlice";
import {setDeliveryAdress} from "../../../../shared/store/slices/Payment/PaymentSlice";
import {AddressDelivery} from "../../../../core/models/Payment";
import {setAutoComplete, setIsOnEditAddress} from "../../../../shared/store/slices/Account/AccountSlice";
import {useTranslation} from "react-i18next";
import PhoneInput, {isPossiblePhoneNumber, isValidPhoneNumber} from "react-phone-number-input";
import {toast} from "react-toastify";
import {GOOGLE_API_KEY} from "../../../../core/enums/APIS";

// Geocode.setApiKey(process.env.REACT_APP_API_GOOGLE);
/* eslint-disable max-lines-per-function */
const AddAddressForm = (Props: {
    dissmissForm?: () => void;
    config?: Address;
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const {t} = useTranslation()
    const focus = useRef<any>(null);
    const navigate = useNavigate();
    const selectedAddress = useSelector(
        (state: RootState) => state.root.selected_address
    );
    const locationAutoComplete = useSelector(
        (state: RootState) => state.account.autoComplete
    );

    const me = useSelector((state: RootState) => state.authentication.profile)
    const [phoneSelected, setPhoneSelected] = useState<any>()
    const [phoneValid, setPhoneValid] = useState(false)
    const onChangePhoneNumber = (value: any) => {
        if (value?.length > 6) {
            if (value && isValidPhoneNumber(value) && isPossiblePhoneNumber(value)) {
                setPhoneValid(true)
                setPhoneSelected(value)
            } else {
                setPhoneValid(false)
            }
        }

    }
    const propsConfig = useRef(selectedAddress);
    useEffect(() => {
        if (propsConfig?.current?.phone_receiver && propsConfig?.current?.phone_receiver?.length > 6) {
            setPhoneValid(true)
        }
    }, [propsConfig?.current?.phone_receiver])
    useEffect(() => {
        if ((!propsConfig?.current?.phone_receiver) && (me?.phone)) {
            setPhoneValid(true)
        }
    }, [])

    const nameAddressState = useSelector(
        (state: RootState) => state.root.address_name
    );

    const formData = useRef<{ [key: string]: any }>({
        id: propsConfig.current?.id,
        name: propsConfig.current?.name,
        first_name: propsConfig.current?.first_name,
        last_name: propsConfig.current?.last_name,
        pivot_code: propsConfig.current?.pivot_code,
        digi_code: propsConfig.current?.digi_code,
        apartment_number: propsConfig.current?.apartment_number,
        position: {
            longitude: propsConfig.current?.position?.longitude,
            latitude: propsConfig.current?.position?.latitude,
            address: propsConfig.current?.position?.address,
        },
        city: propsConfig.current?.city,
        postal_code: propsConfig.current?.postal_code,
        country_code: propsConfig.current?.country_code,
        country: propsConfig.current?.country,
        state: propsConfig.current?.state_name,
        state_name: propsConfig.current?.state_name

    });
    const [ggPlacesErrors, setGgPlacesErrors] = useState<Array<string>>([]);
    const isEditAddress = useSelector(
        (state: RootState) => state.root.isEditAddress
    );
    const formInputsValidity = useRef([
        {
            name: t('personnelInformationFirstNameInput') || STRINGS.ACCOUNT.EDIT_ADRESSES.INPUT_2,
            isValid: true,
        },
        {
            name: t('personnelInformationLastNameInput') || STRINGS.ACCOUNT.EDIT_ADRESSES.INPUT_2,
            isValid: true,
        },

        {
            name: t('updateAddressScreenAddressName') || STRINGS.ACCOUNT.EDIT_ADRESSES.INPUT_2,
            isValid: !!propsConfig.current,
        },

        {
            name: t('updateAddressScreenDigicode') || STRINGS.ACCOUNT.EDIT_ADRESSES.INPUT_3,
            isValid: true,
        },
        {
            name: t('updateAddressScreenAppartmentNumber') || STRINGS.ACCOUNT.EDIT_ADRESSES.INPUT_4,
            isValid: true,
        },
        {
            name: t('profileScreenCountry') || STRINGS.ACCOUNT.EDIT_ADRESSES.COUNTRY,
            isValid: true,
        },
        {
            name: t('updateAddressScreenCodePostal') || STRINGS.ACCOUNT.EDIT_ADRESSES.INPUT_6,
            isValid: true,
        },
        {
            name: t('updateAddressScreenRegion') || STRINGS.ACCOUNT.EDIT_ADRESSES.STATE,
            isValid: true,
        },
        {
            name: t('updateAddressScreenCity') || STRINGS.ACCOUNT.EDIT_ADRESSES.CITY,
            isValid: true,
        },
        {
            name: t('updateAddressScreenAddressName') || STRINGS.ACCOUNT.EDIT_ADRESSES.INPUT_2,
            isValid: true,
        },
        {
            name: t('updateAddressScreenAddressName') || STRINGS.ACCOUNT.EDIT_ADRESSES.INPUT_2,
            isValid: true,
        },
    ]);

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    useEffect(() => {
        if (!phoneValid || phoneSelected?.length < 4) {
            setIsButtonDisabled(true)
        } else {
            setIsButtonDisabled(false)
        }
    }, [phoneValid])
    const onChangeInput = (
        event: { target: { value: any } },
        formName: string,
        inputName: string
    ) => {
        formData.current[formName] = event.target.value;
        const temp = [...inputs];
        if (temp.findIndex((item) => item.name === inputName) > -1) {
            temp[temp.findIndex((item) => item.name === inputName)].value =
                event.target.value;
            setInputs([...temp]);
        }
    };
    // 0 - autocomplete
    // 1 - name
    // 2 - digi_code
    // 3 - apartment_number
    // 4 - city
    // 5 - postal_code
    // 10 - state
    // 12 - country

    const [inputs, setInputs] = useState<InputItem[]>([
        {
            id: 7,
            type: "text",
            label: t('personnelInformationFirstNameInput') || STRINGS.ACCOUNT.EDIT_ADRESSES.INPUT_1,
            name: t('personnelInformationFirstNameInput') || STRINGS.ACCOUNT.EDIT_ADRESSES.INPUT_1,
            value: propsConfig.current?.first_name ? propsConfig.current?.first_name : ' ',
            rules: [{name: "required"}],
            isValid: (isValid: boolean) =>
                setFormIsValid(isValid, t('personnelInformationFirstNameInput')),
            onChange: (event) =>
                onChangeInput(
                    event,
                    "first_name",
                    t('personnelInformationFirstNameInput')
                ),
        },
        {
            id: 8,
            type: "text",
            label: t('personnelInformationLastNameInput') || STRINGS.ACCOUNT.EDIT_ADRESSES.INPUT_1,
            name: t('personnelInformationLastNameInput') || STRINGS.ACCOUNT.EDIT_ADRESSES.INPUT_1,
            value: propsConfig.current?.last_name ? propsConfig.current?.last_name : ' ',
            rules: [{name: "required"}],
            isValid: (isValid: boolean) =>
                setFormIsValid(isValid, t('personnelInformationLastNameInput')),
            // eslint-disable-next-line sonarjs/no-identical-functions
            onChange: (event) =>
                onChangeInput(
                    event,
                    "last_name",
                    t('personnelInformationLastNameInput')
                ),
        },
        {
            id: 0,
            type: "autoComplete",
            label: t('manageMyDeliveryAddressesScreenTitleStepTwo') || STRINGS.ACCOUNT.EDIT_ADRESSES.INPUT_1,
            name: t('manageMyDeliveryAddressesScreenTitleStepTwo') || STRINGS.ACCOUNT.EDIT_ADRESSES.INPUT_1,
            value: Props.config ? Props.config?.position?.address : 'zae',
        },
        {
            id: 5,
            type: "text",
            name: t('updateAddressScreenCodePostal') || STRINGS.ACCOUNT.EDIT_ADRESSES.INPUT_6,
            value: propsConfig.current?.postal_code,
            rules: [{name: "required"}],
            isValid: (isValid: boolean) =>
                setFormIsValid(isValid, t('updateAddressScreenCodePostal') || STRINGS.ACCOUNT.EDIT_ADRESSES.INPUT_6),
            onChange: (event) =>
                onChangeInput(
                    event,
                    "postal_code",
                    t('updateAddressScreenCodePostal') || STRINGS.ACCOUNT.EDIT_ADRESSES.INPUT_6
                ),
        },
        {
            id: 4,
            type: "text",
            name: t('updateAddressScreenCity') || STRINGS.ACCOUNT.EDIT_ADRESSES.CITY,
            value: propsConfig.current?.city,
            rules: [{name: "min"}],
            isValid: (isValid: boolean) =>
                setFormIsValid(isValid, t('updateAddressScreenCity') || STRINGS.ACCOUNT.EDIT_ADRESSES.CITY),
            onChange: (event) =>
                onChangeInput(event, "city", t('updateAddressScreenCity') || STRINGS.ACCOUNT.EDIT_ADRESSES.CITY),
        },
        {
            id: 12,
            type: "text",
            name: t('profileScreenCountry') || STRINGS.ACCOUNT.EDIT_ADRESSES.COUNTRY,
            value: propsConfig.current?.country,
            rules: [{name: "required"}],
            isValid: (isValid: boolean) =>
                setFormIsValid(isValid, t('profileScreenCountry') || STRINGS.ACCOUNT.EDIT_ADRESSES.COUNTRY),
        },
        {
            id: 10,
            type: "text",
            name: t('updateAddressScreenRegion') || STRINGS.ACCOUNT.EDIT_ADRESSES.STATE,
            // value: propsConfig.current?.state,
            rules: [{name: "required"}],
            isValid: (isValid: boolean) =>
                setFormIsValid(isValid, t('updateAddressScreenRegion') || STRINGS.ACCOUNT.EDIT_ADRESSES.STATE),
            onChange: (event) =>
                onChangeInput(event, "state", t('updateAddressScreenRegion') || STRINGS.ACCOUNT.EDIT_ADRESSES.STATE),
        },
        {
            id: 20,
            type: "text",
            name: t('updateAddressScreenBatimen'),
            value: propsConfig.current?.pivot_code,
            isValid: (isValid: boolean) =>
                setFormIsValid(isValid, t('updateAddressScreenBatimen')),
            onChange: (event) =>
                onChangeInput(event, "pivot_code", t('updateAddressScreenBatimen')),
            maxLength: 35
        },
        {
            id: 1,
            type: "text",
            name: t('updateAddressScreenAddressName') || STRINGS.ACCOUNT.EDIT_ADRESSES.INPUT_2,
            value: propsConfig.current?.name,
            rules: [{name: "min"}],
            isValid: (isValid: boolean) =>
                setFormIsValid(isValid, t('updateAddressScreenAddressName') || STRINGS.ACCOUNT.EDIT_ADRESSES.INPUT_2),
            onChange: (event) =>
                onChangeInput(event, "name", t('updateAddressScreenAddressName') || STRINGS.ACCOUNT.EDIT_ADRESSES.INPUT_2),
        },

    ]);
    // 0 - autocomplete
    // 1 - name
    // 2 - digi_code
    // 3 - apartment_number
    // 4 - city
    // 5 - postal_code
    // 10 - state
    // 12 - country
    useEffect(() => {
        setIsFromGeoCoder(false)
        setRegionValue(inputs[inputs.findIndex((element) => element.id === 10)].value)

    }, [inputs[inputs.findIndex((element) => element.id === 10)].value])
    const [address, setAdrees] = useState<any>('')

    const [isFromGeoCoder, setIsFromGeoCoder] = useState<any>(false)
    const [placeAutoCompleteValue, setPlaceAutoCompleteValue] = useState<any>()
    const [regionValue, setRegionValue] = useState<any>('')
    const [regionValueShortName, setRegionValueShortName] = useState<any>('')

    // eslint-disable-next-line sonarjs/cognitive-complexity
    useEffect(() => {
        const address = inputs.find((item) => item.id === 0);
        const region = inputs.find((item) => item.id === 10);

        if (address && !nameAddressState) {

            const temp = [...inputs];
            inputs[2].value = ''
            temp[3].value = "";
            temp[4].value = "";
            temp[5].value = "";
            temp[6].value = "";
            temp[inputs.findIndex((element) => element.id === 4)].readonly = false
            temp[inputs.findIndex((element) => element.id === 10)].readonly = false
            temp[inputs.findIndex((element) => element.id === 5)].readonly = false
            temp[inputs.findIndex((element) => element.id === 12)].readonly = false
            formData.current = {
                ...formData.current,
                position: {
                    longitude: propsConfig.current?.position?.longitude,
                    latitude: propsConfig.current?.position?.latitude,
                    address: '',
                },
                country: '',
                city: '',
                state: '',
                short_name: '',
                postal_code: '',
                country_code: ''
            }

            setIsButtonDisabled(true)
            setInputs([...temp]);
        }
        if (isFromMap) {
            if (address && nameAddressState?.formatted_address) {
                address.value = checkIfDifferentAddress(selectedAddress,nameAddressState) ? nameAddressState.formatted_address : selectedAddress?.position?.address ;
                setCityPostalCodeCountryCode(nameAddressState.address_components)
                setAdrees('')
                !propsConfig.current && setCityPostalCodeCountryCode(nameAddressState?.address_components);
                setInputs([...inputs]);
                formData.current = {
                    ...formData.current,
                    position: {
                        latitude: checkIfDifferentAddress(selectedAddress,nameAddressState) ? nameAddressState.geometry.location.lat : selectedAddress?.position?.latitude,
                        longitude: checkIfDifferentAddress(selectedAddress,nameAddressState) ? nameAddressState.geometry.location.lng : selectedAddress?.position?.longitude,
                        address: checkIfDifferentAddress(selectedAddress,nameAddressState) ? nameAddressState.formatted_address : selectedAddress?.position?.address
                    },
                };
            }
        } else {
            if (address && placeAutoCompleteValue) {
                address.value = placeAutoCompleteValue?.name;
                setCityPostalCodeCountryCode(placeAutoCompleteValue?.address_components)
                setAdrees(placeAutoCompleteValue.name)
                !propsConfig.current && setCityPostalCodeCountryCode(placeAutoCompleteValue?.address_components);
                setInputs([...inputs]);
                formData.current = {
                    ...formData.current,
                    position: {
                        latitude: placeAutoCompleteValue?.geometry?.location?.lat(),
                        longitude: placeAutoCompleteValue?.geometry?.location?.lng(),
                        address: placeAutoCompleteValue?.name,
                    },
                };
            }
        }


    }, [nameAddressState]);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!!propsConfig.current && propsConfig.current?.position?.latitude !== undefined) {
            setFormIsValid(false, "");

            propsConfig?.current?.position &&
            Geocode?.fromLatLng(
                propsConfig?.current?.position.latitude,
                propsConfig?.current?.position.longitude
            ).then((response) => {

                    dispatch(setAddressName(response.results[0]));

                },
            );
        }
        return () => {
            dispatch(setAutoComplete(undefined));
            dispatch(setAddressName(''))
            dispatch(setSelectedAddress(formData?.current));
        };
    }, []);
    const setFormIsValid = (isValid: boolean, name: string) => {
        formInputsValidity.current = formInputsValidity.current.map(
            (item: { name: string; isValid: boolean }) => {
                return {
                    ...item,
                    isValid: item.name === name ? isValid : item.isValid,
                };
            }
        );
        setIsButtonDisabled(
            !!formInputsValidity.current.find(
                (item: { name: string; isValid: boolean }) => !item.isValid
            ) || !formData.current.position.address || !inputs[inputs.findIndex((element) => element.id === 10)].value
            || !inputs[inputs.findIndex((element) => element.id === 5)].value
        );

    };
    // 0 - autocomplete
    // 1 - name
    // 2 - digi_code
    // 3 - apartment_number
    // 4 - city
    // 5 - postal_code
    // 10 - state
    // 12 - country
    // eslint-disable-next-line sonarjs/cognitive-complexity

  useEffect(()=>{
      console.log(formData.current)
  },[formData.current])
    const placeSelectedReceived = (place: any) => {
        if (place.geometry) {
            dispatch(setIsFromMap(false))

            setGgPlacesErrors([]);
            setPlaceAutoCompleteValue(place)
            formData.current = {
                ...formData?.current,
                position: {
                    latitude: place?.geometry?.location?.lat(),
                    longitude: place?.geometry?.location?.lng(),
                    address: place?.name,
                },
            };

            propsConfig.current = {
                ...propsConfig?.current,
                position: {
                    latitude: place?.geometry?.location?.lat(),
                    longitude: place?.geometry?.location?.lng(),
                    address: place?.name,
                },
            };
            Geocode?.fromLatLng(
                place?.geometry?.location?.lat(),
                place?.geometry?.location?.lng()
            ).then((response) => {
                dispatch(
                    setAutoComplete({
                        lat: place?.geometry?.location?.lat(),
                        lng: place?.geometry?.location?.lng(),
                    })
                );
                setAddressName(response.results[0])
                setAdrees(place?.name)
                setCityPostalCodeCountryCode(response?.results[0].address_components, response.results[0].formatted_address);
            })
        }
    };
    const [streetNumber, setStreetNumber] = useState<any>('')
    const [route, setRoute] = useState<any>('')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    const setCityPostalCodeCountryCode = (data: any, adress?: any) => {

        let postal_code = "";
        let state = "";
        let state_name = "";
        let country_code = "";
        let country = "";
        let city = "";
        let city_name = "";
        const temp = [...inputs];
        data?.forEach(
            (item: {
                long_name: string;
                short_name: string;
                types: Array<string>;
            }) => {
                if (item.types.includes("locality")) {
                    city = item.short_name;
                    temp[inputs.findIndex((element) => element.id === 4)].value =
                        city_name = item.long_name;
                    // temp[inputs.findIndex((element) => element.id === 4)].readonly=true
                } else if (item.types.includes("country")) {
                    country_code = item.short_name;
                    country = item.long_name;
                    temp[inputs.findIndex((element) => element.id === 12)].value =
                        country = item.long_name;
                    // temp[inputs.findIndex((element) => element.id === 12)].readonly =true

                } else if (item.types.includes("postal_code")) {
                    postal_code = item.long_name || item.short_name
                    temp[inputs.findIndex((element) => element.id === 5)].value =
                        postal_code;
                    // temp[inputs.findIndex((element) => element.id === 5)].readonly =true


                } else if (item.types.includes("administrative_area_level_1")) {
                    state = item?.short_name;
                    state_name = item?.long_name;
                    temp[inputs.findIndex((element) => element.id === 10)].value =
                        state_name = item?.long_name;
                    // temp[inputs.findIndex((element) => element.id === 10)].readonly =true

                    setIsFromGeoCoder(true)
                    setRegionValue(item?.long_name)
                    setRegionValueShortName(item?.short_name)
                } else if (item?.types.includes('street_number') || item?.types.includes('premise')) {
                    const streetNumber =  selectedAddress?.position?.address?.toString()?.substring(0, selectedAddress?.position?.address?.toString()?.indexOf(' '))
                        setStreetNumber( Props?.config  ? checkIfDifferentAddress(selectedAddress,nameAddressState) ? item?.long_name : streetNumber  : item?.long_name)

                } else if (item?.types.includes('route') || item?.types.includes('locality')) {
                    const route =  selectedAddress?.position?.address?.toString()?.substring(selectedAddress?.position?.address?.toString()?.indexOf(' '), selectedAddress?.position?.address?.toString()?.length)

                        setRoute( Props?.config ?  checkIfDifferentAddress(selectedAddress,nameAddressState) ? item?.long_name : route :item?.long_name)

                }
            }
        );
        setFormIsValid(!!postal_code, STRINGS.ACCOUNT.EDIT_ADRESSES.INPUT_6);
        formData.current = {
            ...formData.current,
            state,
            postal_code,
            country_code,
            country,
            state_name,
            city,
        };
        // temp[3].value = postal_code ;
        setInputs([...temp]);
        setFormIsValid(false, "");

    };
    const isFromMap = useSelector((state: RootState) => state.root.isFromMap)

    const verifyGgPlace = (value: string) => {
        formData.current = {
            ...formData.current,
            position: {
                address: "",
                latitude: "",
                longitude: "",
            },
            postal_code: "",

        };
        const temp = [...inputs];
        temp[3].value = "";
        temp[4].value = "";
        temp[5].value = "";
        temp[6].value = "";

        setInputs([...temp]);
        if (value.trim().length === 0) {
            setGgPlacesErrors([t('fieldIsRequired')]);
        }
        setFormIsValid(false, "");
    };

    // eslint-disable-next-line sonarjs/cognitive-complexity
    const submitForm = () => {
        // eslint-disable-next-line sonarjs/no-collapsible-if
        if (route) {
            if (phoneValid) {
                if (!isButtonDisabled) {
                    dispatch(setIsOnEditAddress(false))
                    updateCreateAddress(
                        {
                            ...formData.current,
                            state: isFromGeoCoder ? formData.current.state ? formData.current.state : propsConfig.current?.state :
                                regionValue.toString().length > 4 ? regionValue.toString().substring(0, 4).toUpperCase() : regionValue.toString().substring(0, regionValue.length).toUpperCase(),
                            state_name: isFromGeoCoder ? formData.current.state_name ? formData.current.state_name : propsConfig.current?.state_name :
                                regionValue
                            ,

                            position: {
                                ...formData.current.position,
                                address:   isMobile() ?  formData.current.position.address:  address ? address : streetNumber + ' ' + route  ,
                                longitude: parseFloat(
                                    formData.current.position.longitude as string
                                ),
                                latitude: parseFloat(formData.current.position.latitude as string),
                            },
                            phone_receiver: phoneSelected,
                            apartment_number: formData.current?.apartment_number
                                ? formData.current?.apartment_number
                                : null,
                            digi_code: formData.current?.digi_code
                                ? formData.current?.digi_code
                                : null,
                        },
                        propsConfig.current?.id
                    ).then((res: any) => {
                        setAsDefaultAddress(res.data.id).then(() => {
                            dispatch(setDeliveryAdress(res.data as AddressDelivery));
                            Props.dissmissForm ? Props.dissmissForm() : navigate("/address");

                        })
                    });

                }
            } else {
                toast.error(t('invalidPhoneNumber'))
            }
        } else {
            toast.error(t('notValidAdress'))
        }

    };
    const setElement = (value: any) => {
        focus.current = value?.current;
    };

    return (
        <>
            <div className="center-vertically selected-adresse">
                <div className="blc-top">
                    <BackBtn pathTo="/address"/>
                    {isEditAddress ? (
                        <h1>
                            {t('edit') || STRINGS.ACCOUNT.EDIT_ADRESSES.FORM.SUB_TITLE2.PART1}{" "}
                            <span>{`${t('updateAddressScreenTitleStepOne')} ${t('address')}` || STRINGS.ACCOUNT.EDIT_ADRESSES.FORM.PART2}</span>
                        </h1>
                    ) : (
                        <h1>
                            {t('add') || STRINGS.ACCOUNT.EDIT_ADRESSES.FORM.SUB_TITLE1.PART1}{" "}
                            <span>{`${t('updateAddressScreenTitleStepOne')} ${t('address')}` || STRINGS.ACCOUNT.EDIT_ADRESSES.FORM.PART2}</span>
                        </h1>
                    )}
                </div>
                <p>
                    {!isEditAddress
                        ? (t('manageMyDeliveryAddressesScreenButtonAdd') || STRINGS.ACCOUNT.EDIT_ADRESSES.SUB_TITLE2)
                        : (t('updateAddressScreenHeaderUpdate') || STRINGS.ACCOUNT.EDIT_ADRESSES.SUB_TITLE1)}
                </p>
                {isMobile() && (
                    <>
                        <MyMapComponent
                            lng={locationAutoComplete
                                ? locationAutoComplete.lng
                                : Number(propsConfig.current?.position?.longitude)}
                            lat={locationAutoComplete
                                ? locationAutoComplete.lat
                                : Number(propsConfig.current?.position?.latitude)}
                            isMarkerShown/>

                        <div className="card-address center-horizontally align-center justify-center">
                            <p className="adress-name">
                                {propsConfig.current?.position?.address
                                    ? propsConfig.current?.position.address
                                    : nameAddressState
                                        ? nameAddressState?.formatted_address
                                        : t('updateAddressScreenClickOnAdjustHint')}
                            </p>
                        </div>
                    </>
                )}
                {inputs.map((item) => {
                    return item.type === "autoComplete" ? (
                        <GooglePlacesAutoComplete
                            onChange={(value: string) => verifyGgPlace(value)}
                            errors={ggPlacesErrors}
                            item={item}
                            placeSelectedEvent={placeSelectedReceived}
                            setElement={(value: any) => setElement(value)}/>
                    ) : (
                        <Input key={item.id} item={item} maxLength={item?.maxLength}/>
                    );

                })}
                <div>
                    <label htmlFor={'phone'}
                           className={'labelAddAdrees'}> {t('phoneScreenInputLabel').toUpperCase()}</label>
                    <PhoneInput
                        style={{position: 'relative', bottom: '10px'}}
                        international={true}
                        value={propsConfig?.current?.phone_receiver ? propsConfig?.current?.phone_receiver : me?.phone}
                        placeholder={t('phoneScreenInputLabel')}
                        // rules={{ required: true ,message:'azeazeaze', validate:isValidPhoneNumber }}
                        withCountryCallingCode={true}
                        useNationalFormatForDefaultCountryValue={true}
                        defaultCountry={'FR'}
                        onChange={(e: any) => {
                            onChangePhoneNumber(e);
                        }}/>
                    {!phoneValid && phoneSelected?.length > 5 &&
                        <span className={'errorAddAdresPhone'}> {t('invalidPhoneNumber')}</span>}
                </div>
                <div className="center-horizontally align-center action-container">
                    <p onClick={() => Props.dissmissForm && Props.dissmissForm()}>
                        {t('cancel') || STRINGS.CANCEL}
                    </p>
                    <button
                        onClick={submitForm}
                        className={`btn-save ${isButtonDisabled && "disabled"}`}
                    >
                        {t('validate') || STRINGS.VALIDATE}
                    </button>
                </div>
            </div>
        </>

    );
};
/* eslint-enable max-lines-per-function */
export default AddAddressForm;
