import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Input from "../../../../components/input/Input";
import TextArea from "../../../../components/text-area/TextArea";
import {PATHS} from "../../../../core/enums/paths";
import {STRINGS} from "../../../../core/enums/strings";
import {RootState, store} from "../../../../shared/store";
import {contactUs, getListCommandToClaim, sendClaim} from "../../../../shared/store/slices/Account/AccountService";
import {
	errorMessage,
	isMobile,
} from "../../../../shared/utils/utils-functions";
import "../Contact.scss";
import {Spin, Switch} from 'antd';
import {OrderClaimInterface} from "../../../../core/models/Order";
import {HTTPResponse} from "../../../../core/models/HttpModels";
import {ContactFormInterface} from "../../../../core/models/Contact";
import {useSelector} from "react-redux";
import {Select} from 'antd';
import {useTranslation} from "react-i18next";
import {getTerms} from "../../../../shared/store/slices/Auth/AuthService";
import {Item} from "../../../../components/chef-card/ChefCard";
import {alertMessage} from "../../../../shared/store/interceptor";
/* eslint-disable max-lines-per-function */
// eslint-disable-next-line sonarjs/cognitive-complexity
const ContactForm = (Props: any): JSX.Element => {
	const history = useNavigate();
	const profile = useSelector((state: RootState) => state.authentication.profile);
	const [listCommand, setListCommand] = useState<OrderClaimInterface[]>([]);
	const [data, setData] = useState<ContactFormInterface>({
		firstName : profile?.firstName ?  profile?.firstName : '',
		lastName: profile?.lastName ? profile?.lastName :'',
		email: profile?.email ? profile?.email : "",
		message: "",
		id_command: null,
		id_type: 4,
		products: null
	});
	useEffect(() => {
		getTerms().then()
	}, [])
	const [isCheck, setIsCheck] = useState<boolean>(false);
	const {t} = useTranslation()
	const isLogged = useSelector((state:RootState)=>state.authentication.isLoggedIn)
	const isValid =
		!errorMessage(data.firstName || "", [{name: "required"}], t) &&
		!errorMessage(data.lastName || "", [{name: "required"}], t) &&
		!errorMessage(data.email || "", [
			{name: "required"},
			{name: "email"},
		], t) &&
		!errorMessage(data.message || "", [{name: "required"}], t) &&
		isCheck;
	const isValidSecondary =
		!errorMessage(data.firstName || "", [{name: "required"}], t) &&
		!errorMessage(data.lastName || "", [{name: "required"}], t) &&
		!errorMessage(data.email || "", [
			{name: "required"},
			{name: "email"},
		], t) &&
		!errorMessage(data.message || "", [{name: "required"}], t) ;


	const sendMessage = async (data: any) => {
		isValid &&
		(await sendClaim(data).then((res) => {
			const pathTo = isMobile() ? PATHS.HELP_SUCCESS_MOBILE : "/account/success";
			history(pathTo, {state: {fromContact: true}})
		}));
	};

  const [spin,setSpin]=useState(false)
  const [spinner,setSpinner]=useState(false)
	// eslint-disable-next-line sonarjs/cognitive-complexity
	// eslint-disable-next-line sonarjs/no-all-duplicated-branches
	const sendMessageSecondary = async (data: any) => {
		isValidSecondary &&
			setSpin(true)
		setTimeout(()=>{
			setSpin(false)
			alertMessage(t('send_message_contact_support'),true)
		},2000)

	};

	function handleChange(e: { target: { value: any } }) {
		setData({...data, id_command: e.target.value ? Number(e.target.value) : null});
	}

	function getListCommand() {
		getListCommandToClaim().then((data: HTTPResponse) => {
			setListCommand(data.data as OrderClaimInterface[]);
		}).catch(() => {
			setListCommand([]);
		});
	}

	const terms = useSelector((state: RootState) => state.authentication.terms)

	const [isOpenNew, setIsOpenNews] = useState<any>(false)
	const onCloseMenu = () => {
		setIsOpenNews(false);
		document.body.style.overflow = "auto";
	};
	const width = useSelector((state: RootState) => state.root.DEVICE_WIDTH)
	useEffect(() => {
		if (isLogged){
			getListCommand();
		}
	}, []);
	const {Option} = Select;
	const txt = t('newsLettreTitle')
	useEffect(() => {
		const target = document.getElementById(1 as unknown as string);
		if (target) {
			target.className = 'newsLettreContainerReglement'
		}
	}, [isOpenNew])
	const setSelectedOrder = (id: any) => {
		setData({...data, id_command: id})
	}
	useEffect(() => {
		if (isOpenNew) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = 'auto'

		}
	}, [isOpenNew])
	return (
		<div className="blc-contact" style={{width:!isMobile() && window.location.pathname.includes('/contact-support')?'100%':''}}>
			<p>{t('contactScreenFormText') || STRINGS.ACCOUNT.CONTACT.TXT2}</p>
			{isLogged  && !Props?.secondary &&
            <div className="select-blc">
                <Select onSelect={(item) => {
					setSelectedOrder(item)
				}} placeholder={t('chooseCommandeNumber')} defaultOpen={false}
                        disabled={!listCommand || listCommand?.length === 0}>
					{listCommand && listCommand.map((item) => {
						return (
							<Option key={item.id} value={item.id} className="order-options">
								#{item.id + ' ' + item.restaurant.name}
							</Option>
						);
					})}
                </Select>
                <label>{t('contactScreenNumberDelivery') || STRINGS.ACCOUNT.CONTACT.PLACEHOLDER7} </label>
            </div>
			}
			<Input
				item={{
					type: "text",
					name: t('personnelInformationFirstNameInput') || STRINGS.ACCOUNT.CONTACT.PLACEHOLDER4,
					rules: [{name: "required"}],
					id: 1,
					value: !Props?.secondary ? profile?.firstName  : '',
					onChange: (e) => {
						setData({...data, firstName: e.target.value});
					},
				}}
			/>
			<Input
				item={{
					type: "text",
					name: t('personnelInformationLastNameInput') || STRINGS.ACCOUNT.CONTACT.PLACEHOLDER5,
					rules: [{name: "required"}],
					id: 2,
					value: !Props?.secondary ?  profile?.lastName : '',
					onChange: (e) => {
						setData({...data, lastName: e.target.value});
					},
				}}
			/>
			<Input
				item={{
					type: "email",
					name: t('emailScreenTitleStepThree') || STRINGS.ACCOUNT.CONTACT.PLACEHOLDER6,
					rules: [{name: "required"}, {name: "email"}],
					id: 3,
					value:  !Props?.secondary ? profile?.email : '',
					onChange: (e) => {
						setData({...data, email: e.target.value});
					},
				}}
			/>
			<TextArea
				item={{
					type: "text",
					name: t('contactScreenMessageInput') || STRINGS.ACCOUNT.CONTACT.PLACEHOLDER2,
					id: 4,
					rules: [{name: "required"}],
					onChange: (e) => {
						setData({...data, message: e.target.value});
					},
				}}
			/>

			{!window.location.pathname.includes('/contact-support') &&
            <div className="blc-checkbox center-horizontally align-center space-between">
                <label>
					{t('contactScreenCheckboxText1') || STRINGS.ACCOUNT.CONTACT.CHECKBOX}
                    &nbsp;<label onClick={() => {
					setIsOpenNews(true)
				}}
                                 style={{
									 borderBottom: '0.5px solid',
									 cursor: 'pointer',
									 color: "black",
									 pointerEvents:'all'
								 }}>{t('politic').toUpperCase()}</label>&nbsp;
                    <label className={'politicSuite'}>{t('politicSuite')}</label>
                </label>
                <Switch onChange={() => setIsCheck(!isCheck)} checked={isCheck}/>
            </div>

			}

			{
				!isMobile() && isOpenNew &&
                <div id={'1'} className={'newsLettreContainer'} style={{width: '30%'}}>
                    <div style={{display: "flex"}}>
                        <button onClick={onCloseMenu} className="back-btn"/>
                        <h1 className={'regleTitle'} style={{
							color: "black",
							width: '85%',
							fontStyle: 'unset',
							position: "relative",
							top: '10px'
						}}> {txt.toUpperCase()} </h1>
                    </div>
                    <div className={'divHight'}/>
                    <div style={{position: "relative"}}>
						{
							terms?.map((item: any) => (
								<div className={'regleContainer'} key={item?.id}>
                <span className={'regleText'}>
                            <p style={{color: 'black'}} dangerouslySetInnerHTML={{__html: item?.text}}
							   className={'regleText'}/>
                </span>
								</div>
							))
						}
						{/*<div className={'regleBlur1'}/>*/}
                        <div className={'regleBlur'}/>
                    </div>
                </div>
			}
			{
				isMobile() && isOpenNew &&
                <div id={'1'} className={'newsLettreContainer'} style={{width: '100%'}}>
                    <div style={{display: "flex"}}>
                        <button onClick={onCloseMenu} className="back-btn"/>
                        <h1 className={'regleTitle'} style={{
							color: "black",
							width: '85%',
							fontStyle: 'unset',
							position: "relative",
							top: '10px',
							left: '10px'
						}}> {txt.toUpperCase()} </h1>
                    </div>
                    <div className={'divHight'}/>
                    <div style={{position: "relative"}}>
						{
							// eslint-disable-next-line sonarjs/no-identical-functions
							terms?.map((item: any) => (
								<div className={'regleContainer'} key={item?.id}>
                <span className={'regleText'}>
                    <span dangerouslySetInnerHTML={{__html: item?.text}} className={'regleText'}/>
                </span>
								</div>
							))
						}
						{/*<div className={'regleBlur1'}/>*/}
                        <div className={'regleBlur'}/>
                    </div>
                </div>
			}
			{
				Props?.secondary ?

					<button
						onClick={() => sendMessageSecondary(data)}
						className={isValidSecondary ? "btn-primary" : "btn-primary disabled"}
					>
						{spin ? <Spin spinning={spin} delay={300}/> : t('send') || STRINGS.HOME.BTN.SEND}
					</button>
					:

					<button
						onClick={() => sendMessage(data)}
						className={isValid ? "btn-primary" : "btn-primary disabled"}
					>
						{t('send') || STRINGS.HOME.BTN.SEND}
					</button>
			}


			{/*<div style={{height:'150px'}}/>*/}
		</div>
	);
};
/* eslint-enable max-lines-per-function */

export default ContactForm;
