import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CategoryHome, Chef, ChefModel, HomeModel} from "../../../../core/models/Chef";
import {CategorieModel, Menu, MenuModel, plate} from "../../../../core/models/Menu";
import {Resto, RestoModel} from "../../../../core/models/Resto";

export interface ChefsState {
  homeList: HomeModel;
  chefList: Chef;
  restos: RestoModel[];
  chef: Resto,
  menuChef: Menu,
  totalChefs: number,
  totalRestos?: number,
  plate: plate,
  plateBottomSheet: CategorieModel;
  category: CategoryHome,
  categories: CategoryHome[],
  chefSelected: any,
  rechercheRecent: any
  rechercheResult:any,
  closeSearch:any
}

export const chefsInitialState: ChefsState = {
  closeSearch:false,
  homeList: {
    restaurant: [],
    category: [],
    sub_categories:[]
  },
  rechercheResult:undefined,
  chefSelected: undefined,
  rechercheRecent: undefined,
  chefList: {
    list: [],
    category: {
      description: "",
      id: 0,
      image: [],
      name: "",
    }
  },
  restos: [],
  chef: {
    restaurant: {

      id: undefined,
      name: '',
      city: '',
      address: '',
      category: {
        id: undefined,
        name: '',
        description: '',
        image: []
      },
      number_menu: 0,
      number_plate: 0,
      menus: [],
      plates: [],
      image: '',
      description: '',
      chef_restaurant: {
        id: undefined,
        email: '',
        role: '',
        firstName: '',
        lastName: '',
        description: '',
        image_profile: [],
        number_restaurant: 0,
        isResto: false,
        restaurant: [],
        stars: undefined
      }
    },
    categories: [],
    same_plates: []
  },
  menuChef: {
    menu: {
      id: 0,
      title: '',
      description: '',
      total_price: 0,
      cover_picture: [],
      desserts: [],
      plates: [],
      entries: [],
      quantity: 0,
      id_product_basket: 0
    },
    same_chef: []
  },
  totalChefs: 0,
  totalRestos: 0,

  plate: {
    plate: {
      id: 0,
      price: 0,
      description: '',
      title: '',
      categorie: '',
      utensils: [],
      allergenes: '',
      preparation_array: [],
      imagePlats: [],
      preparation_stage: [],
      composition_package: [],
      conservation_plate: [],
      quantity: 0,
      id_product_basket: 0,
      is_best_seller: false
    },
    same_chef: []
  },
  plateBottomSheet: {
    id: 0,
    price: 0,
    description: '',
    title: '',
    categorie: '',
    utensils: [],
    allergenes: '',
    preparation_array: [],
    imagePlats: [],
    preparation_stage: [],
    composition_package: [],
    conservation_plate: [],
    quantity: 0,
    id_product_basket: 0
  },
  category: {
    description: '',
    id: 0,
    image: undefined,
    name: '',
    chef_category: [],
    is_best_category: false
  },
  categories: []
};

export const chefSlice = createSlice({
  name: 'chefs',
  initialState: chefsInitialState,
  reducers: {
    closeSearching: (state, action: PayloadAction<any>): void => {
      state.closeSearch = action.payload;
    },
    getChefs: (state, action: PayloadAction<Chef>): void => {
      state.chefList = action.payload;
    },
    getRestos: (state, action: PayloadAction<RestoModel[]>): void => {
      state.restos = action.payload;
    },
    getChefDetails: (state, action: PayloadAction<Resto>): void => {
      state.chef = action.payload;
    },
    getChefMenuDetails: (state, action: PayloadAction<Menu>): void => {
      state.menuChef = action.payload;
    },
    setTotalChefs: (state, action: PayloadAction<number>): void => {
      state.totalChefs = action.payload;
    },
    setTotalRestos: (state, action: PayloadAction<number>): void => {
      state.totalRestos = action.payload;
    },
    getChefPlateDetails: (state, action: PayloadAction<plate>): void => {
      state.plate = action.payload;
    },
    getChefPlatDetails: (state, action: PayloadAction<CategorieModel>): void => {
      state.plateBottomSheet = action.payload;
    },
    getCategory: (state, action: PayloadAction<CategoryHome>): void => {
      state.category = action.payload;
    },
    getCategories: (state, action: PayloadAction<CategoryHome[]>): void => {
      state.categories = action.payload;
    },
    getHomeChefs: (state, action: PayloadAction<HomeModel>): void => {
      state.homeList = action.payload;
    },
    setMenuQuantity: (state, action: PayloadAction<number>): void => {
      state.menuChef.menu.quantity = action.payload;
    },
    setSelectedChef: (state, action: PayloadAction<any>) => {
      state.chefSelected = action.payload
    },
    setRechercheRecent: (state, action: PayloadAction<any>) => {
      state.rechercheRecent = action.payload
    },
    setResult: (state, action: PayloadAction<any>) => {
      state.rechercheResult = action.payload
    },
    setChefListWithStars: (state, action: PayloadAction<any>) => {
      state.chefSelected = action.payload
    },

  },
});
export const {
  getHomeChefs,
  setRechercheRecent,
  getCategories,
  getCategory,
  getChefPlatDetails,
  getChefPlateDetails,
  getChefs,
  getRestos,
  getChefDetails,
  getChefMenuDetails,
  setTotalChefs,
  setTotalRestos,
  setSelectedChef,
  setResult,
  closeSearching,
    setChefListWithStars
} = chefSlice.actions;