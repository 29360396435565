import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import { useNavigate} from "react-router";
import goToFlech from '../../../../../assets/icons/explore/rightArrow.svg'
import Parallax from "../../../../../components/parallex/parallax";
import {motion} from "framer-motion";
import {setBackRoot} from "../../../../../shared/store/slices/Root/rootSlice";
import {getChef} from "../../../../../shared/store/slices/Chefs/ChefsService";
import {useTranslation} from "react-i18next";
import ImageContainer from "../../../../../components/imageContainer/imageContainer";
import {isMobile} from "../../../../../shared/utils/utils-functions";

// eslint-disable-next-line max-lines-per-function
const ChefCardExplorer = (Props:any): JSX.Element => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // eslint-disable-next-line sonarjs/cognitive-complexity,max-lines-per-function
  const goTo = async (item:any) => {
    dispatch(setBackRoot({path: location.pathname}));
    let state: any = {item:{}};
    let path = '';
    if (item.number_restaurant>1){
      path = `/details-chef/chef=${item?.id}`;
      state = {
        from: location.pathname,
        state: {item:item},
      }
      dispatch(setBackRoot({path: location.pathname, ...state.state}))
      navigate(path, state);
    } else {
        await getChef(item?.id).then((data) => {
        dispatch(setBackRoot({
          path: location.pathname,
          item: item,
          itemResto: item.restaurant[0].id
        }));
        navigate(`/more-detail/chef=${item?.id}/restaurant=${item?.restaurant[0].id}`, {
          state: {
            from: location.pathname,
            item: item,
            itemResto: {id: item.restaurant[0].id},
            id:item?.id
          }
        })
      });
    }
  }

  const [slicedList, setListSlice] = useState<any>([])
  useEffect(() => {

    if (Props.item.length > 3) {
      setListSlice(Props.item.slice(0,3))
    } else {
      setListSlice(Props.item)
    }

  }, Props.item)

  const {t} = useTranslation()
  const MotionComponent = motion(ImageContainer)
  return (
      // eslint-disable-next-line max-lines-per-function
    slicedList?.map((item: any,index:any) => {
      return (
        <div onClick={() => goTo(item)}  key={item.id} className={'chefCards'}>
          <div className={'topCard'}>
            {
              isMobile() ?
                    <MotionComponent
                        key={item?.id}
                        src={item?.image_profile[1].url}
                        alt={''}
                        className={'chefPhoto'}
                    />
                  :
                  <Parallax>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/*@ts-ignore*/}
                    <MotionComponent
                        key={item?.id}
                        src={item?.image_profile[1].url}
                        alt={''}
                        className={'chefPhoto'}
                    />
                  </Parallax>
            }
          </div>
          {/*<img src={item.image_profile} alt={''} className={'chefPhoto'}/>*/}
          <div className={'footerCard'}>
            <p>{item?.firstName}</p>
            <p>{item?.lastName}</p>
            <div className={'restaurant_Chef'}>
              <div className={'restoNumber'}>
                <h3 className={'restoNumberPerChef'}> {item?.number_restaurant === 1 ? item?.number_restaurant + ' ' + 'restaurant ':
                item?.number_restaurant + ' ' + 'restaurants'
                }</h3>
              </div>
              <img src={goToFlech} alt={''} className={'fleche'} onClick={() => goTo(item)}/>
            </div>
          </div>
        </div>
      )
    })

  )
}
export default ChefCardExplorer
