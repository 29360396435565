import React, {FunctionComponent, ReactChild, useEffect, useRef, useState} from "react";
import './horizontalScrollComponent.scss'

// eslint-disable-next-line max-lines-per-function
const HorizontalScrollComponent:
  React.FC<{
    children: React.ReactChild[] | React.ReactChild,
    className: string,
    // eslint-disable-next-line max-lines-per-function
  }> = (
  {
    children,
    className,
  }
) => {

  const baseStartingX = useRef<number>(NaN);
  const clickEventStartX = useRef<number>(NaN);
  const startingX = useRef<number>(NaN);
  const scrollMenuRef = useRef<HTMLDivElement>(null);
  const [translateX, setTranslateX] = useState(0);
  const timeline = useRef({
    start: 0,
    end: 0,
  })
  const [leftPos, setLeftPos] = useState(0)
  const [showScrollBar, setShowScrollBar] = useState(false)
  const thumbRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    document.addEventListener('mouseup', (e) => {
      mouseUp(e)
    })
    document.addEventListener('touchend', () => {
      setTranslateX(0)
    })
    window.addEventListener('resize', () => {
      checkScrollBarVisibility()
    })
    checkScrollBarVisibility()
  }, [])

  const checkScrollBarVisibility = () => {
    if (scrollMenuRef.current) {
      setShowScrollBar(scrollMenuRef.current?.scrollWidth !== scrollMenuRef.current?.clientWidth)
    }
  }

  const mouseDown = (event: React.MouseEvent<HTMLDivElement> | React.Touch) => {
    startingX.current = event.screenX
    baseStartingX.current = event.screenX
    clickEventStartX.current = event.screenX
    timeline.current.start = performance.now();
    clearInterval(interval.current)
  }

  const mouseMove = (event: React.MouseEvent<HTMLDivElement> | React.Touch) => {
    if (!isNaN(startingX.current)) {
      scrollMenuRef.current?.scrollBy({
        behavior: 'auto',
        left: (startingX.current || 0) - event.screenX
      })
      checkOverScrollBehaviour(startingX.current, event.screenX)
      startingX.current = event.screenX
      getScrollPercentage()
    }
  }

  const touchMove = (event: React.Touch) => {
    if (!isNaN(startingX.current)) {
      checkOverScrollBehaviour(startingX.current, event.screenX)
      startingX.current = event.screenX
      getScrollPercentage()
    }
  }

  const mouseUp = (event: MouseEvent | React.Touch) => {
    if (!isNaN(startingX.current)) {
      timeline.current.end = performance.now();
      checkFreeScrollBehaviour(baseStartingX.current as number, event.screenX);
    }
    setTranslateX(0);
  }

  const checkOverScrollBehaviour = (x: number, x1: number) => {
    if (scrollMenuRef.current) {
      const scrollRight = scrollMenuRef.current?.scrollWidth -
        (scrollMenuRef.current?.scrollLeft + scrollMenuRef.current?.clientWidth)
      setTranslateX(getTranslateXValue(scrollMenuRef.current?.scrollLeft, scrollRight, x, x1))
    }
  }

  const getTranslateXValue = (left: number, right: number, x: number, x1: number): number => {
    if (left === 0 || right === 0) {
      const acc = x > x1 ? -0.5 : 0.5
      return translateX + acc
    }
    return 0
  }

  const checkFreeScrollBehaviour = (startX: number, endX: number) => {
    startingX.current = NaN;
    baseStartingX.current = NaN
    if (timeline.current.end - timeline.current.start < 150) {
      scrollInterval(startX, endX)
    }
  }

  const interval = useRef<any>(null)
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const scrollInterval = (startX: number, endX: number) => {
    let distance = Math.abs((startX - endX) * 1.5) + 300;
    const initialDistance = JSON.parse(JSON.stringify(distance))
    interval.current = setInterval(() => {
      if (distance > 0) {
        const data = getFreeScrollMultiplier(startX > endX, distance, initialDistance)
        scrollMenuRef.current?.scrollBy({
          behavior: 'auto',
          left: data.left
        })
        distance = data.distance;
      } else {
        clearInterval(interval.current)
      }
    }, 7)
  }

  const getFreeScrollMultiplier = (direction: boolean, distanceLeftToScrollOver: number, initialDistance: number): {
    left: number,
    distance: number
    // eslint-disable-next-line sonarjs/cognitive-complexity
  } => {
    const multiplier = (distanceLeftToScrollOver / initialDistance)
    return {
      left: (50 * multiplier > 0.5 ? 50 * multiplier : 0.5) * (direction ? 1 : -1),
      distance: distanceLeftToScrollOver - (50 * multiplier > 0.5 ? 50 * multiplier : 0.5)
    }
  }

  const getScrollPercentage = () => {
    if (scrollMenuRef.current && thumbRef.current) {
      setLeftPos(100 * scrollMenuRef.current?.scrollLeft / (scrollMenuRef.current?.scrollWidth ))
    }
  }

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    clickEventStartX.current = NaN;
  }

  const getWidth = () => {
    if (scrollMenuRef.current)
      return `${((scrollMenuRef.current?.clientWidth || 0) * 100) / (scrollMenuRef.current?.scrollWidth || 0)}%`
    return '100px'
  }

  return (
    <div>
      <div
        onMouseDown={(e) => mouseDown(e)}
        onTouchStart={(e) => mouseDown(e.changedTouches[0])}
        onMouseMove={(e) => mouseMove(e)}
        onTouchMove={(e) => touchMove(e.changedTouches[0])}
        className={`horizontalScrollCont ${className} ${translateX === 0 ? 'transition' : ''}`}
        ref={scrollMenuRef}
        style={{transform: `translate(${translateX}px)`}}
        onScroll={getScrollPercentage}
        onClick={handleClick}
      >
        {children}
      </div>
      {
        showScrollBar && (
          <div className={'scrollBarCont'}>
            <div ref={thumbRef} className={'scrollBarThumb'} style={{width: getWidth(), left: `min(calc(100% - 100px), ${leftPos}%)`}}/>
          </div>
        )
      }
    </div>
  )
}

export default HorizontalScrollComponent;
