import React, { useState } from "react";
import { STRINGS } from "../../core/enums/strings";
import { Address } from "../../core/models/Address";
import {
  deleteAddress,
  getAddressesList,
  setAsDefaultAddress,
} from "../../shared/store/slices/Account/AccountService";
import "./AdresseItem.scss";
import ConfirmBottomSheet from "../confirm-bottom-sheet/ConfirmBottomSheet";
import { useDispatch } from "react-redux";
import { setDeliveryAdress } from "../../shared/store/slices/Payment/PaymentSlice";
import { AddressDelivery } from "../../core/models/Payment";
import { logout } from "../../shared/store/slices/Auth/AuthService";
import { Modal } from "antd";
import {t} from "i18next";
import {setIsEditAddress} from "../../shared/store/slices/Root/rootSlice";
import {setIsOnEditAddress} from "../../shared/store/slices/Account/AccountSlice";

interface Item {
  item: Address;
  action: () => void;
}

// eslint-disable-next-line max-lines-per-function
const AdresseItem = (props: Item): JSX.Element => {
  const [isConfirmBottomSheet, setIsConfirmBottomSheet] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const setAsDefault = () => {
    if (!props.item?.default_address && props?.item?.id) {
      setAsDefaultAddress(props.item.id).then(() => {
        dispatch(setDeliveryAdress(props.item as AddressDelivery));
        getAddressesList().then();
      });
    }
  };

  const confirmAction = (isYes: boolean) => {
    if (props.item.id && isYes) {
      deleteAddress(props.item.id).then(() => getAddressesList());
      dispatch(setDeliveryAdress(null));
    }
    setIsConfirmBottomSheet(false);
  };
  const navigateToUpdate=()=>{
    if (!props.item.isOnEdit){
      dispatch(setIsOnEditAddress(true))
      return props.action
    }

    // !props.item.isOnEdit ? props.action : undefined;
  }
  return (
    <div
      className={` center-vertically item-content ${
        props.item?.default_address && "default"
      }`}
      key={props.item.id}
    >
      <div className="center-horizontally space-between align-center">
        <div
          className={
            !props.item.isOnEdit
              ? "type-content center-horizontally align-center"
              : "type-content-edit center-horizontally align-center"
          }
        >
          <p>{props.item.name}</p>
        </div>

        <p className={!props.item.isOnEdit ? "edit" : "is-edit"}>
          <button
            className="delete"
            onClick={() => setIsConfirmBottomSheet(true)}
          >
            {t('manageMyDeliveryAddressesModalMessageConfirmDelete')}
          </button>
          <label
            className="cursor"
            onClick={navigateToUpdate()}
          >
            {t('edit')}
          </label>
        </p>
      </div>
      <div
        onClick={setAsDefault}
        className={
          !props.item.isOnEdit
            ? "center-vertically info-content"
            : "center-vertically info-content-edit"
        }
      >
        {/*<p>{props?.item?.name + " " + props?.item?.position?.address}</p>*/}
        <p>{props?.item?.first_name} {props?.item?.last_name} </p>
        <p>{props?.item?.position?.address}</p>
        <p>
          {
            props?.item?.pivot_code}
        </p>
        <p>{props?.item?.postal_code}  {props?.item?.city}</p>

        <p>{props?.item?.country}</p>
        <p>{props?.item?.phone_receiver}</p>

        <span className="default-txt">{t('defaultAdress')}</span>
      </div>
      <ConfirmBottomSheet
        action={confirmAction}
        yesText={t('manageMyDeliveryAddressesModalMessageConfirmDelete')}
        title={t('manageMyDeliveryAddressesModalQuestion')}
        text=""
        isDelete={true}
        open={isConfirmBottomSheet}
      />
    </div>
  );
};

export default AdresseItem;
