import React, {useEffect, useState} from "react";
import Header from "../../components/header/Header";
import { STRINGS } from "../../core/enums/strings";
import Input from "../../components/input/Input";
import { Link, useNavigate } from "react-router-dom";
import { PATHS } from "../../core/enums/paths";
import "./auth.scss";
import {login, socialAccountLogin} from "../../shared/store/slices/Auth/AuthService";
import { useSelector } from "react-redux";
import { RootState } from "../../shared/store";
import { isMobile } from "../../shared/utils/utils-functions";
import { useLocation } from "react-router-dom";
import  sun from '../../assets/icons/INSCRIPTION CONNEXION FR.svg'
import  sun2 from '../../assets/icons/SIGN IN LOG IN EN.svg'
import {useTranslation} from "react-i18next";
import {GoogleLogin} from "react-google-login";
import '../../index.scss'
import {APP_ID_FACEBOOK_AUTHENTIFICATION, CLIENT_ID_GOOGLE_AUTHENTIFICATION} from "../../core/enums/APIS";
import close from '../../assets/icons/close-btn.svg'
import {alertMessage} from "../../shared/store/interceptor";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import AppleSignin from 'react-apple-signin-auth';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

export interface Login {
  email: string;
  password: string;
  basket_id?: number | null;
}

// eslint-disable-next-line max-lines-per-function,sonarjs/cognitive-complexity
const LoginWeb = (): JSX.Element => {
    const [language,setLanguage]=useState<any>()
    useEffect(()=>{
        setLanguage(localStorage.getItem('SUPERNOVA_LANGUAGE'))
    },[])
  const history = useNavigate();
  const state=useLocation()

  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState<Login>({
    email:state ? state?.state?.email :"" ,
    password: "",
    basket_id: localStorage.getItem("basket_id")
      ? Number(localStorage.getItem("basket_id"))
      : null,
  });
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
  const [isValidPassword, setIsValidPassword] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
    const [isFromSocial,setIsFromSocial]=useState<boolean>()
    const [openLoginModal,setIsOpenModal]=useState<boolean>()
    const [loginType,setLoginType]=useState<string>('')
//     const socialResponse=useSelector((state:RootState)=>state.authentication.socialResponse)
// // eslint-disable-next-line sonarjs/no-identical-functions
//     useEffect(()=>{
//         if (socialResponse){
//             setLoginType(socialResponse.data.social_account_method)
//             setIsFromSocial(socialResponse.data.is_used_social_account)
//         }
//     },[socialResponse])
  const onShowPassword = () => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

    // eslint-disable-next-line sonarjs/cognitive-complexity
  const loginUser = async () => {

    await login(data)
      .then((res:any) => {
          if (res?.message.includes('Impossible de vous')){
              setIsFromSocial(res.data.social_account_method)
              setLoginType(res.data.social_account_method)
              setIsOpenModal(true)
          }else{
              !isMobile() ? history("/account/profile") : history("/account");
          }
      })
  };

  const setFormIsValid = (isValid: boolean, isEmail: boolean) => {
    if (isEmail) {
      setIsValidEmail(isValid);
    } else {
      setIsValidPassword(isValid);
    }
  };

  const Submit = () => {
    setIsSubmit(true);
    if (isValidEmail && isValidPassword) {
      loginUser();
    }
  };

  const {t} = useTranslation()
    const connectSocialAccount = (data:any) => {
        socialAccountLogin(data).then(() => {
            // eslint-disable-next-line sonarjs/no-duplicate-string
            !isMobile() ? history("/account/profile") : history("/account")  ;
        });
    };
    const responseApple = (response:any) => {

        if (response?.authorization) {
            const appleData  = {
                firstName: response?.user?.name?.firstName,
                lastName: response?.user?.name?.lastName,
                email:data.email,
                provider_id: response?.authorization?.id_token,
                provider_name: "Apple",
                basket_id: localStorage.getItem('basket_id') ? Number(localStorage.getItem('basket_id')) : null
            }
            connectSocialAccount(appleData);
        }
    }
    const responseFacebook = (response:any) => {
        console.warn('response facebook', response);
        if (response?.email){
            const fullName=response?.name;
            let details=[]
            details=fullName?.split(' ');
            let firstName = '';
            let lastName = '';
            if (details?.length > 1) {
                firstName = details[0];
                lastName = details[1];
            }
            const facebookData = {
                firstName,
                lastName,
                email: response?.email,
                provider_id: response?.accessToken,
                provider_name: "Facebook",
                basket_id: localStorage.getItem('basket_id') ? Number(localStorage.getItem('basket_id')) : null
            }
            connectSocialAccount(facebookData);
        }
    };

    const responseGoogle = (response:any) => {
        const googleData = {
            firstName: response?.profileObj?.givenName,
            lastName: response?.profileObj?.familyName,
            email: response?.profileObj?.email,
            provider_id: response?.accessToken,
            provider_name: "Google",
            basket_id: localStorage.getItem('basket_id') ? Number(localStorage.getItem('basket_id')) : null
        }
        connectSocialAccount(googleData);
    };
  return (

    <div className="container-desk">


      <div className="img-top">
          {
              language === 'fr' ?
                      <img src={sun} className="round"/>
                  :
                      <img src={sun2} className="round"/>

          }
      </div>

      <h1 className="title">{t('connectWithMail') || STRINGS.LOGIN_MAIL.TITLE}</h1>

      <div className="formulaire">

        <div className="center-vertically">

          <Input
            item={{
              type: "email",
              name: t('emailScreenPlaceholderText') || STRINGS.ACCOUNT.EDITPROFILE.ADDRESS_MAIL,
              onChange: (e) => setData({ ...data, email: e.target.value }),
              rules: [{ name: "required" }, { name: "email" }],
              isValid: (isValid: boolean) => setFormIsValid(isValid, true),
              value: data.email,
            }}
            isSubmit={isSubmit}
          />

          <Input
            eyes={true}
            onShow={onShowPassword}
            isShow={showPassword}
            item={{
              type: !showPassword ? "password" : "text",
              name: t('passwordScreenInputPassword') || STRINGS.ACCOUNT.EDITPROFILE.PASSWORD,
              onChange: (e) => setData({ ...data, password: e.target.value }),
              rules: [{ name: "minLength", min: 8 }, { name: "required" }],
              isValid: (isValid: boolean) => setFormIsValid(isValid, false),
              value: data.password,
            }}
            isSubmit={isSubmit}
          />
        </div>

        <div onClick={() => history(PATHS.FORGET_PASSWORD)} className="link">
          {t('loginPasswordForgetPassword') || STRINGS.LOGIN_MAIL.FORGET_PASSWORD}
        </div>
          {
              openLoginModal&&isFromSocial && loginType!=='' &&
              <div className={'loginModal'} style={!isMobile() ? {position:'relative',width:'100%',marginRight:'unset'}:{}} >
                  <button className={'close'} style={{position:'absolute',backgroundSize:'22px',width:'30px',height:'30px',right:'0',top:'-40px',left:'unset'}}
                          onClick={() => {
                              setIsOpenModal(false)
                              setLoginType('')
                              setIsFromSocial(false)
                          }
                          } />
                  <div className={'titleModalLogin'}>
						<span className={'titleText'}>
							{t('mailAlreadyInUse')}
						</span>
                  </div>
                  <div className={'bodyModalLogin'}>
                      <span className={'bodyText'}> {t('otherLoginMethod')}</span>

                  </div>
                  <div className={'Smedia'}>
                      {
                          loginType==='Google' ?
                              <GoogleLogin
                                  clientId={`${process.env.REACT_APP_API_GOOGLE_AUTHENTIFICATION}`}
                                  render={(renderProps) => (
                                      <button onClick={renderProps.onClick} className="gmail">
                                          <span>{t('registrationScreenWithGoogle') || STRINGS.LOGIN.LOGIN_GOOGLE}</span>
                                      </button>
                                  )}
                                  onSuccess={responseGoogle}
                                  onFailure={(res) => res?.details && alertMessage(res.details)}
                                  cookiePolicy={"single_host_origin"}
                              />
                              : loginType=== 'Apple' ?
                                  <AppleSignin
                                      authOptions={{
                                          clientId: "com.supernova.service",
                                          scope: "email name",
                                          redirectURI: "https://suppernova.com/login",
                                          state: "",
                                          nonce: "nonce",
                                          usePopup: true,
                                      }}
                                      uiType="dark"
                                      className="apple-auth-btn"
                                      noDefaultStyle={false}
                                      onSuccess={responseApple}
                                      onError={(error:any) => console.error(error)}
                                      skipScript={false}
                                      iconProp={{ style: { marginRight: "10px" } }}
                                      render={(props:any) => (
                                          <button onClick={props.onClick} className="apple">
                                              <span>{t('registrationScreenWithApple').toUpperCase() || STRINGS.LOGIN.LOGIN_APPLE.toUpperCase()}</span>
                                          </button>
                                      )}
                                  /> :
                                  <FacebookLogin
                                      appId={APP_ID_FACEBOOK_AUTHENTIFICATION}
                                      callback={responseFacebook}
                                      onFailure={(res:any) => res?.status && alertMessage(res.status)}
                                      fields="name,picture,email"
                                      render={(renderProps:any) => (
                                          <button onClick={renderProps.onClick} className="fb">
                                              <span>{t('registrationScreenWithFacebook') || STRINGS.LOGIN.LOGIN_FACEBOOK}</span>
                                          </button>
                                      )}
                                      disableMobileRedirect={true}
                                      autoLoad={false}
                                  />
                      }
                  </div>

              </div>
          }
        <button onClick={Submit} className="btn-primary">
          {t('registrationScreenTitleRegistrationStepTwo') || STRINGS.LOGIN_MAIL.LOGIN}
        </button>
        <button
          onClick={() => history(PATHS.REGISTER)}
          className="btn-primary "
        >
          {t('createYourAccount') || STRINGS.REGISTER.BTN}
        </button>

      </div>

    </div>
  );
};

/* eslint-disable max-lines-per-function */

const LoginMail = (): JSX.Element => {
  const width = useSelector((state: RootState) => state.root.DEVICE_WIDTH);
  return (
    <div className="auth login">
      <Header />
      <div className="auth-content">
        {" "}
        <LoginWeb />
      </div>
    </div>
  );
};
/* eslint-enable max-lines-per-function */
export default LoginMail;
