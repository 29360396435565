import React, {useEffect, useState} from "react";
import './desiresAndDiets.scss'
import HorizontalScrollComponent from "../horizontalScrollComponents/horizontalScrollComponents";
import DesireAndDietItem from "./desireAndDietItem/desireAndDietItem";
import {desireAndDiets} from "../../consts";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../shared/store";
import {CategoryInterface, SubCategoryInterface} from "../../../../../shared/store/slices/explore/exploreSlice";
import DestinationItem from "../destinations/destinationItem/destinationItem";

// eslint-disable-next-line max-lines-per-function
const DesiresAndDiets: React.FC<{ showAll?: Array<SubCategoryInterface> , data?:CategoryInterface, }> = ({data}) => {
    return (
        <div className={'cont'}>
            {data && <HorizontalScrollComponent className={'desireAndDietsMainContainer'}>
                {
                    data?.subCategories?.slice(0, 8).map((item, index) => {
                        return (
                            <DesireAndDietItem key={index} item={{...item}}/>
                        )
                    })

                }
            </HorizontalScrollComponent>}
        </div>
    )
}

export default DesiresAndDiets;
