import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {CategoryHome, ChefModel} from "../../core/models/Chef";
import "./ChefCard.scss";
import {STRINGS} from "../../core/enums/strings";
import {RestoModel} from "../../core/models/Resto";
import {getChef} from "../../shared/store/slices/Chefs/ChefsService";
import {motion, useReducedMotion, useTransform, useViewportScroll} from "framer-motion";
import {setBackRoot} from "../../shared/store/slices/Root/rootSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../shared/store";
import {useParams} from "react-router";
import {useTranslation} from "react-i18next";
import ImageContainer from "../imageContainer/imageContainer";

export interface Restaurant {
    name?: string;
    id?: number;
}

 export interface Item {
    id?:number
    item?: ChefModel;
    itemResto?: RestoModel;
    isChef?: boolean;
    isCategory?: boolean;
    idCat?: any;
    category?: CategoryHome;
    subCategory?:CategoryHome
    restaurant?: Restaurant;
     number?:any
    action?: (item: ChefModel, restaurant: Restaurant) => void;
    isHome?: boolean;
    image?: string;
     className?:string
    isSubCategori?:boolean;
    isGray?: boolean;
    video?: string;
    gif?:string,
    idCustom?:string
    active_option?:string,
     thumbnail?:string
}

// eslint-disable-next-line max-lines-per-function,sonarjs/cognitive-complexity
const ChefCard = (Props: Item): JSX.Element => {
    const [listResto, setListResto] = useState<string>("");
    const history = useNavigate();
    const params=useParams()
    const [play, setPlay] = useState<any>(false)
    // const videoPlay = useRef<any>();

    const shouldReduceMotion = useReducedMotion();
    const {scrollY} = useViewportScroll();
    const location = useLocation();
    const dispatch = useDispatch();
    const y = useTransform(scrollY, [0, 1], [0, -0.1], {
        clamp: false,
    })
    useEffect(() => {
        let textResto = "";
        Props.item?.restaurant?.map((item, index) => {
            textResto += (index > 0 ? ", " : "") + listResto + item.name;
            setListResto(textResto);
        });
    }, []);
    // eslint-disable-next-line max-lines-per-function,sonarjs/cognitive-complexity
    const goTo = async () => {
        dispatch(setBackRoot({path: location.pathname}));
        let state: any = {};
        let path = '';

        if (Props?.action && Props.item && Props.restaurant) {
            Props.action(Props.item, Props.restaurant);
        } else if (!Props.isHome && Props.isChef && Props.item?.number_restaurant && Props.item?.number_restaurant > 1) {
            path = `/details-chef/chef=${Props.item?.id}`;
            state = {
                from: location.pathname,
                state: Props,
            }
            dispatch(setBackRoot({path: location.pathname, ...state.state}))
            history(path, state);

        }
        // eslint-disable-next-line sonarjs/no-duplicated-branches
        else if (!Props.isHome && Props.isChef && Props?.number && Props?.number > 1) {
            path = `/details-chef/chef=${Props.item?.id}`;
            state = {
                from: location.pathname,
                state: Props,
            }
            dispatch(setBackRoot({path: location.pathname, ...state.state}))
            history(path, state);


        } else if (Props.isCategory ) {
            path = `/new-chef/category/${Props.idCat}`;
            state = {
                from: location.pathname,
                state: {id: Props.idCat},
            }
            dispatch(setBackRoot({path: location.pathname, ...state.state}))
            history(path, state);
            // eslint-disable-next-line sonarjs/no-duplicated-branches
        } else if ( Props.isSubCategori)  {

            path = `/new-chef/subcategory/${Props.idCat}`;
            state = {
                from: location.pathname,
                state: {id: Props.idCat},
            }
            dispatch(setBackRoot({path: location.pathname, ...state.state}))
            history(path, state);

        }

        else {
                dispatch(setBackRoot({
                    path: location.pathname,
                    item: Props.item,
                    itemResto: {id: Props?.itemResto && Props.itemResto.id }
                }));
                history(`/more-detail/chef=${Props?.idCat}/restaurant=${!Props.number ? Props?.restaurant?.id : Props?.item?.restaurant?.[0].id}`, {
                    state: {
                        from: location.pathname,
                        item: Props.item,
                        itemResto: {id: Props?.itemResto && Props.itemResto.id },
                        id:Props.idCat
                    }
                })

        }
    }
    const width = useSelector((state: RootState) => state.root.DEVICE_WIDTH)
    const videoRef = useRef<HTMLVideoElement>(null)
    useEffect(() => {
        if(play) {
            videoRef?.current?.play()
        }else {
            videoRef?.current?.pause()
        }
    }, [play])
    const {t} = useTranslation()
    const callback = (entries: any, observer: any) => {
        entries?.forEach((entry: any) => {
            setPlay(entry?.isIntersecting);
        });
    };
    const options = {
        root: document.querySelector('#list-chef'),
        rootMargin: '0px',
        threshold: 0.8
    }
    const observer = new IntersectionObserver(callback, options);
    useEffect(() => {
        const target = document.getElementById(Props?.idCustom as string);
        // eslint-disable-next-line sonarjs/no-collapsible-if
        if ((width < 1024) && (target)) {
            observer.observe(target);
        }
    }, [])
    const MotionComponent = motion(ImageContainer)
    return (
    <div
      onClick={() => goTo()}
      className="chef-card"
      key={Props?.item?.id}
      id={Props.idCustom}
    >
        <motion.div className="mask"/>
        {(Props.isChef || Props.isSubCategori || Props.isCategory )&& Props.active_option==='image'  ?
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
            <MotionComponent
            // style={{y: shouldReduceMotion ? 0 : y}}
            className={Props.isGray ? "gray" : ""}
            src={
                Props.image as string
            }
            alt=""
          /> :  Props.isHome && Props.active_option==='video' ?
            <video playsInline={true} poster={Props?.thumbnail}  loop={true}  className={'video'} id={Props?.item?.id?.toString()} ref={width>1024 ? null : videoRef} autoPlay={width > 1024}  controls={false} muted={true}>
                <source type={'video/mp4'}  src={Props.video}/>
            </video> :
                Props.isHome && Props.active_option==='gif' ?
                <ImageContainer src={Props.gif as string} alt={'azeaz'}/> :
                ''
        }
        <div className={`blc-absolute ${!Props.isChef && "resto-absolute"}`}>
            { Props.isChef ? (
              <>
                      <p>
                      <div>
                          {Props?.item?.first_name}{""}
                      </div>
                      <div>
                          {Props?.item?.last_name}
                      </div>
                  </p>
                  <p>
                      <div>
                          {Props?.item?.firstName}{""}
                      </div>
                  </p>
                  <p>
                        <div>
                          <p style={{fontStyle:'unset'}}> {Props?.item?.lastName}</p>
                      </div>
                  </p>

                  <span>{Props?.restaurant ? Props.restaurant.name : listResto}</span>
              </>
            ) : Props.isCategory ?  (
              <>
                <div>
                    <p>
                        {Props?.itemResto?.name
                            ? Props?.itemResto?.name
                            : Props.category?.name}
                    </p>
                </div>
                  <span>
              {" "}
							{Props?.item?.firstName} {" "} {Props?.item?.lastName}
            </span>
					</>
				):
                <>
                    <p className="restoo center-horizontally align-center justify-center">
                        {Props?.itemResto?.name
                            ? Props?.itemResto?.name
                            : Props.subCategory?.name}
                    </p>
                    <span>
              {" "}
                        {Props?.item?.firstName} {" "} {Props?.item?.lastName}
            </span>
                </>
            }
             <div className="show">{t('discoverHome') || STRINGS.HOME.BTN.DISCOVER}</div>
			</div>
		</div>

	);
};
/ eslint-enable max-lines-per-function /
export default ChefCard;
