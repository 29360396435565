import React, {useEffect, useState} from "react";
import "antd/dist/antd.css";
import * as env from 'dotenv';

import "./assets/fonts/EditorialNew/EditorialNew.css";
import "./assets/fonts/Futuraaa/Futura.css";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./App.scss";
import {Navigate, BrowserRouter, Routes, Route, useNavigate} from "react-router-dom";
import { useLocation} from "react-router";
import Home from "./pages/home/Home";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import MoreDetails from "./pages/chefs/more-details/MoreDetails";
import ForgetPassword from "./pages/auth/ForgetPassword";
import AllChefs from "./pages/chefs/all-chefs/AllChefs";
import DetailsChef from "./pages/chefs/details-chef/DetailsChef";
import NewChef from "./pages/new-chef/NewChef";
import DishPage from "./pages/dish-page/DishPage";
import DetailsMenu from "./pages/detail-menu/DetailsMenu";
import MyAccount from "./pages/my-account/MyAccount";
import {PATHS} from "./core/enums/paths";
import About from "./pages/my-account/about/About";
import LoginMail from "./pages/auth/LoginMail";
import { store} from "./shared/store";
import {Provider} from "react-redux";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./components/loader/Loader";
import MyOrders from "./pages/my-account/my-orders/MyOrders";
import OrderDetails from "./pages/my-account/my-orders/order-details/OrderDetails";
import EditProfil from "./pages/my-account/edit-profil/EditProfil";
import MyAddresses from "./pages/my-account/my-addresses/MyAddresses";
import Newslettres from "./pages/my-account/newsletters/Newslettres";
import Contact from "./pages/my-account/contact/Contact";
import Help from "./pages/my-account/my-orders/order-details/help/Help";
import SuccessRequestHelp
	from "./pages/my-account/my-orders/order-details/help/success-request-help/SuccessRequestHelp";
import Mail from "./pages/my-account/about/mail/Mail";
import Sms from "./pages/my-account/about/sms/Sms";
import Cgv from "./pages/my-account/about/cgv/Cgv";
import HelpSuggestion from "./components/help-suggestion/HelpSuggestion";
import HelpCheckbox from "./components/help-checkbox/HelpCheckbox";
import MyAccountForm from "./pages/my-account/edit-profil/myAccount_form/MyAccountForm";
import {setDeviceWidth} from "./shared/store/slices/Root/rootSlice";
import AboutSidebar from "./components/about-sidebar/AboutSidebar";
import ManageAddress from "./pages/my-account/my-addresses/manage-address/ManageAddress";
import AddAddressForm from "./pages/my-account/my-addresses/add-address-form/AddAddressForm";
import CheckoutPage from "./pages/checkout-page/CheckoutPage";
import AddEditAddress from "./pages/my-account/my-addresses/AddEditAddress";
import Map from "./components/map/MapMobile";
import Categories from "./pages/categories/Categories";
import {currency, isMobile} from "./shared/utils/utils-functions";
import Explore from "./pages/explore/explore";
import Kitchen from "./pages/explore/kitchen/kitchen";
import Chef from "./pages/explore/chef/chef";
import SearchChef from "./pages/explore/chef/components/searchChef/searchChef";
import CategoryDetail from "./pages/explore/kitchen/components/categoryDetail/categoryDetail";
import {useTranslation} from "react-i18next";
import Maintenance from "./pages/maintenance/Maintennace";
import {checkoutPayment, getMyCurrency} from "./shared/store/slices/Payment/PaymentService";
import {getPublicOwnCart} from "./shared/store/slices/Cart/CartService";
import Contactsecondary from "./components/secondaryComponenets/Contactsecondary";
import Cgusecondary from "./components/secondaryComponenets/Cgusecondary";
import PolitqueSecondary from "./components/secondaryComponenets/PolitqueSecondary";
import MentionLegal from "./components/secondaryComponenets/MentionLegal";
import ConditionVente from "./components/secondaryComponenets/condtionVente";
import Sustainability from "./components/secondaryComponenets/Sustainability";
import StripPage from "./components/stripPage/StripPage";
import StripAuxPage from "./components/stripPage/stripAuxPage";

const _ScrollToTop = ({children}: any) => {

	const {pathname} = useLocation();
	const history = useNavigate();

	const blocBackButton = () => {
		if (pathname.includes(isMobile() ? PATHS.HELP_SUCCESS_MOBILE : "/account/success")) {
			history(isMobile() ? "/account" : "/account/profile");
		}
	}
	useEffect(() => {
		window.addEventListener('popstate', blocBackButton);
		return () => {
			window.removeEventListener('popstate', blocBackButton);
		}
	});
	// eslint-disable-next-line sonarjs/cognitive-complexity
	useEffect(() => {
		window.scrollTo({top: 0, left: 1000, behavior: "auto"});
	}, [pathname]);
	return children;
};
/* eslint-disable max-lines-per-function */
const App = (): JSX.Element => {

	// eslint-disable-next-line sonarjs/cognitive-complexity


	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [screenSize, getDimension] = useState({
		dynamicWidth: window.innerWidth,
		dynamicHeight: window.innerHeight
	});
	const setDimension = () => {
		getDimension({
			dynamicWidth: window.innerWidth,
			dynamicHeight: window.innerHeight
		})
	}

	useEffect(() => {
		window.addEventListener('resize', setDimension);
		return(() => {
			window.removeEventListener('resize', setDimension);
			store.dispatch(setDeviceWidth(window.screen.width));
		})
	}, [screenSize])

	const {i18n} = useTranslation();

	useEffect(() => {
		if (localStorage.getItem('supernova_token')) {
			getPublicOwnCart().then()
		}
		getMyCurrency().then((res: any) => {
			localStorage.setItem('currency', JSON.stringify(res.data))
		})
		i18n.changeLanguage(navigator.language).then(() => {
			localStorage.setItem('SUPERNOVA_LANGUAGE', i18n.language.slice(0, 2))
		})
		store.subscribe(() => {
			setIsLoading(store.getState().root.isLoading);
		});
		// window.addEventListener("resize", () => {
		// 	store.dispatch(setDeviceWidth(window.screen.width));
		// });
	}, [])


	const getMobileRoute = (element: JSX.Element) => {
		if (window.innerWidth < 768)
			return element
		return <Navigate to={'/home'}/>
	}

	const getDesktopRoute = (element: JSX.Element) => {
		if (window.innerWidth >=768)
			return element
		return <Navigate to={'/home'}/>
	}

	useEffect(()=>{
		console.log(process.env.REACT_APP_API_ENDPOINT);

	},[])
	return (
		<Provider store={store}>
			<div className="App">
				<BrowserRouter>
					{/*<CheckImages/>*/}
					<_ScrollToTop>
						{(window.location.pathname !== PATHS.EXPLORE.LOCATION_SEARCH_MOBILE) && (!window.location.pathname.includes('explore')) &&
                        <Loader isLoading={isLoading}/>}
						{/*<Loader isLoading={isLoading}/>*/}
						<Routes>
							<Route path={PATHS.SECONDARY.CONTACT} element={<Contactsecondary/>}/>
							<Route path={'/stripe_connect/success_url_session_stripe'} element={<StripPage/>}/>
							<Route path={'/success_url_session_stripe'} element={<StripAuxPage/>}/>
							<Route path={PATHS.SECONDARY.CGU} element={<Cgusecondary/>}/>
							<Route path={PATHS.SECONDARY.POLITIQUE} element={<PolitqueSecondary/>}/>
							<Route path={PATHS.SECONDARY.MENTION} element={<MentionLegal/>}/>
							<Route path={PATHS.SECONDARY.CONDITION_VENTE} element={<ConditionVente/>}/>
							<Route path={PATHS.SECONDARY.SUSTAINABILITY} element={<Sustainability/>}/>

							{/*<Route element={<DesktopRoute />}>*/}
								<Route path={PATHS.MAINTENANCE} element={<Maintenance/>}/>
								<Route path={PATHS.HOME} element={<Home/>}/>
								<Route path={PATHS.CATEGORIES} element={<Categories/>}/>
								<Route path={PATHS.LOGIN} element={<Login/>}/>
								<Route path={PATHS.LOGIN_MAIL} element={<LoginMail/>}/>
								<Route path={PATHS.REGISTER} element={<Register/>}/>
								<Route
									path={PATHS.FORGET_PASSWORD}
									element={<ForgetPassword/>}
								/>

								<Route path={PATHS.MORE_DETAILS} element={<MoreDetails/>}/>
								<Route path={PATHS.ALL_CHEFS} element={<AllChefs/>}/>
								<Route path={PATHS.CHEF_DETAILS} element={<DetailsChef/>}/>
								<Route path={PATHS.NEW_CHEF} element={<NewChef/>}/>
								<Route path={PATHS.DISH_DETAILS} element={<DishPage/>}/>
								<Route path={PATHS.MENU_DETAILS} element={<DetailsMenu/>}/>
								<Route path={PATHS.RECHERCHE} element={<SearchChef/>}/>
								<Route path={PATHS.EXPLORE.MAIN} element={<Explore/>}>
									<Route path={PATHS.EXPLORE.KITCHEN} element={<Kitchen/>}/>
									<Route path={PATHS.EXPLORE.CHEF} element={<Chef/>}/>
									<Route path={PATHS.EXPLORE.CATEGORY_DETAIL} element={<CategoryDetail/>}/>
									<Route path={'*'} element={<Navigate to={PATHS.EXPLORE.KITCHEN}/>}/>
								</Route>
								<Route path={PATHS.MY_ACCOUNT.MAIN} element={ <MyAccount/>}>
									<Route
										path={PATHS.MY_ACCOUNT.MY_ORDERS}
										element={getDesktopRoute(<MyOrders/>)}
									/>
									<Route
										path={PATHS.MY_ACCOUNT.ORDER_DETAILS}
										element={getDesktopRoute(<OrderDetails/>)}
									/>

									<Route
										path={PATHS.MY_ACCOUNT.MY_PROFILE}
										element={getDesktopRoute(<EditProfil/>)}
									/>

									<Route
										path={PATHS.MY_ACCOUNT.MY_ADDRESSES}
										element={<MyAddresses/>}
									/>
									<Route
										path={PATHS.MY_ACCOUNT.ADD_EDIT_ADDRESS}
										element={<AddEditAddress/>}
									/>

									<Route
										path={PATHS.MY_ACCOUNT.NEWSLETTERS}
										element={getDesktopRoute(<Newslettres/>)}
									/>

									<Route path={PATHS.MY_ACCOUNT.CONTACT} element={getDesktopRoute(<Contact/>)}/>

									<Route path={PATHS.MY_ACCOUNT.ABOUT.MAIN} element={<About/>}>
										<Route
											path={PATHS.MY_ACCOUNT.ABOUT.MAIL}
											element={<Mail/>}
										/>

										<Route path={PATHS.MY_ACCOUNT.ABOUT.SMS} element={<Sms/>}/>

										<Route path={PATHS.MY_ACCOUNT.ABOUT.CGV} element={<Cgv/>}/>
									</Route>

									<Route path={PATHS.MY_ACCOUNT.HELP} element={<Help/>}/>

									<Route
										path={PATHS.MY_ACCOUNT.SUCCESSHELP}
										element={<SuccessRequestHelp/>}
									/>
								</Route>
							{/*</Route>*/}
							{/* paths mobile */}
							{/*<Route element={<MobileRoute />}>*/}
								<Route path={PATHS.MY_ORDERS_MOBILE} element={<MyOrders/>}/>
								<Route
									path={PATHS.ORDERS_DETAIL_MOBILE}
									element={getMobileRoute(<OrderDetails/>)}
								/>
								<Route
									path={PATHS.HELP_SUGGESTION_MOBILE}
									element={getMobileRoute(<HelpSuggestion/>)}
								/>
								<Route
									path={PATHS.HELP_CHECKBOX_MOBILE}
									element={getMobileRoute(<HelpCheckbox/>)}
								/>
								<Route
									path={PATHS.HELP_SUCCESS_MOBILE}
									element={getMobileRoute(<SuccessRequestHelp/>)}
								/>
								<Route
									path={PATHS.EDIT_PROFILE_MOBILE}
									element={getMobileRoute(<MyAccountForm/>)}
								/>
								<Route
									path={PATHS.NEWSLETTERS_MOBILE}
									element={getMobileRoute(<Newslettres/>)}
								/>
								<Route path={PATHS.ABOUT_MOBILE} element={getMobileRoute(<AboutSidebar/>)}/>
								<Route path={PATHS.CGV_MOBILE} element={getMobileRoute(<Cgv/>)}/>
								<Route
									path={PATHS.EDIT_ADDRESSES_MOBILE}
									element={<ManageAddress/>}
								/>
								<Route
									path={PATHS.ADD_ADDRESSES_MOBILE}
									element={<AddAddressForm/>}
								/>
								<Route path={PATHS.CONTACT_MOBILE} element={getMobileRoute(<Contact/>)}/>

								<Route path={PATHS.CHECKOUT} element={<CheckoutPage/>}/>
								<Route path={PATHS.MAP_MOBILE} element={getMobileRoute(<Map/>)}/>
							{/*</Route>*/}

							{/* end paths mobile */}
							<Route path="/" element={<Navigate replace to="/home"/>}/>
						</Routes>
					</_ScrollToTop>
				</BrowserRouter>

				<ToastContainer
					position="top-right"
					autoClose={4000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					pauseOnHover
				/>
			</div>
		</Provider>
	);
};


// const CheckImages = () => {
// 	const LOCATION = useLocation()
// 	const RE_CORRECT = useSelector((state: RootState) => state.root.RE_CORRECT);
//
// 	useEffect(() => {
// 		checkImagesValidity()
// 	}, [])
//
// 	return (
// 		<></>
// 	)
// }
/* eslint-enable max-lines-per-function */
export default App;
