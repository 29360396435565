import React from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { RootState } from "../../shared/store";
import { useSelector } from "react-redux";
import "./checkout.scss";
import { checkoutPayment } from "../../shared/store/slices/Payment/PaymentService";
import { useLocation, useNavigate } from "react-router";
import { isMobile } from "../../shared/utils/utils-functions";
import {t} from "i18next";
import {useTranslation} from "react-i18next";
import {STRIP_CONFIRM_PAYMENT_REDIRECT} from "../../core/enums/APIS";
/* eslint-disable max-lines-per-function */
const CheckoutForm = () => {
  const payment = useSelector(
    (state: RootState) => state.payment.paymentIntent
  );
  const history = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
 const PromoCode=useSelector((state:RootState)=>state.payment.PromoCode)
  const state = useLocation();
  const Redirect = () => {
    !isMobile() ? history("/account/orders") : history("/orders");
  };
  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }
    const { error } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
    });
    if (error) {
      return;
    }

  };
const {t}=useTranslation()
  return (
    <div className="payment-form center-horizontally align-center justify-center">
      <div className="form-section">
        <PaymentElement id="payment-element" />
        <button
          onClick={handleSubmit}
          disabled={!stripe || !elements}
          id="submit"
          className="btn-primary"
        >
          <span id="button-text">{t('payerButton')}</span>
        </button>
      </div>
    </div>
  );
};

/* eslint-enable max-lines-per-function */
export default CheckoutForm;
