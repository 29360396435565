import React, {useEffect, useLayoutEffect, useState} from "react";
import {useLocation} from "react-router";
import {useSelector} from "react-redux";
import {RootState} from "../../shared/store";
import {getListAbout} from "../../shared/store/slices/Account/AccountService";
import HeaderAccount from "../header-account/HeaderAccount";
import {t} from "i18next";
import {isMobile} from "../../shared/utils/utils-functions";
import {Link} from "react-router-dom";
import logoBlack from "../../assets/icons/Logo-black.svg";

// eslint-disable-next-line max-lines-per-function
const MentionLegal=()=>{
    const state = useLocation();
    const about = useSelector((state: RootState) => state.account.about);
    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        await getListAbout().then((res) => {
            // console.log(res);
        });
    };
    const [scrollY, setScrollY] = useState(0);

    useLayoutEffect(() => {
        const handleScroll = () => {
            setScrollY(Math.min(0, window.scrollY / 3 - 60) - 10);
        }
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    }, [scrollY])
    return(
        <div className={'about-page'} style={{height:'100%',overflow:"unset",padding:'20px',width:"unset",maxWidth:'unset'}} >
            {!isMobile() &&
                <div className="blc-center">
                    <Link to="" className="logo">
                        <img src={logoBlack} alt="logo"/>
                    </Link>
                </div>
            }
            <div style={{height:'40px'}}/>
            <HeaderAccount title={t('legalNotice')} />


            <div  style={{height:'100%'}} className={'regleContainer'} >
                <span className={'regleText'}  dangerouslySetInnerHTML={{__html: about[5]?.text}}/>
            </div>


        </div>
    )
}
export default MentionLegal
