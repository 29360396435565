import React from 'react';
import {Modal} from 'antd';
import './ModalWidget.scss'
interface ModalProps {
    children: React.ReactNode;
    isVisible: boolean;
    onCancel?: (isCancel: boolean) => void
}
const ModalWidget = (props: ModalProps): JSX.Element => {
    return (
        <Modal visible={props.isVisible} onCancel={() => props?.onCancel && props.onCancel(false)} closable={false} footer={false} width={"fit-content"} wrapClassName={'ModalWidget'} style={{padding: 0}}
               centered>
            {props.children}
        </Modal>
    );
};

export default ModalWidget;
