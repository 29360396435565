import React from "react";

const StripAuxPage=()=>{
    return(
        <div>

        </div>
    )
}
export default StripAuxPage
