import React from "react";
import './searchCard.scss'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../shared/store";
import {setBackRoot} from "../../../shared/store/slices/Root/rootSlice";
import {useLocation, useNavigate} from "react-router-dom";
import {getChef, getMenuChef} from "../../../shared/store/slices/Chefs/ChefsService";
import {getMacAddress, setSearchRecent} from "../../../shared/store/slices/explore/exploreService";
import {closeSearching} from "../../../shared/store/slices/Chefs/ChefsSlice";
// eslint-disable-next-line max-lines-per-function
const SearchCard: React.FC<{ item: any, index: any }> = ({item, index}) => {
	const width = useSelector((state: RootState) => state.root.DEVICE_WIDTH)
	const dispatch = useDispatch()
	const history = useNavigate()
	const location = useLocation();

	// eslint-disable-next-line max-lines-per-function,sonarjs/cognitive-complexity
	const navigateToSelected = async (val: any) => {
		dispatch(setBackRoot({path: location.pathname}));
		dispatch(closeSearching(false))
		setSearchRecent({
			id:val?.type !== 'restaurant' ?  (parseInt(val?.id) ? parseInt(val?.id) : parseInt(val?.chefId)) : val?.chefIdWhenSecondSpan,
			type: val?.type,
			device_id: getMacAddress()
			// eslint-disable-next-line max-lines-per-function
		}).then((res) => {
			let state = {} as any;
			let path;
			switch (val?.type) {
				case 'chef':
					if (val?.number_restaurant === 1) {
						getChef(val?.id).then(data => {
							dispatch(setBackRoot({
								path: location.pathname,
								item: data?.data,
								itemResto: data?.data?.chef?.restaurant[0]?.id
							}))
							history(`/more-detail/chef=${val?.id}/restaurant=${data?.data?.chef?.restaurant?.[0]?.id}`, {
								state: {
									from: location.pathname,
									item: data,
									itemResto: {id: data?.data?.chef?.restaurant?.[0]?.id},
									id: parseInt(val?.id)
								}
							})
						})
					} else {
						history(`/details-chef/chef=${val?.id}`, {
							state: {
								from: location.pathname,
								item: {id: val?.id, firstName: val?.firstName, lastName: val?.lastName},
							}
						})
					}
					break;
				case 'restaurant':
						getChef(val?.id).then(data => {
							dispatch(setBackRoot({
								path: location.pathname,
								item: data?.data,
								itemResto: val?.chefIdWhenSecondSpan
							}))
							history(`/more-detail/chef=${val?.id}/restaurant=${val?.chefIdWhenSecondSpan}`, {
								state: {
									from: location.pathname,
									item: data,
									itemResto: val?.chefIdWhenSecondSpan,
									id: parseInt(val?.id)
								}
							})
						})
					break;
				case 'category':
				case 'sub_category':
				case 'restaurant_category':
					path = `/new-chef/category/${val?.id}`;
					state = {
						from: location.pathname,
						state: {id: val?.id},
					}
					dispatch(setBackRoot({path: location.pathname, ...state.state}))
					history(path, state);
					break;
				case 'menu':
					getMenuChef(val.chefIdWhenSecondSpan, val.restaurantId, val.id).then((res: any) => {
						history(`/menu-details/chef=${val?.chefIdWhenSecondSpan}/restaurant=${val.restaurantId}/menu=${val.id}`, {
							state: {
								idChef: val?.chefIdWhenSecondSpan,
								idResto: val?.restaurantId,
								idMenu: val?.id,
							},
						})
					})
					break;
				case 'plate':
					history(`/dish-details/chef=${val.chefIdWhenSecondSpan}/restaurant=${val.restaurantId}/dish=${val.id}`, {
						state: {
							idChef: val?.chefIdWhenSecondSpan,
							idResto: val?.restaurantId,
							idPlate: val?.id
						}
					})
					break;
				default:
					break;
			}
		})
	}
	return (
		<div className={'listResult'} onClick={() => {
			navigateToSelected({
				id: item?.idFirstSpan,
				type: item?.typeFirstSpan,
				chefIdWhenSecondSpan: item?.idSecondSpan,
				restaurantId: item?.restaurantID,
				number_restaurant: item?.number_restaurant,
				lastName: item?.lastName,
				firstName: item?.firstSpan
			}).then()

		}}>
			<span className={width < 768 ? 'firstSpanDesktop' : 'firstSpanMobile'}>
				{item?.firstSpan}
			</span>
			<span className={width < 768 ? 'secondSpanDesktop' : 'secondSpanMobile'}>
				{item?.SecondSpan}
			</span>
		</div>
	)
}
export default SearchCard