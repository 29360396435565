import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import BackBtn from "../../../../components/back-btn/BackBtn";
import Footer from "../../../../components/footer/Footer";
import { STRINGS } from "../../../../core/enums/strings";
import { RootState } from "../../../../shared/store";
import { getListAbout } from "../../../../shared/store/slices/Account/AccountService";
import "./Cgv.scss";

const Cgv = (): JSX.Element => {
  const state = useLocation();
  const about = useSelector((state: RootState) => state.account.about);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await getListAbout().then((res) => {
      // console.log(res);
    });
  };
  return (
    <div className="about-page">
      <div className="blc-top">
        <BackBtn />
        <h1>
          {STRINGS.ACCOUNT.ABOUT.CGV.PART1}{" "}
          <span>{STRINGS.ACCOUNT.ABOUT.CGV.PART2}</span>{" "}
          {STRINGS.ACCOUNT.ABOUT.CGV.PART3}
        </h1>
      </div>

      <div className="item">
          {/*<h1></h1>*/}
          {/*<h2></h2>*/}
        <p className="infos" dangerouslySetInnerHTML={{__html: about[3]?.text}}/>
      </div>

      <div className="blc-footer">
        <Footer quantity={0} priceOrder={0} isHome={true} isOrder={false} />
      </div>
    </div>
  );
};

export default Cgv;
