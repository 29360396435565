import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import { useLocation, useNavigate } from "react-router";
import Geocode from "react-geocode";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
Geocode.setApiKey(GOOGLE_API_KEY);
import "./Map.scss";
import {setAddressName, setIsFromMap, setSelectedAddress} from "../../shared/store/slices/Root/rootSlice";
import {t} from "i18next";
import {GOOGLE_API_KEY} from "../../core/enums/APIS";
import {RootState} from "../../shared/store";
/*global google*/
// eslint-disable-next-line max-lines-per-function
const MapMobile = () => {
    const [isClicked,setClicked] = useState(false)
  const history = useNavigate();
  const dispatch = useDispatch();
  const state = useLocation();
  const [address, setAddress] = useState({});
  const [position, setPosition] = useState({
    lat: 0,
    lng: 0,
  });
  useEffect(() => {
    if (!!navigator.geolocation && !state.state.lat && !state.state.lng) {
      navigator.geolocation.watchPosition(
        (position) => {
          setPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        () => console.log('err'),
        { enableHighAccuracy: true, timeout: 10000000, maximumAge: 1000000 }
      );
    } else if (state.state.lat && state.state.lng) {
      setPosition({
        lat: state.state.lat,
        lng: state.state.lng,
      });
    } else {
      alert("Le navigateur ne supporte pas la géolocalisation");
    }
  }, []);
  useEffect(() => {
    isClicked && position &&

      Geocode.fromLatLng(position.lat.toString().substring(0.5), position.lng.toString().substring(0.5)).then(
        (response) => {

          const adress = response.results[0];
          setAddress(adress);
            dispatch(setAddressName(adress));
            dispatch(setIsFromMap(true))

        },
        (error) => {
          console.error(error);
        }
      );
  }, [position]);

  const addMarker = (place) => {
    const { latLng } = place;
    setPosition({
      lat: latLng.lat(),
      lng: latLng.lng(),
    });
  };
    const selectedAddress = useSelector((state) => state?.root?.selected_address);
    // eslint-disable-next-line sonarjs/cognitive-complexity
  const onConfirmPosition = () => {
     dispatch(setSelectedAddress({
         ...selectedAddress,
         position: {
             longitude: isClicked ? position.lng : selectedAddress.position.longitude,
             latitude: isClicked ? position.lat : selectedAddress.position.latitude,
             address: isClicked ? address?.formatted_address :selectedAddress.position.address,
         },
     }));
    history("/add-address", {
      state: {
        position: {
            longitude: isClicked ? position.lng : selectedAddress.position.longitude,
            latitude: isClicked ? position.lat : selectedAddress.position.latitude,
            address: isClicked ? address?.formatted_address :selectedAddress.position.address,
        },
      },
    });
  };
  return (
    <div>
      <button className="close" onClick={() => history(-1)} />
      <GoogleMap
          options={
              {gestureHandling:'greedy',
              minZoom:15,
              maxZoom:15
              }
          }
        ref={(map) => {
          if (map && position.lat && position.lng) {
            map.panTo(position);
          }
        }}
          defaultZoom={15}
          defaultCenter={position}
        onClick={(item) => {
            setClicked(true)
          addMarker(item);
        }}
      >
        <Marker position={position} />
      </GoogleMap>
      <div className="bottomSheet">
        <div className="card-address center-horizontally align-center justify-center">
          <p className="adress-name">
            { isClicked && address?.formatted_address ? address?.formatted_address : selectedAddress.position.address}
          </p>
        </div>
        <button onClick={onConfirmPosition} className="btn-save btn-primary">
            {t('updateAddressScreenConfirmePosition')}
        </button>
      </div>
    </div>
  );
};
const Map = compose(
  withProps({
    googleMapURL:
      `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=geometry,drawing,places`,
    loadingElement: <div  />,
    containerElement: (
      <div
          className="mapMobile"

      />
    ),
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => <MapMobile />);
// eslint-enable-next-line max-lines-per-function
export default Map;
