import React, {useEffect, useLayoutEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Card from "../../../components/card/Card";
import HeaderAccount from "../../../components/header-account/HeaderAccount";
import Input from "../../../components/input/Input";
import {STRINGS} from "../../../core/enums/strings";
import {InputItem} from "../../../core/models/Input";
import {RootState} from "../../../shared/store";
import {
	editCountry,
	editIdentity,
	editPassword,
	editPhoneStepOne,
	editPhoneStepTwo, getListCountry,
	getMyProfile,
} from "../../../shared/store/slices/Auth/AuthService";
import {errorMessage, isMobile} from "../../../shared/utils/utils-functions";
import "./EditProfil.scss";
import MyAccountForm from "./myAccount_form/MyAccountForm";
import check from ".././../../assets/icons/check.svg";
import checkValid from ".././../../assets/icons/check-valid.svg";
import {
	checkAtLeastLength,
	checkDigit,
	checkSpecialCharacter,
	checkUpper,
} from "../../../shared/Validator/validation-service";
import {toast} from "react-toastify";
import OrderBottomsheet from "../../../components/order-bottomsheet/OrderBottomsheet";
import Footer from "../../../components/footer/Footer";
import {Select} from "antd";
import img from "../../../assets/images/chef2.jpg";
import {newCountryId} from "../../../shared/store/slices/Auth/AuthSlice";
import {useTranslation} from "react-i18next";
import ImageContainer from "../../../components/imageContainer/imageContainer";
import PhoneInput, {isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumber} from "react-phone-number-input";

interface SelectedInputs {
	id: number;
	title: string;
	isSelect?: boolean
	inputs: InputItem[];
}

interface Error {
	id: number;
	message: string;
	icon: string;
	className: string;
}

const error = "MOT DE PASSE NON SIMILAIRE"
/* eslint-disable max-lines-per-function */
// eslint-disable-next-line sonarjs/cognitive-complexity
const EditProfil = (): JSX.Element => {
	const {t} = useTranslation()
	const [countryInVallue,setCountryInValue]=useState<any>()

	const country = useSelector((state: RootState) => state.authentication.countryId)
	const [selectedInputs, setSelectedInputs] = useState<SelectedInputs>({
		id: 1,
		title: "",
		inputs: [],
	});
	const profile = useSelector(
		(state: RootState) => state.authentication.profile
	);
	const [errorPassword, setErrorPassword] = useState<Error[]>([
		{
			id: 1,
			message: STRINGS.REGISTER.ERRORS.ERR_1,
			icon: check,
			className: "",
		},
		{
			id: 2,
			message: STRINGS.REGISTER.ERRORS.ERR_2,
			icon: check,
			className: "",
		},
		{
			id: 3,
			message: STRINGS.REGISTER.ERRORS.ERR_3,
			icon: check,
			className: "",
		},
		{
			id: 4,
			message: STRINGS.REGISTER.ERRORS.ERR_4,
			icon: check,
			className: "",
		},
	]);
	const dispatch = useDispatch();
	useEffect(() => {
		if(!isMobile()){
			getData();
		}

	}, []);
	const isPassword = useSelector(
		(state: RootState) => state.authentication.loggedInUser
	);
	const [isError, setIsError] = useState<boolean>(true);

	// eslint-disable-next-line sonarjs/cognitive-complexity
	const getData = async () => {
		await getMyProfile().then((me) => {
			if (me?.data.firstName=== undefined ||me?.data.lastName===undefined|| me?.data.firstName=== null ||me?.data.lastName===null|| me?.data.firstName=== ''||me?.data.lastName==='' || me?.data.firstName === 'null' || me?.data.lastName ==='null' || me?.data.firstName === 'Null' || me?.data.lastName ==='Null' ){
				editIdentity({firstName:' ', lastName: ' '}).then()
			}
			getListCountry().then((res: any) => {
				setListCountry(res.data)
				setFilteredList(res.data?.filter((item: any) => item?.name?.trim()?.toLowerCase()?.includes(me?.data?.country?.name?.trim()?.toLowerCase() || '')))
			})
			setInputs([
				{
					id: 1,
					type: "text",
					label: t('profileScreenIdentify') || STRINGS.ACCOUNT.EDITPROFILE.IDENTITY_TXT,
					// value: res?.data?.firstName + " " + res?.data?.lastName,
					value: ( me?.data.firstName === undefined || me.data.lastName === undefined || me?.data.firstName === null || me.data.lastName === null || me?.data.firstName === '' || me.data.lastName === '' || me?.data.firstName !== 'null' || me?.data.lastName !=='null' || me?.data.lastName !=='Null' || me?.data.firstName !=='Null') ? me?.data?.firstName + " " + me?.data?.lastName : ' '   ,
					isEditable: true,
					isOnEdit: false,
					name: "identity",
					readonly: true,
				},
				{
					id: 2,
					type: "text",
					label: t('phoneScreenInputLabel') || STRINGS.ACCOUNT.EDITPROFILE.PHONE_NUMBER,
					value: me?.data?.phone,
					isEditable: true,
					isOnEdit: false,
					name: "phone",
					readonly: true,
				},
				{
					id: 3,
					type: "email",
					label: t('emailScreenPlaceholderText') || STRINGS.ACCOUNT.EDITPROFILE.ADDRESS_MAIL,
					value: me?.data?.email,
					isEditable: false,
					isOnEdit: false,
					name: "email",
					readonly: true,
				},
				{
					id: 4,
					type: "password",
					label: t('passwordScreenStepTwo') || STRINGS.ACCOUNT.EDITPROFILE.PASSWORD2,
					value: "0000000",
					isEditable: true,
					isOnEdit: false,
					name: "password",
					readonly: true,
				},
				{
					id: 5,
					type: "select",
					label: t('profileScreenCountry') || STRINGS.ACCOUNT.EDITPROFILE.COUNTRY,
					value: me?.data?.country?.name,
					isEditable: true,
					isOnEdit: false,
					name: "country",
					readonly: true,


				},
			]);
		});
	};

	const [inputs, setInputs] = useState<InputItem[]>([
		{
			id: 1,
			type: "text",
			label: t('profileScreenIdentify') || STRINGS.ACCOUNT.EDITPROFILE.IDENTITY_TXT,
			value: profile?.lastName + " " + profile?.firstName,
			isEditable: true,
			isOnEdit: false,
			name: "identity",
			readonly: true,
		},
		{
			id: 2,
			type: "text",
			label: t('phoneScreenInputLabel') || STRINGS.ACCOUNT.EDITPROFILE.PHONE_NUMBER,
			value: profile?.phone,
			isEditable: true,
			isOnEdit: false,
			name: "phone",
			readonly: true,
		},
		{
			id: 3,
			type: "email",
			label: t('emailScreenPlaceholderText') || STRINGS.ACCOUNT.EDITPROFILE.ADDRESS_MAIL,
			value: profile?.email,
			isEditable: false,
			isOnEdit: false,
			name: "email",
			readonly: true,
		},
		{
			id: 4,
			type: "password",
			label: t('passwordScreenStepTwo') || STRINGS.ACCOUNT.EDITPROFILE.PASSWORD2,
			value: "0000000",
			isEditable: true,
			isOnEdit: false,
			name: "password",
			readonly: true,
		},
		{
			id: 5,
			type: "select",
			label: t('profileScreenCountry') || STRINGS.ACCOUNT.EDITPROFILE.COUNTRY,
			value: profile?.country?.name,
			isEditable: true,
			isOnEdit: false,
			name: "country",
			readonly: true,
		},

	]);
	const {Option} = Select;

	const model: SelectedInputs[] = [
		{
			id: 1,
			title: t('profileScreenIdentify') || STRINGS.ACCOUNT.EDITPROFILE.IDENTITY_TXT,
			inputs: [
				{
					id: 2,
					type: "text",
					label: t('personnelInformationFirstNameInput') || STRINGS.ACCOUNT.EDITPROFILE.FIRST_NAME,
					value: profile?.firstName,
					rules: [{name: "required"}],
					name: "firstName",
				},
				{
					id: 1,
					type: "text",
					label: t('personnelInformationLastNameInput') || STRINGS.ACCOUNT.EDITPROFILE.LAST_NAME,
					value: profile?.lastName,
					rules: [{name: "required"}],
					name: "lastName",
				},
			],
		},
		{
			id: 2,
			title: t('phoneScreenInputLabel') || STRINGS.ACCOUNT.EDITPROFILE.PHONE_NUMBER,
			inputs: [
				{
					// eslint-disable-next-line sonarjs/no-duplicate-string
					type:  t('pickNumberPlaceholder'),
					name:  t('pickNumberPlaceholder'),
				},
			],
		},
		{
			id: 3,
			title: t('phoneScreenVerificationCode') || STRINGS.ACCOUNT.EDITPROFILE.CODE,
			inputs: [
				{
					id: 2,
					label: t('phoneScreenVerificationCode') || STRINGS.REGISTER.INPUTS.VERIFICATION_CODE,
					rules: [{name: "verification-code"}],
					type: "number",
					name: "code",
					value: "",
				},
			],
		},

		{
			id: 4,
			title: t('passwordScreenStepTwo') || STRINGS.ACCOUNT.EDITPROFILE.PASSWORD2,
			inputs: [
				{
					id: 1,
					type: "oldPassword",
					label: t('passwordScreenUpdateOldPassword') || STRINGS.ACCOUNT.EDITPROFILE.OLD_PASSWORD,
					value: "",
					rules: [{name: "required"}],
					name: "old_password",
				},
				{
					id: 2,
					type: "confirmPassword",
					label: t('passwordScreenInputPassword') || STRINGS.ACCOUNT.EDITPROFILE.NEW_PASSWORD,
					value: "",
					name: "new_password",
				},
				{
					id: 3,
					type: "confirmPassword",
					label: t('passwordScreenInputConfirmPassword') || STRINGS.REGISTER.INPUTS.CONFIRM_PASSWORD,
					value: "",
					name: "confirmPassword",
				},
			],
		},
		{
			id: 5,
			title: t('profileScreenCountry') || STRINGS.ACCOUNT.EDITPROFILE.COUNTRY,
			inputs: [
				{
					id: 5,
					type: "select",
					label: t('profileScreenCountry') || STRINGS.ACCOUNT.EDITPROFILE.COUNTRY,
					value: profile?.country?.name,
					// rules: [{ name: "required" }],
					name: "country",
				},

			],
		},
	];
	useEffect(() => {
		selectedInputs?.inputs?.length > 0 &&
		selectedInputs?.inputs?.map((item) => {
			setData(
				Object.assign(data, {
					[item.name]:
						item.name === "phone" ? item?.value?.toString() : item?.value,
				})
			);
		});
	}, [selectedInputs]);

	const [errorMessagePassword, setErrorMessagePassword] = useState<string>("");
	const [errorMessagePhone, setErrorMessagePhone] = useState<string>("");

	const [isCode, setIsCode] = useState<boolean>(false);
	const toggleIsOnEdit = (id: number) => {
		const updatedList = inputs?.map((item) => {
			if (item.id !== id) {
				return {...item, isOnEdit: !item.isOnEdit};
			}
			return item;
		});

		setInputs(updatedList);
	};

	const [data, setData] = useState<any>({
		code:''
	});
	const [isSubmit, setIsSubmit] = useState<boolean>(false);
	const [name, setName] = useState<string>("");


	const editMyPassword = async () => {
		data.new_password !== data.confirmPassword
			? setErrorMessagePassword(error)
			: isError
				? toast.error(t('passwordScreenErrorToast'))
				: await editPassword({...data, password: data?.old_password}).then((res) => {
					const updatedList = inputs?.map((item) => {
						return {...item, isOnEdit: false};
					});

					setInputs(updatedList);
					setErrorMessagePassword("");
					setName("");
					setErrorPassword([
						{
							id: 1,
							message: STRINGS.REGISTER.ERRORS.ERR_1,
							icon: check,
							className: "",
						},
						{
							id: 2,
							message: STRINGS.REGISTER.ERRORS.ERR_2,
							icon: check,
							className: "",
						},
						{
							id: 3,
							message: STRINGS.REGISTER.ERRORS.ERR_3,
							icon: check,
							className: "",
						},
						{
							id: 4,
							message: STRINGS.REGISTER.ERRORS.ERR_4,
							icon: check,
							className: "",
						},
					])
				});
	};
	 const  getmyCountryId= async (value:any)=>{
		const countryId = ListCountry?.filter((item: any) => item?.isoCode?.trim()?.toLowerCase()?.includes(value?.trim()?.toLowerCase() || ''))
		 if (!profile.country){
			 await editCountry({
				 country:countryId?.[0]?.id
			 })
		 }
	 }
    const [errorCountryMessage,setErrorCountryMessage]=useState('')
	const [isSelectCountry, setIsSelectCountry] = useState(false)

	const editMyCountry = async () => {
               if ((errorCountryMessage?.length>0 )|| (countryInVallue?.length < 3)){
				   setErrorCountryMessage(t('messageSlectCountry').toUpperCase())
			   }else{
				   editCountry({
					   country: country?.id
				   }).then((res: any) => {
					   const Country = inputs?.find((item) => item.id === 5);
					   Country &&
					   (Country.value = res.data?.country.name);
					   setInputs([...inputs]);
					   const updatedList = inputs?.map((item) => {
						   return {...item, isOnEdit: false};
					   });
					   setInputs(updatedList);
					   setClicked(300)
					   setIsSelectCountry(false)
					   setFilteredList(ListCountry?.filter((item: any) => item?.name?.trim()?.toLowerCase()?.includes(country?.name?.trim()?.toLowerCase() || '')))
					   setErrorCountryMessage('')

				   });
			   }


	}

	const editMyIdentity = async () => {
		await editIdentity(data).then((res) => {
			const fullName = inputs.find((item) => item.id === 1);
			fullName &&
			(fullName.value = res.data?.firstName + " " + res.data?.lastName);
			setInputs([...inputs]);
			const updatedList = inputs?.map((item) => {
				return {...item, isOnEdit: false};
			});

			setInputs(updatedList);
			setName("");
		});
	};
	const editMyPhoneStepTwo = async () => {
		const countryObject = parsePhoneNumber(data?.phone)
		await editPhoneStepTwo({...data, phone: data?.phone,code:data?.code})
		.then((res) => {
			if (res.success) {
				const phone = inputs.find((item) => item.id === 2);
				phone && (phone.value = res?.data?.phone);
				setInputs([...inputs]);
				const updatedList = inputs?.map((item) => {
					return {...item, isOnEdit: false};
				});

				setInputs(updatedList);
				setName("");
				setIsCode(false);
				getmyCountryId(countryObject?.country)
				setData({...data,code:''})

			}
		})
		.catch((err) => {
			if (!err.data.success) {
				setName("");
				setIsCode(true);
			}
		});
	};

	// eslint-disable-next-line sonarjs/cognitive-complexity
	const editMyPhoneStepOne = async () => {
		if(!data.phone){

			setErrorMessagePhone(t('invalidPhoneNumber'))
		}else {
			if (!validPhoneNumber) {
				setErrorMessagePhone(t('invalidPhoneNumber'))
			}else {
				await editPhoneStepTwo({...data, phone: data?.phone}).then(
					(res) => {
						if (res?.success) {
							const updatedList = inputs?.map((item) => {
								return {...item, isOnEdit: false};
							});

							setInputs(updatedList);
							setName("code");
							setIsCode(true);
						}
					}
				);
			}
		}

	};
	// eslint-disable-next-line sonarjs/cognitive-complexity
	const checkCurrentInputValidity = () => {
		const errors = selectedInputs?.inputs?.map((item) => {
			return item.rules && (!item.value || item.rules)
				? errorMessage(
					data && item.name in data ? data[item.name] : "",
					item.rules,
					t
				)
				: null;
		});
		return errors?.filter((item) => !!item).length === 0
	};
	const isValid = checkCurrentInputValidity();
	// eslint-disable-next-line sonarjs/cognitive-complexity
	const validate = (name: string) => {
		if (isValid) {
			setIsSubmit(false);
			switch (name) {
				case "identity":
					editMyIdentity();
					break;
				case "phone":
					editMyPhoneStepTwo();
					break;
				case "password":
					editMyPassword();
					break;
				case "country":
					editMyCountry();
					break;
				default:
					break;
			}
		} else {
			setIsSubmit(true);
		}
	};

	const onCancel = () => {
		setErrorCountryMessage('')
		setErrorMessagePhone('')
		const updatedList = inputs?.map((item) => {
			return {...item, isOnEdit: false};
		});
		setName("");
		setIsCode(false);
		setIsSelectCountry(false)
		setFilteredList(ListCountry?.filter((item: any) => item?.name?.trim()?.toLowerCase()?.includes(profile?.country?.name?.trim()?.toLowerCase() || '')))

		dispatch(newCountryId(undefined))
		setClicked(profile?.country?.id)
		setErrorMessagePassword("");
		setInputs(updatedList);
		setClicked(300)
		setErrorPassword([
			{
				id: 1,
				message: STRINGS.REGISTER.ERRORS.ERR_1,
				icon: check,
				className: "",
			},
			{
				id: 2,
				message: STRINGS.REGISTER.ERRORS.ERR_2,
				icon: check,
				className: "",
			},
			{
				id: 3,
				message: STRINGS.REGISTER.ERRORS.ERR_3,
				icon: check,
				className: "",
			},
			{
				id: 4,
				message: STRINGS.REGISTER.ERRORS.ERR_4,
				icon: check,
				className: "",
			},

		])
	};
	const edit = (id: number, name: string) => {
		setName(name);
		toggleIsOnEdit(id);
		setSelectedInputs(model[id - 1]);
	};
	const updateItem = (id: number) => {
		const indexToUpdate = errorPassword.findIndex((todo) => todo.id === id);
		const updatedTodos = [...errorPassword]; // creates a copy of the array

		updatedTodos[indexToUpdate].className = "valid";
		updatedTodos[indexToUpdate].icon = checkValid;
		setErrorPassword(updatedTodos);
	};
	const ResetItem = (id: number) => {
		const indexToUpdate = errorPassword.findIndex((todo) => todo.id === id);
		const updatedTodos = [...errorPassword]; // creates a copy of the array

		updatedTodos[indexToUpdate].className = "";
		updatedTodos[indexToUpdate].icon = check;
		setErrorPassword(updatedTodos);
	};
	// eslint-disable-next-line sonarjs/cognitive-complexity
	const onValueChange = (value: any, key: string) => {
		setData({...data,code:value})
		if (key === "new_password") {
			if (checkAtLeastLength(value, 8)) {
				updateItem(1);
			} else {
				ResetItem(1);
			}

			if (checkUpper(value)) {
				updateItem(2);
			} else {
				ResetItem(2);
			}

			if (checkSpecialCharacter(value)) {
				updateItem(3);
			} else {
				ResetItem(3);
			}
			if (checkDigit(value)) {
				updateItem(4);
			} else {
				ResetItem(4);
			}
		}
		key === "confirmPassword" &&
		data?.new_password?.length > 0 &&
		setErrorMessagePassword(
			data?.new_password !== value ? error : ""
		);
		key === "new_password" &&
		data?.confirmPassword?.length > 0 &&
		setErrorMessagePassword(
			data?.confirmPassword !== value ? error : ""
		);
		setData({...data, [key]: key === "code" ? Number(value) : value});
	};
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
	const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
	const isFromRecap = useSelector((state: RootState) => state.root.isBackFromAddress);
	const [openRecap, setOpenRecap] = useState<boolean>(false);
	const [open, setOpen] = useState<boolean>(false);
	const onShowPassword = () => {
		setShowPassword(!showPassword);
	};
	const onShowConfirmPassword = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	const onShowOldPassword = () => {
		setShowOldPassword(!showOldPassword);
	};
	const onCloseMenu = () => {
		setOpen(false);
		document.body.style.overflow = "auto";
	};
	useEffect(() => {
		const result = errorPassword.some((i) => {
			return i.icon === check;
		});
		!result ? setIsError(false) : setIsError(true);
	}, [errorPassword]);
	const [ListCountry, setListCountry] = useState<Array<any>>([])
	const [filteredList, setFilteredList] = useState<Array<any>>([]);


	const filterList = (value: string) => {
		setClicked(300)
		setCountryInValue(value)
		if (!value){
			setErrorCountryMessage(t('messageSlectCountry').toUpperCase())
			setIsSelectCountry(false)
		}else{
			setErrorCountryMessage('')
			setIsSelectCountry(true)
			setFilteredList(ListCountry?.filter((item: any) => item?.name?.trim()?.toLowerCase()?.includes(value?.trim()?.toLowerCase() || '')))
		}

	}
	const [clicked, setClicked] = useState<any>()
	const [itemSelected,setItemSelected]=useState<any>()
	const selectCountry = (item: any, index: any) => {
		setItemSelected(item)
		setIsSelectCountry(true)
		setErrorCountryMessage('')
		setClicked(index)
		setCountryInValue(item.name)
		dispatch(newCountryId(item))
	}
	const [validPhoneNumber, setValidPhoneNumber] = useState(false)
	const [isEmpty,setisEmpty]=useState(true)
	const checkValidty = (value: any) => {
		setErrorMessagePhone('')
          if(!value){
			  setisEmpty(false)
		  }else{
			  setisEmpty(true)

		  }
		if (value && isValidPhoneNumber(value) && isPossiblePhoneNumber(value)) {
			setData({...data, phone: value})
			setValidPhoneNumber(true)
		} else {
			setValidPhoneNumber(false)
		}
	}
	const isLogged=useSelector((state:RootState)=>state.authentication.isLoggedIn)
// eslint-disable-next-line sonarjs/cognitive-complexity
const returnToRecap =()=>{

						 return isMobile() ? setOpenRecap(true) : setOpen(true)


}
	return (
		<>
			{
				profile &&
                <div className="profile">
                    <HeaderAccount title={t('globalProfileScreenMyProfileText') || STRINGS.ACCOUNT.EDITPROFILE.TITLE}/>
                    <div className="center-horizontally profile-content">
                        <div className="inputs-container center-vertically">
							{isMobile() ? (
								<MyAccountForm/>
							) : (
								<>
									{inputs?.map((item) => {
										return (
											<div key={item.id}>
												{!isPassword?.user?.has_password && item?.id === 4 ? (
													""
												) : (
													<Input
														key={item.id}
														item={item}
														action={() => item.id && edit(item.id, item.name)}
													/>
												)}
											</div>
										);
									})}
								</>
							)}
							{
								isFromRecap && profile.phone &&
								(
									<div>
										<button onClick={() => returnToRecap()}
												className="btn-primary ">
											{t('profileScreenContinueOrder') || STRINGS.ACCOUNT.EDIT_ADRESSES.RETURN_RECAP}
										</button>
									</div>
								)
							}
                        </div>
						{isCode && (
							<Card>
								<div className="center-vertically selected-inputs">
									<p>{model[2]?.title}</p>

									{model[2]?.inputs?.map((item) => {
										return (
											<Input
												isSubmit={isSubmit}
												key={item.id}
												item={{
													...item,
													onChange: (e) => onValueChange(e.target.value, item.name),
												}}
											/>
										);
									})}
									<div className="center-horizontally align-center action-container">
										<p onClick={onCancel}>{t('cancel') || STRINGS.CANCEL}</p>
										<button
											onClick={() => validate(name)}
											className={`btn-save `}
										>
											{t('validate') || STRINGS.VALIDATE}
										</button>
									</div>
								</div>
							</Card>
						)}
						{inputs[0].isOnEdit || inputs[1].isOnEdit || inputs[3].isOnEdit || inputs[4].isOnEdit ? (
							<Card>
								<div className="center-vertically selected-inputs">
									<p>{selectedInputs?.title}</p>

									{/* eslint-disable-next-line sonarjs/cognitive-complexity */}
									{selectedInputs?.inputs?.map((item) => {
										return (
											<>
												{item.type !== 'select' ?


													<Input
														isSubmit={isSubmit}
														key={item.id}
														item={{
															...item,
															type: (item.name === "new_password" && !showPassword) ||
															(item.name === "confirmPassword" && !showConfirmPassword) ||
															(item.name === "old_password" && !showOldPassword)
																? "password"
																: item.type,
															onChange: (e) =>
																onValueChange(e.target.value, item.name),
														}}
														eyes={
															item.name === "new_password" || item.name === "confirmPassword" || item.name === "old_password"
														}
														onShow={
															item.name === "old_password" ? onShowOldPassword :
																item.name === "new_password" ? onShowPassword
																	: onShowConfirmPassword
														}
														isShow={
															item.name === "old_password" ? showOldPassword :
																item.name === "new_password" ? showPassword
																	: showConfirmPassword
														}
													/> :
													<>

														<>
															<Input
																item={{
																	label: t('profileScreenCountry') || STRINGS.REGISTER.INPUTS.COUNTRY,
																	name: "countryEdit",
																	type: "text",
																	onChange: (e) => filterList(e.target.value),
																	rules: [{name: "required"}, {name: "country"}],
																	value: itemSelected?.name.length > 0 ? itemSelected?.name : profile?.country?.name,
																}}

															/>
															{errorCountryMessage?.length >0 && <p style={{color:'red',position:'relative',bottom:'19px',fontSize:'10px'}}> {errorCountryMessage}</p>}

															<div className={'listCountryDesktop'}>
																{filteredList?.map((item: any, index: any) => (
																	// eslint-disable-next-line react/jsx-key
																	<div key={index+item.id} className={`${clicked === index}`}
																		 onClick={() => selectCountry(item, index)}
																		 style={{
																			 cursor: 'pointer',
																			 alignItems: 'center',
																			 textAlign: 'left'
																		 }}>

																		<img key={item.id} style={{width: '30px'}}
																			 className={'flag'}
																			 src={item.flag}
																			 alt={''}/> &emsp; <span
																		className={'itemOption'}>{item.name}</span>
																		<hr/>

																	</div>

																))}
															</div>
															<div style={{height: '30px'}}></div>
														</>


													</>


												}
												{item.name === t('pickNumberPlaceholder') && (
													<div>
														<PhoneInput
															name={'phone'}
															value={profile.phone}
															international={true}
															withCountryCallingCode={true}
															defaultCountry={'FR'}
															useNationalFormatForDefaultCountryValue={true}
															placeholder={t('phoneScreenInputLabel')}
															onChange={(e: any) => checkValidty(e)}/>

														<div style={{height: '30px'}}/>

													</div>
												)}
												{item.name === "new_password" && isError && (
													<div className="error-container center-vertically">
														{errorPassword?.map((error, i) => {
															if (error.icon === check) {
																return (
																	<div
																		key={i}
																		className="center-horizontally align-center"
																	>
																		<ImageContainer alt="check" src={error.icon}/>
																		<span
																			className={error.className}>{error.message}</span>
																	</div>
																);
															} else {
																null
															}
														})}
													</div>
												)}
												{selectedInputs.id === 2 && (<label
													className="verificationInfo">{t('updatePhoneScreenMessageValidation') || STRINGS.ACCOUNT.EDITPROFILE.CODE_VERIFICAION_MESSAGE}</label>)}

											</>
										);
									})}
									{errorMessagePassword.length > 0 && (
										<div className="error-margin-top card-content center-vertically">
											<p className="error">{errorMessagePassword}</p>
										</div>
									)}
									{
										errorMessagePhone.length > 0 && (
											<div style={{position:'relative',bottom:'15px',color:'red'}} className="error-margin-top card-content center-vertically">
												<p className="error">{errorMessagePhone}</p>
											</div>
										)
									}
									<div className="center-horizontally align-center action-container">
										<p onClick={onCancel}>{t('cancel') || STRINGS.CANCEL}</p>
										<button
											onClick={() => validate(name)}
											className={`btn-save`}
										>
											{t('validate') || STRINGS.VALIDATE}
										</button>
									</div>
								</div>
							</Card>
						) : null}
                    </div>
                    <div className={open ? "overlay" : "hide"}>
                        <OrderBottomsheet onClose={onCloseMenu} open={open}/>
                    </div>
                    <div className="blc-footer">
                        <Footer quantity={0} priceOrder={0} isHome={true} returnToRecap={openRecap}/>
                    </div>
                </div>
			}
		</>
	);
};
/* eslint-enable max-lines-per-function */

export default EditProfil;
