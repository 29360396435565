import React from 'react';
import { STRINGS } from '../../core/enums/strings';
import TextArea from '../text-area/TextArea';
import "./PersonalMsg.scss";
import {t} from "i18next";
import {useTranslation} from "react-i18next";
interface PersonalMessageProps {
    onChange: (message: string) => void;
}
const PersonalMsg = (props: PersonalMessageProps): JSX.Element => {
 const {t}=useTranslation()
    const name = t('orderSummaryYourPersonalizedMessagePlacehorder')
    return (
        <div className="msg-card">
            <h1 className="msg-title">{t('orderSummaryAddPersonalizedMessage')}</h1>
            <TextArea
                item={{
                    type: "text",
                    name: name,
                    id: 2,
                    onChange: (e) => {
                        props.onChange(e.target.value);
                    },
                }}
            />
        </div>
    );
};

export default PersonalMsg;
