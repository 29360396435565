import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import BackBtn from "../../../components/back-btn/BackBtn";
import Card from "../../../components/card/Card";
import MyMapComponent from "../../../components/map/Map";
import { STRINGS } from "../../../core/enums/strings";
import { store } from "../../../shared/store";
import { RootState } from "../../../shared/store/index";
import { isMobile } from "../../../shared/utils/utils-functions";
import AddAddressForm from "./add-address-form/AddAddressForm";
import "./MyAddresses.scss";
import {useTranslation} from "react-i18next";
// eslint-disable-next-line max-lines-per-function
const AddEditAddress = () => {
  
  const state = useLocation();
  const history = useNavigate();
  const {t} = useTranslation();
 
  return (
    <div>
      <div className="container-btn center-horizontally align-center">
        <BackBtn/>
        <p>{t('return') || STRINGS.BACK}</p>
      </div>
      <div className="center-horizontally address-content space-between">
        <Card>
          <AddAddressForm
            config={state.state}
            dissmissForm={() =>
              !isMobile()  ? history("/account/addresses") : history("/address")
            }
          />
        </Card>
        <MyMapComponent
          lng={Number(state.state?.position.longitude)}
          lat={Number(state.state?.position.latitude)}
          isMarkerShown
        />
      </div>
    </div>
  );
};
// eslint-enable-next-line max-lines-per-function
export default AddEditAddress;
